import {Typography, List, ListItem, ListItemText, useMediaQuery} from "@material-ui/core";
import {theme} from "../shared/styles";

export function SongResults(props: any) {
    
    if (props.numSongs > 0) {
        return (
            <List>
                {props.songResults.map((song: any) => SongLink(song, props.query, props))}
            </List>
        );
    } else {
        return (
            <List>
                <ListItem>
                    <ListItemText primary={<Typography>No songs found</Typography>}/>
                </ListItem>
            </List>
        );
    }
}

function SongLink(song: any, query: string, props: any) {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleListItemClick = (event: MouseEvent, song: any) => {
        props.setSelectedSongId(song.songMetadataId)
        if (isSmallScreen) {
            props.setIsDrawerOpen(false);
        }
    };

    let lyrics = null;
    if (query.length > 1) {
        lyrics = song.lyrics.split('\n')
            // filter out duplicate lines
            .filter((songLine: string , index: number) => song.lyrics.split('\n').indexOf(songLine) === index)
            // filter out lines with query/phrase
            .filter((songLine: string) => songLine.toLowerCase().includes(query.toLowerCase()))
            // output only the first three lines
            .slice(0,3)
            .map((songLine: string) => <Typography key={songLine}>{songLine}</Typography>);
    }

    return (
        <ListItem
            key={song.title} 
            selected={Number(props.selectedSongId) === song.songMetadataId}
            onClick={(event: any) => handleListItemClick(event, song)}
            button
            component="nav"
            >
            <ListItemText 
                key={song.title} 
                primary={<Typography key={song.title}>{song.title}</Typography>} 
                primaryTypographyProps={{component:'div'}}
                secondaryTypographyProps={{component:'div'}}
                secondary={lyrics}
            />
        </ListItem>
    );
}