{
  "pagination": {
    "ListBusinessReportSchedules": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListConferenceProviders": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListDeviceEvents": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListGatewayGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListGateways": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListSkills": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListSkillsStoreCategories": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListSkillsStoreSkillsByCategory": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListSmartHomeAppliances": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListTags": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "SearchAddressBooks": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "SearchContacts": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "SearchDevices": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "SearchNetworkProfiles": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "SearchProfiles": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "SearchRooms": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "SearchSkillGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "SearchUsers": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}
