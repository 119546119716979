{
  "pagination": {
    "ListComponentBuildVersions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "componentSummaryList"
    },
    "ListComponents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "componentVersionList"
    },
    "ListContainerRecipes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "containerRecipeSummaryList"
    },
    "ListDistributionConfigurations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "distributionConfigurationSummaryList"
    },
    "ListImageBuildVersions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "imageSummaryList"
    },
    "ListImagePackages": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "imagePackageList"
    },
    "ListImagePipelineImages": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "imageSummaryList"
    },
    "ListImagePipelines": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "imagePipelineList"
    },
    "ListImageRecipes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "imageRecipeSummaryList"
    },
    "ListImages": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "imageVersionList"
    },
    "ListInfrastructureConfigurations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "infrastructureConfigurationSummaryList"
    }
  }
}
