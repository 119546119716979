{
  "pagination": {
    "ListDatasetGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "DatasetGroups"
    },
    "ListDatasetImportJobs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "DatasetImportJobs"
    },
    "ListDatasets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Datasets"
    },
    "ListExplainabilities": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Explainabilities"
    },
    "ListExplainabilityExports": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ExplainabilityExports"
    },
    "ListForecastExportJobs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ForecastExportJobs"
    },
    "ListForecasts": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Forecasts"
    },
    "ListMonitorEvaluations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PredictorMonitorEvaluations"
    },
    "ListMonitors": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Monitors"
    },
    "ListPredictorBacktestExportJobs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PredictorBacktestExportJobs"
    },
    "ListPredictors": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Predictors"
    },
    "ListWhatIfAnalyses": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "WhatIfAnalyses"
    },
    "ListWhatIfForecastExports": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "WhatIfForecastExports"
    },
    "ListWhatIfForecasts": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "WhatIfForecasts"
    }
  }
}