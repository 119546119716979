{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-05-03",
    "endpointPrefix": "ssm-contacts",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "SSM Contacts",
    "serviceFullName": "AWS Systems Manager Incident Manager Contacts",
    "serviceId": "SSM Contacts",
    "signatureVersion": "v4",
    "signingName": "ssm-contacts",
    "targetPrefix": "SSMContacts",
    "uid": "ssm-contacts-2021-05-03"
  },
  "operations": {
    "AcceptPage": {
      "input": {
        "type": "structure",
        "required": [
          "PageId",
          "AcceptType",
          "AcceptCode"
        ],
        "members": {
          "PageId": {},
          "ContactChannelId": {},
          "AcceptType": {},
          "Note": {},
          "AcceptCode": {},
          "AcceptCodeValidation": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ActivateContactChannel": {
      "input": {
        "type": "structure",
        "required": [
          "ContactChannelId",
          "ActivationCode"
        ],
        "members": {
          "ContactChannelId": {},
          "ActivationCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateContact": {
      "input": {
        "type": "structure",
        "required": [
          "Alias",
          "Type",
          "Plan"
        ],
        "members": {
          "Alias": {},
          "DisplayName": {},
          "Type": {},
          "Plan": {
            "shape": "Sf"
          },
          "Tags": {
            "shape": "Sp"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ContactArn"
        ],
        "members": {
          "ContactArn": {}
        }
      }
    },
    "CreateContactChannel": {
      "input": {
        "type": "structure",
        "required": [
          "ContactId",
          "Name",
          "Type",
          "DeliveryAddress"
        ],
        "members": {
          "ContactId": {},
          "Name": {},
          "Type": {},
          "DeliveryAddress": {
            "shape": "Sy"
          },
          "DeferActivation": {
            "type": "boolean"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ContactChannelArn"
        ],
        "members": {
          "ContactChannelArn": {}
        }
      }
    },
    "DeactivateContactChannel": {
      "input": {
        "type": "structure",
        "required": [
          "ContactChannelId"
        ],
        "members": {
          "ContactChannelId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteContact": {
      "input": {
        "type": "structure",
        "required": [
          "ContactId"
        ],
        "members": {
          "ContactId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteContactChannel": {
      "input": {
        "type": "structure",
        "required": [
          "ContactChannelId"
        ],
        "members": {
          "ContactChannelId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeEngagement": {
      "input": {
        "type": "structure",
        "required": [
          "EngagementId"
        ],
        "members": {
          "EngagementId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ContactArn",
          "EngagementArn",
          "Sender",
          "Subject",
          "Content"
        ],
        "members": {
          "ContactArn": {},
          "EngagementArn": {},
          "Sender": {},
          "Subject": {},
          "Content": {},
          "PublicSubject": {},
          "PublicContent": {},
          "IncidentId": {},
          "StartTime": {
            "type": "timestamp"
          },
          "StopTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribePage": {
      "input": {
        "type": "structure",
        "required": [
          "PageId"
        ],
        "members": {
          "PageId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "PageArn",
          "EngagementArn",
          "ContactArn",
          "Sender",
          "Subject",
          "Content"
        ],
        "members": {
          "PageArn": {},
          "EngagementArn": {},
          "ContactArn": {},
          "Sender": {},
          "Subject": {},
          "Content": {},
          "PublicSubject": {},
          "PublicContent": {},
          "IncidentId": {},
          "SentTime": {
            "type": "timestamp"
          },
          "ReadTime": {
            "type": "timestamp"
          },
          "DeliveryTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetContact": {
      "input": {
        "type": "structure",
        "required": [
          "ContactId"
        ],
        "members": {
          "ContactId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ContactArn",
          "Alias",
          "Type",
          "Plan"
        ],
        "members": {
          "ContactArn": {},
          "Alias": {},
          "DisplayName": {},
          "Type": {},
          "Plan": {
            "shape": "Sf"
          }
        }
      }
    },
    "GetContactChannel": {
      "input": {
        "type": "structure",
        "required": [
          "ContactChannelId"
        ],
        "members": {
          "ContactChannelId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ContactArn",
          "ContactChannelArn",
          "Name",
          "Type",
          "DeliveryAddress"
        ],
        "members": {
          "ContactArn": {},
          "ContactChannelArn": {},
          "Name": {},
          "Type": {},
          "DeliveryAddress": {
            "shape": "Sy"
          },
          "ActivationStatus": {}
        }
      }
    },
    "GetContactPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ContactArn"
        ],
        "members": {
          "ContactArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactArn": {},
          "Policy": {}
        }
      }
    },
    "ListContactChannels": {
      "input": {
        "type": "structure",
        "required": [
          "ContactId"
        ],
        "members": {
          "ContactId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ContactChannels"
        ],
        "members": {
          "NextToken": {},
          "ContactChannels": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ContactChannelArn",
                "ContactArn",
                "Name",
                "DeliveryAddress",
                "ActivationStatus"
              ],
              "members": {
                "ContactChannelArn": {},
                "ContactArn": {},
                "Name": {},
                "Type": {},
                "DeliveryAddress": {
                  "shape": "Sy"
                },
                "ActivationStatus": {}
              }
            }
          }
        }
      }
    },
    "ListContacts": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "AliasPrefix": {},
          "Type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Contacts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ContactArn",
                "Alias",
                "Type"
              ],
              "members": {
                "ContactArn": {},
                "Alias": {},
                "DisplayName": {},
                "Type": {}
              }
            }
          }
        }
      }
    },
    "ListEngagements": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "IncidentId": {},
          "TimeRangeValue": {
            "type": "structure",
            "members": {
              "StartTime": {
                "type": "timestamp"
              },
              "EndTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Engagements"
        ],
        "members": {
          "NextToken": {},
          "Engagements": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "EngagementArn",
                "ContactArn",
                "Sender"
              ],
              "members": {
                "EngagementArn": {},
                "ContactArn": {},
                "Sender": {},
                "IncidentId": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "StopTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListPageReceipts": {
      "input": {
        "type": "structure",
        "required": [
          "PageId"
        ],
        "members": {
          "PageId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Receipts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ReceiptType",
                "ReceiptTime"
              ],
              "members": {
                "ContactChannelArn": {},
                "ReceiptType": {},
                "ReceiptInfo": {},
                "ReceiptTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListPagesByContact": {
      "input": {
        "type": "structure",
        "required": [
          "ContactId"
        ],
        "members": {
          "ContactId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Pages"
        ],
        "members": {
          "NextToken": {},
          "Pages": {
            "shape": "S2d"
          }
        }
      }
    },
    "ListPagesByEngagement": {
      "input": {
        "type": "structure",
        "required": [
          "EngagementId"
        ],
        "members": {
          "EngagementId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Pages"
        ],
        "members": {
          "NextToken": {},
          "Pages": {
            "shape": "S2d"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sp"
          }
        }
      }
    },
    "PutContactPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ContactArn",
          "Policy"
        ],
        "members": {
          "ContactArn": {},
          "Policy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SendActivationCode": {
      "input": {
        "type": "structure",
        "required": [
          "ContactChannelId"
        ],
        "members": {
          "ContactChannelId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartEngagement": {
      "input": {
        "type": "structure",
        "required": [
          "ContactId",
          "Sender",
          "Subject",
          "Content"
        ],
        "members": {
          "ContactId": {},
          "Sender": {},
          "Subject": {},
          "Content": {},
          "PublicSubject": {},
          "PublicContent": {},
          "IncidentId": {},
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EngagementArn"
        ],
        "members": {
          "EngagementArn": {}
        }
      }
    },
    "StopEngagement": {
      "input": {
        "type": "structure",
        "required": [
          "EngagementId"
        ],
        "members": {
          "EngagementId": {},
          "Reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateContact": {
      "input": {
        "type": "structure",
        "required": [
          "ContactId"
        ],
        "members": {
          "ContactId": {},
          "DisplayName": {},
          "Plan": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateContactChannel": {
      "input": {
        "type": "structure",
        "required": [
          "ContactChannelId"
        ],
        "members": {
          "ContactChannelId": {},
          "Name": {},
          "DeliveryAddress": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "Sf": {
      "type": "structure",
      "required": [
        "Stages"
      ],
      "members": {
        "Stages": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DurationInMinutes",
              "Targets"
            ],
            "members": {
              "DurationInMinutes": {
                "type": "integer"
              },
              "Targets": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "ChannelTargetInfo": {
                      "type": "structure",
                      "required": [
                        "ContactChannelId"
                      ],
                      "members": {
                        "ContactChannelId": {},
                        "RetryIntervalInMinutes": {
                          "type": "integer"
                        }
                      }
                    },
                    "ContactTargetInfo": {
                      "type": "structure",
                      "required": [
                        "IsEssential"
                      ],
                      "members": {
                        "ContactId": {},
                        "IsEssential": {
                          "type": "boolean"
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "SimpleAddress": {}
      }
    },
    "S2d": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "PageArn",
          "EngagementArn",
          "ContactArn",
          "Sender"
        ],
        "members": {
          "PageArn": {},
          "EngagementArn": {},
          "ContactArn": {},
          "Sender": {},
          "IncidentId": {},
          "SentTime": {
            "type": "timestamp"
          },
          "DeliveryTime": {
            "type": "timestamp"
          },
          "ReadTime": {
            "type": "timestamp"
          }
        }
      }
    }
  }
}