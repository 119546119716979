import { Auth } from "../components/core/Auth";
import appconfig from "../appconfig";
import moment from "moment";

const API_ADDRESS = appconfig.expenseReportUrl;

const POST_REPORT_PATH = '/insert';
const GET_REPORTS_BY_REQUESTER_PATH = '/requester/'
const PUT_REPORT_STATUS_PATH = '/status';
const PUT_MARK_AS_PAID_PATH = '/MarkAsPaid';
const GET_REPORT_APPROVERS_DICTIONARY = "/approversDictionary/";

// Default headers for POST requests
const headers = new Headers()
headers.append('Content-Type', 'application/json')

export const postReport = async (data) => {
  const response = await fetch(API_ADDRESS + POST_REPORT_PATH, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers,
  });
  
  return await Auth.handleResponse(response);
}

export const getReports = async (requester, startDate, endDate) => {
  startDate = moment(startDate).utc();
  endDate = moment(endDate).utc();
  
  const url = new URL(API_ADDRESS + GET_REPORTS_BY_REQUESTER_PATH + requester);
  url.searchParams.append("startDate", startDate.format("YYYY-MM-DDTHH:mm:ss"));
  url.searchParams.append("endDate", endDate.format("YYYY-MM-DDTHH:mm:ss"));
  
  const response = await fetch(url, { 
    credentials: 'include',
  });
    
  return await Auth.handleResponse(response);
}

export const getApproversDictionary = async () => {
  const response = await fetch(API_ADDRESS + GET_REPORT_APPROVERS_DICTIONARY, {
    credentials: 'include',
  });
  
  return await Auth.handleResponse(response);
};

export const putReportStatus = async (id, status, notes) => {
  const url = new URL(API_ADDRESS + PUT_REPORT_STATUS_PATH);
  url.searchParams.append("id", id);
  url.searchParams.append("status", status);
  
  if (notes) {
    url.searchParams.append("notes", notes);
  }
  
  const response = await fetch(url, {
    credentials: 'include',
    method: "PUT",
  });
  
  return await Auth.handleResponse(response);
};

export const markAsPaid = async (id, checkNumber, checkDate) => {
  const url = new URL(API_ADDRESS + PUT_MARK_AS_PAID_PATH);
  url.searchParams.append("id", id);
  url.searchParams.append("checkNumber", checkNumber);
  url.searchParams.append("checkDate", moment(checkDate).format("YYYY-MM-DD"));

  const response = await fetch(url, {
    credentials: 'include',
    method: "PUT",
  });
  
  return await Auth.handleResponse(response);
};
