import React from 'react';
import {
  Redirect,
  Route,
} from "react-router-dom";

import { Auth } from './Auth';

function PrivateRoute({ component: Component, ...rest }) {

  return (
    <Route
      {...rest}
      render={props => <Component {...props} />}
    />
  );
}

export { PrivateRoute };