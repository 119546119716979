{
  "pagination": {
    "ListAWSDefaultServiceQuotas": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Quotas"
    },
    "ListRequestedServiceQuotaChangeHistory": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "RequestedQuotas"
    },
    "ListRequestedServiceQuotaChangeHistoryByQuota": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "RequestedQuotas"
    },
    "ListServiceQuotaIncreaseRequestsInTemplate": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ServiceQuotaIncreaseRequestInTemplateList"
    },
    "ListServiceQuotas": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Quotas"
    },
    "ListServices": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Services"
    }
  }
}