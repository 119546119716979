import React, {useEffect, useState, useCallback} from "react";
import {Link} from "react-router-dom";
import classNames from 'classnames';
import useUrlState from "@ahooksjs/use-url-state";
import "@material-ui/core";
import {CircularProgress, Divider, Drawer, Fab, Grid, Hidden, MuiThemeProvider, Toolbar} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import {getSongbyIndex, getSongContentsbySearch} from '../../../api/song';
import {Search} from "../shared/Search";
import {SongResults} from "./SongResults";
import {styles, theme} from "../shared/styles";
import {Index} from "./Index";
import {Song} from "./Song";

/** Default drawer width in pixels. */
const DEFAULT_DRAWER_WIDTH_PX = 360;

/** Minimal drawer width in pixels. */
const MIN_DRAWER_WIDTH_PX = 200;

/** Maximal drawer width in pixels. */
const MAX_DRAWER_WIDTH_PX = 1000;

/** Index width in pixels. */
const INDEX_WIDTH_PX = 53;

export default function Songs() {
    const classes = styles();
    const [songResults, setSongResults] = useState([] as any[]);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [urlState, setUrlState] = useUrlState({index: '', songId: '', search: ''});
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [savedDrawerWidth, setSavedDrawerWidth] = useState(DEFAULT_DRAWER_WIDTH_PX);
    const [drawerWidth, setDrawerWidth] = useState(savedDrawerWidth);
    const [isDragging, setIsDragging] = useState(false);

    // Selected index synced to url query param 'index'.
    const setIndex = (index: string) => {
        setUrlState({...urlState, index});
    }

    // Selected song ID synced to url query param 'songId'.
    const setSongId = (songId: number) => {
        setUrlState({...urlState, songId});
    }

    // Selected search keyword synced to url query param 'search'.
    const setSearch = (search: string) => {
        setUrlState({...urlState, search});
    }

    // Handle dragger mouse down event.
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
        setIsDragging(true);
        e.preventDefault();
      };
    
    // Handle dragger mouse up event.
    const handleMouseUp = (e: MouseEvent) => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
        setIsDragging(false);
        e.preventDefault();
    };

    // Handle dragger mouse move event.
    const handleMouseMove = useCallback(e => {
        const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > MIN_DRAWER_WIDTH_PX && newWidth < MAX_DRAWER_WIDTH_PX) {
            setSavedDrawerWidth(newWidth);
        }
        e.preventDefault();
    }, []);

    // Load song list based on index
    async function getSongByIndex(alphabet: string) {
        if (alphabet === "Numbers") { alphabet = '#' }
        let songs = await getSongbyIndex(alphabet);
        setSongResults(songs);
        setLoadingStatus(false);
    }

    // Load song list based on search keyword
    async function getSongByWord(word: string) {
        let songs = (word) ? await getSongContentsbySearch(word) : null;
        setSongResults(songs);
        setLoadingStatus(false);
    }

    // Load song list based on selected index/search
    useEffect(() => {
        setLoadingStatus(true)
        if (urlState.index) {
            getSongByIndex(urlState.index)
        } else {
            getSongByWord(urlState.search)
        }
    }, [urlState.index, urlState.search]);

    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    useEffect(() => {
        if (isDrawerOpen) {
            // Restore saved drawer width when opened.
            setDrawerWidth(savedDrawerWidth);
        } else {
            // Set drawer width to index width when drawer is closed.
            setDrawerWidth(INDEX_WIDTH_PX);
        }
    }, [savedDrawerWidth, isDrawerOpen]);

    // Classes for drawer and its child paper container
    const drawerClasses = classNames(classes.drawer, {
        [classes.enterAnimation]: !isDragging && isDrawerOpen,
        [classes.leaveAnimation]: !isDragging && !isDrawerOpen,
    });

    // Styling for drawer and its child paper container
    const drawerStyle = {width: drawerWidth};

    // Dragger to manually set left panel width
    const dragger = (
        <div onMouseDown={e => handleMouseDown(e)}
             className={classes.dragger} />
    );
    
    const drawer = (
        <Grid container
              direction="row"
              className={classes.drawerContent}
              style={{width: savedDrawerWidth}}>
            <Index selectedIndex={urlState.index}
                   setSelectedIndex={setIndex}
                   setSearchText={setSearch}
                   isDrawerOpen={isDrawerOpen}
                   setIsDrawerOpen={setIsDrawerOpen}
                   handleDrawerToggle={handleDrawerToggle}/>
            <Divider orientation="vertical" flexItem />
            <Grid container
                  direction="column"
                  className={classes.listContainer}
                  alignItems="stretch">
                {!urlState.index &&
                    <Search placeholder="Search by title or content"
                            searchText={urlState.search}
                            setSearchText={setSearch}
                            setSelectedIndex={setIndex}/>}
                {loadingStatus ? (
                    <Grid container justifyContent="center">
                        <CircularProgress className={classes.listLoading}/>
                    </Grid>
                ) : (
                    <SongResults
                        className={classes.list}
                        songResults={songResults} 
                        numSongs={songResults ? songResults.length : 0} 
                        query={urlState.index ? "" : urlState.search}
                        selectedSongId={urlState.songId}
                        setSelectedSongId={setSongId}
                        setIsDrawerOpen={setIsDrawerOpen}/> 
                )}
            </Grid>
        </Grid>
    );

    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.drawerContainer}>
                <Drawer variant="permanent"
                        className={drawerClasses}
                        PaperProps={{style: drawerStyle}}
                        style={drawerStyle}>
                    <Toolbar/>
                    {/* Hide dragger for small screen size / closed drawer. */}
                    {isDrawerOpen && (
                        <Hidden xsDown>{dragger}</Hidden>
                    )}
                    {drawer}
                </Drawer>
                <main className={classes.main}>
                    {/* Hide song for small screen & closed drawer. */}
                    <Hidden xsDown>
                        <Song selectedSongId={urlState.songId}/>
                    </Hidden>
                    <Hidden smUp>
                        {!isDrawerOpen && (
                            <Song selectedSongId={urlState.songId}/>
                        )}
                    </Hidden>
                </main>
                <Fab aria-label="add" 
                     className={classes.fab} 
                     color="secondary"
                     component={Link}
                     to="/worship/songs/insert">
                    <AddIcon />
                </Fab>
            </div>
        </MuiThemeProvider>
    );
}