{
  "pagination": {
    "DescribeObjects": {
      "input_token": "marker",
      "more_results": "hasMoreResults",
      "output_token": "marker",
      "result_key": "pipelineObjects"
    },
    "DescribePipelines": {
      "result_key": "pipelineDescriptionList"
    },
    "ListPipelines": {
      "input_token": "marker",
      "more_results": "hasMoreResults",
      "output_token": "marker",
      "result_key": "pipelineIdList"
    },
    "QueryObjects": {
      "input_token": "marker",
      "limit_key": "limit",
      "more_results": "hasMoreResults",
      "output_token": "marker",
      "result_key": "ids"
    }
  }
}