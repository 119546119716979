{
  "pagination": {
    "ListFirewallConfigs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "FirewallConfigs"
    },
    "ListFirewallDomainLists": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "FirewallDomainLists"
    },
    "ListFirewallDomains": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Domains"
    },
    "ListFirewallRuleGroupAssociations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "FirewallRuleGroupAssociations"
    },
    "ListFirewallRuleGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "FirewallRuleGroups"
    },
    "ListFirewallRules": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "FirewallRules"
    },
    "ListResolverConfigs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ResolverConfigs"
    },
    "ListResolverDnssecConfigs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ResolverDnssecConfigs"
    },
    "ListResolverEndpointIpAddresses": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "IpAddresses"
    },
    "ListResolverEndpoints": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ResolverEndpoints"
    },
    "ListResolverQueryLogConfigAssociations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ResolverQueryLogConfigAssociations"
    },
    "ListResolverQueryLogConfigs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ResolverQueryLogConfigs"
    },
    "ListResolverRuleAssociations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ResolverRuleAssociations"
    },
    "ListResolverRules": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ResolverRules"
    },
    "ListTagsForResource": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Tags"
    }
  }
}
