{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-04-20",
    "endpointPrefix": "identity-chime",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Chime SDK Identity",
    "serviceId": "Chime SDK Identity",
    "signatureVersion": "v4",
    "signingName": "chime",
    "uid": "chime-sdk-identity-2021-04-20"
  },
  "operations": {
    "CreateAppInstance": {
      "http": {
        "requestUri": "/app-instances",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ClientRequestToken"
        ],
        "members": {
          "Name": {
            "shape": "S2"
          },
          "Metadata": {
            "shape": "S3"
          },
          "ClientRequestToken": {
            "shape": "S4",
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceArn": {}
        }
      }
    },
    "CreateAppInstanceAdmin": {
      "http": {
        "requestUri": "/app-instances/{appInstanceArn}/admins",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceAdminArn",
          "AppInstanceArn"
        ],
        "members": {
          "AppInstanceAdminArn": {},
          "AppInstanceArn": {
            "location": "uri",
            "locationName": "appInstanceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceAdmin": {
            "shape": "Sd"
          },
          "AppInstanceArn": {}
        }
      }
    },
    "CreateAppInstanceUser": {
      "http": {
        "requestUri": "/app-instance-users",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceArn",
          "AppInstanceUserId",
          "Name",
          "ClientRequestToken"
        ],
        "members": {
          "AppInstanceArn": {},
          "AppInstanceUserId": {
            "type": "string",
            "sensitive": true
          },
          "Name": {
            "shape": "Sh"
          },
          "Metadata": {
            "shape": "S3"
          },
          "ClientRequestToken": {
            "shape": "S4",
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceUserArn": {}
        }
      }
    },
    "DeleteAppInstance": {
      "http": {
        "method": "DELETE",
        "requestUri": "/app-instances/{appInstanceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceArn"
        ],
        "members": {
          "AppInstanceArn": {
            "location": "uri",
            "locationName": "appInstanceArn"
          }
        }
      }
    },
    "DeleteAppInstanceAdmin": {
      "http": {
        "method": "DELETE",
        "requestUri": "/app-instances/{appInstanceArn}/admins/{appInstanceAdminArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceAdminArn",
          "AppInstanceArn"
        ],
        "members": {
          "AppInstanceAdminArn": {
            "location": "uri",
            "locationName": "appInstanceAdminArn"
          },
          "AppInstanceArn": {
            "location": "uri",
            "locationName": "appInstanceArn"
          }
        }
      }
    },
    "DeleteAppInstanceUser": {
      "http": {
        "method": "DELETE",
        "requestUri": "/app-instance-users/{appInstanceUserArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceUserArn"
        ],
        "members": {
          "AppInstanceUserArn": {
            "location": "uri",
            "locationName": "appInstanceUserArn"
          }
        }
      }
    },
    "DeregisterAppInstanceUserEndpoint": {
      "http": {
        "method": "DELETE",
        "requestUri": "/app-instance-users/{appInstanceUserArn}/endpoints/{endpointId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceUserArn",
          "EndpointId"
        ],
        "members": {
          "AppInstanceUserArn": {
            "shape": "Sn",
            "location": "uri",
            "locationName": "appInstanceUserArn"
          },
          "EndpointId": {
            "shape": "So",
            "location": "uri",
            "locationName": "endpointId"
          }
        }
      }
    },
    "DescribeAppInstance": {
      "http": {
        "method": "GET",
        "requestUri": "/app-instances/{appInstanceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceArn"
        ],
        "members": {
          "AppInstanceArn": {
            "location": "uri",
            "locationName": "appInstanceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstance": {
            "type": "structure",
            "members": {
              "AppInstanceArn": {},
              "Name": {
                "shape": "S2"
              },
              "CreatedTimestamp": {
                "type": "timestamp"
              },
              "LastUpdatedTimestamp": {
                "type": "timestamp"
              },
              "Metadata": {
                "shape": "S3"
              }
            }
          }
        }
      }
    },
    "DescribeAppInstanceAdmin": {
      "http": {
        "method": "GET",
        "requestUri": "/app-instances/{appInstanceArn}/admins/{appInstanceAdminArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceAdminArn",
          "AppInstanceArn"
        ],
        "members": {
          "AppInstanceAdminArn": {
            "location": "uri",
            "locationName": "appInstanceAdminArn"
          },
          "AppInstanceArn": {
            "location": "uri",
            "locationName": "appInstanceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceAdmin": {
            "type": "structure",
            "members": {
              "Admin": {
                "shape": "Sd"
              },
              "AppInstanceArn": {},
              "CreatedTimestamp": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeAppInstanceUser": {
      "http": {
        "method": "GET",
        "requestUri": "/app-instance-users/{appInstanceUserArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceUserArn"
        ],
        "members": {
          "AppInstanceUserArn": {
            "location": "uri",
            "locationName": "appInstanceUserArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceUser": {
            "type": "structure",
            "members": {
              "AppInstanceUserArn": {},
              "Name": {
                "shape": "Sh"
              },
              "Metadata": {
                "shape": "S3"
              },
              "CreatedTimestamp": {
                "type": "timestamp"
              },
              "LastUpdatedTimestamp": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeAppInstanceUserEndpoint": {
      "http": {
        "method": "GET",
        "requestUri": "/app-instance-users/{appInstanceUserArn}/endpoints/{endpointId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceUserArn",
          "EndpointId"
        ],
        "members": {
          "AppInstanceUserArn": {
            "shape": "S10",
            "location": "uri",
            "locationName": "appInstanceUserArn"
          },
          "EndpointId": {
            "shape": "So",
            "location": "uri",
            "locationName": "endpointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceUserEndpoint": {
            "type": "structure",
            "members": {
              "AppInstanceUserArn": {
                "shape": "Sn"
              },
              "EndpointId": {
                "shape": "So"
              },
              "Name": {
                "shape": "S10"
              },
              "Type": {},
              "ResourceArn": {
                "shape": "Sn"
              },
              "EndpointAttributes": {
                "shape": "S14"
              },
              "CreatedTimestamp": {
                "type": "timestamp"
              },
              "LastUpdatedTimestamp": {
                "type": "timestamp"
              },
              "AllowMessages": {},
              "EndpointState": {
                "shape": "S17"
              }
            }
          }
        }
      }
    },
    "GetAppInstanceRetentionSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/app-instances/{appInstanceArn}/retention-settings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceArn"
        ],
        "members": {
          "AppInstanceArn": {
            "location": "uri",
            "locationName": "appInstanceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceRetentionSettings": {
            "shape": "S1c"
          },
          "InitiateDeletionTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "ListAppInstanceAdmins": {
      "http": {
        "method": "GET",
        "requestUri": "/app-instances/{appInstanceArn}/admins",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceArn"
        ],
        "members": {
          "AppInstanceArn": {
            "location": "uri",
            "locationName": "appInstanceArn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S1h",
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceArn": {},
          "AppInstanceAdmins": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Admin": {
                  "shape": "Sd"
                }
              }
            }
          },
          "NextToken": {
            "shape": "S1h"
          }
        }
      }
    },
    "ListAppInstanceUserEndpoints": {
      "http": {
        "method": "GET",
        "requestUri": "/app-instance-users/{appInstanceUserArn}/endpoints",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceUserArn"
        ],
        "members": {
          "AppInstanceUserArn": {
            "shape": "Sn",
            "location": "uri",
            "locationName": "appInstanceUserArn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S1h",
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceUserEndpoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AppInstanceUserArn": {
                  "shape": "Sn"
                },
                "EndpointId": {
                  "shape": "So"
                },
                "Name": {
                  "shape": "S10"
                },
                "Type": {},
                "AllowMessages": {},
                "EndpointState": {
                  "shape": "S17"
                }
              }
            }
          },
          "NextToken": {
            "shape": "S1h"
          }
        }
      }
    },
    "ListAppInstanceUsers": {
      "http": {
        "method": "GET",
        "requestUri": "/app-instance-users"
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceArn"
        ],
        "members": {
          "AppInstanceArn": {
            "location": "querystring",
            "locationName": "app-instance-arn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S1h",
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceArn": {},
          "AppInstanceUsers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AppInstanceUserArn": {},
                "Name": {
                  "shape": "Sh"
                },
                "Metadata": {
                  "shape": "S3"
                }
              }
            }
          },
          "NextToken": {
            "shape": "S1h"
          }
        }
      }
    },
    "ListAppInstances": {
      "http": {
        "method": "GET",
        "requestUri": "/app-instances"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "shape": "S1h",
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AppInstanceArn": {},
                "Name": {
                  "shape": "S2"
                },
                "Metadata": {
                  "shape": "S3"
                }
              }
            }
          },
          "NextToken": {
            "shape": "S1h"
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {
            "location": "querystring",
            "locationName": "arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S5"
          }
        }
      }
    },
    "PutAppInstanceRetentionSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/app-instances/{appInstanceArn}/retention-settings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceArn",
          "AppInstanceRetentionSettings"
        ],
        "members": {
          "AppInstanceArn": {
            "location": "uri",
            "locationName": "appInstanceArn"
          },
          "AppInstanceRetentionSettings": {
            "shape": "S1c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceRetentionSettings": {
            "shape": "S1c"
          },
          "InitiateDeletionTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "RegisterAppInstanceUserEndpoint": {
      "http": {
        "requestUri": "/app-instance-users/{appInstanceUserArn}/endpoints",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceUserArn",
          "Type",
          "ResourceArn",
          "EndpointAttributes",
          "ClientRequestToken"
        ],
        "members": {
          "AppInstanceUserArn": {
            "shape": "Sn",
            "location": "uri",
            "locationName": "appInstanceUserArn"
          },
          "Name": {
            "shape": "S10"
          },
          "Type": {},
          "ResourceArn": {
            "shape": "Sn"
          },
          "EndpointAttributes": {
            "shape": "S14"
          },
          "ClientRequestToken": {
            "shape": "S4",
            "idempotencyToken": true
          },
          "AllowMessages": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceUserArn": {
            "shape": "Sn"
          },
          "EndpointId": {
            "shape": "So"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags?operation=tag-resource",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S5"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/tags?operation=untag-resource",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {
              "shape": "S7"
            }
          }
        }
      }
    },
    "UpdateAppInstance": {
      "http": {
        "method": "PUT",
        "requestUri": "/app-instances/{appInstanceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceArn",
          "Name",
          "Metadata"
        ],
        "members": {
          "AppInstanceArn": {
            "location": "uri",
            "locationName": "appInstanceArn"
          },
          "Name": {
            "shape": "S2"
          },
          "Metadata": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceArn": {}
        }
      }
    },
    "UpdateAppInstanceUser": {
      "http": {
        "method": "PUT",
        "requestUri": "/app-instance-users/{appInstanceUserArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceUserArn",
          "Name",
          "Metadata"
        ],
        "members": {
          "AppInstanceUserArn": {
            "location": "uri",
            "locationName": "appInstanceUserArn"
          },
          "Name": {
            "shape": "Sh"
          },
          "Metadata": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceUserArn": {}
        }
      }
    },
    "UpdateAppInstanceUserEndpoint": {
      "http": {
        "method": "PUT",
        "requestUri": "/app-instance-users/{appInstanceUserArn}/endpoints/{endpointId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AppInstanceUserArn",
          "EndpointId"
        ],
        "members": {
          "AppInstanceUserArn": {
            "shape": "Sn",
            "location": "uri",
            "locationName": "appInstanceUserArn"
          },
          "EndpointId": {
            "shape": "So",
            "location": "uri",
            "locationName": "endpointId"
          },
          "Name": {
            "shape": "S10"
          },
          "AllowMessages": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppInstanceUserArn": {
            "shape": "Sn"
          },
          "EndpointId": {
            "shape": "So"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "string",
      "sensitive": true
    },
    "S3": {
      "type": "string",
      "sensitive": true
    },
    "S4": {
      "type": "string",
      "sensitive": true
    },
    "S5": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {
            "shape": "S7"
          },
          "Value": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "S7": {
      "type": "string",
      "sensitive": true
    },
    "Sd": {
      "type": "structure",
      "members": {
        "Arn": {},
        "Name": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "Sh": {
      "type": "string",
      "sensitive": true
    },
    "Sn": {
      "type": "string",
      "sensitive": true
    },
    "So": {
      "type": "string",
      "sensitive": true
    },
    "S10": {
      "type": "string",
      "sensitive": true
    },
    "S14": {
      "type": "structure",
      "required": [
        "DeviceToken"
      ],
      "members": {
        "DeviceToken": {
          "shape": "S15"
        },
        "VoipDeviceToken": {
          "shape": "S15"
        }
      }
    },
    "S15": {
      "type": "string",
      "sensitive": true
    },
    "S17": {
      "type": "structure",
      "required": [
        "Status"
      ],
      "members": {
        "Status": {},
        "StatusReason": {}
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "ChannelRetentionSettings": {
          "type": "structure",
          "members": {
            "RetentionDays": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S1h": {
      "type": "string",
      "sensitive": true
    }
  }
}