{
  "pagination": {
    "ListClientDevicesAssociatedWithCoreDevice": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "associatedClientDevices"
    },
    "ListComponentVersions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "componentVersions"
    },
    "ListComponents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "components"
    },
    "ListCoreDevices": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "coreDevices"
    },
    "ListDeployments": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "deployments"
    },
    "ListEffectiveDeployments": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "effectiveDeployments"
    },
    "ListInstalledComponents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "installedComponents"
    }
  }
}
