{
  "pagination" : {
    "ListEntitlements" : {
      "input_token" : "NextToken",
      "output_token" : "NextToken",
      "limit_key" : "MaxResults",
      "result_key" : "Entitlements"
    },
    "ListFlows" : {
      "input_token" : "NextToken",
      "output_token" : "NextToken",
      "limit_key" : "MaxResults",
      "result_key" : "Flows"
    },
    "ListOfferings" : {
      "input_token" : "NextToken",
      "output_token" : "NextToken",
      "limit_key" : "MaxResults",
      "result_key" : "Offerings"
    },
    "ListReservations" : {
      "input_token" : "NextToken",
      "output_token" : "NextToken",
      "limit_key" : "MaxResults",
      "result_key" : "Reservations"
    }
  }
}
