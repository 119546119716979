{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-10-19",
    "endpointPrefix": "wisdom",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Connect Wisdom Service",
    "serviceId": "Wisdom",
    "signatureVersion": "v4",
    "signingName": "wisdom",
    "uid": "wisdom-2020-10-19"
  },
  "operations": {
    "CreateAssistant": {
      "http": {
        "requestUri": "/assistants",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "type"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {},
          "name": {},
          "serverSideEncryptionConfiguration": {
            "shape": "S5"
          },
          "tags": {
            "shape": "S7"
          },
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "assistant": {
            "shape": "Sc"
          }
        }
      },
      "idempotent": true
    },
    "CreateAssistantAssociation": {
      "http": {
        "requestUri": "/assistants/{assistantId}/associations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assistantId",
          "association",
          "associationType"
        ],
        "members": {
          "assistantId": {
            "location": "uri",
            "locationName": "assistantId"
          },
          "association": {
            "type": "structure",
            "members": {
              "knowledgeBaseId": {}
            },
            "union": true
          },
          "associationType": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "assistantAssociation": {
            "shape": "Sl"
          }
        }
      },
      "idempotent": true
    },
    "CreateContent": {
      "http": {
        "requestUri": "/knowledgeBases/{knowledgeBaseId}/contents",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "knowledgeBaseId",
          "name",
          "uploadId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          },
          "metadata": {
            "shape": "Sp"
          },
          "name": {},
          "overrideLinkOutUri": {},
          "tags": {
            "shape": "S7"
          },
          "title": {},
          "uploadId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "content": {
            "shape": "St"
          }
        }
      },
      "idempotent": true
    },
    "CreateKnowledgeBase": {
      "http": {
        "requestUri": "/knowledgeBases",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "knowledgeBaseType",
          "name"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {},
          "knowledgeBaseType": {},
          "name": {},
          "renderingConfiguration": {
            "shape": "S10"
          },
          "serverSideEncryptionConfiguration": {
            "shape": "S5"
          },
          "sourceConfiguration": {
            "shape": "S11"
          },
          "tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "knowledgeBase": {
            "shape": "S16"
          }
        }
      },
      "idempotent": true
    },
    "CreateSession": {
      "http": {
        "requestUri": "/assistants/{assistantId}/sessions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assistantId",
          "name"
        ],
        "members": {
          "assistantId": {
            "location": "uri",
            "locationName": "assistantId"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {},
          "name": {},
          "tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "session": {
            "shape": "S1a"
          }
        }
      },
      "idempotent": true
    },
    "DeleteAssistant": {
      "http": {
        "method": "DELETE",
        "requestUri": "/assistants/{assistantId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "assistantId"
        ],
        "members": {
          "assistantId": {
            "location": "uri",
            "locationName": "assistantId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteAssistantAssociation": {
      "http": {
        "method": "DELETE",
        "requestUri": "/assistants/{assistantId}/associations/{assistantAssociationId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "assistantAssociationId",
          "assistantId"
        ],
        "members": {
          "assistantAssociationId": {
            "location": "uri",
            "locationName": "assistantAssociationId"
          },
          "assistantId": {
            "location": "uri",
            "locationName": "assistantId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteContent": {
      "http": {
        "method": "DELETE",
        "requestUri": "/knowledgeBases/{knowledgeBaseId}/contents/{contentId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "contentId",
          "knowledgeBaseId"
        ],
        "members": {
          "contentId": {
            "location": "uri",
            "locationName": "contentId"
          },
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteKnowledgeBase": {
      "http": {
        "method": "DELETE",
        "requestUri": "/knowledgeBases/{knowledgeBaseId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "knowledgeBaseId"
        ],
        "members": {
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetAssistant": {
      "http": {
        "method": "GET",
        "requestUri": "/assistants/{assistantId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assistantId"
        ],
        "members": {
          "assistantId": {
            "location": "uri",
            "locationName": "assistantId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "assistant": {
            "shape": "Sc"
          }
        }
      }
    },
    "GetAssistantAssociation": {
      "http": {
        "method": "GET",
        "requestUri": "/assistants/{assistantId}/associations/{assistantAssociationId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assistantAssociationId",
          "assistantId"
        ],
        "members": {
          "assistantAssociationId": {
            "location": "uri",
            "locationName": "assistantAssociationId"
          },
          "assistantId": {
            "location": "uri",
            "locationName": "assistantId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "assistantAssociation": {
            "shape": "Sl"
          }
        }
      }
    },
    "GetContent": {
      "http": {
        "method": "GET",
        "requestUri": "/knowledgeBases/{knowledgeBaseId}/contents/{contentId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "contentId",
          "knowledgeBaseId"
        ],
        "members": {
          "contentId": {
            "location": "uri",
            "locationName": "contentId"
          },
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "content": {
            "shape": "St"
          }
        }
      }
    },
    "GetContentSummary": {
      "http": {
        "method": "GET",
        "requestUri": "/knowledgeBases/{knowledgeBaseId}/contents/{contentId}/summary",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "contentId",
          "knowledgeBaseId"
        ],
        "members": {
          "contentId": {
            "location": "uri",
            "locationName": "contentId"
          },
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "contentSummary": {
            "shape": "S1r"
          }
        }
      }
    },
    "GetKnowledgeBase": {
      "http": {
        "method": "GET",
        "requestUri": "/knowledgeBases/{knowledgeBaseId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "knowledgeBaseId"
        ],
        "members": {
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "knowledgeBase": {
            "shape": "S16"
          }
        }
      }
    },
    "GetRecommendations": {
      "http": {
        "method": "GET",
        "requestUri": "/assistants/{assistantId}/sessions/{sessionId}/recommendations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assistantId",
          "sessionId"
        ],
        "members": {
          "assistantId": {
            "location": "uri",
            "locationName": "assistantId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          },
          "waitTimeSeconds": {
            "location": "querystring",
            "locationName": "waitTimeSeconds",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "recommendations"
        ],
        "members": {
          "recommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "document",
                "recommendationId"
              ],
              "members": {
                "document": {
                  "shape": "S20"
                },
                "recommendationId": {},
                "relevanceLevel": {},
                "relevanceScore": {
                  "type": "double"
                },
                "type": {}
              }
            }
          },
          "triggers": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "data",
                "id",
                "recommendationIds",
                "source",
                "type"
              ],
              "members": {
                "data": {
                  "type": "structure",
                  "members": {
                    "query": {
                      "type": "structure",
                      "members": {
                        "text": {
                          "shape": "S2f"
                        }
                      }
                    }
                  },
                  "union": true
                },
                "id": {},
                "recommendationIds": {
                  "shape": "S2g"
                },
                "source": {},
                "type": {}
              }
            }
          }
        }
      }
    },
    "GetSession": {
      "http": {
        "method": "GET",
        "requestUri": "/assistants/{assistantId}/sessions/{sessionId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assistantId",
          "sessionId"
        ],
        "members": {
          "assistantId": {
            "location": "uri",
            "locationName": "assistantId"
          },
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "session": {
            "shape": "S1a"
          }
        }
      }
    },
    "ListAssistantAssociations": {
      "http": {
        "method": "GET",
        "requestUri": "/assistants/{assistantId}/associations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assistantId"
        ],
        "members": {
          "assistantId": {
            "location": "uri",
            "locationName": "assistantId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assistantAssociationSummaries"
        ],
        "members": {
          "assistantAssociationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "assistantArn",
                "assistantAssociationArn",
                "assistantAssociationId",
                "assistantId",
                "associationData",
                "associationType"
              ],
              "members": {
                "assistantArn": {},
                "assistantAssociationArn": {},
                "assistantAssociationId": {},
                "assistantId": {},
                "associationData": {
                  "shape": "Sm"
                },
                "associationType": {},
                "tags": {
                  "shape": "S7"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAssistants": {
      "http": {
        "method": "GET",
        "requestUri": "/assistants",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assistantSummaries"
        ],
        "members": {
          "assistantSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "assistantArn",
                "assistantId",
                "name",
                "status",
                "type"
              ],
              "members": {
                "assistantArn": {},
                "assistantId": {},
                "description": {},
                "name": {},
                "serverSideEncryptionConfiguration": {
                  "shape": "S5"
                },
                "status": {},
                "tags": {
                  "shape": "S7"
                },
                "type": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListContents": {
      "http": {
        "method": "GET",
        "requestUri": "/knowledgeBases/{knowledgeBaseId}/contents",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "knowledgeBaseId"
        ],
        "members": {
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "contentSummaries"
        ],
        "members": {
          "contentSummaries": {
            "shape": "S2w"
          },
          "nextToken": {}
        }
      }
    },
    "ListKnowledgeBases": {
      "http": {
        "method": "GET",
        "requestUri": "/knowledgeBases",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "knowledgeBaseSummaries"
        ],
        "members": {
          "knowledgeBaseSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "knowledgeBaseArn",
                "knowledgeBaseId",
                "knowledgeBaseType",
                "name",
                "status"
              ],
              "members": {
                "description": {},
                "knowledgeBaseArn": {},
                "knowledgeBaseId": {},
                "knowledgeBaseType": {},
                "name": {},
                "renderingConfiguration": {
                  "shape": "S10"
                },
                "serverSideEncryptionConfiguration": {
                  "shape": "S5"
                },
                "sourceConfiguration": {
                  "shape": "S11"
                },
                "status": {},
                "tags": {
                  "shape": "S7"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S7"
          }
        }
      }
    },
    "NotifyRecommendationsReceived": {
      "http": {
        "requestUri": "/assistants/{assistantId}/sessions/{sessionId}/recommendations/notify",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assistantId",
          "recommendationIds",
          "sessionId"
        ],
        "members": {
          "assistantId": {
            "location": "uri",
            "locationName": "assistantId"
          },
          "recommendationIds": {
            "shape": "S2g"
          },
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "message": {},
                "recommendationId": {}
              }
            }
          },
          "recommendationIds": {
            "shape": "S2g"
          }
        }
      },
      "idempotent": true
    },
    "QueryAssistant": {
      "http": {
        "requestUri": "/assistants/{assistantId}/query",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assistantId",
          "queryText"
        ],
        "members": {
          "assistantId": {
            "location": "uri",
            "locationName": "assistantId"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "queryText": {
            "shape": "S2f"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "results"
        ],
        "members": {
          "nextToken": {},
          "results": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "document",
                "resultId"
              ],
              "members": {
                "document": {
                  "shape": "S20"
                },
                "relevanceScore": {
                  "type": "double"
                },
                "resultId": {}
              }
            }
          }
        }
      }
    },
    "RemoveKnowledgeBaseTemplateUri": {
      "http": {
        "method": "DELETE",
        "requestUri": "/knowledgeBases/{knowledgeBaseId}/templateUri",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "knowledgeBaseId"
        ],
        "members": {
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SearchContent": {
      "http": {
        "requestUri": "/knowledgeBases/{knowledgeBaseId}/search",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "knowledgeBaseId",
          "searchExpression"
        ],
        "members": {
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "searchExpression": {
            "shape": "S3f"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "contentSummaries"
        ],
        "members": {
          "contentSummaries": {
            "shape": "S2w"
          },
          "nextToken": {}
        }
      }
    },
    "SearchSessions": {
      "http": {
        "requestUri": "/assistants/{assistantId}/searchSessions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assistantId",
          "searchExpression"
        ],
        "members": {
          "assistantId": {
            "location": "uri",
            "locationName": "assistantId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "searchExpression": {
            "shape": "S3f"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "sessionSummaries"
        ],
        "members": {
          "nextToken": {},
          "sessionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "assistantArn",
                "assistantId",
                "sessionArn",
                "sessionId"
              ],
              "members": {
                "assistantArn": {},
                "assistantId": {},
                "sessionArn": {},
                "sessionId": {}
              }
            }
          }
        }
      }
    },
    "StartContentUpload": {
      "http": {
        "requestUri": "/knowledgeBases/{knowledgeBaseId}/upload",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "contentType",
          "knowledgeBaseId"
        ],
        "members": {
          "contentType": {},
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "headersToInclude",
          "uploadId",
          "url",
          "urlExpiry"
        ],
        "members": {
          "headersToInclude": {
            "type": "map",
            "key": {},
            "value": {}
          },
          "uploadId": {},
          "url": {
            "shape": "Sw"
          },
          "urlExpiry": {
            "shape": "Sx"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateContent": {
      "http": {
        "requestUri": "/knowledgeBases/{knowledgeBaseId}/contents/{contentId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "contentId",
          "knowledgeBaseId"
        ],
        "members": {
          "contentId": {
            "location": "uri",
            "locationName": "contentId"
          },
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          },
          "metadata": {
            "shape": "Sp"
          },
          "overrideLinkOutUri": {},
          "removeOverrideLinkOutUri": {
            "type": "boolean"
          },
          "revisionId": {},
          "title": {},
          "uploadId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "content": {
            "shape": "St"
          }
        }
      }
    },
    "UpdateKnowledgeBaseTemplateUri": {
      "http": {
        "requestUri": "/knowledgeBases/{knowledgeBaseId}/templateUri",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "knowledgeBaseId",
          "templateUri"
        ],
        "members": {
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          },
          "templateUri": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "knowledgeBase": {
            "shape": "S16"
          }
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "kmsKeyId": {}
      }
    },
    "S7": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sc": {
      "type": "structure",
      "required": [
        "assistantArn",
        "assistantId",
        "name",
        "status",
        "type"
      ],
      "members": {
        "assistantArn": {},
        "assistantId": {},
        "description": {},
        "name": {},
        "serverSideEncryptionConfiguration": {
          "shape": "S5"
        },
        "status": {},
        "tags": {
          "shape": "S7"
        },
        "type": {}
      }
    },
    "Sl": {
      "type": "structure",
      "required": [
        "assistantArn",
        "assistantAssociationArn",
        "assistantAssociationId",
        "assistantId",
        "associationData",
        "associationType"
      ],
      "members": {
        "assistantArn": {},
        "assistantAssociationArn": {},
        "assistantAssociationId": {},
        "assistantId": {},
        "associationData": {
          "shape": "Sm"
        },
        "associationType": {},
        "tags": {
          "shape": "S7"
        }
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "knowledgeBaseAssociation": {
          "type": "structure",
          "members": {
            "knowledgeBaseArn": {},
            "knowledgeBaseId": {}
          }
        }
      },
      "union": true
    },
    "Sp": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "St": {
      "type": "structure",
      "required": [
        "contentArn",
        "contentId",
        "contentType",
        "knowledgeBaseArn",
        "knowledgeBaseId",
        "metadata",
        "name",
        "revisionId",
        "status",
        "title",
        "url",
        "urlExpiry"
      ],
      "members": {
        "contentArn": {},
        "contentId": {},
        "contentType": {},
        "knowledgeBaseArn": {},
        "knowledgeBaseId": {},
        "linkOutUri": {},
        "metadata": {
          "shape": "Sp"
        },
        "name": {},
        "revisionId": {},
        "status": {},
        "tags": {
          "shape": "S7"
        },
        "title": {},
        "url": {
          "shape": "Sw"
        },
        "urlExpiry": {
          "shape": "Sx"
        }
      }
    },
    "Sw": {
      "type": "string",
      "sensitive": true
    },
    "Sx": {
      "type": "timestamp",
      "timestampFormat": "unixTimestamp"
    },
    "S10": {
      "type": "structure",
      "members": {
        "templateUri": {}
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "appIntegrations": {
          "type": "structure",
          "required": [
            "appIntegrationArn",
            "objectFields"
          ],
          "members": {
            "appIntegrationArn": {},
            "objectFields": {
              "type": "list",
              "member": {}
            }
          }
        }
      },
      "union": true
    },
    "S16": {
      "type": "structure",
      "required": [
        "knowledgeBaseArn",
        "knowledgeBaseId",
        "knowledgeBaseType",
        "name",
        "status"
      ],
      "members": {
        "description": {},
        "knowledgeBaseArn": {},
        "knowledgeBaseId": {},
        "knowledgeBaseType": {},
        "lastContentModificationTime": {
          "shape": "Sx"
        },
        "name": {},
        "renderingConfiguration": {
          "shape": "S10"
        },
        "serverSideEncryptionConfiguration": {
          "shape": "S5"
        },
        "sourceConfiguration": {
          "shape": "S11"
        },
        "status": {},
        "tags": {
          "shape": "S7"
        }
      }
    },
    "S1a": {
      "type": "structure",
      "required": [
        "name",
        "sessionArn",
        "sessionId"
      ],
      "members": {
        "description": {},
        "name": {},
        "sessionArn": {},
        "sessionId": {},
        "tags": {
          "shape": "S7"
        }
      }
    },
    "S1r": {
      "type": "structure",
      "required": [
        "contentArn",
        "contentId",
        "contentType",
        "knowledgeBaseArn",
        "knowledgeBaseId",
        "metadata",
        "name",
        "revisionId",
        "status",
        "title"
      ],
      "members": {
        "contentArn": {},
        "contentId": {},
        "contentType": {},
        "knowledgeBaseArn": {},
        "knowledgeBaseId": {},
        "metadata": {
          "shape": "Sp"
        },
        "name": {},
        "revisionId": {},
        "status": {},
        "tags": {
          "shape": "S7"
        },
        "title": {}
      }
    },
    "S20": {
      "type": "structure",
      "required": [
        "contentReference"
      ],
      "members": {
        "contentReference": {
          "type": "structure",
          "members": {
            "contentArn": {},
            "contentId": {},
            "knowledgeBaseArn": {},
            "knowledgeBaseId": {}
          }
        },
        "excerpt": {
          "shape": "S22"
        },
        "title": {
          "shape": "S22"
        }
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "highlights": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "beginOffsetInclusive": {
                "type": "integer"
              },
              "endOffsetExclusive": {
                "type": "integer"
              }
            }
          }
        },
        "text": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "S2f": {
      "type": "string",
      "sensitive": true
    },
    "S2g": {
      "type": "list",
      "member": {}
    },
    "S2w": {
      "type": "list",
      "member": {
        "shape": "S1r"
      }
    },
    "S3f": {
      "type": "structure",
      "required": [
        "filters"
      ],
      "members": {
        "filters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "field",
              "operator",
              "value"
            ],
            "members": {
              "field": {},
              "operator": {},
              "value": {}
            }
          }
        }
      }
    }
  }
}