{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-07-15",
    "endpointPrefix": "media-pipelines-chime",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Chime SDK Media Pipelines",
    "serviceId": "Chime SDK Media Pipelines",
    "signatureVersion": "v4",
    "signingName": "chime",
    "uid": "chime-sdk-media-pipelines-2021-07-15"
  },
  "operations": {
    "CreateMediaCapturePipeline": {
      "http": {
        "requestUri": "/sdk-media-capture-pipelines",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "SourceType",
          "SourceArn",
          "SinkType",
          "SinkArn"
        ],
        "members": {
          "SourceType": {},
          "SourceArn": {
            "shape": "S3"
          },
          "SinkType": {},
          "SinkArn": {
            "shape": "S3"
          },
          "ClientRequestToken": {
            "shape": "S5",
            "idempotencyToken": true
          },
          "ChimeSdkMeetingConfiguration": {
            "shape": "S6"
          },
          "Tags": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaCapturePipeline": {
            "shape": "Sx"
          }
        }
      }
    },
    "CreateMediaConcatenationPipeline": {
      "http": {
        "requestUri": "/sdk-media-concatenation-pipelines",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "Sources",
          "Sinks"
        ],
        "members": {
          "Sources": {
            "shape": "S12"
          },
          "Sinks": {
            "shape": "S1h"
          },
          "ClientRequestToken": {
            "shape": "S5",
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaConcatenationPipeline": {
            "shape": "S1m"
          }
        }
      }
    },
    "CreateMediaLiveConnectorPipeline": {
      "http": {
        "requestUri": "/sdk-media-live-connector-pipelines",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "Sources",
          "Sinks"
        ],
        "members": {
          "Sources": {
            "shape": "S1o"
          },
          "Sinks": {
            "shape": "S1t"
          },
          "ClientRequestToken": {
            "shape": "S5",
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaLiveConnectorPipeline": {
            "shape": "S21"
          }
        }
      }
    },
    "DeleteMediaCapturePipeline": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sdk-media-capture-pipelines/{mediaPipelineId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "MediaPipelineId"
        ],
        "members": {
          "MediaPipelineId": {
            "location": "uri",
            "locationName": "mediaPipelineId"
          }
        }
      }
    },
    "DeleteMediaPipeline": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sdk-media-pipelines/{mediaPipelineId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "MediaPipelineId"
        ],
        "members": {
          "MediaPipelineId": {
            "location": "uri",
            "locationName": "mediaPipelineId"
          }
        }
      }
    },
    "GetMediaCapturePipeline": {
      "http": {
        "method": "GET",
        "requestUri": "/sdk-media-capture-pipelines/{mediaPipelineId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MediaPipelineId"
        ],
        "members": {
          "MediaPipelineId": {
            "location": "uri",
            "locationName": "mediaPipelineId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaCapturePipeline": {
            "shape": "Sx"
          }
        }
      }
    },
    "GetMediaPipeline": {
      "http": {
        "method": "GET",
        "requestUri": "/sdk-media-pipelines/{mediaPipelineId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MediaPipelineId"
        ],
        "members": {
          "MediaPipelineId": {
            "location": "uri",
            "locationName": "mediaPipelineId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaPipeline": {
            "type": "structure",
            "members": {
              "MediaCapturePipeline": {
                "shape": "Sx"
              },
              "MediaLiveConnectorPipeline": {
                "shape": "S21"
              },
              "MediaConcatenationPipeline": {
                "shape": "S1m"
              }
            }
          }
        }
      }
    },
    "ListMediaCapturePipelines": {
      "http": {
        "method": "GET",
        "requestUri": "/sdk-media-capture-pipelines",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaCapturePipelines": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MediaPipelineId": {},
                "MediaPipelineArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMediaPipelines": {
      "http": {
        "method": "GET",
        "requestUri": "/sdk-media-pipelines",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MediaPipelines": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MediaPipelineId": {},
                "MediaPipelineArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {
            "location": "querystring",
            "locationName": "arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Ss"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags?operation=tag-resource",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/tags?operation=untag-resource",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "string",
      "sensitive": true
    },
    "S5": {
      "type": "string",
      "sensitive": true
    },
    "S6": {
      "type": "structure",
      "members": {
        "SourceConfiguration": {
          "shape": "S7"
        },
        "ArtifactsConfiguration": {
          "type": "structure",
          "required": [
            "Audio",
            "Video",
            "Content"
          ],
          "members": {
            "Audio": {
              "type": "structure",
              "required": [
                "MuxType"
              ],
              "members": {
                "MuxType": {}
              }
            },
            "Video": {
              "type": "structure",
              "required": [
                "State"
              ],
              "members": {
                "State": {},
                "MuxType": {}
              }
            },
            "Content": {
              "type": "structure",
              "required": [
                "State"
              ],
              "members": {
                "State": {},
                "MuxType": {}
              }
            },
            "CompositedVideo": {
              "shape": "Sl"
            }
          }
        }
      }
    },
    "S7": {
      "type": "structure",
      "members": {
        "SelectedVideoStreams": {
          "type": "structure",
          "members": {
            "AttendeeIds": {
              "type": "list",
              "member": {}
            },
            "ExternalUserIds": {
              "type": "list",
              "member": {
                "type": "string",
                "sensitive": true
              }
            }
          }
        }
      }
    },
    "Sl": {
      "type": "structure",
      "required": [
        "GridViewConfiguration"
      ],
      "members": {
        "Layout": {},
        "Resolution": {},
        "GridViewConfiguration": {
          "type": "structure",
          "required": [
            "ContentShareLayout"
          ],
          "members": {
            "ContentShareLayout": {},
            "PresenterOnlyConfiguration": {
              "type": "structure",
              "members": {
                "PresenterPosition": {}
              }
            }
          }
        }
      }
    },
    "Ss": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "MediaPipelineId": {},
        "MediaPipelineArn": {},
        "SourceType": {},
        "SourceArn": {
          "shape": "S3"
        },
        "Status": {},
        "SinkType": {},
        "SinkArn": {
          "shape": "S3"
        },
        "CreatedTimestamp": {
          "shape": "S10"
        },
        "UpdatedTimestamp": {
          "shape": "S10"
        },
        "ChimeSdkMeetingConfiguration": {
          "shape": "S6"
        }
      }
    },
    "S10": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Type",
          "MediaCapturePipelineSourceConfiguration"
        ],
        "members": {
          "Type": {},
          "MediaCapturePipelineSourceConfiguration": {
            "type": "structure",
            "required": [
              "MediaPipelineArn",
              "ChimeSdkMeetingConfiguration"
            ],
            "members": {
              "MediaPipelineArn": {
                "shape": "S3"
              },
              "ChimeSdkMeetingConfiguration": {
                "type": "structure",
                "required": [
                  "ArtifactsConfiguration"
                ],
                "members": {
                  "ArtifactsConfiguration": {
                    "type": "structure",
                    "required": [
                      "Audio",
                      "Video",
                      "Content",
                      "DataChannel",
                      "TranscriptionMessages",
                      "MeetingEvents",
                      "CompositedVideo"
                    ],
                    "members": {
                      "Audio": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      },
                      "Video": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      },
                      "Content": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      },
                      "DataChannel": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      },
                      "TranscriptionMessages": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      },
                      "MeetingEvents": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      },
                      "CompositedVideo": {
                        "type": "structure",
                        "required": [
                          "State"
                        ],
                        "members": {
                          "State": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Type",
          "S3BucketSinkConfiguration"
        ],
        "members": {
          "Type": {},
          "S3BucketSinkConfiguration": {
            "type": "structure",
            "required": [
              "Destination"
            ],
            "members": {
              "Destination": {
                "shape": "S3"
              }
            }
          }
        }
      }
    },
    "S1m": {
      "type": "structure",
      "members": {
        "MediaPipelineId": {},
        "MediaPipelineArn": {},
        "Sources": {
          "shape": "S12"
        },
        "Sinks": {
          "shape": "S1h"
        },
        "Status": {},
        "CreatedTimestamp": {
          "shape": "S10"
        },
        "UpdatedTimestamp": {
          "shape": "S10"
        }
      }
    },
    "S1o": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "SourceType",
          "ChimeSdkMeetingLiveConnectorConfiguration"
        ],
        "members": {
          "SourceType": {},
          "ChimeSdkMeetingLiveConnectorConfiguration": {
            "type": "structure",
            "required": [
              "Arn",
              "MuxType"
            ],
            "members": {
              "Arn": {
                "shape": "S3"
              },
              "MuxType": {},
              "CompositedVideo": {
                "shape": "Sl"
              },
              "SourceConfiguration": {
                "shape": "S7"
              }
            }
          }
        }
      }
    },
    "S1t": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "SinkType",
          "RTMPConfiguration"
        ],
        "members": {
          "SinkType": {},
          "RTMPConfiguration": {
            "type": "structure",
            "required": [
              "Url"
            ],
            "members": {
              "Url": {
                "type": "string",
                "sensitive": true
              },
              "AudioChannels": {},
              "AudioSampleRate": {}
            }
          }
        }
      }
    },
    "S21": {
      "type": "structure",
      "members": {
        "Sources": {
          "shape": "S1o"
        },
        "Sinks": {
          "shape": "S1t"
        },
        "MediaPipelineId": {},
        "MediaPipelineArn": {},
        "Status": {},
        "CreatedTimestamp": {
          "shape": "S10"
        },
        "UpdatedTimestamp": {
          "shape": "S10"
        }
      }
    }
  }
}