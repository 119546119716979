import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation, withRouter} from "react-router-dom";

import "@material-ui/core";
import {
  AppBar,
  Avatar,
  Button,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Menu,
  MenuItem,
  Toolbar
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import {createTheme, makeStyles, ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';

import {Auth} from './Auth';
import {MenuConfig} from './MenuConfig';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff'
    }
  },
  typography: {
    fontSize: 13,
    fontFamily: "Montserrat"
  }
});

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.down('sm')]: {
      flexShrink: 0
    },
  },
  drawerList: {
    minWidth: 200
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarGrid: {
    alignItems: 'center'
  },
  menuArrow: {
    [theme.breakpoints.down('sm')]: {
      width: '15px',
      height: '15px'
    }
  },
  menuButton: {
    "&:hover": {
      backgroundColor: 'transparent'
    }
  },
  menuItem: {
    fontSize: 13,
    fontFamily: "Montserrat",
    justifyContent: "flex-end"
  },
  menuGrid: {
    marginLeft: '10px',
    height: '60px'
  },
  avatar: {
    fontSize: 15,
    width: '40px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '30px',
      height: '30px',
      fontSize: 14
    }
  },
  avatarButton: {
    padding: '0px',
    float: 'right',
    height: '60px'
  },
  avatarButtonGrid: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    float: 'right',
    width: '100px',
    height: '100%'
  },
  img: {
    height: '100%'
  },
  logoGrid: {
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'left'
    }
  },
  siteLogo: {
    height: '50px',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
    }
  },
  stretch: {
    flex: '1'
  },
  toolBar: {
    minHeight: '60px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 8px',
    }
  }
}));

function MenuArrow(props) {
  const classes = useStyles();

  if (!!props.open) {
    return (
      <ArrowDropUpIcon className={classes.menuArrow} />
    );
  } else {
    return (<
      ArrowDropDownIcon className={classes.menuArrow} />
    );
  }
}

const UserAvatarMenu = withRouter(
  (props) => {
    const classes = useStyles();
    const {user} = props;

    if (user) {
      const firstInitial = user.firstName ? user.firstName.charAt(0) : "";
      const lastInitial = user.lastName ? user.lastName.charAt(0) : "";
      const initial = firstInitial.concat(lastInitial).toUpperCase();

      return (Auth.isAuthenticated && (
        <Grid container className={classes.avatarButtonGrid}>
          <Button
            className={classes.avatarButton}
            onClick={props.handleMenuClick("avatar")}
            disableRipple
          >
            <Avatar className={classes.avatar}>{initial}</Avatar>
            <MenuArrow open={props.menuStatus.avatar} />
          </Button>
          <Menu
            open={!!props.menuStatus.avatar}
            anchorEl={props.anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            onClick={props.handleMenuClick("avatar")}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                Auth.logout();
              }}>
              Logout
            </MenuItem>
          </Menu>
        </Grid>
      ))
    } else {
      return null;
    }
  }
);



function Logo() {
  const classes = useStyles();

  return (
    <Grid item className={classes.siteLogo}>
      <Link to="/">
        <img
          className={classes.img}
          data-altlogo="https://s3-us-west-2.amazonaws.com/ccc-web-assets/Cornerstone_logo_half.png"
          src="https://s3-us-west-2.amazonaws.com/ccc-web-assets/Cornerstone_logo_half.png"
          alt="Christ the Cornerstone Church"
          scale="0" />
      </Link>
    </Grid>
  );
}

function AppBarMenu(props) {
  const classes = useStyles();
  const {user} = props;

  // Expense view is only allowed for Accounts Payable
  if (user && user.role === 2) {
    MenuConfig["expense"] = {
      dashboard: {
        text: "Dashboard",
        route: "/expense",
      },
      create: {
        text: "Submit an expense",
        route: "/expense/submit"
      }
    }
  }

  return (
    <Grid item>
      <Grid container>
        {
          Object.keys(MenuConfig).map((serviceName) => {
            const menus = Object.keys(MenuConfig[serviceName]);
            
            return (
              <Grid item xs className={classes.menuGrid} key={serviceName}>
                {
                  menus.length === 1 ? (
                    <Button className={classes.avatarButton} component={Link} to={MenuConfig[serviceName][menus[0]].route} disableRipple>
                      {serviceName}
                    </Button>
                  ) : (
                    <>
                      <Button
                        className={classes.avatarButton}
                        onClick={props.handleMenuClick(serviceName)}
                        disableRipple
                      >
                        {serviceName}
                        <MenuArrow open={props.menuStatus[serviceName]} />
                      </Button>

                      <Menu
                        open={!!props.menuStatus[serviceName]}
                        anchorEl={props.anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                        onClick={props.handleMenuClick(serviceName)}
                      >
                        {
                          Object.keys(MenuConfig[serviceName]).map((subMenu) => {

                            return (
                              <MenuItem className={classes.menuItem} key={subMenu}>
                                <Button className={classes.menuButton} component={Link} to={MenuConfig[serviceName][subMenu].route}>
                                  {MenuConfig[serviceName][subMenu].text}
                                </Button>
                              </MenuItem>
                            )
                          })
                        }
                      </Menu>
                    </>
                  )
                }
              </Grid>
            );
          })
        }
      </Grid>
    </Grid>
  )
}

function DrawerMenu(props) {
  const classes = useStyles();
  const {user} = props;

  return (
    <>
      <Drawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={props.drawerToggle}
        onClose={props.handleDrawerToggle(false)}
      >
        <Toolbar className={classes.toolBar} />
        {
          Object.keys(MenuConfig).map((serviceName) => {
            // Expense view is only allowed for Accounts Payable
            if (serviceName === "expense" && user && user.role !== 2) {
              return;
            }

            return (
              <Grid container key={serviceName}>
                <List className={classes.drawerList}>
                  <ListSubheader>{serviceName.toUpperCase()}</ListSubheader>
                  {
                    Object.keys(MenuConfig[serviceName]).map((subMenu) => {
                      return (
                        <ListItem button key={subMenu}>
                          <Button className={classes.menuButton}
                                  component={Link}
                                  to={MenuConfig[serviceName][subMenu].route}
                                  onClick={props.handleDrawerToggle(false)}>
                            {MenuConfig[serviceName][subMenu].text}
                          </Button>
                        </ListItem>
                      )
                    })
                  }
                  <Divider />
                </List>
              </Grid>
            );
          })
        }
      </Drawer>
    </>
  );
}

const MainAppBar = () => {
  const classes = useStyles();
  const location = useLocation(); // force refresh the app bar when URL changes on login/logout
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    async function loadUser() {
      if (!user) {
        setUser(await Auth.getUser());
      }
    };

    loadUser();
  }, [location]);


  const [menuStatus, setmenuStatus] = useState({});
  const [drawerToggle, setDrawerToggle] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (menuName) => (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
    setmenuStatus({ ...menuStatus, [menuName]: !menuStatus[menuName] });
  };

  const handleDrawerToggle = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerToggle(open);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar className={classes.toolBar}>
          <Grid container className={classes.appBarGrid}>
            <Hidden mdUp>
              <Grid item xs={3} md={false}>
                <IconButton
                  edge="start"
                  onClick={handleDrawerToggle(!drawerToggle)}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs={6} md={10}>
              <Grid item className={classes.stretch}>
                <Grid container className={classes.logoGrid}>
                  <Logo />
                  <Hidden smDown>
                    <AppBarMenu
                      menuStatus={menuStatus}
                      anchorEl={anchorEl}
                      handleMenuClick={handleMenuClick}
                      user={user}
                    />
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} md={2}>
              <UserAvatarMenu
                menuStatus={menuStatus}
                anchorEl={anchorEl}
                handleMenuClick={handleMenuClick}
                user={user}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <DrawerMenu
          drawerToggle={drawerToggle}
          handleDrawerToggle={handleDrawerToggle}
          user={user}
        />
      </Hidden>
    </MuiThemeProvider>
  );
}

export { MainAppBar };