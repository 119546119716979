{
  "pagination": {
    "ListCrls": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "crls"
    },
    "ListProfiles": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "profiles"
    },
    "ListSubjects": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "subjects"
    },
    "ListTrustAnchors": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "trustAnchors"
    }
  }
}
