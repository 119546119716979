{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-02-01",
    "endpointPrefix": "account",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS Account",
    "serviceId": "Account",
    "signatureVersion": "v4",
    "signingName": "account",
    "uid": "account-2021-02-01"
  },
  "operations": {
    "DeleteAlternateContact": {
      "http": {
        "requestUri": "/deleteAlternateContact",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AlternateContactType"
        ],
        "members": {
          "AccountId": {},
          "AlternateContactType": {}
        }
      },
      "idempotent": true
    },
    "GetAlternateContact": {
      "http": {
        "requestUri": "/getAlternateContact",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AlternateContactType"
        ],
        "members": {
          "AccountId": {},
          "AlternateContactType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AlternateContact": {
            "type": "structure",
            "members": {
              "AlternateContactType": {},
              "EmailAddress": {
                "shape": "S7"
              },
              "Name": {
                "shape": "S8"
              },
              "PhoneNumber": {
                "shape": "S9"
              },
              "Title": {
                "shape": "Sa"
              }
            }
          }
        }
      }
    },
    "GetContactInformation": {
      "http": {
        "requestUri": "/getContactInformation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "AccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactInformation": {
            "shape": "Sd"
          }
        }
      }
    },
    "PutAlternateContact": {
      "http": {
        "requestUri": "/putAlternateContact",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AlternateContactType",
          "EmailAddress",
          "Name",
          "PhoneNumber",
          "Title"
        ],
        "members": {
          "AccountId": {},
          "AlternateContactType": {},
          "EmailAddress": {
            "shape": "S7"
          },
          "Name": {
            "shape": "S8"
          },
          "PhoneNumber": {
            "shape": "S9"
          },
          "Title": {
            "shape": "Sa"
          }
        }
      },
      "idempotent": true
    },
    "PutContactInformation": {
      "http": {
        "requestUri": "/putContactInformation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ContactInformation"
        ],
        "members": {
          "AccountId": {},
          "ContactInformation": {
            "shape": "Sd"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S7": {
      "type": "string",
      "sensitive": true
    },
    "S8": {
      "type": "string",
      "sensitive": true
    },
    "S9": {
      "type": "string",
      "sensitive": true
    },
    "Sa": {
      "type": "string",
      "sensitive": true
    },
    "Sd": {
      "type": "structure",
      "required": [
        "AddressLine1",
        "City",
        "CountryCode",
        "FullName",
        "PhoneNumber",
        "PostalCode"
      ],
      "members": {
        "AddressLine1": {
          "shape": "Se"
        },
        "AddressLine2": {
          "shape": "Se"
        },
        "AddressLine3": {
          "shape": "Se"
        },
        "City": {
          "type": "string",
          "sensitive": true
        },
        "CompanyName": {
          "type": "string",
          "sensitive": true
        },
        "CountryCode": {
          "type": "string",
          "sensitive": true
        },
        "DistrictOrCounty": {
          "type": "string",
          "sensitive": true
        },
        "FullName": {
          "type": "string",
          "sensitive": true
        },
        "PhoneNumber": {
          "type": "string",
          "sensitive": true
        },
        "PostalCode": {
          "type": "string",
          "sensitive": true
        },
        "StateOrRegion": {
          "type": "string",
          "sensitive": true
        },
        "WebsiteUrl": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "Se": {
      "type": "string",
      "sensitive": true
    }
  }
}