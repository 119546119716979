{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-07-01",
    "endpointPrefix": "featurestore-runtime.sagemaker",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon SageMaker Feature Store Runtime",
    "serviceId": "SageMaker FeatureStore Runtime",
    "signatureVersion": "v4",
    "signingName": "sagemaker",
    "uid": "sagemaker-featurestore-runtime-2020-07-01"
  },
  "operations": {
    "BatchGetRecord": {
      "http": {
        "requestUri": "/BatchGetRecord"
      },
      "input": {
        "type": "structure",
        "required": [
          "Identifiers"
        ],
        "members": {
          "Identifiers": {
            "type": "list",
            "member": {
              "shape": "S3"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Records",
          "Errors",
          "UnprocessedIdentifiers"
        ],
        "members": {
          "Records": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "FeatureGroupName",
                "RecordIdentifierValueAsString",
                "Record"
              ],
              "members": {
                "FeatureGroupName": {},
                "RecordIdentifierValueAsString": {},
                "Record": {
                  "shape": "Sc"
                }
              }
            }
          },
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "FeatureGroupName",
                "RecordIdentifierValueAsString",
                "ErrorCode",
                "ErrorMessage"
              ],
              "members": {
                "FeatureGroupName": {},
                "RecordIdentifierValueAsString": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          },
          "UnprocessedIdentifiers": {
            "type": "list",
            "member": {
              "shape": "S3"
            }
          }
        }
      }
    },
    "DeleteRecord": {
      "http": {
        "method": "DELETE",
        "requestUri": "/FeatureGroup/{FeatureGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "FeatureGroupName",
          "RecordIdentifierValueAsString",
          "EventTime"
        ],
        "members": {
          "FeatureGroupName": {
            "location": "uri",
            "locationName": "FeatureGroupName"
          },
          "RecordIdentifierValueAsString": {
            "location": "querystring",
            "locationName": "RecordIdentifierValueAsString"
          },
          "EventTime": {
            "location": "querystring",
            "locationName": "EventTime"
          }
        }
      }
    },
    "GetRecord": {
      "http": {
        "method": "GET",
        "requestUri": "/FeatureGroup/{FeatureGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "FeatureGroupName",
          "RecordIdentifierValueAsString"
        ],
        "members": {
          "FeatureGroupName": {
            "location": "uri",
            "locationName": "FeatureGroupName"
          },
          "RecordIdentifierValueAsString": {
            "location": "querystring",
            "locationName": "RecordIdentifierValueAsString"
          },
          "FeatureNames": {
            "shape": "S7",
            "location": "querystring",
            "locationName": "FeatureName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Record": {
            "shape": "Sc"
          }
        }
      }
    },
    "PutRecord": {
      "http": {
        "method": "PUT",
        "requestUri": "/FeatureGroup/{FeatureGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "FeatureGroupName",
          "Record"
        ],
        "members": {
          "FeatureGroupName": {
            "location": "uri",
            "locationName": "FeatureGroupName"
          },
          "Record": {
            "shape": "Sc"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "required": [
        "FeatureGroupName",
        "RecordIdentifiersValueAsString"
      ],
      "members": {
        "FeatureGroupName": {},
        "RecordIdentifiersValueAsString": {
          "type": "list",
          "member": {}
        },
        "FeatureNames": {
          "shape": "S7"
        }
      }
    },
    "S7": {
      "type": "list",
      "member": {}
    },
    "Sc": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "FeatureName",
          "ValueAsString"
        ],
        "members": {
          "FeatureName": {},
          "ValueAsString": {}
        }
      }
    }
  }
}