{
  "pagination": {
    "ListDiscoverers": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "Discoverers"
    },
    "ListRegistries": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "Registries"
    },
    "ListSchemaVersions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "SchemaVersions"
    },
    "ListSchemas": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "Schemas"
    },
    "SearchSchemas": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "Schemas"
    }
  }
}
