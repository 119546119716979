import React, {useEffect, useState} from "react";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {Button, Container, Grid, MuiThemeProvider, TextField, makeStyles, Tooltip, Link, InputLabel, Select, MenuItem} from "@material-ui/core";
import {useParams, useHistory} from "react-router-dom";
import {getDocument, createDocument, updateDocument, listDocumentVersions} from "../../../api/document";
import {createTheme} from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
      primary: {
          main: "#1976d2",
      },
      secondary: {
          main: "#ffffff"
      }
  },
  typography: {
      fontSize: 15,
      fontFamily: "Montserrat"
  }
});

const useStyle = makeStyles({
    centerForm: {
        borderStyle: "dashed",
        borderWidth: 1,
    },
    textBox: {
        marginTop: "8px",
        marginBottom: "8px",
        width: "100%",
    },
    formButton: {
        display: "block",
        marginBottom: "15px",
        backgroundColor: "#DD5150",
        color: "#ffffff",
        fontWeight: "bold",
    },
    addLang: {
        textAlign: "center",
        width: "100%"
    },
    removeButton: {
        color: "#DD5150",
    },
})

function RenderSubmitCancelButtons(props: any) {
    const history = useHistory()
    const handleSubmit = async () => {
        props.setIsSubmitted(true);

        let data = {
            document: {
                id: props.document.id,
                category: props.document.category,
            },
            documentVersions: props.documentVersions,
        };

        let document;
        let id;

        if (props.exist) {
            document = await updateDocument(data);
            id = data.document.id;
        } else {
            document = await createDocument(data);
            id = document.id;
        }
        history.push('/worship/documents?documentId=' + id)
    }

    const handleCancel = async () => {
        history.goBack();
    }

    function isValidDocument(document: any) {
        return document.category.length >= 1;
    }
    
    function isValidDocumentVersion(documentVersion: any) {
        return documentVersion.version.length >= 1 &&
                documentVersion.title.length >= 1 && 
                documentVersion.content.length >= 1;
    }
    
    const isSubmitEnabled = props.documentVersions.every(isValidDocumentVersion) && isValidDocument(props.document) ;

    const styles = useStyle();

    return (
        <Grid container item xs={12}>
            <hr />
            <Grid item xs={12}>
            <Button
                className={styles.formButton}
                id="submitButton"
                variant="contained"
                disabled={!isSubmitEnabled}
                onClick={ () => handleSubmit()}
                role = "submit"
            >
                Submit
            </Button>
            </Grid>
            <Grid item xs={12}>
            <Button
                className={styles.formButton}
                id="cancelButton"
                variant="contained"
                onClick={ () => handleCancel()}
                role = "cancel"
            >
                Cancel
            </Button>
            </Grid>
        </Grid>
    )
}

function RenderDocumentVersionForm(props: any){
  const documentVersions = props.documentVersions;
  const styles = useStyle();
  const arr = new Array(documentVersions.length).fill(true);
  if(documentVersions) {
    return (
      <Grid container item xs={12} style={{ marginTop: "15px" }}>
        {documentVersions.map((val: any, index: any) => (
          <div style={{ width: "100%" }} key={index}>
            {index !== 0 && ( 
              <div>
                <hr />
                <Grid item xs={12} style={{ textAlign: "end" }}>
                  <Link
                    className={styles.removeButton}
                    component="button"
                    variant="body2"
                    onClick={(event) =>
                      props.handleDeleteVersion(event, index, arr)
                    }
                    role = "remove"
                  >
                    Remove
                  </Link>
                </Grid>
              </div>
            )}
            <Grid item xs={12}>
              <TextField
                className={styles.textBox}
                required
                error={val.version === ""}
                id="version"
                label="Version"
                name="version"
                value={val.version}
                placeholder={"Insert Version"}
                onChange={(event) => props.handleDocumentVersionChange(event, index)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={styles.textBox}
                required
                error={val.title === ""}
                id="title"
                label="Title"
                name="title"
                value={val.title}
                placeholder={"Insert Title"}
                onChange={ (event) => props.handleDocumentVersionChange(event, index)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={styles.textBox}
                multiline
                required
                error={val.content === ""}
                id="content"
                fullWidth
                label="Content"
                name="content"
                margin="normal"
                value={val.content}
                placeholder={"Insert Content"}
                onChange={ (event) => props.handleDocumentVersionChange(event, index)}
                minRows="10"
                InputProps={{ style: { fontFamily: "Courier New" }}}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={styles.textBox}
                id="link"
                label="Link"
                name="link"
                value={val.link}
                placeholder={"Insert Link"}
                onChange={ (event) => props.handleDocumentVersionChange(event,index)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={styles.textBox}
                id="source"
                label="Source"
                name="source"
                value={val.source}
                placeholder={"Insert Source"}
                onChange={ (event) => props.handleDocumentVersionChange(event,index)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
          </div>
        ))}
        <Grid item xs={12} className={styles.addLang}>
          <Tooltip
            title="Add new version"
            aria-label="add-new-version"
          >
            <AddCircleIcon
              style={{ fontSize: "60px", color: "#DD5150" }}
              onClick={(event) => props.handleClick(event)}
            ></AddCircleIcon>
          </Tooltip>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

function RenderDocumentForm(props: any){
  const document = props.document;
  const styles = useStyle();

  return(
    <Grid container item xs={12} style={{ marginTop: "10px" }}>
      <Grid item xs={12} className={styles.textBox}>
      <TextField
        className={styles.textBox}
        id="category"
        required
        value={document.category}
        name="category"
        label="Category"
        onChange={(event) => props.handleDocumentChange(event)}
        variant="outlined"
        select
      >
        <MenuItem value={"Creeds"}>Creeds</MenuItem>
        <MenuItem value={"Hymns Author"}>Hymns Author</MenuItem>
        <MenuItem value={"Hymns Book"}>Hymns Book</MenuItem>
        <MenuItem value={"Sacraments"}>Sacraments</MenuItem>
        <MenuItem value={"Training Material"}>Training Materials</MenuItem>
        <MenuItem value={"Deacon Ordination"}>Deacon Ordination</MenuItem>
        <MenuItem value={"Membership"}>Membership</MenuItem>
        <MenuItem value={"Others"}>Others</MenuItem>
      </TextField>
      </Grid>
    </Grid> 
  )
}

function CreateForm() {
  const [document,  setDocument] = useState({
    id: 0,
    category: "",
  });
  const [documentVersions, setDocumentVersions] = useState([{
    documentId: 0,
    version: "",
    title: "",
    content: "",
    link: "",
    source: ""
  }]);
  
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [failedToSubmit, setFailedToSubmit] = useState(false);
  const [exist, setExist] = useState(false);

  const handleClick = (event: MouseEvent) => {
    const emptyDocumentVersion = {
      documentId: document.id,
      version: "",
      title: "",
      content: "",
      link: "",
      source: "",
    };

    setDocumentVersions(documentVersions => ([
      ...documentVersions,
      emptyDocumentVersion
    ]));
  }

  const handleDeleteVersion = (event: MouseEvent, index: number, arr: any) => {
      const newDocumentVersions = [...documentVersions];
      newDocumentVersions.splice(index, 1);
      setDocumentVersions(newDocumentVersions);
  }

  const handleDocumentVersionChange = (event: any, index: any) => {
    let copyDocumentVersions = [...documentVersions] as any[];
    copyDocumentVersions[index][event.target.name] = event.target.value;
    setDocumentVersions(copyDocumentVersions);
  }

  const handleDocumentChange = (event: any) => {
    event.persist();
    setDocument(document => ({...document, [event.target.name]: event.target.value}));
  };
    type documentId = {id?: string}
    const extractId: documentId = useParams();
    useEffect(() => {
    async function getDocumentById(extractId: any) {
      var existingDocument = await getDocument(extractId.id);
      var existingDocumentVersion = await listDocumentVersions(extractId.id);

      setDocument(existingDocument);
      setDocumentVersions(existingDocumentVersion);
    }
    if(!!extractId.id) {
      setExist(true);
      getDocumentById(extractId);
    }
  }, [])
  
  const styles = useStyle();
  function renderForm() {
    if (documentVersions) {
      return (
        <Grid container>
          <RenderDocumentForm
            document = {document}
            handleDocumentChange = {(event: any) => handleDocumentChange(event)}
          />
          <RenderDocumentVersionForm
            documentVersions={documentVersions}
            handleDocumentVersionChange = {(event: React.ChangeEvent<HTMLInputElement>, index: number) => handleDocumentVersionChange(event, index)}
            handleClick={handleClick}
            handleDeleteVersion={handleDeleteVersion}
          />
          <RenderSubmitCancelButtons 
            exist={exist}
            document={document}
            documentVersions={documentVersions}
            setExist={(doesExist: any) => {setExist(doesExist)}}
            setIsSubmitted={(submitted: any) => {setIsSubmitted(submitted)}}
            setFailedToSubmit={(failedToSubmit: any) => {setFailedToSubmit(failedToSubmit)}}
            />
        </Grid>
      );
    } else {
        return null
    }
  }
  return (
      renderForm()
  );
}

export default function Create() {
    return (
    <MuiThemeProvider theme ={theme}>
        <Container>
            <CreateForm/>
        </Container>
    </MuiThemeProvider>
  )
}