{
  "pagination": {
    "ListPlugins": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "plugins"
    },
    "ListTemplateStepGroups": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "templateStepGroupSummary"
    },
    "ListTemplateSteps": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "templateStepSummaryList"
    },
    "ListTemplates": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "templateSummary"
    },
    "ListWorkflowStepGroups": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "workflowStepGroupsSummary"
    },
    "ListWorkflowSteps": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "workflowStepsSummary"
    },
    "ListWorkflows": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "migrationWorkflowSummary"
    }
  }
}
