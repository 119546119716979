{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-08-01",
    "endpointPrefix": "aps",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Prometheus Service",
    "serviceId": "amp",
    "signatureVersion": "v4",
    "signingName": "aps",
    "uid": "amp-2020-08-01"
  },
  "operations": {
    "CreateAlertManagerDefinition": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/alertmanager/definition",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "data",
          "workspaceId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "data": {
            "type": "blob"
          },
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "status"
        ],
        "members": {
          "status": {
            "shape": "S6"
          }
        }
      },
      "idempotent": true
    },
    "CreateLoggingConfiguration": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/logging",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "logGroupArn",
          "workspaceId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "logGroupArn": {},
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "status"
        ],
        "members": {
          "status": {
            "shape": "Sc"
          }
        }
      },
      "idempotent": true
    },
    "CreateRuleGroupsNamespace": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/rulegroupsnamespaces",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "data",
          "name",
          "workspaceId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "data": {
            "type": "blob"
          },
          "name": {},
          "tags": {
            "shape": "Sh"
          },
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "arn",
          "name",
          "status"
        ],
        "members": {
          "arn": {},
          "name": {},
          "status": {
            "shape": "Sm"
          },
          "tags": {
            "shape": "Sh"
          }
        }
      },
      "idempotent": true
    },
    "CreateWorkspace": {
      "http": {
        "requestUri": "/workspaces",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "alias": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "tags": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "arn",
          "status",
          "workspaceId"
        ],
        "members": {
          "arn": {},
          "status": {
            "shape": "Ss"
          },
          "tags": {
            "shape": "Sh"
          },
          "workspaceId": {}
        }
      },
      "idempotent": true
    },
    "DeleteAlertManagerDefinition": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workspaces/{workspaceId}/alertmanager/definition",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "idempotent": true
    },
    "DeleteLoggingConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workspaces/{workspaceId}/logging",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "idempotent": true
    },
    "DeleteRuleGroupsNamespace": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workspaces/{workspaceId}/rulegroupsnamespaces/{name}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "workspaceId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "idempotent": true
    },
    "DeleteWorkspace": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workspaces/{workspaceId}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "idempotent": true
    },
    "DescribeAlertManagerDefinition": {
      "http": {
        "method": "GET",
        "requestUri": "/workspaces/{workspaceId}/alertmanager/definition",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "alertManagerDefinition"
        ],
        "members": {
          "alertManagerDefinition": {
            "type": "structure",
            "required": [
              "createdAt",
              "data",
              "modifiedAt",
              "status"
            ],
            "members": {
              "createdAt": {
                "type": "timestamp"
              },
              "data": {
                "type": "blob"
              },
              "modifiedAt": {
                "type": "timestamp"
              },
              "status": {
                "shape": "S6"
              }
            }
          }
        }
      }
    },
    "DescribeLoggingConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/workspaces/{workspaceId}/logging",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "loggingConfiguration"
        ],
        "members": {
          "loggingConfiguration": {
            "type": "structure",
            "required": [
              "createdAt",
              "logGroupArn",
              "modifiedAt",
              "status",
              "workspace"
            ],
            "members": {
              "createdAt": {
                "type": "timestamp"
              },
              "logGroupArn": {},
              "modifiedAt": {
                "type": "timestamp"
              },
              "status": {
                "shape": "Sc"
              },
              "workspace": {}
            }
          }
        }
      }
    },
    "DescribeRuleGroupsNamespace": {
      "http": {
        "method": "GET",
        "requestUri": "/workspaces/{workspaceId}/rulegroupsnamespaces/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "workspaceId"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ruleGroupsNamespace"
        ],
        "members": {
          "ruleGroupsNamespace": {
            "type": "structure",
            "required": [
              "arn",
              "createdAt",
              "data",
              "modifiedAt",
              "name",
              "status"
            ],
            "members": {
              "arn": {},
              "createdAt": {
                "type": "timestamp"
              },
              "data": {
                "type": "blob"
              },
              "modifiedAt": {
                "type": "timestamp"
              },
              "name": {},
              "status": {
                "shape": "Sm"
              },
              "tags": {
                "shape": "Sh"
              }
            }
          }
        }
      }
    },
    "DescribeWorkspace": {
      "http": {
        "method": "GET",
        "requestUri": "/workspaces/{workspaceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "workspace"
        ],
        "members": {
          "workspace": {
            "type": "structure",
            "required": [
              "arn",
              "createdAt",
              "status",
              "workspaceId"
            ],
            "members": {
              "alias": {},
              "arn": {},
              "createdAt": {
                "type": "timestamp"
              },
              "prometheusEndpoint": {},
              "status": {
                "shape": "Ss"
              },
              "tags": {
                "shape": "Sh"
              },
              "workspaceId": {}
            }
          }
        }
      }
    },
    "ListRuleGroupsNamespaces": {
      "http": {
        "method": "GET",
        "requestUri": "/workspaces/{workspaceId}/rulegroupsnamespaces",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "name": {
            "location": "querystring",
            "locationName": "name"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ruleGroupsNamespaces"
        ],
        "members": {
          "nextToken": {},
          "ruleGroupsNamespaces": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "modifiedAt",
                "name",
                "status"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "modifiedAt": {
                  "type": "timestamp"
                },
                "name": {},
                "status": {
                  "shape": "Sm"
                },
                "tags": {
                  "shape": "Sh"
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sh"
          }
        }
      }
    },
    "ListWorkspaces": {
      "http": {
        "method": "GET",
        "requestUri": "/workspaces",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "alias": {
            "location": "querystring",
            "locationName": "alias"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "workspaces"
        ],
        "members": {
          "nextToken": {},
          "workspaces": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "status",
                "workspaceId"
              ],
              "members": {
                "alias": {},
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "status": {
                  "shape": "Ss"
                },
                "tags": {
                  "shape": "Sh"
                },
                "workspaceId": {}
              }
            }
          }
        }
      }
    },
    "PutAlertManagerDefinition": {
      "http": {
        "method": "PUT",
        "requestUri": "/workspaces/{workspaceId}/alertmanager/definition",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "data",
          "workspaceId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "data": {
            "type": "blob"
          },
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "status"
        ],
        "members": {
          "status": {
            "shape": "S6"
          }
        }
      },
      "idempotent": true
    },
    "PutRuleGroupsNamespace": {
      "http": {
        "method": "PUT",
        "requestUri": "/workspaces/{workspaceId}/rulegroupsnamespaces/{name}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "data",
          "name",
          "workspaceId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "data": {
            "type": "blob"
          },
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "arn",
          "name",
          "status"
        ],
        "members": {
          "arn": {},
          "name": {},
          "status": {
            "shape": "Sm"
          },
          "tags": {
            "shape": "Sh"
          }
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateLoggingConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/workspaces/{workspaceId}/logging",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "logGroupArn",
          "workspaceId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "logGroupArn": {},
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "status"
        ],
        "members": {
          "status": {
            "shape": "Sc"
          }
        }
      },
      "idempotent": true
    },
    "UpdateWorkspaceAlias": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/alias",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "alias": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S6": {
      "type": "structure",
      "required": [
        "statusCode"
      ],
      "members": {
        "statusCode": {},
        "statusReason": {}
      }
    },
    "Sc": {
      "type": "structure",
      "required": [
        "statusCode"
      ],
      "members": {
        "statusCode": {},
        "statusReason": {}
      }
    },
    "Sh": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sm": {
      "type": "structure",
      "required": [
        "statusCode"
      ],
      "members": {
        "statusCode": {},
        "statusReason": {}
      }
    },
    "Ss": {
      "type": "structure",
      "required": [
        "statusCode"
      ],
      "members": {
        "statusCode": {}
      }
    }
  }
}