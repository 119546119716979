import {ListItemText, ListItem, List} from "@material-ui/core";
import {ChevronLeft, ChevronRight, Search} from '@material-ui/icons';
import {styles} from "../shared/styles";

function _IndexNavigation(props: any) {
    const classes = styles();
    const alphabets = ["#","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

    const handleIndexClick = (event: MouseEvent, index: string) => {
        props.setIsDrawerOpen(true);
        props.setSelectedIndex(index);
        window.sessionStorage.setItem('selectedIndex', index);
    };

    const handleSearchClick = () => {
        props.setIsDrawerOpen(true);
        props.setSelectedIndex('');
    };

    return (
        <List>
            <ListItem button
                      component="nav"
                      onClick={props.handleDrawerToggle}
                      style={{textAlign: "center"}}>
                <ListItemText primary={props.isDrawerOpen ? <ChevronLeft/> : <ChevronRight/>}/>
            </ListItem>
            <ListItem button
                      selected={!props.selectedIndex}
                      component="nav"
                      onClick={() => handleSearchClick()}
                      style={{textAlign: "center"}}>
                <ListItemText primary={<Search/>}/>
            </ListItem>
            {alphabets.map(alphabet =>
                <ListItem key={alphabet} 
                          selected={props.selectedIndex === alphabet}
                          onClick={(event: any) => handleIndexClick(event, alphabet)}
                          button
                          style={{textAlign: "center"}}
                          component="nav">
                    <ListItemText primary={alphabet}/>
                </ListItem>
            )}
        </List>
    );
}

export const Index = _IndexNavigation;