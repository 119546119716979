{
  "pagination": {
    "GetCurrentMetricData": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetCurrentUserData": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetMetricData": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListAgentStatuses": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AgentStatusSummaryList"
    },
    "ListApprovedOrigins": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Origins"
    },
    "ListBots": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LexBots"
    },
    "ListContactFlowModules": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ContactFlowModulesSummaryList"
    },
    "ListContactFlows": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ContactFlowSummaryList"
    },
    "ListContactReferences": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "ReferenceSummaryList"
    },
    "ListDefaultVocabularies": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "DefaultVocabularyList"
    },
    "ListHoursOfOperations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "HoursOfOperationSummaryList"
    },
    "ListInstanceAttributes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Attributes"
    },
    "ListInstanceStorageConfigs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "StorageConfigs"
    },
    "ListInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceSummaryList"
    },
    "ListIntegrationAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "IntegrationAssociationSummaryList"
    },
    "ListLambdaFunctions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LambdaFunctions"
    },
    "ListLexBots": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LexBots"
    },
    "ListPhoneNumbers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PhoneNumberSummaryList"
    },
    "ListPhoneNumbersV2": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ListPhoneNumbersSummaryList"
    },
    "ListPrompts": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PromptSummaryList"
    },
    "ListQueueQuickConnects": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "QuickConnectSummaryList"
    },
    "ListQueues": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "QueueSummaryList"
    },
    "ListQuickConnects": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "QuickConnectSummaryList"
    },
    "ListRoutingProfileQueues": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "RoutingProfileQueueConfigSummaryList"
    },
    "ListRoutingProfiles": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "RoutingProfileSummaryList"
    },
    "ListSecurityKeys": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SecurityKeys"
    },
    "ListSecurityProfilePermissions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Permissions"
    },
    "ListSecurityProfiles": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SecurityProfileSummaryList"
    },
    "ListTaskTemplates": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TaskTemplates"
    },
    "ListTrafficDistributionGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TrafficDistributionGroupSummaryList"
    },
    "ListUseCases": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "UseCaseSummaryList"
    },
    "ListUserHierarchyGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "UserHierarchyGroupSummaryList"
    },
    "ListUsers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "UserSummaryList"
    },
    "SearchAvailablePhoneNumbers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AvailableNumbersList"
    },
    "SearchQueues": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "non_aggregate_keys": [
        "ApproximateTotalCount"
      ],
      "output_token": "NextToken",
      "result_key": "Queues"
    },
    "SearchRoutingProfiles": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "non_aggregate_keys": [
        "ApproximateTotalCount"
      ],
      "output_token": "NextToken",
      "result_key": "RoutingProfiles"
    },
    "SearchSecurityProfiles": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "non_aggregate_keys": [
        "ApproximateTotalCount"
      ],
      "output_token": "NextToken",
      "result_key": "SecurityProfiles"
    },
    "SearchUsers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "non_aggregate_keys": [
        "ApproximateTotalCount"
      ],
      "output_token": "NextToken",
      "result_key": "Users"
    },
    "SearchVocabularies": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VocabularySummaryList"
    }
  }
}