{
  "version": 2,
  "waiters": {
    "AlarmExists": {
      "delay": 5,
      "maxAttempts": 40,
      "operation": "DescribeAlarms",
      "acceptors": [
        {
          "matcher": "path",
          "expected": true,
          "argument": "length(MetricAlarms[]) > `0`",
          "state": "success"
        }
      ]
    },
    "CompositeAlarmExists": {
      "delay": 5,
      "maxAttempts": 40,
      "operation": "DescribeAlarms",
      "acceptors": [
        {
          "matcher": "path",
          "expected": true,
          "argument": "length(CompositeAlarms[]) > `0`",
          "state": "success"
        }
      ]
    }
  }
}
