import {MuiThemeProvider} from "@material-ui/core/styles";
import {createTheme, FormControl, TextField} from "@material-ui/core";
import React, {ChangeEvent, useEffect, useState} from "react";
import "./expenseForm.css";
import {createExpense} from "../../api/expense";
import {Auth} from "../core";
import {useHistory} from "react-router-dom";
import {InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import LoadingBar from "../reimbursement/loading";

interface User {
    id: number;
    role: number;
}

const theme = createTheme({
    typography: {
        fontSize: 18,
        fontFamily: "Montserrat"
    }
});

const EXPENSE_TYPE = "REPORT";
const DEFAULT_CURRENCY = "USD";

export default function ExpenseForm() {

    const history = useHistory();
    const [user, setUser] = useState<User|null>(null);
    const [transactionDate, setTransactionDate] = useState("");
    const [payee, setPayee] = useState("");
    const [description, setDescription] = useState("");
    const [amount, setAmount] = useState("0.00");
    const [paymentMethod, setPaymentMethod] = useState("CHECK");
    const [paymentReferenceNumber, setPaymentReferenceNumber] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [failedToSubmit, setFailedToSubmit] = useState<boolean>(false);

    const loadUser = async () => {
        if (!user) {
            setUser(await Auth.getUser());
        }
    }

    const authorizeUser = () => {
        if (user && user.role !== 2) {
            history.push('/unauthorized');
        }
    }

    const loadPage = async () => {
        await loadUser();
        authorizeUser();
    }

    useEffect(() => {
        loadPage();
    }, [user, isSubmitted]);

    const handlePayeeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPayee(event.target.value)
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value)
    }

    // TODO: How to do number for the event type?
    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        setAmount(event.target.value)
    }

    const handlePaymentMethodChange = (event: SelectChangeEvent) => {
        setPaymentMethod(event.target.value as string);
    };

    const handleReferenceNumber = (event: ChangeEvent<HTMLInputElement>) => {
        setPaymentReferenceNumber(event.target.value);
    }

    const handleSubmit = async () =>  {
        setIsSubmitted(true);

        // The request amount is in minor units, so we need to multiply with 100 to
        // remove the two decimal points
        let finalAmount = 100 * parseFloat(amount);

        let request = {
            userID: user?.id,
            amount: {
                amount: finalAmount,
                currency: DEFAULT_CURRENCY
            },
            transactionDate: transactionDate,
            type: EXPENSE_TYPE,
            description: description,
            title: payee,
            category: "UNKNOWN", // We are not sure what the category is right now
            receipts: [],
            report: {
                payment: {
                    type: paymentMethod,
                    paymentID: paymentReferenceNumber,
                    paymentDate: transactionDate
                }
            }
        }

        let response = await createExpense(request);
        if (response) {
            window.location.replace("/confirmation");
        } else {
            setFailedToSubmit(true);
            setIsSubmitted(false);
        }
    }

    return (
        <MuiThemeProvider theme={theme}>
            <div className="container">
                <div
                    className="loadingBar"
                    style={{display: isSubmitted ? "flex" : "none"}}
                >
                    <LoadingBar inProgress={isSubmitted}/>
                </div>
                <form className="expenseForm">
                    <h1>Expense Form</h1>
                    {failedToSubmit && (
                        <p className="errorMessage">
                            Something went wrong. Failed to submit! Please verify input.
                        </p>
                    )}
                    <div>
                        <TextField
                            required
                            id="expenseDate"
                            label="Expense Date"
                            type="date"
                            defaultValue={transactionDate}
                            InputProps={{disableUnderline: true}}
                            InputLabelProps={{shrink: true}}
                            onBlur={event =>
                                setTransactionDate(event.target.value)
                            }
                        />
                    </div>

                    <div>
                        <TextField
                            required
                            id="payee"
                            fullWidth
                            label="Payee"
                            margin="normal"
                            InputProps={{disableUnderline: true}}
                            InputLabelProps={{shrink: true}}
                            placeholder="Payee"
                            value={payee}
                            onChange={handlePayeeChange}
                        />
                    </div>

                    <div>
                        <TextField
                            multiline
                            required
                            id="description"
                            fullWidth
                            label="Description"
                            margin="normal"
                            InputProps={{disableUnderline: true}}
                            InputLabelProps={{shrink: true}}
                            placeholder="Provide as many details and expenses breakdown as you can"
                            rows="10"
                            value={description}
                            onChange={handleDescriptionChange}
                        />
                    </div>

                    <div>
                        <TextField
                            required
                            id="amount"
                            fullWidth
                            label="Amount"
                            margin="normal"
                            InputProps={{disableUnderline: true}}
                            InputLabelProps={{shrink: true}}
                            placeholder="12.34"
                            value={amount}
                            onChange={handleAmountChange}
                        />
                    </div>

                    <div>
                        <FormControl required>
                            <InputLabel>Payment Method</InputLabel>
                            <Select
                                value={paymentMethod}
                                label="Payment Method"
                                disableUnderline={true}
                                onChange={handlePaymentMethodChange}
                            >
                                <MenuItem value={"CHECK"}>Check</MenuItem>
                                <MenuItem value={"CREDIT_CARD"}>Credit Card</MenuItem>
                                <MenuItem value={"ACH"}>ACH</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div>
                        <TextField
                            required
                            id="referenceNumber"
                            fullWidth
                            label="Reference Number"
                            margin="normal"
                            InputProps={{disableUnderline: true}}
                            InputLabelProps={{shrink: true}}
                            placeholder="123"
                            value={paymentReferenceNumber}
                            onChange={handleReferenceNumber}
                        />
                    </div>

                    <div>
                        <input
                            id="submitButton"
                            type="button"
                            value="Submit"
                            onClick={handleSubmit}
                        />
                    </div>
                </form>
            </div>
        </MuiThemeProvider>
    );
}