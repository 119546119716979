import React from 'react'
import {
    Button,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    Icon,
    IconButton,
    Paper,
    Tab,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from '@material-ui/core'
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import {getDirectoryByEmail} from '../../api/directory'
import {getReports, markAsPaid, putReportStatus} from '../../api/report';
import {Document, Page, pdfjs} from 'react-pdf';
import {Roles} from './Enum'
import {Auth} from '../core'
import './Dashboard.css'
import {css} from '@emotion/react';
import {MoonLoader} from 'react-spinners';
import LoadingBar from "./loading";

import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'
import {convertToCapitalCase} from "./Utility";

import MaterialTable from "material-table";

const theme = createTheme({
    palette: {
        primary: {
            main: "#1976d2",
        },
        secondary: {
            main: "#ffffff"
        }
    },
    typography: {
        fontSize: 12,
        fontFamily: "Montserrat"
    }
});

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'npm:pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

const options = {
    cMapUrl: 'cmaps/',
    standardFontDataUrl: 'standard_fonts/',
};

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.handleApply = this.handleApply.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleMarkAsPaid = this.handleMarkAsPaid.bind(this);

        this.state = {
            reportsByStatus: {},
            statuses: ['Open', 'Approved', 'Paid', 'Denied', 'Cancelled'],
            email: '',
            review: undefined,
            markAsPaidReport: undefined,
            order: 'desc',
            orderBy: 'expenseDate',
            startDate: moment().subtract(2, 'month'),
            endDate: moment(),
            loaderEnabledMap: {},
            updateInProgress: false,
            tabStatus: 0,
            loading: true,
            idToDelete: undefined,
            notes: undefined,
            urlToPageCountMap: {},
        }

        moment.updateLocale(moment.locale(), {invalidDate: ""});
    }

    // Get user information to determined the dashboard view and the reports to load
    async componentDidMount() {
        await this.getUserInformation();
        await this.loadReports();
    }

    // Get all information about user from directory
    async getUserInformation() {
        const user = await Auth.getUser();
        const email = user.email;
        let directories = await getDirectoryByEmail(email);
        let userInformation = directories[0];
        this.setState({userInformation, email});
    }

    // Convert dates in report from UTC to local timezone
    convertDatesInReport(report) {
        report.expenseDate = moment(report.expenseDate);
        report.closeDate = moment.utc(report.closeDate).local();
        report.openDate = moment.utc(report.openDate).local();
        report.lastModifiedDate = moment.utc(report.lastModifiedDate).local();
    }

    // Get the reports that user can view based on their role
    async loadReports() {
        const {email, statuses, startDate, endDate} = this.state;

        let reports = await getReports(email, startDate, endDate);
        if (reports) {
            reports.forEach(this.convertDatesInReport);

            // classify
            let submittedReports = reports.filter(report => report.email === email);

            let reportsByStatus = {};
            statuses.map(status => reportsByStatus[status] = []);

            reports.filter(report => report.email !== email).map(
                report => reportsByStatus[report.status].push(report)
            )

            // sort reports
            submittedReports = this.sortReports(submittedReports);
            statuses.map(status => reportsByStatus[status] = this.sortReports(reportsByStatus[status]));

            this.setState({
                submittedReports,
                reportsByStatus
            });
        }

        this.disableLoadingBar();
    }

    // turn off the loading bar (spinner)
    disableLoadingBar() {
        this.setState({loading: false})
    }

    // render the spinner
    renderLoading() {
        const {loading} = this.state;
        return (
            <div style={{position: 'fixed', top: '45%', left: '45%'}}>
                <LoadingBar inProgress={loading}/>
            </div>
        )
    }

    // Helper function to sort the reports
    sortReports(reports) {
        const {order, orderBy} = this.state;
        return reports.sort((reportA, reportB) => {
            let a = reportA[orderBy];
            let b = reportB[orderBy];
            let comparison;
            if (orderBy === 'expenseDate' || orderBy === 'closeDate') {
                comparison = Date.parse(a) - Date.parse(b)
            } else if (orderBy === 'reimbursementAmount') {
                comparison = a - b
            } else {
                comparison = a.localeCompare(b)
            }
            if (order === 'desc') {
                comparison = -comparison
            }
            return comparison
        })
    }

    // Apply the date range chosen by user from DatePicker
    handleApply(event, picker) {
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate.endOf('day'),
            loading: true,
        });
        this.loadReports()
    }

    // Handle the event when user cancel the DatePicker
    handleCancel(event) {
        const {startDate, endDate} = this.state;
        this.setState({
            startDate,
            endDate
        });
    }

    // Handle node change
    handleNotesChange = (event) => {
        this.setState({notes: event.target.value});
    }

    // Handle close deny dialog
    handleCloseDenyDialog = () => this.setState({idToDelete: undefined, notes: undefined});

    // render the options of date range in the DatePicker
    renderDateRange() {
        let ranges = {
            'Today': [moment(), moment()],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'Last 2 Months': [moment().subtract(2, 'month'), moment()],
            'Last 3 Months': [moment().subtract(3, 'month'), moment()],
            'Last 6 Months': [moment().subtract(6, 'month'), moment()]
        };

        let {startDate, endDate} = this.state;

        let initialSettings = {
            ranges: ranges,
            alwaysShowCalendars: true,
            showCustomRangeLabel: true,
            showDropdowns: true,
            startDate: startDate,
            endDate: endDate
        }

        return (
            <DateRangePicker
                initialSettings={initialSettings}
                onApply={this.handleApply}
                onCancel={this.handleCancel}
                color="primary"
            >
                <ButtonGroup variant="contained" color="secondary" aria-label="split button">
                    <Button>
                        {startDate.format("MM/DD/YYYY")} - {endDate.format("MM/DD/YYYY")}
                    </Button>
                    <Button
                        size="small"
                        aria-haspopup="true"
                        onClick={this.handleClick}
                    >
                        <i className="fa fa-calendar" aria-hidden="true"> </i>
                        <ArrowDropDownIcon/>
                    </Button>
                </ButtonGroup>
            </DateRangePicker>
        );
    }

    // Render the views based on role
    // Currently: Approver and AccountPayable are able to see extra reports under their care
    renderReportBasedOnRole() {
        const {userInformation, statuses, loading} = this.state;

        if (!loading && userInformation && (userInformation.role === Roles.Approver || userInformation.role === Roles.AccountPayable)) {
            return statuses.map(status => this.renderReportTableForApproverOrAccountPayable(status))
        }
    }

    // Render the dialog that allows approver to navigate through image attachments
    renderReportReviewDialog() {
        const {review} = this.state;
        if (review) {
            return (
                <Dialog open={!!review} onClose={this.handleClose} className="dialog" maxWidth="xl">
                    <DialogContent>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            {this.renderImagesWithDivider(review.imagePaths)}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="column">
                            {this.renderApproveDenyButtons()}
                        </Grid>
                    </DialogActions>
                </Dialog>
            )
        }
    }

    /** Render images separated by dividers. */
    renderImagesWithDivider(images) {
        let divider = undefined;
        const imagesWithDivider = [];
        for (const image of images) {
            if (divider) {
                imagesWithDivider.push(divider);
            } else {
                divider = <Divider key={`${image}-divider`} className="divider"/>;
            }
            imagesWithDivider.push(this.renderImage(image));
        }
        return imagesWithDivider;
    }

    renderDenyDialog() {
        const {idToDelete, notes} = this.state;

        if (idToDelete) {
            return (
                <Dialog open={!!idToDelete} onClose={this.handleCloseDenyDialog} fullWidth maxWidth="xs"
                        aria-labelledby="form-dialog-deny">
                    <DialogTitle id="form-deny-title"><b>Confirmation</b></DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            id="notes"
                            label="Reason (optional)"
                            type="text"
                            onChange={this.handleNotesChange}
                            multiline
                            fullWidth
                            inputProps={{maxLength: 100}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDenyDialog} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" onClick={this.handleStatusChange(idToDelete, 'Denied', notes)}
                                color="primary">
                            Deny
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
    }

    // Render the dialog that allows account payable to enter check number and mark report as 'Paid'
    renderMarkAsPaidReviewDialog() {
        const report = this.state.markAsPaidReport;

        if (report) {
            report.checkDate = moment().format("YYYY-MM-DD");
            return (
                <div>
                    <Dialog open={!!report} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title"><b>Confirmation</b></DialogTitle>
                        <DialogContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="normal">Id</TableCell>
                                        <TableCell padding="normal">{report.id}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell padding="normal">Name</TableCell>
                                        <TableCell
                                            padding="normal">{report.submitterFirstName} {report.submitterLastName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell padding="normal">Category</TableCell>
                                        <TableCell padding="normal">{report.expenseTitle}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell padding="normal">Description</TableCell>
                                        <TableCell padding="normal">{report.expenseDescription}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell padding="normal">Amount</TableCell>
                                        <TableCell padding="normal">${report.reimbursementAmount}</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </DialogContent>
                        <FormControl>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    id="checkNumber"
                                    label="Check Number"
                                    type="number"
                                    onChange={this.handleCheckNumberChange()}
                                    fullWidth
                                    required
                                />
                            </DialogContent>
                            <DialogContent>
                                <TextField
                                    id="checkDate"
                                    label="Check Date"
                                    type="date"
                                    defaultValue={report.checkDate}
                                    onChange={this.handleCheckDateChange()}
                                    fullWidth
                                    required
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit" onClick={this.handleMarkAsPaid} color="primary">
                                    Mark As Paid
                                </Button>
                            </DialogActions>
                        </FormControl>
                    </Dialog>
                </div>
            )
        }
    }

    // Render Approve/Deny buttons for approver only
    renderApproveDenyButtons() {
        const {userInformation, review} = this.state;
        if (!userInformation) {
            return;
        }
        const isReviewerSameAsApprover = review.email === userInformation.email;
        if (isReviewerSameAsApprover) {
            return;
        }
        const isReviewerAnAppprover = userInformation.role === Roles.Approver;
        if (isReviewerAnAppprover) {
            return (
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        <Button onClick={this.handleStatusChange(review.id, 'Approved')}
                                className="approve">Approve</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => this.handleDenyClick(review.id)} className="deny">Deny</Button>
                    </Grid>
                </Grid>
            )
        }
    }

    // Render image attachment given the url
    renderImage(url) {
        if (this.isPdf(url)) {
            const {urlToPageCountMap} = this.state;
            const pageCount = urlToPageCountMap ? urlToPageCountMap[url] : 0;
            return (
                <Document
                    file={url}
                    onLoadSuccess={(pdf) => this.setPdfInfo(pdf, url)}
                    key={url}
                    options={options}
                >
                    {Array.from(
                        new Array(pageCount),
                        (el, index) => (
                            <Page
                                renderAnnotationLayer={false}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                            />
                        ),
                    )}
                </Document>
            )
        } else {
            return <img alt="receipt" src={url} className="full-image" key={url}/>
        }
    }

    // Check if the given url is a pdf
    isPdf(url) {
        return this.getFileExtension(url) === 'pdf'
    }

    // Get the file extension given the url
    getFileExtension(url) {
        let parsedUrl = new URL(url);
        let pathname = parsedUrl.pathname;
        return pathname.substring(pathname.lastIndexOf('.') + 1, pathname.length)
    }

    // Set the page count information of each pdf
    setPdfInfo = (pdf, url) => {
        const {urlToPageCountMap} = this.state;
        urlToPageCountMap[url] = pdf.numPages;
        this.setState({urlToPageCountMap})
    };

    // Handle closing the image attachment dialog itself
    handleClose = () => {
        this.setState({review: undefined, markAsPaidReport: undefined})
    };

    // Handle check number change
    handleCheckNumberChange = () => (event) => {
        this.state.markAsPaidReport.checkNumber = event.target.value;
    };

    // Handle check date change
    handleCheckDateChange = () => (event) => {
        this.state.markAsPaidReport.checkDate = event.target.value;
    };

    // Handle status change of a report
    // This function involves showing spinner correctly
    handleStatusChange = (id, status, notes = null) => async () => {
        // Turn off deny dialog
        this.setState({idToDelete: undefined});

        // Turn on spinner
        let loaderEnabledMap = this.state.loaderEnabledMap;
        loaderEnabledMap[id] = true;
        this.setState({loaderEnabledMap});

        // Send update request and reload reports
        await putReportStatus(id, status, notes)
        this.setState({review: undefined});
        await this.loadReports()

        // Turn off spinner
        loaderEnabledMap[id] = false;
        this.setState({loaderEnabledMap})
    };

    handleDenyClick = (id) => {
        this.setState({idToDelete: id});
    };

    // Handle mark as paid by account payable
    handleMarkAsPaid() {
        // Mark report as paid with the check number
        const report = this.state.markAsPaidReport
        this.setState({
                updateInProgress: true,
                markAsPaidReport: undefined
            }, async () => {
                const result = await markAsPaid(report.id, report.checkNumber, report.checkDate);

                if (result.status === 'Paid') {
                    this.state.reportsByStatus['Approved'] = this.state.reportsByStatus['Approved'].filter(approvedReport => approvedReport.id != result.id)
                    this.state.reportsByStatus['Paid'].push(result);
                    this.state.reportsByStatus['Paid'] = this.sortReports(this.state.reportsByStatus['Paid']);
                }

                this.setState({updateInProgress: false})
            }
        );
    };

    buildTable(status) {
        const {userInformation} = this.state;

        const sharedData = [
            {title: "ID", field: "id"},
            {title: "Name", field: "name"},
            {title: "Expense Date", field: "expenseDate", type: "date"},
            {title: "Approved Date", field: "closeDate", type: "date"},
            {title: "Title", field: "expenseTitle"},
            {title: "Description", field: "expenseDescription"},
            {title: "Approver", field: "approverEmail"},
            {
                title: "Amount", field: "reimbursementAmount", type: "currency", currencySetting: {currencyCode: "USD"},
                align: "left"
            },
        ]

        const receipt = [{title: "Receipts", render: report => this.renderThumbnail(report), sorting: false},]

        if (status === 'Paid') {
            return sharedData.concat([
                {title: "Check Number", field: "checkNumber"},
                {title: "Check Date", field: "checkDate"},
            ], receipt)
        } else if (status === 'Approved') {
            if (userInformation.role === Roles.AccountPayable) {
                return sharedData.concat(receipt, [
                    {
                        title: "Actions",
                        render: report => this.renderAccountPayableActionColumn(report, status),
                        sorting: false
                    },
                ])
            }
        } else if (userInformation.role === Roles.Approver) {
            return sharedData.concat(receipt, [
                {title: "Actions", render: report => this.renderApproverActionColumn(report, status), sorting: false},
            ])
        }
        return sharedData.concat(receipt)
    }

    renderReportTableForApproverOrAccountPayable(status) {
        const {reportsByStatus, tabStatus, statuses} = this.state;
        const reports = reportsByStatus[status].map(report => {
            return {
                id: report.id,
                email: report.email,
                name: `${report.submitterFirstName} ${report.submitterLastName}`,
                expenseDate: report.expenseDate.format('ll'),
                closeDate: report.closeDate.format('ll'),
                expenseTitle: convertToCapitalCase(report.expenseTitle),
                expenseDescription: report.expenseDescription,
                approverEmail: report.approverEmail,
                reimbursementAmount: report.reimbursementAmount,
                checkNumber: report.checkNumber,
                checkDate: moment(report.checkDate).format('ll'),
                imagePaths: report.imagePaths,
            };
        });
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={tabStatus !== statuses.indexOf(status) + 1}
                key={`tabstatus-${statuses.indexOf(status)}`}
            >
                <Grid key={status}>
                    {reports && reports.length > 0 ? (
                        <Paper className="table-container">
                            <div style={{maxWidth: "100%"}}>
                                <MaterialTable
                                    columns={this.buildTable(status)}
                                    data={reports}
                                    options={{
                                        exportButton: true,
                                        paging: false,
                                        actionsColumnIndex: -1
                                    }}
                                    title=""
                                />
                            </div>
                        </Paper>
                    ) : (
                        <Typography>No {status} Reports</Typography>
                    )}
                </Grid>
            </Typography>
        )
    }

// Render the action column for approver
    renderApproverActionColumn(report, status) {
        const {userInformation} = this.state;
        if (userInformation.role === Roles.Approver) {
            if (status === 'Open') {
                return (
                    <Grid>
                        <IconButton onClick={!report.imagePaths || report.imagePaths.length === 0 ?
                            this.handleStatusChange(report.id, 'Approved') :
                            this.handleReview(report)}
                                    className="approve"
                                    title="Approve">
                            <Icon>check_circle</Icon>
                        </IconButton>
                        <IconButton onClick={() => this.handleDenyClick(report.id)}
                                    className="deny"
                                    title="Deny">
                            <Icon>cancel</Icon>
                        </IconButton>
                    </Grid>
                )
            } else {
                return (
                    <IconButton onClick={this.handleStatusChange(report.id, 'Open')} title="Re-open">
                        <Icon>undo</Icon>
                    </IconButton>
                )
            }
        }
    }

// Render the action column for account payable
    renderAccountPayableActionColumn(report, status) {
        const {userInformation} = this.state;
        if (userInformation.role === Roles.AccountPayable) {
            if (status === 'Approved') {
                return (
                    <TableCell padding="normal">
                        <IconButton onClick={this.handleMarkAsPaidReview(report)} title="Mark as 'Paid'">
                            <AttachMoneyIcon></AttachMoneyIcon>
                        </IconButton>
                    </TableCell>
                )
            }
        }
    }

// Render the report table that user submitted
    renderUserSubmittedReports() {
        const {submittedReports, tabStatus, loading} = this.state;
        if (!submittedReports) {
            return <Typography>No Submitted Reports</Typography>
        }
        const reports = submittedReports.map(report => {
            return {
                id: report.id,
                email: report.email,
                name: report.submitterFirstName + ' ' + report.submitterLastName,
                expenseDate: report.expenseDate.format('ll'),
                closeDate: report.closeDate.format('ll'),
                expenseTitle: convertToCapitalCase(report.expenseTitle),
                expenseDescription: report.expenseDescription,
                approverEmail: report.approverEmail,
                reimbursementAmount: report.reimbursementAmount,
                status: report.status,
                notes: report.notes,
                checkNumber: report.checkNumber,
                imagePaths: report.imagePaths,
            };
        });
        const submissionTab = 0;
        let openExists = reports && reports.some(report => (
            report.status === 'Open'
        ));
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={tabStatus !== submissionTab || loading}
                key={`tabstatus-${submissionTab}`}
            >
                <Grid>
                    {reports && reports.length > 0 ? (
                        <Paper className="table-container">
                            <div style={{maxWidth: "100%"}}>
                                <MaterialTable
                                    columns={[
                                        {title: "ID", field: "id"},
                                        {title: "Name", field: "name"},
                                        {title: "Expense Date", field: "expenseDate", type: "date"},
                                        {title: "Approved Date", field: "closeDate", type: "date"},
                                        {title: "Title", field: "expenseTitle"},
                                        {title: "Description", field: "expenseDescription"},
                                        {title: "Approver", field: "approverEmail"},
                                        {
                                            title: "Amount",
                                            field: "reimbursementAmount",
                                            type: "currency",
                                            currencySetting: {currencyCode: "USD"},
                                            align: "left"
                                        },
                                        {title: "Status", field: "status"},
                                        {title: "Notes", field: "notes"},
                                        {title: "Check Number", field: "checkNumber"},
                                        {
                                            title: "Receipts",
                                            render: report => this.renderThumbnail(report),
                                            sorting: false
                                        },
                                        {
                                            title: "Actions",
                                            render: report => openExists ? this.renderSubmitterActionColumn(report) : null,
                                            sorting: false
                                        },
                                    ]}
                                    data={reports}
                                    options={{
                                        exportButton: true,
                                        paging: false
                                    }}
                                    title=""
                                />
                            </div>
                        </Paper>
                    ) : (
                        <Typography>No Submitted Reports</Typography>
                    )
                    }
                </Grid>
            </Typography>
        )
    }

    // Handle the event when user change tabs
    handleTabs = (event, newValue) => {
        this.setState({tabStatus: newValue});
    }

    // render tabs for different reports
    renderTab() {
        const {tabStatus, userInformation, loading, reportsByStatus} = this.state;
        const openReportsCount = reportsByStatus && reportsByStatus['Open'] ? reportsByStatus['Open'].length : 0;
        const approvedReportsCountForAccountsPayable = userInformation && userInformation.role === Roles.AccountPayable
        && reportsByStatus && reportsByStatus['Approved'] ? reportsByStatus['Approved'].length : 0;

        return (
            <Grid hidden={loading}>
                {(userInformation && (userInformation.role === Roles.Approver || userInformation.role === Roles.AccountPayable)) ? (
                    <Tabs
                        value={tabStatus}
                        onChange={this.handleTabs}
                        aria-label="tabs"
                        variant={'scrollable'}
                        textColor={'primary'}
                        indicatorColor="primary"
                    >
                        <Tab label="Your Submission"/>
                        <Tab label={"Open " + "(" + openReportsCount + ")"}/>
                        <Tab
                            label={userInformation.role == Roles.AccountPayable ? "Approved " + "(" + approvedReportsCountForAccountsPayable + ")" : "Approved"}/>
                        <Tab label="Paid"/>
                        <Tab label="Denied"/>
                        <Tab label="Cancelled"/>
                    </Tabs>
                ) : (
                    <Tabs
                        value={tabStatus}
                        textColor={'primary'}
                        indicatorColor="primary"
                    >
                        <Tab label="Your Submission"/>
                    </Tabs>
                )
                }
            </Grid>
        )
    }

    // Render the action column for submitter
    renderSubmitterActionColumn(report) {
        const loaderEnabled = this.state.loaderEnabledMap[report.id];
        const isLoading = loaderEnabled != null && loaderEnabled;
        const override = css`
            display: block;
            margin: 0 auto;
        `;

        return (
            <div className="cancelButton">
                {
                    !isLoading && report.status === 'Cancelled' ? (
                        <IconButton onClick={this.handleStatusChange(report.id, 'Open')} title="Re-open">
                            <Icon>undo</Icon>
                        </IconButton>
                    ) : (!isLoading && report.status === 'Open') ? (
                        <Button onClick={this.handleStatusChange(report.id, 'Cancelled')}
                                className="cancel">Cancel</Button>
                    ) : null
                }
                <MoonLoader
                    css={override}
                    sizeUnit={"px"}
                    size={20}
                    margin={"0px"}
                    color={'#123abc'}
                    loading={isLoading}
                />
            </div>
        );
    }

    // Render the image thumbnail
    renderThumbnail(report) {
        if (report.imagePaths && report.imagePaths.length > 0) {
            let url = report.imagePaths[0];
            return (
                <Grid onClick={this.handleImageClick(report)} className="thumbnail link">
                    {this.isPdf(url) ? (
                        <Document file={url} loading="">
                            <Page
                                height={50}
                                pageNumber={1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        </Document>
                    ) : (
                        <img alt="thumbnail" className="thumbnail" src={url}/>
                    )}
                </Grid>
            )
        } else {
            return <Typography>None</Typography>
        }
    }

    // Handle image click event
    handleImageClick = (report) => () => {
        this.setState({review: report})
    };

    // Handle review event
    handleReview = (report) => () => {
        this.setState({review: report})
    };

    handleMarkAsPaidReview = (report) => () => {
        this.setState({markAsPaidReport: report})
    };

    // Render the Dashboard
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <div
                    className="loadingBar"
                    style={{display: this.state.updateInProgress ? "flex" : "none"}}
                >
                    <LoadingBar inProgress={this.state.updateInProgress}/>
                </div>
                <Grid className="dashboard" style={{padding: 24}}>
                    {this.renderReportReviewDialog()}
                    {this.renderDenyDialog()}
                    {this.renderMarkAsPaidReviewDialog()}
                    {this.renderDateRange()}
                    {this.renderLoading()}
                    {this.renderTab()}
                    {this.renderUserSubmittedReports()}
                    {this.renderReportBasedOnRole()}
                </Grid>
            </MuiThemeProvider>
        )
    }
}

export default Dashboard
