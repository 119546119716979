{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-08-20",
    "endpointPrefix": "s3-control",
    "protocol": "rest-xml",
    "serviceFullName": "AWS S3 Control",
    "serviceId": "S3 Control",
    "signatureVersion": "s3v4",
    "signingName": "s3",
    "uid": "s3control-2018-08-20"
  },
  "operations": {
    "CreateAccessPoint": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/accesspoint/{name}"
      },
      "input": {
        "locationName": "CreateAccessPointRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "AccountId",
          "Name",
          "Bucket"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            }
          },
          "VpcConfiguration": {
            "shape": "S5"
          },
          "PublicAccessBlockConfiguration": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccessPointArn": {},
          "Alias": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "CreateAccessPointForObjectLambda": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/accesspointforobjectlambda/{name}"
      },
      "input": {
        "locationName": "CreateAccessPointForObjectLambdaRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "AccountId",
          "Name",
          "Configuration"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "Configuration": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ObjectLambdaAccessPointArn": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "CreateBucket": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/bucket/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "uri",
            "locationName": "name"
          },
          "CreateBucketConfiguration": {
            "locationName": "CreateBucketConfiguration",
            "xmlNamespace": {
              "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
            },
            "type": "structure",
            "members": {
              "LocationConstraint": {}
            }
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWrite": {
            "location": "header",
            "locationName": "x-amz-grant-write"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          },
          "ObjectLockEnabledForBucket": {
            "location": "header",
            "locationName": "x-amz-bucket-object-lock-enabled",
            "type": "boolean"
          },
          "OutpostId": {
            "contextParam": {
              "name": "OutpostId"
            },
            "location": "header",
            "locationName": "x-amz-outpost-id"
          }
        },
        "payload": "CreateBucketConfiguration"
      },
      "output": {
        "type": "structure",
        "members": {
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "BucketArn": {}
        }
      },
      "httpChecksumRequired": true
    },
    "CreateJob": {
      "http": {
        "requestUri": "/v20180820/jobs"
      },
      "input": {
        "locationName": "CreateJobRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "AccountId",
          "Operation",
          "Report",
          "ClientRequestToken",
          "Priority",
          "RoleArn"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "ConfirmationRequired": {
            "type": "boolean"
          },
          "Operation": {
            "shape": "S19"
          },
          "Report": {
            "shape": "S2g"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Manifest": {
            "shape": "S2k"
          },
          "Description": {},
          "Priority": {
            "type": "integer"
          },
          "RoleArn": {},
          "Tags": {
            "shape": "S1r"
          },
          "ManifestGenerator": {
            "shape": "S2v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "CreateMultiRegionAccessPoint": {
      "http": {
        "requestUri": "/v20180820/async-requests/mrap/create"
      },
      "input": {
        "locationName": "CreateMultiRegionAccessPointRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "AccountId",
          "ClientToken",
          "Details"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "Details": {
            "shape": "S3b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestTokenARN": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "httpChecksumRequired": true,
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeleteAccessPoint": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/accesspoint/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "contextParam": {
              "name": "AccessPointName"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeleteAccessPointForObjectLambda": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/accesspointforobjectlambda/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeleteAccessPointPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/accesspoint/{name}/policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "contextParam": {
              "name": "AccessPointName"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeleteAccessPointPolicyForObjectLambda": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/accesspointforobjectlambda/{name}/policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeleteBucket": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/bucket/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeleteBucketLifecycleConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/bucket/{name}/lifecycleconfiguration"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeleteBucketPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/bucket/{name}/policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeleteBucketTagging": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/bucket/{name}/tagging",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeleteJobTagging": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/jobs/{id}/tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "JobId"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeleteMultiRegionAccessPoint": {
      "http": {
        "requestUri": "/v20180820/async-requests/mrap/delete"
      },
      "input": {
        "locationName": "DeleteMultiRegionAccessPointRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "AccountId",
          "ClientToken",
          "Details"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "Details": {
            "shape": "S3s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestTokenARN": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "httpChecksumRequired": true,
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeletePublicAccessBlock": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/configuration/publicAccessBlock"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeleteStorageLensConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/storagelens/{storagelensid}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ConfigId",
          "AccountId"
        ],
        "members": {
          "ConfigId": {
            "location": "uri",
            "locationName": "storagelensid"
          },
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DeleteStorageLensConfigurationTagging": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/storagelens/{storagelensid}/tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "ConfigId",
          "AccountId"
        ],
        "members": {
          "ConfigId": {
            "location": "uri",
            "locationName": "storagelensid"
          },
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DescribeJob": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/jobs/{id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "JobId"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Job": {
            "type": "structure",
            "members": {
              "JobId": {},
              "ConfirmationRequired": {
                "type": "boolean"
              },
              "Description": {},
              "JobArn": {},
              "Status": {},
              "Manifest": {
                "shape": "S2k"
              },
              "Operation": {
                "shape": "S19"
              },
              "Priority": {
                "type": "integer"
              },
              "ProgressSummary": {
                "shape": "S44"
              },
              "StatusUpdateReason": {},
              "FailureReasons": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "FailureCode": {},
                    "FailureReason": {}
                  }
                }
              },
              "Report": {
                "shape": "S2g"
              },
              "CreationTime": {
                "type": "timestamp"
              },
              "TerminationDate": {
                "type": "timestamp"
              },
              "RoleArn": {},
              "SuspendedDate": {
                "type": "timestamp"
              },
              "SuspendedCause": {},
              "ManifestGenerator": {
                "shape": "S2v"
              },
              "GeneratedManifestDescriptor": {
                "type": "structure",
                "members": {
                  "Format": {},
                  "Location": {
                    "shape": "S2p"
                  }
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "DescribeMultiRegionAccessPointOperation": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/async-requests/mrap/{request_token+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "RequestTokenARN"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "RequestTokenARN": {
            "location": "uri",
            "locationName": "request_token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AsyncOperation": {
            "type": "structure",
            "members": {
              "CreationTime": {
                "type": "timestamp"
              },
              "Operation": {},
              "RequestTokenARN": {},
              "RequestParameters": {
                "type": "structure",
                "members": {
                  "CreateMultiRegionAccessPointRequest": {
                    "shape": "S3b"
                  },
                  "DeleteMultiRegionAccessPointRequest": {
                    "shape": "S3s"
                  },
                  "PutMultiRegionAccessPointPolicyRequest": {
                    "shape": "S4q"
                  }
                }
              },
              "RequestStatus": {},
              "ResponseDetails": {
                "type": "structure",
                "members": {
                  "MultiRegionAccessPointDetails": {
                    "type": "structure",
                    "members": {
                      "Regions": {
                        "type": "list",
                        "member": {
                          "locationName": "Region",
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "RequestStatus": {}
                          }
                        }
                      }
                    }
                  },
                  "ErrorDetails": {
                    "type": "structure",
                    "members": {
                      "Code": {},
                      "Message": {},
                      "Resource": {},
                      "RequestId": {}
                    }
                  }
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "httpChecksumRequired": true,
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetAccessPoint": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/accesspoint/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "contextParam": {
              "name": "AccessPointName"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Bucket": {},
          "NetworkOrigin": {},
          "VpcConfiguration": {
            "shape": "S5"
          },
          "PublicAccessBlockConfiguration": {
            "shape": "S7"
          },
          "CreationDate": {
            "type": "timestamp"
          },
          "Alias": {},
          "AccessPointArn": {},
          "Endpoints": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetAccessPointConfigurationForObjectLambda": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/accesspointforobjectlambda/{name}/configuration"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configuration": {
            "shape": "Se"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetAccessPointForObjectLambda": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/accesspointforobjectlambda/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "PublicAccessBlockConfiguration": {
            "shape": "S7"
          },
          "CreationDate": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetAccessPointPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/accesspoint/{name}/policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "contextParam": {
              "name": "AccessPointName"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetAccessPointPolicyForObjectLambda": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/accesspointforobjectlambda/{name}/policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetAccessPointPolicyStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/accesspoint/{name}/policyStatus"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "contextParam": {
              "name": "AccessPointName"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyStatus": {
            "shape": "S5f"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetAccessPointPolicyStatusForObjectLambda": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/accesspointforobjectlambda/{name}/policyStatus"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyStatus": {
            "shape": "S5f"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetBucket": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/bucket/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Bucket": {},
          "PublicAccessBlockEnabled": {
            "type": "boolean"
          },
          "CreationDate": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetBucketLifecycleConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/bucket/{name}/lifecycleconfiguration"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rules": {
            "shape": "S5o"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetBucketPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/bucket/{name}/policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetBucketTagging": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/bucket/{name}/tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TagSet"
        ],
        "members": {
          "TagSet": {
            "shape": "S1r"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetBucketVersioning": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/bucket/{name}/versioning"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket"
        ],
        "members": {
          "AccountId": {
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {},
          "MFADelete": {
            "locationName": "MfaDelete"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      }
    },
    "GetJobTagging": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/jobs/{id}/tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "JobId"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S1r"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetMultiRegionAccessPoint": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/mrap/instances/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccessPoint": {
            "shape": "S6m"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "httpChecksumRequired": true,
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetMultiRegionAccessPointPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/mrap/instances/{name}/policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {
            "type": "structure",
            "members": {
              "Established": {
                "type": "structure",
                "members": {
                  "Policy": {}
                }
              },
              "Proposed": {
                "type": "structure",
                "members": {
                  "Policy": {}
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "httpChecksumRequired": true,
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetMultiRegionAccessPointPolicyStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/mrap/instances/{name}/policystatus"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Name"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Established": {
            "shape": "S5f"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "httpChecksumRequired": true,
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetPublicAccessBlock": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/configuration/publicAccessBlock"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicAccessBlockConfiguration": {
            "shape": "S7"
          }
        },
        "payload": "PublicAccessBlockConfiguration"
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetStorageLensConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/storagelens/{storagelensid}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ConfigId",
          "AccountId"
        ],
        "members": {
          "ConfigId": {
            "location": "uri",
            "locationName": "storagelensid"
          },
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageLensConfiguration": {
            "shape": "S73"
          }
        },
        "payload": "StorageLensConfiguration"
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "GetStorageLensConfigurationTagging": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/storagelens/{storagelensid}/tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "ConfigId",
          "AccountId"
        ],
        "members": {
          "ConfigId": {
            "location": "uri",
            "locationName": "storagelensid"
          },
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S7x"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "ListAccessPoints": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/accesspoint"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "querystring",
            "locationName": "bucket"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccessPointList": {
            "type": "list",
            "member": {
              "locationName": "AccessPoint",
              "type": "structure",
              "required": [
                "Name",
                "NetworkOrigin",
                "Bucket"
              ],
              "members": {
                "Name": {},
                "NetworkOrigin": {},
                "VpcConfiguration": {
                  "shape": "S5"
                },
                "Bucket": {},
                "AccessPointArn": {},
                "Alias": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "ListAccessPointsForObjectLambda": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/accesspointforobjectlambda"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ObjectLambdaAccessPointList": {
            "type": "list",
            "member": {
              "locationName": "ObjectLambdaAccessPoint",
              "type": "structure",
              "required": [
                "Name"
              ],
              "members": {
                "Name": {},
                "ObjectLambdaAccessPointArn": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "ListJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/jobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "JobStatuses": {
            "location": "querystring",
            "locationName": "jobStatuses",
            "type": "list",
            "member": {}
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "JobId": {},
                "Description": {},
                "Operation": {},
                "Priority": {
                  "type": "integer"
                },
                "Status": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "TerminationDate": {
                  "type": "timestamp"
                },
                "ProgressSummary": {
                  "shape": "S44"
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "ListMultiRegionAccessPoints": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/mrap/instances"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccessPoints": {
            "type": "list",
            "member": {
              "shape": "S6m",
              "locationName": "AccessPoint"
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "httpChecksumRequired": true,
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "ListRegionalBuckets": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/bucket"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "OutpostId": {
            "contextParam": {
              "name": "OutpostId"
            },
            "location": "header",
            "locationName": "x-amz-outpost-id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegionalBucketList": {
            "type": "list",
            "member": {
              "locationName": "RegionalBucket",
              "type": "structure",
              "required": [
                "Bucket",
                "PublicAccessBlockEnabled",
                "CreationDate"
              ],
              "members": {
                "Bucket": {},
                "BucketArn": {},
                "PublicAccessBlockEnabled": {
                  "type": "boolean"
                },
                "CreationDate": {
                  "type": "timestamp"
                },
                "OutpostId": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "ListStorageLensConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/storagelens"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "StorageLensConfigurationList": {
            "type": "list",
            "member": {
              "locationName": "StorageLensConfiguration",
              "type": "structure",
              "required": [
                "Id",
                "StorageLensArn",
                "HomeRegion"
              ],
              "members": {
                "Id": {},
                "StorageLensArn": {},
                "HomeRegion": {},
                "IsEnabled": {
                  "type": "boolean"
                }
              }
            },
            "flattened": true
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "PutAccessPointConfigurationForObjectLambda": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/accesspointforobjectlambda/{name}/configuration"
      },
      "input": {
        "locationName": "PutAccessPointConfigurationForObjectLambdaRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "AccountId",
          "Name",
          "Configuration"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "Configuration": {
            "shape": "Se"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "PutAccessPointPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/accesspoint/{name}/policy"
      },
      "input": {
        "locationName": "PutAccessPointPolicyRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "AccountId",
          "Name",
          "Policy"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "contextParam": {
              "name": "AccessPointName"
            },
            "location": "uri",
            "locationName": "name"
          },
          "Policy": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "PutAccessPointPolicyForObjectLambda": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/accesspointforobjectlambda/{name}/policy"
      },
      "input": {
        "locationName": "PutAccessPointPolicyForObjectLambdaRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "AccountId",
          "Name",
          "Policy"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "Policy": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "PutBucketLifecycleConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/bucket/{name}/lifecycleconfiguration"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "uri",
            "locationName": "name"
          },
          "LifecycleConfiguration": {
            "locationName": "LifecycleConfiguration",
            "xmlNamespace": {
              "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
            },
            "type": "structure",
            "members": {
              "Rules": {
                "shape": "S5o"
              }
            }
          }
        },
        "payload": "LifecycleConfiguration"
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "httpChecksumRequired": true,
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "PutBucketPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/bucket/{name}/policy"
      },
      "input": {
        "locationName": "PutBucketPolicyRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket",
          "Policy"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "uri",
            "locationName": "name"
          },
          "ConfirmRemoveSelfBucketAccess": {
            "location": "header",
            "locationName": "x-amz-confirm-remove-self-bucket-access",
            "type": "boolean"
          },
          "Policy": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "httpChecksumRequired": true,
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "PutBucketTagging": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/bucket/{name}/tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket",
          "Tagging"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "contextParam": {
              "name": "Bucket"
            },
            "location": "uri",
            "locationName": "name"
          },
          "Tagging": {
            "locationName": "Tagging",
            "xmlNamespace": {
              "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
            },
            "type": "structure",
            "required": [
              "TagSet"
            ],
            "members": {
              "TagSet": {
                "shape": "S1r"
              }
            }
          }
        },
        "payload": "Tagging"
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "httpChecksumRequired": true,
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "PutBucketVersioning": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/bucket/{name}/versioning"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "Bucket",
          "VersioningConfiguration"
        ],
        "members": {
          "AccountId": {
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Bucket": {
            "location": "uri",
            "locationName": "name"
          },
          "MFA": {
            "location": "header",
            "locationName": "x-amz-mfa"
          },
          "VersioningConfiguration": {
            "locationName": "VersioningConfiguration",
            "xmlNamespace": {
              "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
            },
            "type": "structure",
            "members": {
              "MFADelete": {
                "locationName": "MfaDelete"
              },
              "Status": {}
            }
          }
        },
        "payload": "VersioningConfiguration"
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "httpChecksumRequired": true
    },
    "PutJobTagging": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/jobs/{id}/tagging"
      },
      "input": {
        "locationName": "PutJobTaggingRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "AccountId",
          "JobId",
          "Tags"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "id"
          },
          "Tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "PutMultiRegionAccessPointPolicy": {
      "http": {
        "requestUri": "/v20180820/async-requests/mrap/put-policy"
      },
      "input": {
        "locationName": "PutMultiRegionAccessPointPolicyRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "AccountId",
          "ClientToken",
          "Details"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "Details": {
            "shape": "S4q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestTokenARN": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "httpChecksumRequired": true,
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "PutPublicAccessBlock": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/configuration/publicAccessBlock"
      },
      "input": {
        "type": "structure",
        "required": [
          "PublicAccessBlockConfiguration",
          "AccountId"
        ],
        "members": {
          "PublicAccessBlockConfiguration": {
            "shape": "S7",
            "locationName": "PublicAccessBlockConfiguration",
            "xmlNamespace": {
              "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
            }
          },
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          }
        },
        "payload": "PublicAccessBlockConfiguration"
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "PutStorageLensConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/storagelens/{storagelensid}"
      },
      "input": {
        "locationName": "PutStorageLensConfigurationRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "ConfigId",
          "AccountId",
          "StorageLensConfiguration"
        ],
        "members": {
          "ConfigId": {
            "location": "uri",
            "locationName": "storagelensid"
          },
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "StorageLensConfiguration": {
            "shape": "S73"
          },
          "Tags": {
            "shape": "S7x"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "PutStorageLensConfigurationTagging": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/storagelens/{storagelensid}/tagging"
      },
      "input": {
        "locationName": "PutStorageLensConfigurationTaggingRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "ConfigId",
          "AccountId",
          "Tags"
        ],
        "members": {
          "ConfigId": {
            "location": "uri",
            "locationName": "storagelensid"
          },
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "Tags": {
            "shape": "S7x"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "UpdateJobPriority": {
      "http": {
        "requestUri": "/v20180820/jobs/{id}/priority"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "JobId",
          "Priority"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "id"
          },
          "Priority": {
            "location": "querystring",
            "locationName": "priority",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobId",
          "Priority"
        ],
        "members": {
          "JobId": {},
          "Priority": {
            "type": "integer"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    },
    "UpdateJobStatus": {
      "http": {
        "requestUri": "/v20180820/jobs/{id}/status"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "JobId",
          "RequestedJobStatus"
        ],
        "members": {
          "AccountId": {
            "contextParam": {
              "name": "AccountId"
            },
            "hostLabel": true,
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "id"
          },
          "RequestedJobStatus": {
            "location": "querystring",
            "locationName": "requestedJobStatus"
          },
          "StatusUpdateReason": {
            "location": "querystring",
            "locationName": "statusUpdateReason"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "Status": {},
          "StatusUpdateReason": {}
        }
      },
      "endpoint": {
        "hostPrefix": "{AccountId}."
      },
      "staticContextParams": {
        "RequiresAccountId": {
          "value": true
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "required": [
        "VpcId"
      ],
      "members": {
        "VpcId": {}
      }
    },
    "S7": {
      "type": "structure",
      "members": {
        "BlockPublicAcls": {
          "locationName": "BlockPublicAcls",
          "type": "boolean"
        },
        "IgnorePublicAcls": {
          "locationName": "IgnorePublicAcls",
          "type": "boolean"
        },
        "BlockPublicPolicy": {
          "locationName": "BlockPublicPolicy",
          "type": "boolean"
        },
        "RestrictPublicBuckets": {
          "locationName": "RestrictPublicBuckets",
          "type": "boolean"
        }
      }
    },
    "Se": {
      "type": "structure",
      "required": [
        "SupportingAccessPoint",
        "TransformationConfigurations"
      ],
      "members": {
        "SupportingAccessPoint": {},
        "CloudWatchMetricsEnabled": {
          "type": "boolean"
        },
        "AllowedFeatures": {
          "type": "list",
          "member": {
            "locationName": "AllowedFeature"
          }
        },
        "TransformationConfigurations": {
          "type": "list",
          "member": {
            "locationName": "TransformationConfiguration",
            "type": "structure",
            "required": [
              "Actions",
              "ContentTransformation"
            ],
            "members": {
              "Actions": {
                "type": "list",
                "member": {
                  "locationName": "Action"
                }
              },
              "ContentTransformation": {
                "type": "structure",
                "members": {
                  "AwsLambda": {
                    "type": "structure",
                    "required": [
                      "FunctionArn"
                    ],
                    "members": {
                      "FunctionArn": {},
                      "FunctionPayload": {}
                    }
                  }
                },
                "union": true
              }
            }
          }
        }
      }
    },
    "S19": {
      "type": "structure",
      "members": {
        "LambdaInvoke": {
          "type": "structure",
          "members": {
            "FunctionArn": {}
          }
        },
        "S3PutObjectCopy": {
          "type": "structure",
          "members": {
            "TargetResource": {},
            "CannedAccessControlList": {},
            "AccessControlGrants": {
              "shape": "S1e"
            },
            "MetadataDirective": {},
            "ModifiedSinceConstraint": {
              "type": "timestamp"
            },
            "NewObjectMetadata": {
              "type": "structure",
              "members": {
                "CacheControl": {},
                "ContentDisposition": {},
                "ContentEncoding": {},
                "ContentLanguage": {},
                "UserMetadata": {
                  "type": "map",
                  "key": {},
                  "value": {}
                },
                "ContentLength": {
                  "type": "long"
                },
                "ContentMD5": {},
                "ContentType": {},
                "HttpExpiresDate": {
                  "type": "timestamp"
                },
                "RequesterCharged": {
                  "type": "boolean"
                },
                "SSEAlgorithm": {}
              }
            },
            "NewObjectTagging": {
              "shape": "S1r"
            },
            "RedirectLocation": {},
            "RequesterPays": {
              "type": "boolean"
            },
            "StorageClass": {},
            "UnModifiedSinceConstraint": {
              "type": "timestamp"
            },
            "SSEAwsKmsKeyId": {},
            "TargetKeyPrefix": {},
            "ObjectLockLegalHoldStatus": {},
            "ObjectLockMode": {},
            "ObjectLockRetainUntilDate": {
              "type": "timestamp"
            },
            "BucketKeyEnabled": {
              "type": "boolean"
            },
            "ChecksumAlgorithm": {}
          }
        },
        "S3PutObjectAcl": {
          "type": "structure",
          "members": {
            "AccessControlPolicy": {
              "type": "structure",
              "members": {
                "AccessControlList": {
                  "type": "structure",
                  "required": [
                    "Owner"
                  ],
                  "members": {
                    "Owner": {
                      "type": "structure",
                      "members": {
                        "ID": {},
                        "DisplayName": {}
                      }
                    },
                    "Grants": {
                      "shape": "S1e"
                    }
                  }
                },
                "CannedAccessControlList": {}
              }
            }
          }
        },
        "S3PutObjectTagging": {
          "type": "structure",
          "members": {
            "TagSet": {
              "shape": "S1r"
            }
          }
        },
        "S3DeleteObjectTagging": {
          "type": "structure",
          "members": {}
        },
        "S3InitiateRestoreObject": {
          "type": "structure",
          "members": {
            "ExpirationInDays": {
              "type": "integer"
            },
            "GlacierJobTier": {}
          }
        },
        "S3PutObjectLegalHold": {
          "type": "structure",
          "required": [
            "LegalHold"
          ],
          "members": {
            "LegalHold": {
              "type": "structure",
              "required": [
                "Status"
              ],
              "members": {
                "Status": {}
              }
            }
          }
        },
        "S3PutObjectRetention": {
          "type": "structure",
          "required": [
            "Retention"
          ],
          "members": {
            "BypassGovernanceRetention": {
              "type": "boolean"
            },
            "Retention": {
              "type": "structure",
              "members": {
                "RetainUntilDate": {
                  "type": "timestamp"
                },
                "Mode": {}
              }
            }
          }
        },
        "S3ReplicateObject": {
          "type": "structure",
          "members": {}
        }
      }
    },
    "S1e": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Grantee": {
            "type": "structure",
            "members": {
              "TypeIdentifier": {},
              "Identifier": {},
              "DisplayName": {}
            }
          },
          "Permission": {}
        }
      }
    },
    "S1r": {
      "type": "list",
      "member": {
        "shape": "S1s"
      }
    },
    "S1s": {
      "type": "structure",
      "required": [
        "Key",
        "Value"
      ],
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S2g": {
      "type": "structure",
      "required": [
        "Enabled"
      ],
      "members": {
        "Bucket": {},
        "Format": {},
        "Enabled": {
          "type": "boolean"
        },
        "Prefix": {},
        "ReportScope": {}
      }
    },
    "S2k": {
      "type": "structure",
      "required": [
        "Spec",
        "Location"
      ],
      "members": {
        "Spec": {
          "type": "structure",
          "required": [
            "Format"
          ],
          "members": {
            "Format": {},
            "Fields": {
              "type": "list",
              "member": {}
            }
          }
        },
        "Location": {
          "shape": "S2p"
        }
      }
    },
    "S2p": {
      "type": "structure",
      "required": [
        "ObjectArn",
        "ETag"
      ],
      "members": {
        "ObjectArn": {},
        "ObjectVersionId": {},
        "ETag": {}
      }
    },
    "S2v": {
      "type": "structure",
      "members": {
        "S3JobManifestGenerator": {
          "type": "structure",
          "required": [
            "SourceBucket",
            "EnableManifestOutput"
          ],
          "members": {
            "ExpectedBucketOwner": {},
            "SourceBucket": {},
            "ManifestOutputLocation": {
              "type": "structure",
              "required": [
                "Bucket",
                "ManifestFormat"
              ],
              "members": {
                "ExpectedManifestBucketOwner": {},
                "Bucket": {},
                "ManifestPrefix": {},
                "ManifestEncryption": {
                  "type": "structure",
                  "members": {
                    "SSES3": {
                      "locationName": "SSE-S3",
                      "type": "structure",
                      "members": {}
                    },
                    "SSEKMS": {
                      "locationName": "SSE-KMS",
                      "type": "structure",
                      "required": [
                        "KeyId"
                      ],
                      "members": {
                        "KeyId": {}
                      }
                    }
                  }
                },
                "ManifestFormat": {}
              }
            },
            "Filter": {
              "type": "structure",
              "members": {
                "EligibleForReplication": {
                  "type": "boolean"
                },
                "CreatedAfter": {
                  "type": "timestamp"
                },
                "CreatedBefore": {
                  "type": "timestamp"
                },
                "ObjectReplicationStatuses": {
                  "type": "list",
                  "member": {}
                }
              }
            },
            "EnableManifestOutput": {
              "type": "boolean"
            }
          }
        }
      },
      "union": true
    },
    "S3b": {
      "type": "structure",
      "required": [
        "Name",
        "Regions"
      ],
      "members": {
        "Name": {},
        "PublicAccessBlock": {
          "shape": "S7"
        },
        "Regions": {
          "type": "list",
          "member": {
            "locationName": "Region",
            "type": "structure",
            "required": [
              "Bucket"
            ],
            "members": {
              "Bucket": {}
            }
          }
        }
      }
    },
    "S3s": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {}
      }
    },
    "S44": {
      "type": "structure",
      "members": {
        "TotalNumberOfTasks": {
          "type": "long"
        },
        "NumberOfTasksSucceeded": {
          "type": "long"
        },
        "NumberOfTasksFailed": {
          "type": "long"
        },
        "Timers": {
          "type": "structure",
          "members": {
            "ElapsedTimeInActiveSeconds": {
              "type": "long"
            }
          }
        }
      }
    },
    "S4q": {
      "type": "structure",
      "required": [
        "Name",
        "Policy"
      ],
      "members": {
        "Name": {},
        "Policy": {}
      }
    },
    "S5f": {
      "type": "structure",
      "members": {
        "IsPublic": {
          "locationName": "IsPublic",
          "type": "boolean"
        }
      }
    },
    "S5o": {
      "type": "list",
      "member": {
        "locationName": "Rule",
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Expiration": {
            "type": "structure",
            "members": {
              "Date": {
                "type": "timestamp"
              },
              "Days": {
                "type": "integer"
              },
              "ExpiredObjectDeleteMarker": {
                "type": "boolean"
              }
            }
          },
          "ID": {},
          "Filter": {
            "type": "structure",
            "members": {
              "Prefix": {},
              "Tag": {
                "shape": "S1s"
              },
              "And": {
                "type": "structure",
                "members": {
                  "Prefix": {},
                  "Tags": {
                    "shape": "S1r"
                  },
                  "ObjectSizeGreaterThan": {
                    "type": "long"
                  },
                  "ObjectSizeLessThan": {
                    "type": "long"
                  }
                }
              },
              "ObjectSizeGreaterThan": {
                "type": "long"
              },
              "ObjectSizeLessThan": {
                "type": "long"
              }
            }
          },
          "Status": {},
          "Transitions": {
            "type": "list",
            "member": {
              "locationName": "Transition",
              "type": "structure",
              "members": {
                "Date": {
                  "type": "timestamp"
                },
                "Days": {
                  "type": "integer"
                },
                "StorageClass": {}
              }
            }
          },
          "NoncurrentVersionTransitions": {
            "type": "list",
            "member": {
              "locationName": "NoncurrentVersionTransition",
              "type": "structure",
              "members": {
                "NoncurrentDays": {
                  "type": "integer"
                },
                "StorageClass": {}
              }
            }
          },
          "NoncurrentVersionExpiration": {
            "type": "structure",
            "members": {
              "NoncurrentDays": {
                "type": "integer"
              },
              "NewerNoncurrentVersions": {
                "type": "integer"
              }
            }
          },
          "AbortIncompleteMultipartUpload": {
            "type": "structure",
            "members": {
              "DaysAfterInitiation": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S6m": {
      "type": "structure",
      "members": {
        "Name": {},
        "Alias": {},
        "CreatedAt": {
          "type": "timestamp"
        },
        "PublicAccessBlock": {
          "shape": "S7"
        },
        "Status": {},
        "Regions": {
          "type": "list",
          "member": {
            "locationName": "Region",
            "type": "structure",
            "members": {
              "Bucket": {},
              "Region": {}
            }
          }
        }
      }
    },
    "S73": {
      "type": "structure",
      "required": [
        "Id",
        "AccountLevel",
        "IsEnabled"
      ],
      "members": {
        "Id": {},
        "AccountLevel": {
          "type": "structure",
          "required": [
            "BucketLevel"
          ],
          "members": {
            "ActivityMetrics": {
              "shape": "S75"
            },
            "BucketLevel": {
              "type": "structure",
              "members": {
                "ActivityMetrics": {
                  "shape": "S75"
                },
                "PrefixLevel": {
                  "type": "structure",
                  "required": [
                    "StorageMetrics"
                  ],
                  "members": {
                    "StorageMetrics": {
                      "type": "structure",
                      "members": {
                        "IsEnabled": {
                          "type": "boolean"
                        },
                        "SelectionCriteria": {
                          "type": "structure",
                          "members": {
                            "Delimiter": {},
                            "MaxDepth": {
                              "type": "integer"
                            },
                            "MinStorageBytesPercentage": {
                              "type": "double"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "Include": {
          "type": "structure",
          "members": {
            "Buckets": {
              "shape": "S7f"
            },
            "Regions": {
              "shape": "S7g"
            }
          }
        },
        "Exclude": {
          "type": "structure",
          "members": {
            "Buckets": {
              "shape": "S7f"
            },
            "Regions": {
              "shape": "S7g"
            }
          }
        },
        "DataExport": {
          "type": "structure",
          "members": {
            "S3BucketDestination": {
              "type": "structure",
              "required": [
                "Format",
                "OutputSchemaVersion",
                "AccountId",
                "Arn"
              ],
              "members": {
                "Format": {},
                "OutputSchemaVersion": {},
                "AccountId": {},
                "Arn": {},
                "Prefix": {},
                "Encryption": {
                  "type": "structure",
                  "members": {
                    "SSES3": {
                      "locationName": "SSE-S3",
                      "type": "structure",
                      "members": {}
                    },
                    "SSEKMS": {
                      "locationName": "SSE-KMS",
                      "type": "structure",
                      "required": [
                        "KeyId"
                      ],
                      "members": {
                        "KeyId": {}
                      }
                    }
                  }
                }
              }
            },
            "CloudWatchMetrics": {
              "type": "structure",
              "required": [
                "IsEnabled"
              ],
              "members": {
                "IsEnabled": {
                  "type": "boolean"
                }
              }
            }
          }
        },
        "IsEnabled": {
          "type": "boolean"
        },
        "AwsOrg": {
          "type": "structure",
          "required": [
            "Arn"
          ],
          "members": {
            "Arn": {}
          }
        },
        "StorageLensArn": {}
      }
    },
    "S75": {
      "type": "structure",
      "members": {
        "IsEnabled": {
          "type": "boolean"
        }
      }
    },
    "S7f": {
      "type": "list",
      "member": {
        "locationName": "Arn"
      }
    },
    "S7g": {
      "type": "list",
      "member": {
        "locationName": "Region"
      }
    },
    "S7x": {
      "type": "list",
      "member": {
        "locationName": "Tag",
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    }
  },
  "clientContextParams": {
    "UseArnRegion": {
      "documentation": "Enables this client to use an ARN's region when constructing an endpoint instead of the client's configured region.",
      "type": "boolean"
    }
  }
}