{
  "pagination": {
    "ListGatewayRoutes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "limit",
      "result_key": "gatewayRoutes"
    },
    "ListMeshes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "limit",
      "result_key": "meshes"
    },
    "ListRoutes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "limit",
      "result_key": "routes"
    },
    "ListTagsForResource": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "limit",
      "result_key": "tags"
    },
    "ListVirtualGateways": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "limit",
      "result_key": "virtualGateways"
    },
    "ListVirtualNodes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "limit",
      "result_key": "virtualNodes"
    },
    "ListVirtualRouters": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "limit",
      "result_key": "virtualRouters"
    },
    "ListVirtualServices": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "limit",
      "result_key": "virtualServices"
    }
  }
}
