{
  "pagination": {
    "DescribeApps": {
      "result_key": "Apps"
    },
    "DescribeCommands": {
      "result_key": "Commands"
    },
    "DescribeDeployments": {
      "result_key": "Deployments"
    },
    "DescribeEcsClusters": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "EcsClusters"
    },
    "DescribeElasticIps": {
      "result_key": "ElasticIps"
    },
    "DescribeElasticLoadBalancers": {
      "result_key": "ElasticLoadBalancers"
    },
    "DescribeInstances": {
      "result_key": "Instances"
    },
    "DescribeLayers": {
      "result_key": "Layers"
    },
    "DescribeLoadBasedAutoScaling": {
      "result_key": "LoadBasedAutoScalingConfigurations"
    },
    "DescribePermissions": {
      "result_key": "Permissions"
    },
    "DescribeRaidArrays": {
      "result_key": "RaidArrays"
    },
    "DescribeServiceErrors": {
      "result_key": "ServiceErrors"
    },
    "DescribeStacks": {
      "result_key": "Stacks"
    },
    "DescribeTimeBasedAutoScaling": {
      "result_key": "TimeBasedAutoScalingConfigurations"
    },
    "DescribeUserProfiles": {
      "result_key": "UserProfiles"
    },
    "DescribeVolumes": {
      "result_key": "Volumes"
    }
  }
}