{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-03-01",
    "endpointPrefix": "fsx",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon FSx",
    "serviceId": "FSx",
    "signatureVersion": "v4",
    "signingName": "fsx",
    "targetPrefix": "AWSSimbaAPIService_v20180301",
    "uid": "fsx-2018-03-01"
  },
  "operations": {
    "AssociateFileSystemAliases": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "Aliases"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemId": {},
          "Aliases": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "shape": "S7"
          }
        }
      }
    },
    "CancelDataRepositoryTask": {
      "input": {
        "type": "structure",
        "required": [
          "TaskId"
        ],
        "members": {
          "TaskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Lifecycle": {},
          "TaskId": {}
        }
      },
      "idempotent": true
    },
    "CopyBackup": {
      "input": {
        "type": "structure",
        "required": [
          "SourceBackupId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SourceBackupId": {},
          "SourceRegion": {},
          "KmsKeyId": {},
          "CopyTags": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backup": {
            "shape": "So"
          }
        }
      },
      "idempotent": true
    },
    "CreateBackup": {
      "input": {
        "type": "structure",
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sj"
          },
          "VolumeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backup": {
            "shape": "So"
          }
        }
      },
      "idempotent": true
    },
    "CreateDataRepositoryAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "DataRepositoryPath"
        ],
        "members": {
          "FileSystemId": {},
          "FileSystemPath": {},
          "DataRepositoryPath": {},
          "BatchImportMetaDataOnCreate": {
            "type": "boolean"
          },
          "ImportedFileChunkSize": {
            "type": "integer"
          },
          "S3": {
            "shape": "S45"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Association": {
            "shape": "S4b"
          }
        }
      },
      "idempotent": true
    },
    "CreateDataRepositoryTask": {
      "input": {
        "type": "structure",
        "required": [
          "Type",
          "FileSystemId",
          "Report"
        ],
        "members": {
          "Type": {},
          "Paths": {
            "shape": "S4k"
          },
          "FileSystemId": {},
          "Report": {
            "shape": "S4m"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sj"
          },
          "CapacityToRelease": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataRepositoryTask": {
            "shape": "S4r"
          }
        }
      },
      "idempotent": true
    },
    "CreateFileCache": {
      "input": {
        "type": "structure",
        "required": [
          "FileCacheType",
          "FileCacheTypeVersion",
          "StorageCapacity",
          "SubnetIds"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileCacheType": {},
          "FileCacheTypeVersion": {},
          "StorageCapacity": {
            "type": "integer"
          },
          "SubnetIds": {
            "shape": "S15"
          },
          "SecurityGroupIds": {
            "shape": "S53"
          },
          "Tags": {
            "shape": "Sj"
          },
          "CopyTagsToDataRepositoryAssociations": {
            "type": "boolean"
          },
          "KmsKeyId": {},
          "LustreConfiguration": {
            "type": "structure",
            "required": [
              "PerUnitStorageThroughput",
              "DeploymentType",
              "MetadataConfiguration"
            ],
            "members": {
              "PerUnitStorageThroughput": {
                "type": "integer"
              },
              "DeploymentType": {},
              "WeeklyMaintenanceStartTime": {},
              "MetadataConfiguration": {
                "shape": "S58"
              }
            }
          },
          "DataRepositoryAssociations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "FileCachePath",
                "DataRepositoryPath"
              ],
              "members": {
                "FileCachePath": {},
                "DataRepositoryPath": {},
                "DataRepositorySubdirectories": {
                  "shape": "S4e"
                },
                "NFS": {
                  "type": "structure",
                  "required": [
                    "Version"
                  ],
                  "members": {
                    "Version": {},
                    "DnsIps": {
                      "shape": "S4h"
                    }
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileCache": {
            "type": "structure",
            "members": {
              "OwnerId": {},
              "CreationTime": {
                "type": "timestamp"
              },
              "FileCacheId": {},
              "FileCacheType": {},
              "FileCacheTypeVersion": {},
              "Lifecycle": {},
              "FailureDetails": {
                "shape": "S5g"
              },
              "StorageCapacity": {
                "type": "integer"
              },
              "VpcId": {},
              "SubnetIds": {
                "shape": "S15"
              },
              "NetworkInterfaceIds": {
                "shape": "S17"
              },
              "DNSName": {},
              "KmsKeyId": {},
              "ResourceARN": {},
              "Tags": {
                "shape": "Sj"
              },
              "CopyTagsToDataRepositoryAssociations": {
                "type": "boolean"
              },
              "LustreConfiguration": {
                "shape": "S5h"
              },
              "DataRepositoryAssociationIds": {
                "shape": "S5i"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "CreateFileSystem": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemType",
          "StorageCapacity",
          "SubnetIds"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemType": {},
          "StorageCapacity": {
            "type": "integer"
          },
          "StorageType": {},
          "SubnetIds": {
            "shape": "S15"
          },
          "SecurityGroupIds": {
            "shape": "S53"
          },
          "Tags": {
            "shape": "Sj"
          },
          "KmsKeyId": {},
          "WindowsConfiguration": {
            "shape": "S5k"
          },
          "LustreConfiguration": {
            "shape": "S5o"
          },
          "OntapConfiguration": {
            "type": "structure",
            "required": [
              "DeploymentType",
              "ThroughputCapacity"
            ],
            "members": {
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "DailyAutomaticBackupStartTime": {},
              "DeploymentType": {},
              "EndpointIpAddressRange": {},
              "FsxAdminPassword": {
                "shape": "S5r"
              },
              "DiskIopsConfiguration": {
                "shape": "S3q"
              },
              "PreferredSubnetId": {},
              "RouteTableIds": {
                "shape": "S3t"
              },
              "ThroughputCapacity": {
                "type": "integer"
              },
              "WeeklyMaintenanceStartTime": {}
            }
          },
          "FileSystemTypeVersion": {},
          "OpenZFSConfiguration": {
            "shape": "S5s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sx"
          }
        }
      }
    },
    "CreateFileSystemFromBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId",
          "SubnetIds"
        ],
        "members": {
          "BackupId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SubnetIds": {
            "shape": "S15"
          },
          "SecurityGroupIds": {
            "shape": "S53"
          },
          "Tags": {
            "shape": "Sj"
          },
          "WindowsConfiguration": {
            "shape": "S5k"
          },
          "LustreConfiguration": {
            "shape": "S5o"
          },
          "StorageType": {},
          "KmsKeyId": {},
          "FileSystemTypeVersion": {},
          "OpenZFSConfiguration": {
            "shape": "S5s"
          },
          "StorageCapacity": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sx"
          }
        }
      }
    },
    "CreateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "VolumeId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Name": {},
          "VolumeId": {},
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "S3g"
          }
        }
      },
      "idempotent": true
    },
    "CreateStorageVirtualMachine": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "Name"
        ],
        "members": {
          "ActiveDirectoryConfiguration": {
            "type": "structure",
            "required": [
              "NetBiosName"
            ],
            "members": {
              "NetBiosName": {},
              "SelfManagedActiveDirectoryConfiguration": {
                "shape": "S5l"
              }
            }
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemId": {},
          "Name": {},
          "SvmAdminPassword": {
            "shape": "S5r"
          },
          "Tags": {
            "shape": "Sj"
          },
          "RootVolumeSecurityStyle": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageVirtualMachine": {
            "shape": "S65"
          }
        }
      }
    },
    "CreateVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeType",
          "Name"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeType": {},
          "Name": {},
          "OntapConfiguration": {
            "shape": "S6c"
          },
          "Tags": {
            "shape": "Sj"
          },
          "OpenZFSConfiguration": {
            "type": "structure",
            "required": [
              "ParentVolumeId"
            ],
            "members": {
              "ParentVolumeId": {},
              "StorageCapacityReservationGiB": {
                "type": "integer"
              },
              "StorageCapacityQuotaGiB": {
                "type": "integer"
              },
              "RecordSizeKiB": {
                "type": "integer"
              },
              "DataCompressionType": {},
              "CopyTagsToSnapshots": {
                "type": "boolean"
              },
              "OriginSnapshot": {
                "type": "structure",
                "required": [
                  "SnapshotARN",
                  "CopyStrategy"
                ],
                "members": {
                  "SnapshotARN": {},
                  "CopyStrategy": {}
                }
              },
              "ReadOnly": {
                "type": "boolean"
              },
              "NfsExports": {
                "shape": "S36"
              },
              "UserAndGroupQuotas": {
                "shape": "S3d"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volume": {
            "shape": "S2h"
          }
        }
      }
    },
    "CreateVolumeFromBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId",
          "Name"
        ],
        "members": {
          "BackupId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Name": {},
          "OntapConfiguration": {
            "shape": "S6c"
          },
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volume": {
            "shape": "S2h"
          }
        }
      }
    },
    "DeleteBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId"
        ],
        "members": {
          "BackupId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupId": {},
          "Lifecycle": {}
        }
      },
      "idempotent": true
    },
    "DeleteDataRepositoryAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "DeleteDataInFileSystem": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {},
          "Lifecycle": {},
          "DeleteDataInFileSystem": {
            "type": "boolean"
          }
        }
      },
      "idempotent": true
    },
    "DeleteFileCache": {
      "input": {
        "type": "structure",
        "required": [
          "FileCacheId"
        ],
        "members": {
          "FileCacheId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileCacheId": {},
          "Lifecycle": {}
        }
      },
      "idempotent": true
    },
    "DeleteFileSystem": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "WindowsConfiguration": {
            "type": "structure",
            "members": {
              "SkipFinalBackup": {
                "type": "boolean"
              },
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          },
          "LustreConfiguration": {
            "type": "structure",
            "members": {
              "SkipFinalBackup": {
                "type": "boolean"
              },
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          },
          "OpenZFSConfiguration": {
            "type": "structure",
            "members": {
              "SkipFinalBackup": {
                "type": "boolean"
              },
              "FinalBackupTags": {
                "shape": "Sj"
              },
              "Options": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystemId": {},
          "Lifecycle": {},
          "WindowsResponse": {
            "type": "structure",
            "members": {
              "FinalBackupId": {},
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          },
          "LustreResponse": {
            "type": "structure",
            "members": {
              "FinalBackupId": {},
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          },
          "OpenZFSResponse": {
            "type": "structure",
            "members": {
              "FinalBackupId": {},
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DeleteSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SnapshotId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {},
          "Lifecycle": {}
        }
      },
      "idempotent": true
    },
    "DeleteStorageVirtualMachine": {
      "input": {
        "type": "structure",
        "required": [
          "StorageVirtualMachineId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "StorageVirtualMachineId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageVirtualMachineId": {},
          "Lifecycle": {}
        }
      }
    },
    "DeleteVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeId": {},
          "OntapConfiguration": {
            "type": "structure",
            "members": {
              "SkipFinalBackup": {
                "type": "boolean"
              },
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          },
          "OpenZFSConfiguration": {
            "type": "structure",
            "members": {
              "Options": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeId": {},
          "Lifecycle": {},
          "OntapResponse": {
            "type": "structure",
            "members": {
              "FinalBackupId": {},
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          }
        }
      }
    },
    "DescribeBackups": {
      "input": {
        "type": "structure",
        "members": {
          "BackupIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "S7d"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backups": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeDataRepositoryAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "AssociationIds": {
            "shape": "S5i"
          },
          "Filters": {
            "shape": "S7d"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "type": "list",
            "member": {
              "shape": "S4b"
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "DescribeDataRepositoryTasks": {
      "input": {
        "type": "structure",
        "members": {
          "TaskIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataRepositoryTasks": {
            "type": "list",
            "member": {
              "shape": "S4r"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFileCaches": {
      "input": {
        "type": "structure",
        "members": {
          "FileCacheIds": {
            "type": "list",
            "member": {}
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileCaches": {
            "type": "list",
            "member": {
              "shape": "S83"
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "DescribeFileSystemAliases": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "shape": "S7"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFileSystems": {
      "input": {
        "type": "structure",
        "members": {
          "FileSystemIds": {
            "type": "list",
            "member": {}
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystems": {
            "type": "list",
            "member": {
              "shape": "Sx"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "SnapshotIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshots": {
            "type": "list",
            "member": {
              "shape": "S3g"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStorageVirtualMachines": {
      "input": {
        "type": "structure",
        "members": {
          "StorageVirtualMachineIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageVirtualMachines": {
            "type": "list",
            "member": {
              "shape": "S65"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeVolumes": {
      "input": {
        "type": "structure",
        "members": {
          "VolumeIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volumes": {
            "type": "list",
            "member": {
              "shape": "S2h"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DisassociateFileSystemAliases": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "Aliases"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemId": {},
          "Aliases": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "shape": "S7"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sj"
          },
          "NextToken": {}
        }
      }
    },
    "ReleaseFileSystemNfsV3Locks": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sx"
          }
        }
      },
      "idempotent": true
    },
    "RestoreVolumeFromSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId",
          "SnapshotId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeId": {},
          "SnapshotId": {},
          "Options": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeId": {},
          "Lifecycle": {}
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateDataRepositoryAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "ImportedFileChunkSize": {
            "type": "integer"
          },
          "S3": {
            "shape": "S45"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Association": {
            "shape": "S4b"
          }
        }
      },
      "idempotent": true
    },
    "UpdateFileCache": {
      "input": {
        "type": "structure",
        "required": [
          "FileCacheId"
        ],
        "members": {
          "FileCacheId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "LustreConfiguration": {
            "type": "structure",
            "members": {
              "WeeklyMaintenanceStartTime": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileCache": {
            "shape": "S83"
          }
        }
      },
      "idempotent": true
    },
    "UpdateFileSystem": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "StorageCapacity": {
            "type": "integer"
          },
          "WindowsConfiguration": {
            "type": "structure",
            "members": {
              "WeeklyMaintenanceStartTime": {},
              "DailyAutomaticBackupStartTime": {},
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "ThroughputCapacity": {
                "type": "integer"
              },
              "SelfManagedActiveDirectoryConfiguration": {
                "shape": "S9n"
              },
              "AuditLogConfiguration": {
                "shape": "S5n"
              }
            }
          },
          "LustreConfiguration": {
            "type": "structure",
            "members": {
              "WeeklyMaintenanceStartTime": {},
              "DailyAutomaticBackupStartTime": {},
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "AutoImportPolicy": {},
              "DataCompressionType": {},
              "LogConfiguration": {
                "shape": "S5p"
              },
              "RootSquashConfiguration": {
                "shape": "S27"
              }
            }
          },
          "OntapConfiguration": {
            "type": "structure",
            "members": {
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "DailyAutomaticBackupStartTime": {},
              "FsxAdminPassword": {
                "shape": "S5r"
              },
              "WeeklyMaintenanceStartTime": {},
              "DiskIopsConfiguration": {
                "shape": "S3q"
              },
              "ThroughputCapacity": {
                "type": "integer"
              }
            }
          },
          "OpenZFSConfiguration": {
            "type": "structure",
            "members": {
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "CopyTagsToBackups": {
                "type": "boolean"
              },
              "CopyTagsToVolumes": {
                "type": "boolean"
              },
              "DailyAutomaticBackupStartTime": {},
              "ThroughputCapacity": {
                "type": "integer"
              },
              "WeeklyMaintenanceStartTime": {},
              "DiskIopsConfiguration": {
                "shape": "S3q"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sx"
          }
        }
      }
    },
    "UpdateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SnapshotId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Name": {},
          "SnapshotId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "S3g"
          }
        }
      },
      "idempotent": true
    },
    "UpdateStorageVirtualMachine": {
      "input": {
        "type": "structure",
        "required": [
          "StorageVirtualMachineId"
        ],
        "members": {
          "ActiveDirectoryConfiguration": {
            "type": "structure",
            "members": {
              "SelfManagedActiveDirectoryConfiguration": {
                "shape": "S9n"
              }
            }
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "StorageVirtualMachineId": {},
          "SvmAdminPassword": {
            "shape": "S5r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageVirtualMachine": {
            "shape": "S65"
          }
        }
      }
    },
    "UpdateVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeId": {},
          "OntapConfiguration": {
            "type": "structure",
            "members": {
              "JunctionPath": {},
              "SecurityStyle": {},
              "SizeInMegabytes": {
                "type": "integer"
              },
              "StorageEfficiencyEnabled": {
                "type": "boolean"
              },
              "TieringPolicy": {
                "shape": "S2q"
              }
            }
          },
          "Name": {},
          "OpenZFSConfiguration": {
            "type": "structure",
            "members": {
              "StorageCapacityReservationGiB": {
                "type": "integer"
              },
              "StorageCapacityQuotaGiB": {
                "type": "integer"
              },
              "RecordSizeKiB": {
                "type": "integer"
              },
              "DataCompressionType": {},
              "NfsExports": {
                "shape": "S36"
              },
              "UserAndGroupQuotas": {
                "shape": "S3d"
              },
              "ReadOnly": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volume": {
            "shape": "S2h"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {}
    },
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Lifecycle": {}
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "So": {
      "type": "structure",
      "required": [
        "BackupId",
        "Lifecycle",
        "Type",
        "CreationTime",
        "FileSystem"
      ],
      "members": {
        "BackupId": {},
        "Lifecycle": {},
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Message": {}
          }
        },
        "Type": {},
        "ProgressPercent": {
          "type": "integer"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "KmsKeyId": {},
        "ResourceARN": {},
        "Tags": {
          "shape": "Sj"
        },
        "FileSystem": {
          "shape": "Sx"
        },
        "DirectoryInformation": {
          "type": "structure",
          "members": {
            "DomainName": {},
            "ActiveDirectoryId": {},
            "ResourceARN": {}
          }
        },
        "OwnerId": {},
        "SourceBackupId": {},
        "SourceBackupRegion": {},
        "ResourceType": {},
        "Volume": {
          "shape": "S2h"
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "FileSystemId": {},
        "FileSystemType": {},
        "Lifecycle": {},
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Message": {}
          }
        },
        "StorageCapacity": {
          "type": "integer"
        },
        "StorageType": {},
        "VpcId": {},
        "SubnetIds": {
          "shape": "S15"
        },
        "NetworkInterfaceIds": {
          "shape": "S17"
        },
        "DNSName": {},
        "KmsKeyId": {},
        "ResourceARN": {},
        "Tags": {
          "shape": "Sj"
        },
        "WindowsConfiguration": {
          "type": "structure",
          "members": {
            "ActiveDirectoryId": {},
            "SelfManagedActiveDirectoryConfiguration": {
              "shape": "S1c"
            },
            "DeploymentType": {},
            "RemoteAdministrationEndpoint": {},
            "PreferredSubnetId": {},
            "PreferredFileServerIp": {},
            "ThroughputCapacity": {
              "type": "integer"
            },
            "MaintenanceOperationsInProgress": {
              "type": "list",
              "member": {}
            },
            "WeeklyMaintenanceStartTime": {},
            "DailyAutomaticBackupStartTime": {},
            "AutomaticBackupRetentionDays": {
              "type": "integer"
            },
            "CopyTagsToBackups": {
              "type": "boolean"
            },
            "Aliases": {
              "shape": "S7"
            },
            "AuditLogConfiguration": {
              "type": "structure",
              "required": [
                "FileAccessAuditLogLevel",
                "FileShareAccessAuditLogLevel"
              ],
              "members": {
                "FileAccessAuditLogLevel": {},
                "FileShareAccessAuditLogLevel": {},
                "AuditLogDestination": {}
              }
            }
          }
        },
        "LustreConfiguration": {
          "type": "structure",
          "members": {
            "WeeklyMaintenanceStartTime": {},
            "DataRepositoryConfiguration": {
              "type": "structure",
              "members": {
                "Lifecycle": {},
                "ImportPath": {},
                "ExportPath": {},
                "ImportedFileChunkSize": {
                  "type": "integer"
                },
                "AutoImportPolicy": {},
                "FailureDetails": {
                  "shape": "S1z"
                }
              }
            },
            "DeploymentType": {},
            "PerUnitStorageThroughput": {
              "type": "integer"
            },
            "MountName": {},
            "DailyAutomaticBackupStartTime": {},
            "AutomaticBackupRetentionDays": {
              "type": "integer"
            },
            "CopyTagsToBackups": {
              "type": "boolean"
            },
            "DriveCacheType": {},
            "DataCompressionType": {},
            "LogConfiguration": {
              "shape": "S25"
            },
            "RootSquashConfiguration": {
              "shape": "S27"
            }
          }
        },
        "AdministrativeActions": {
          "shape": "S2b"
        },
        "OntapConfiguration": {
          "type": "structure",
          "members": {
            "AutomaticBackupRetentionDays": {
              "type": "integer"
            },
            "DailyAutomaticBackupStartTime": {},
            "DeploymentType": {},
            "EndpointIpAddressRange": {},
            "Endpoints": {
              "type": "structure",
              "members": {
                "Intercluster": {
                  "shape": "S3o"
                },
                "Management": {
                  "shape": "S3o"
                }
              }
            },
            "DiskIopsConfiguration": {
              "shape": "S3q"
            },
            "PreferredSubnetId": {},
            "RouteTableIds": {
              "shape": "S3t"
            },
            "ThroughputCapacity": {
              "type": "integer"
            },
            "WeeklyMaintenanceStartTime": {}
          }
        },
        "FileSystemTypeVersion": {},
        "OpenZFSConfiguration": {
          "type": "structure",
          "members": {
            "AutomaticBackupRetentionDays": {
              "type": "integer"
            },
            "CopyTagsToBackups": {
              "type": "boolean"
            },
            "CopyTagsToVolumes": {
              "type": "boolean"
            },
            "DailyAutomaticBackupStartTime": {},
            "DeploymentType": {},
            "ThroughputCapacity": {
              "type": "integer"
            },
            "WeeklyMaintenanceStartTime": {},
            "DiskIopsConfiguration": {
              "shape": "S3q"
            },
            "RootVolumeId": {}
          }
        }
      }
    },
    "S15": {
      "type": "list",
      "member": {}
    },
    "S17": {
      "type": "list",
      "member": {}
    },
    "S1c": {
      "type": "structure",
      "members": {
        "DomainName": {},
        "OrganizationalUnitDistinguishedName": {},
        "FileSystemAdministratorsGroup": {},
        "UserName": {},
        "DnsIps": {
          "shape": "S1h"
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {}
    },
    "S1z": {
      "type": "structure",
      "members": {
        "Message": {}
      }
    },
    "S25": {
      "type": "structure",
      "required": [
        "Level"
      ],
      "members": {
        "Level": {},
        "Destination": {}
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "RootSquash": {},
        "NoSquashNids": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S2b": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AdministrativeActionType": {},
          "ProgressPercent": {
            "type": "integer"
          },
          "RequestTime": {
            "type": "timestamp"
          },
          "Status": {},
          "TargetFileSystemValues": {
            "shape": "Sx"
          },
          "FailureDetails": {
            "type": "structure",
            "members": {
              "Message": {}
            }
          },
          "TargetVolumeValues": {
            "shape": "S2h"
          },
          "TargetSnapshotValues": {
            "shape": "S3g"
          }
        }
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "CreationTime": {
          "type": "timestamp"
        },
        "FileSystemId": {},
        "Lifecycle": {},
        "Name": {},
        "OntapConfiguration": {
          "type": "structure",
          "members": {
            "FlexCacheEndpointType": {},
            "JunctionPath": {},
            "SecurityStyle": {},
            "SizeInMegabytes": {
              "type": "integer"
            },
            "StorageEfficiencyEnabled": {
              "type": "boolean"
            },
            "StorageVirtualMachineId": {},
            "StorageVirtualMachineRoot": {
              "type": "boolean"
            },
            "TieringPolicy": {
              "shape": "S2q"
            },
            "UUID": {},
            "OntapVolumeType": {}
          }
        },
        "ResourceARN": {},
        "Tags": {
          "shape": "Sj"
        },
        "VolumeId": {},
        "VolumeType": {},
        "LifecycleTransitionReason": {
          "shape": "S2x"
        },
        "AdministrativeActions": {
          "shape": "S2b"
        },
        "OpenZFSConfiguration": {
          "type": "structure",
          "members": {
            "ParentVolumeId": {},
            "VolumePath": {},
            "StorageCapacityReservationGiB": {
              "type": "integer"
            },
            "StorageCapacityQuotaGiB": {
              "type": "integer"
            },
            "RecordSizeKiB": {
              "type": "integer"
            },
            "DataCompressionType": {},
            "CopyTagsToSnapshots": {
              "type": "boolean"
            },
            "OriginSnapshot": {
              "type": "structure",
              "members": {
                "SnapshotARN": {},
                "CopyStrategy": {}
              }
            },
            "ReadOnly": {
              "type": "boolean"
            },
            "NfsExports": {
              "shape": "S36"
            },
            "UserAndGroupQuotas": {
              "shape": "S3d"
            }
          }
        }
      }
    },
    "S2q": {
      "type": "structure",
      "members": {
        "CoolingPeriod": {
          "type": "integer"
        },
        "Name": {}
      }
    },
    "S2x": {
      "type": "structure",
      "members": {
        "Message": {}
      }
    },
    "S36": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ClientConfigurations"
        ],
        "members": {
          "ClientConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Clients",
                "Options"
              ],
              "members": {
                "Clients": {},
                "Options": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "S3d": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Type",
          "Id",
          "StorageCapacityQuotaGiB"
        ],
        "members": {
          "Type": {},
          "Id": {
            "type": "integer"
          },
          "StorageCapacityQuotaGiB": {
            "type": "integer"
          }
        }
      }
    },
    "S3g": {
      "type": "structure",
      "members": {
        "ResourceARN": {},
        "SnapshotId": {},
        "Name": {},
        "VolumeId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "Lifecycle": {},
        "LifecycleTransitionReason": {
          "shape": "S2x"
        },
        "Tags": {
          "shape": "Sj"
        },
        "AdministrativeActions": {
          "shape": "S2b"
        }
      }
    },
    "S3o": {
      "type": "structure",
      "members": {
        "DNSName": {},
        "IpAddresses": {
          "shape": "S3p"
        }
      }
    },
    "S3p": {
      "type": "list",
      "member": {}
    },
    "S3q": {
      "type": "structure",
      "members": {
        "Mode": {},
        "Iops": {
          "type": "long"
        }
      }
    },
    "S3t": {
      "type": "list",
      "member": {}
    },
    "S45": {
      "type": "structure",
      "members": {
        "AutoImportPolicy": {
          "type": "structure",
          "members": {
            "Events": {
              "shape": "S47"
            }
          }
        },
        "AutoExportPolicy": {
          "shape": "S49"
        }
      }
    },
    "S47": {
      "type": "list",
      "member": {}
    },
    "S49": {
      "type": "structure",
      "members": {
        "Events": {
          "shape": "S47"
        }
      }
    },
    "S4b": {
      "type": "structure",
      "members": {
        "AssociationId": {},
        "ResourceARN": {},
        "FileSystemId": {},
        "Lifecycle": {},
        "FailureDetails": {
          "shape": "S1z"
        },
        "FileSystemPath": {},
        "DataRepositoryPath": {},
        "BatchImportMetaDataOnCreate": {
          "type": "boolean"
        },
        "ImportedFileChunkSize": {
          "type": "integer"
        },
        "S3": {
          "shape": "S45"
        },
        "Tags": {
          "shape": "Sj"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "FileCacheId": {},
        "FileCachePath": {},
        "DataRepositorySubdirectories": {
          "shape": "S4e"
        },
        "NFS": {
          "type": "structure",
          "required": [
            "Version"
          ],
          "members": {
            "Version": {},
            "DnsIps": {
              "shape": "S4h"
            },
            "AutoExportPolicy": {
              "shape": "S49"
            }
          }
        }
      }
    },
    "S4e": {
      "type": "list",
      "member": {}
    },
    "S4h": {
      "type": "list",
      "member": {}
    },
    "S4k": {
      "type": "list",
      "member": {}
    },
    "S4m": {
      "type": "structure",
      "required": [
        "Enabled"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Path": {},
        "Format": {},
        "Scope": {}
      }
    },
    "S4r": {
      "type": "structure",
      "required": [
        "TaskId",
        "Lifecycle",
        "Type",
        "CreationTime"
      ],
      "members": {
        "TaskId": {},
        "Lifecycle": {},
        "Type": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "ResourceARN": {},
        "Tags": {
          "shape": "Sj"
        },
        "FileSystemId": {},
        "Paths": {
          "shape": "S4k"
        },
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Message": {}
          }
        },
        "Status": {
          "type": "structure",
          "members": {
            "TotalCount": {
              "type": "long"
            },
            "SucceededCount": {
              "type": "long"
            },
            "FailedCount": {
              "type": "long"
            },
            "LastUpdatedTime": {
              "type": "timestamp"
            },
            "ReleasedCapacity": {
              "type": "long"
            }
          }
        },
        "Report": {
          "shape": "S4m"
        },
        "CapacityToRelease": {
          "type": "long"
        },
        "FileCacheId": {}
      }
    },
    "S53": {
      "type": "list",
      "member": {}
    },
    "S58": {
      "type": "structure",
      "required": [
        "StorageCapacity"
      ],
      "members": {
        "StorageCapacity": {
          "type": "integer"
        }
      }
    },
    "S5g": {
      "type": "structure",
      "members": {
        "Message": {}
      }
    },
    "S5h": {
      "type": "structure",
      "members": {
        "PerUnitStorageThroughput": {
          "type": "integer"
        },
        "DeploymentType": {},
        "MountName": {},
        "WeeklyMaintenanceStartTime": {},
        "MetadataConfiguration": {
          "shape": "S58"
        },
        "LogConfiguration": {
          "shape": "S25"
        }
      }
    },
    "S5i": {
      "type": "list",
      "member": {}
    },
    "S5k": {
      "type": "structure",
      "required": [
        "ThroughputCapacity"
      ],
      "members": {
        "ActiveDirectoryId": {},
        "SelfManagedActiveDirectoryConfiguration": {
          "shape": "S5l"
        },
        "DeploymentType": {},
        "PreferredSubnetId": {},
        "ThroughputCapacity": {
          "type": "integer"
        },
        "WeeklyMaintenanceStartTime": {},
        "DailyAutomaticBackupStartTime": {},
        "AutomaticBackupRetentionDays": {
          "type": "integer"
        },
        "CopyTagsToBackups": {
          "type": "boolean"
        },
        "Aliases": {
          "shape": "S4"
        },
        "AuditLogConfiguration": {
          "shape": "S5n"
        }
      }
    },
    "S5l": {
      "type": "structure",
      "required": [
        "DomainName",
        "UserName",
        "Password",
        "DnsIps"
      ],
      "members": {
        "DomainName": {},
        "OrganizationalUnitDistinguishedName": {},
        "FileSystemAdministratorsGroup": {},
        "UserName": {},
        "Password": {
          "shape": "S5m"
        },
        "DnsIps": {
          "shape": "S1h"
        }
      }
    },
    "S5m": {
      "type": "string",
      "sensitive": true
    },
    "S5n": {
      "type": "structure",
      "required": [
        "FileAccessAuditLogLevel",
        "FileShareAccessAuditLogLevel"
      ],
      "members": {
        "FileAccessAuditLogLevel": {},
        "FileShareAccessAuditLogLevel": {},
        "AuditLogDestination": {}
      }
    },
    "S5o": {
      "type": "structure",
      "members": {
        "WeeklyMaintenanceStartTime": {},
        "ImportPath": {},
        "ExportPath": {},
        "ImportedFileChunkSize": {
          "type": "integer"
        },
        "DeploymentType": {},
        "AutoImportPolicy": {},
        "PerUnitStorageThroughput": {
          "type": "integer"
        },
        "DailyAutomaticBackupStartTime": {},
        "AutomaticBackupRetentionDays": {
          "type": "integer"
        },
        "CopyTagsToBackups": {
          "type": "boolean"
        },
        "DriveCacheType": {},
        "DataCompressionType": {},
        "LogConfiguration": {
          "shape": "S5p"
        },
        "RootSquashConfiguration": {
          "shape": "S27"
        }
      }
    },
    "S5p": {
      "type": "structure",
      "required": [
        "Level"
      ],
      "members": {
        "Level": {},
        "Destination": {}
      }
    },
    "S5r": {
      "type": "string",
      "sensitive": true
    },
    "S5s": {
      "type": "structure",
      "required": [
        "DeploymentType",
        "ThroughputCapacity"
      ],
      "members": {
        "AutomaticBackupRetentionDays": {
          "type": "integer"
        },
        "CopyTagsToBackups": {
          "type": "boolean"
        },
        "CopyTagsToVolumes": {
          "type": "boolean"
        },
        "DailyAutomaticBackupStartTime": {},
        "DeploymentType": {},
        "ThroughputCapacity": {
          "type": "integer"
        },
        "WeeklyMaintenanceStartTime": {},
        "DiskIopsConfiguration": {
          "shape": "S3q"
        },
        "RootVolumeConfiguration": {
          "type": "structure",
          "members": {
            "RecordSizeKiB": {
              "type": "integer"
            },
            "DataCompressionType": {},
            "NfsExports": {
              "shape": "S36"
            },
            "UserAndGroupQuotas": {
              "shape": "S3d"
            },
            "CopyTagsToSnapshots": {
              "type": "boolean"
            },
            "ReadOnly": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S65": {
      "type": "structure",
      "members": {
        "ActiveDirectoryConfiguration": {
          "type": "structure",
          "members": {
            "NetBiosName": {},
            "SelfManagedActiveDirectoryConfiguration": {
              "shape": "S1c"
            }
          }
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "Endpoints": {
          "type": "structure",
          "members": {
            "Iscsi": {
              "shape": "S68"
            },
            "Management": {
              "shape": "S68"
            },
            "Nfs": {
              "shape": "S68"
            },
            "Smb": {
              "shape": "S68"
            }
          }
        },
        "FileSystemId": {},
        "Lifecycle": {},
        "Name": {},
        "ResourceARN": {},
        "StorageVirtualMachineId": {},
        "Subtype": {},
        "UUID": {},
        "Tags": {
          "shape": "Sj"
        },
        "LifecycleTransitionReason": {
          "shape": "S2x"
        },
        "RootVolumeSecurityStyle": {}
      }
    },
    "S68": {
      "type": "structure",
      "members": {
        "DNSName": {},
        "IpAddresses": {
          "shape": "S3p"
        }
      }
    },
    "S6c": {
      "type": "structure",
      "required": [
        "JunctionPath",
        "SizeInMegabytes",
        "StorageEfficiencyEnabled",
        "StorageVirtualMachineId"
      ],
      "members": {
        "JunctionPath": {},
        "SecurityStyle": {},
        "SizeInMegabytes": {
          "type": "integer"
        },
        "StorageEfficiencyEnabled": {
          "type": "boolean"
        },
        "StorageVirtualMachineId": {},
        "TieringPolicy": {
          "shape": "S2q"
        }
      }
    },
    "S7d": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S83": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "FileCacheId": {},
        "FileCacheType": {},
        "FileCacheTypeVersion": {},
        "Lifecycle": {},
        "FailureDetails": {
          "shape": "S5g"
        },
        "StorageCapacity": {
          "type": "integer"
        },
        "VpcId": {},
        "SubnetIds": {
          "shape": "S15"
        },
        "NetworkInterfaceIds": {
          "shape": "S17"
        },
        "DNSName": {},
        "KmsKeyId": {},
        "ResourceARN": {},
        "LustreConfiguration": {
          "shape": "S5h"
        },
        "DataRepositoryAssociationIds": {
          "shape": "S5i"
        }
      }
    },
    "S9n": {
      "type": "structure",
      "members": {
        "UserName": {},
        "Password": {
          "shape": "S5m"
        },
        "DnsIps": {
          "shape": "S1h"
        }
      }
    }
  }
}