{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-11-25",
    "endpointPrefix": "applicationinsights",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Application Insights",
    "serviceFullName": "Amazon CloudWatch Application Insights",
    "serviceId": "Application Insights",
    "signatureVersion": "v4",
    "signingName": "applicationinsights",
    "targetPrefix": "EC2WindowsBarleyService",
    "uid": "application-insights-2018-11-25"
  },
  "operations": {
    "CreateApplication": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceGroupName": {},
          "OpsCenterEnabled": {
            "type": "boolean"
          },
          "CWEMonitorEnabled": {
            "type": "boolean"
          },
          "OpsItemSNSTopicArn": {},
          "Tags": {
            "shape": "S6"
          },
          "AutoConfigEnabled": {
            "type": "boolean"
          },
          "AutoCreate": {
            "type": "boolean"
          },
          "GroupingType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationInfo": {
            "shape": "Se"
          }
        }
      }
    },
    "CreateComponent": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName",
          "ResourceList"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {},
          "ResourceList": {
            "shape": "Sk"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateLogPattern": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "PatternSetName",
          "PatternName",
          "Pattern",
          "Rank"
        ],
        "members": {
          "ResourceGroupName": {},
          "PatternSetName": {},
          "PatternName": {},
          "Pattern": {},
          "Rank": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LogPattern": {
            "shape": "St"
          },
          "ResourceGroupName": {}
        }
      }
    },
    "DeleteApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName"
        ],
        "members": {
          "ResourceGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteComponent": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteLogPattern": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "PatternSetName",
          "PatternName"
        ],
        "members": {
          "ResourceGroupName": {},
          "PatternSetName": {},
          "PatternName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName"
        ],
        "members": {
          "ResourceGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationInfo": {
            "shape": "Se"
          }
        }
      }
    },
    "DescribeComponent": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationComponent": {
            "shape": "S15"
          },
          "ResourceList": {
            "shape": "Sk"
          }
        }
      }
    },
    "DescribeComponentConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Monitor": {
            "type": "boolean"
          },
          "Tier": {},
          "ComponentConfiguration": {}
        }
      }
    },
    "DescribeComponentConfigurationRecommendation": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName",
          "Tier"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {},
          "Tier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComponentConfiguration": {}
        }
      }
    },
    "DescribeLogPattern": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "PatternSetName",
          "PatternName"
        ],
        "members": {
          "ResourceGroupName": {},
          "PatternSetName": {},
          "PatternName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceGroupName": {},
          "LogPattern": {
            "shape": "St"
          }
        }
      }
    },
    "DescribeObservation": {
      "input": {
        "type": "structure",
        "required": [
          "ObservationId"
        ],
        "members": {
          "ObservationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Observation": {
            "shape": "S1o"
          }
        }
      }
    },
    "DescribeProblem": {
      "input": {
        "type": "structure",
        "required": [
          "ProblemId"
        ],
        "members": {
          "ProblemId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Problem": {
            "shape": "S30"
          }
        }
      }
    },
    "DescribeProblemObservations": {
      "input": {
        "type": "structure",
        "required": [
          "ProblemId"
        ],
        "members": {
          "ProblemId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RelatedObservations": {
            "type": "structure",
            "members": {
              "ObservationList": {
                "type": "list",
                "member": {
                  "shape": "S1o"
                }
              }
            }
          }
        }
      }
    },
    "ListApplications": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationInfoList": {
            "type": "list",
            "member": {
              "shape": "Se"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListComponents": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName"
        ],
        "members": {
          "ResourceGroupName": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationComponentList": {
            "type": "list",
            "member": {
              "shape": "S15"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListConfigurationHistory": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceGroupName": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "EventStatus": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MonitoredResourceARN": {},
                "EventStatus": {},
                "EventResourceType": {},
                "EventTime": {
                  "type": "timestamp"
                },
                "EventDetail": {},
                "EventResourceName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLogPatternSets": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName"
        ],
        "members": {
          "ResourceGroupName": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceGroupName": {},
          "LogPatternSets": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListLogPatterns": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName"
        ],
        "members": {
          "ResourceGroupName": {},
          "PatternSetName": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceGroupName": {},
          "LogPatterns": {
            "type": "list",
            "member": {
              "shape": "St"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListProblems": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceGroupName": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "ComponentName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProblemList": {
            "type": "list",
            "member": {
              "shape": "S30"
            }
          },
          "NextToken": {},
          "ResourceGroupName": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S6"
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName"
        ],
        "members": {
          "ResourceGroupName": {},
          "OpsCenterEnabled": {
            "type": "boolean"
          },
          "CWEMonitorEnabled": {
            "type": "boolean"
          },
          "OpsItemSNSTopicArn": {},
          "RemoveSNSTopic": {
            "type": "boolean"
          },
          "AutoConfigEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationInfo": {
            "shape": "Se"
          }
        }
      }
    },
    "UpdateComponent": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {},
          "NewComponentName": {},
          "ResourceList": {
            "shape": "Sk"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateComponentConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {},
          "Monitor": {
            "type": "boolean"
          },
          "Tier": {},
          "ComponentConfiguration": {},
          "AutoConfigEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateLogPattern": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "PatternSetName",
          "PatternName"
        ],
        "members": {
          "ResourceGroupName": {},
          "PatternSetName": {},
          "PatternName": {},
          "Pattern": {},
          "Rank": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceGroupName": {},
          "LogPattern": {
            "shape": "St"
          }
        }
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "ResourceGroupName": {},
        "LifeCycle": {},
        "OpsItemSNSTopicArn": {},
        "OpsCenterEnabled": {
          "type": "boolean"
        },
        "CWEMonitorEnabled": {
          "type": "boolean"
        },
        "Remarks": {},
        "AutoConfigEnabled": {
          "type": "boolean"
        },
        "DiscoveryType": {}
      }
    },
    "Sk": {
      "type": "list",
      "member": {}
    },
    "St": {
      "type": "structure",
      "members": {
        "PatternSetName": {},
        "PatternName": {},
        "Pattern": {},
        "Rank": {
          "type": "integer"
        }
      }
    },
    "S15": {
      "type": "structure",
      "members": {
        "ComponentName": {},
        "ComponentRemarks": {},
        "ResourceType": {},
        "OsType": {},
        "Tier": {},
        "Monitor": {
          "type": "boolean"
        },
        "DetectedWorkload": {
          "type": "map",
          "key": {},
          "value": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "S1o": {
      "type": "structure",
      "members": {
        "Id": {},
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "SourceType": {},
        "SourceARN": {},
        "LogGroup": {},
        "LineTime": {
          "type": "timestamp"
        },
        "LogText": {},
        "LogFilter": {},
        "MetricNamespace": {},
        "MetricName": {},
        "Unit": {},
        "Value": {
          "type": "double"
        },
        "CloudWatchEventId": {},
        "CloudWatchEventSource": {},
        "CloudWatchEventDetailType": {},
        "HealthEventArn": {},
        "HealthService": {},
        "HealthEventTypeCode": {},
        "HealthEventTypeCategory": {},
        "HealthEventDescription": {},
        "CodeDeployDeploymentId": {},
        "CodeDeployDeploymentGroup": {},
        "CodeDeployState": {},
        "CodeDeployApplication": {},
        "CodeDeployInstanceGroupId": {},
        "Ec2State": {},
        "RdsEventCategories": {},
        "RdsEventMessage": {},
        "S3EventName": {},
        "StatesExecutionArn": {},
        "StatesArn": {},
        "StatesStatus": {},
        "StatesInput": {},
        "EbsEvent": {},
        "EbsResult": {},
        "EbsCause": {},
        "EbsRequestId": {},
        "XRayFaultPercent": {
          "type": "integer"
        },
        "XRayThrottlePercent": {
          "type": "integer"
        },
        "XRayErrorPercent": {
          "type": "integer"
        },
        "XRayRequestCount": {
          "type": "integer"
        },
        "XRayRequestAverageLatency": {
          "type": "long"
        },
        "XRayNodeName": {},
        "XRayNodeType": {}
      }
    },
    "S30": {
      "type": "structure",
      "members": {
        "Id": {},
        "Title": {},
        "Insights": {},
        "Status": {},
        "AffectedResource": {},
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "SeverityLevel": {},
        "ResourceGroupName": {},
        "Feedback": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "RecurringCount": {
          "type": "long"
        },
        "LastRecurrenceTime": {
          "type": "timestamp"
        }
      }
    }
  }
}