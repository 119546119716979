import React, { useEffect, useState } from "react";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Button, Container, Grid, MuiThemeProvider, TextField, makeStyles, Tooltip, Link } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { getSongContents, getSongMetadata, insertSong, updateSong } from "../../../api/song";
import { createTheme } from '@material-ui/core/styles';
import { Auth } from "../../core/Auth";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#ffffff"
    }
  },
  typography: {
    fontSize: 15,
    fontFamily: "Montserrat"
  }
});

const useStyle = makeStyles({
  centerForm: {
    borderStyle: "dashed",
    borderWidth: 1,
  },
  textBox: {
    marginTop: "8px",
    marginBottom: "8px",
    width: "100%",
  },
  formButton: {
    display: "block",
    marginBottom: "15px",
    backgroundColor: "#DD5150",
    color: "#ffffff",
    fontWeight: "bold",
  },
  addLang: {
    textAlign: "center",
    width: "100%"
  },
  removeButton: {
    color: "#DD5150",
  },
})

// render the submit and cancel button
function RenderSubmitCancelButtons(props: any) {
  const history = useHistory()
  const handleSubmit = async () => {
    props.setIsSubmitted(true);

    let data = {
      SongMetadata: {
        Id: props.songMetadata.id,
        LyricsComposer: props.songMetadata.lyricsComposer,
        MusicComposer: props.songMetadata.musicComposer,
        MusicSheetLink: props.songMetadata.musicSheetLink,
        ScriptureReference: props.songMetadata.scriptureReference,
        Notes: props.songMetadata.notes,
        Status: 0,
      },
      SongContentList: props.songContents,
    };

    let song;
    let id;

    if (props.exist) {
      song = await updateSong(data, props.user.email);
      id = data.SongMetadata.Id;
    } else {
      song = await insertSong(data, props.user.email);
      id = song.songMetadata.id;
    }
    history.push('/worship?songId=' + id)
  }

  const handleCancel = async () => {
    history.goBack();
  }

  function isValidContent(content: any) {
    return content.language.length >= 1 &&
      content.title.length >= 1 &&
      content.lyrics.length >= 1;
  }

  function isValidMetadata(metadata: any) {
    return metadata.lyricsComposer.length >= 1 &&
      metadata.musicComposer.length >= 1;
  }

  const isSubmitEnabled = props.songContents.every(isValidContent) && isValidMetadata(props.songMetadata);

  const styles = useStyle();

  return (
    <Grid container item xs={12}>
      <hr />
      <Grid item xs={12}>
        <Button
          className={styles.formButton}
          id="submitButton"
          variant="contained"
          disabled={!isSubmitEnabled}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={styles.formButton}
          id="cancelButton"
          variant="contained"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  )
}

// render the song content form to fill with the song data
function RenderSongContentForm(props: any) {
  const songContents = props.songContents;
  const styles = useStyle();
  const arr = new Array(songContents.length).fill(true);
  if (songContents) {
    return (
      <Grid container item xs={12} style={{ marginTop: "15px" }}>
        {songContents.map((val: any, index: any) =>
          <div style={{ display: arr[index] ? "block" : "none", width: "100%" }} key={index}>
            <hr />
            <Grid item xs={12} style={{ textAlign: "end" }}>
              <Link className={styles.removeButton} component="button" variant="body2" onClick={(event) => props.handleDeleteLanguage(event, index, arr)}>
                Remove
              </Link>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={styles.textBox}
                required
                error={val.language === ""}
                id="language"
                label="Language"
                name="language"
                value={val.language}
                placeholder={"Insert language"}
                onChange={(event) => props.handleSongContentChange(event, index)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={styles.textBox}
                required
                error={val.title === ""}
                id="title"
                label="Title"
                name="title"
                value={val.title}
                placeholder={"Insert title"}
                onChange={(event) => props.handleSongContentChange(event, index)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={styles.textBox}
                id="hymnBookLink"
                label="HymnBookLink"
                name="hymnBookLink"
                value={val.hymnBookLink}
                placeholder={"Insert hymnbook link"}
                onChange={(event) => props.handleSongContentChange(event, index)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={styles.textBox}
                id="youTubeLink"
                label="YouTube Link"
                name="youTubeLink"
                value={val.youTubeLink}
                placeholder={"Insert youtube link"}
                onChange={(event) => props.handleSongContentChange(event, index)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={styles.textBox}
                multiline
                required
                error={val.lyrics === ""}
                id="lyrics"
                fullWidth
                label="Lyrics"
                name="lyrics"
                margin="normal"
                value={val.lyrics}
                placeholder={"Insert lyrics"}
                onChange={(event) => props.handleSongContentChange(event, index)}
                minRows="10"
                InputProps={{ style: { fontFamily: "Courier New" } }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
          </div>
        )}
        <Grid item xs={12} className={styles.addLang}>
          <Tooltip title="Add new language" aria-label="add-new-language">
            <AddCircleIcon style={{ fontSize: "60px", color: "#DD5150" }} onClick={(event) => props.handleClick(event)} ></AddCircleIcon>
          </Tooltip>
        </Grid>
      </Grid>
    );
  } else {
    return null
  }
}

// render the song metadata form to fill with the metadata
function RenderSongMetadataForm(props: any) {
  const songMetadata = props.songMetadata;
  const styles = useStyle();

  return (
    <Grid container item xs={12} style={{ marginTop: "10px" }}>
      <Grid item xs={12} className={styles.textBox}>
        <TextField
          className={styles.textBox}
          required
          error={songMetadata.musicComposer === ""}
          id="lyricsComposer"
          label="Lyrics Composer"
          name="lyricsComposer"
          value={songMetadata.lyricsComposer}
          placeholder={"Insert Lyrics Composer"}
          onChange={(event) => props.handleSongMetadataChange(event)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={styles.textBox}
          required
          error={songMetadata.musicComposer === ""}
          id="musicComposer"
          label="Music Composer"
          name="musicComposer"
          value={songMetadata.musicComposer}
          placeholder={"Insert Music Composer"}
          onChange={(event) => props.handleSongMetadataChange(event)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={styles.textBox}
          id="musicSheetLink"
          label="Music Sheet Link"
          name="musicSheetLink"
          value={songMetadata.musicSheetLink}
          placeholder={"Insert Music Sheet Link"}
          onChange={(event) => props.handleSongMetadataChange(event)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={styles.textBox}
          id="scriptureReference"
          label="Scripture Reference"
          name="scriptureReference"
          value={songMetadata.scriptureReference}
          placeholder={"Insert Scripture Reference"}
          onChange={(event) => props.handleSongMetadataChange(event)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={styles.textBox}
          id="notes"
          label="Notes"
          name="notes"
          value={songMetadata.notes}
          placeholder={"Insert Notes"}
          onChange={(event) => props.handleSongMetadataChange(event)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
}

// the big function that handle rendering the form and its functionality
function InsertForm() {
  const [songMetadata, setSongMetadata] = useState({
    id: 0,
    lyricsComposer: "",
    musicComposer: "",
    musicSheetLink: "",
    scriptureReference: "",
    notes: "",
  });
  const [songContents, setSongContents] = useState([{
    songMetadataId: 0,
    language: "",
    title: "",
    hymnBookLink: "",
    youTubeLink: "",
    lyrics: ""
  }]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [failedToSubmit, setFailedToSubmit] = useState(false);
  const [exist, setExist] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [user, setUser] = useState(undefined);

  const handleClick = (event: MouseEvent) => {
    const emptySongContent = {
      songMetadataId: songMetadata.id,
      language: "",
      title: "",
      lyrics: "",
      youTubeLink: "",
      hymnBookLink: "",
    };

    setSongContents(songContents => ([
      ...songContents,
      emptySongContent
    ]));
  }

  const handleDeleteLanguage = (event: MouseEvent, index: number, arr: any) => {
    const newSongContents = [...songContents];
    newSongContents.splice(index, 1);
    setSongContents(newSongContents);
  }

  const handleSongContentChange = (event: any, index: any) => {
    let copySongContent = [...songContents] as any[];
    copySongContent[index][event.target.name] = event.target.value;
    setSongContents(copySongContent);
  }

  const handleSongMetadataChange = (event: any) => {
    event.persist();
    setSongMetadata(songMetadata => ({ ...songMetadata, [event.target.name]: event.target.value }));
  };
  type songId = { id?: string }
  const extractId: songId = useParams();
  useEffect(() => {
    async function getSong(extractId: any) {
      var existingSongMetadata = await getSongMetadata(extractId.id);
      var existingSongContent = await getSongContents(extractId.id);

      setSongMetadata(existingSongMetadata);
      setSongContents(existingSongContent);
    }

    async function loadUser() {
      if (!user) {
        setUser(await Auth.getUser());
      }
    }

    if (!!extractId.id) {
      setExist(true);
      getSong(extractId);
    }
    loadUser();
  }, [])

  const styles = useStyle();
  function renderForm() {
    if (songContents) {
      return (
        <Grid container>
          <RenderSongMetadataForm
            songMetadata={songMetadata}
            handleSongMetadataChange={(event: any) => handleSongMetadataChange(event)}
          />
          <RenderSongContentForm
            songContents={songContents}
            handleSongContentChange={(event: React.ChangeEvent<HTMLInputElement>, index: number) => handleSongContentChange(event, index)}
            handleClick={handleClick}
            handleDeleteLanguage={handleDeleteLanguage}
          />
          <RenderSubmitCancelButtons
            exist={exist}
            songMetadata={songMetadata}
            songContents={songContents}
            setExist={(doesExist: any) => { setExist(doesExist) }}
            setIsSubmitted={(submitted: any) => { setIsSubmitted(submitted) }}
            setFailedToSubmit={(failedToSubmit: any) => { setFailedToSubmit(failedToSubmit) }}
            user={user}
          />
        </Grid>
      );
    } else {
      return null
    }
  }
  return (
    renderForm()
  );
}

export default function Insert() {
  return (
    <MuiThemeProvider theme={theme}>
      <Container>
        <InsertForm />
      </Container>
    </MuiThemeProvider>
  )
}