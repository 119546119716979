{
  "pagination": {
    "ListResourceRequests": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ResourceRequestStatusSummaries"
    },
    "ListResources": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ResourceDescriptions"
    }
  }
}
