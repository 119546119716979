{
  "pagination": {
    "ListHumanLoops": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "HumanLoopSummaries"
    }
  }
}
