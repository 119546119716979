import {googleLogout} from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

import {createSessionWithToken, getUser, logout} from '../../api/directory';

export const TOKEN_KEY = 'AccessToken';

class AppAuth {

  /** Whether user is authenticated and a session is created in the server. */
  isAuthenticated = true;

  user = null;

  /** The user info retrieved by decoding the token returned by Google OAuth login. */
  async getUser() {
    if (this.user) {
      return this.user;
    }
    this.user = await getUser();
    return this.user;
  }

  /** The user id */
  userId = null;

  /** The directory information. The default is 0 = Submitter*/
  role = 0;

  /**
   * Populates user info and save the raw token string in local storage.
   * Returns true if the session is successfully created and the user is
   * registered in the directory, returns false otherwise.
   */
  async handleLoginResponse(loginResponse) {
    try {
      const token = loginResponse.credential;
      const user = await createSessionWithToken(token);
      if (user) {
        this.isAuthenticated = true;
        this.user = user;
        return true;
      } else {
        // If session ID is missing from response, google login is successful
        // but user is not yet registered in directory.
        this.logout();
        return false;
      }
    } catch (e){
      this.logout();
      return false;
    }
  }

  /** Clears saved raw token string, deletes user info and logs the user out. */
  async logout() {
    this.isAuthenticated = false;
    this.user = null;
    googleLogout();
    await logout();

    // Redirect users to login page.
    if (window.location.pathname !== '/login') {
      window.location.pathname = '/login';
    }
  }

  /**
   * A function that handles general API response.
   * Returns the response body as json if status is ok and
   * logs the user out if the API returns unauthorized(401) status code.
   */
  async handleResponse(response) {
    if (response.ok) {
      return await response.json();
    } else if (response.status === 401 && this.isAuthenticated) {
      // Unauthorized
      this.logout();
    }
  }

  /**
   * A function that handles GetUser API response.
   * Returns the response body as json if status is ok and
   * logs the user out if the API returns not found(404) status code.
   */
  async handleGetUserResponse(response) {
    if (response.ok) {
      return await response.json();
    } else if (response.status === 404 || response.status === 401) {
      // Not logged in or unauthorized
      this.logout();
    }
  }
}

const Auth =  new AppAuth();

export { Auth, AppAuth };
