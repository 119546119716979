const MenuConfig = {
    reimbursement: {
        dashboard: {
            text: "Dashboard",
            route: "/reimbursement"
        },
        reimburse: {
            text: "Reimburse an expense",
            route: "/reimbursement/submit"
        }
    },
    worship: {
        songs: {
            text: "Songs",
            route: "/worship/songs"
        },
        documents: {
            text: "Documents",
            route: "/worship/documents"
        },
    }
};

export { MenuConfig };