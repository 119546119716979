{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-09-14",
    "endpointPrefix": "kafkaconnect",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Kafka Connect",
    "serviceFullName": "Managed Streaming for Kafka Connect",
    "serviceId": "KafkaConnect",
    "signatureVersion": "v4",
    "signingName": "kafkaconnect",
    "uid": "kafkaconnect-2021-09-14"
  },
  "operations": {
    "CreateConnector": {
      "http": {
        "requestUri": "/v1/connectors",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "capacity",
          "connectorConfiguration",
          "connectorName",
          "kafkaCluster",
          "kafkaClusterClientAuthentication",
          "kafkaClusterEncryptionInTransit",
          "kafkaConnectVersion",
          "plugins",
          "serviceExecutionRoleArn"
        ],
        "members": {
          "capacity": {
            "type": "structure",
            "members": {
              "autoScaling": {
                "type": "structure",
                "required": [
                  "maxWorkerCount",
                  "mcuCount",
                  "minWorkerCount"
                ],
                "members": {
                  "maxWorkerCount": {
                    "type": "integer"
                  },
                  "mcuCount": {
                    "type": "integer"
                  },
                  "minWorkerCount": {
                    "type": "integer"
                  },
                  "scaleInPolicy": {
                    "type": "structure",
                    "required": [
                      "cpuUtilizationPercentage"
                    ],
                    "members": {
                      "cpuUtilizationPercentage": {
                        "type": "integer"
                      }
                    }
                  },
                  "scaleOutPolicy": {
                    "type": "structure",
                    "required": [
                      "cpuUtilizationPercentage"
                    ],
                    "members": {
                      "cpuUtilizationPercentage": {
                        "type": "integer"
                      }
                    }
                  }
                }
              },
              "provisionedCapacity": {
                "type": "structure",
                "required": [
                  "mcuCount",
                  "workerCount"
                ],
                "members": {
                  "mcuCount": {
                    "type": "integer"
                  },
                  "workerCount": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "connectorConfiguration": {
            "type": "map",
            "key": {},
            "value": {},
            "sensitive": true
          },
          "connectorDescription": {},
          "connectorName": {},
          "kafkaCluster": {
            "type": "structure",
            "required": [
              "apacheKafkaCluster"
            ],
            "members": {
              "apacheKafkaCluster": {
                "type": "structure",
                "required": [
                  "bootstrapServers",
                  "vpc"
                ],
                "members": {
                  "bootstrapServers": {},
                  "vpc": {
                    "type": "structure",
                    "required": [
                      "subnets"
                    ],
                    "members": {
                      "securityGroups": {
                        "shape": "Sh"
                      },
                      "subnets": {
                        "shape": "Sh"
                      }
                    }
                  }
                }
              }
            }
          },
          "kafkaClusterClientAuthentication": {
            "type": "structure",
            "required": [
              "authenticationType"
            ],
            "members": {
              "authenticationType": {}
            }
          },
          "kafkaClusterEncryptionInTransit": {
            "type": "structure",
            "required": [
              "encryptionType"
            ],
            "members": {
              "encryptionType": {}
            }
          },
          "kafkaConnectVersion": {},
          "logDelivery": {
            "type": "structure",
            "required": [
              "workerLogDelivery"
            ],
            "members": {
              "workerLogDelivery": {
                "type": "structure",
                "members": {
                  "cloudWatchLogs": {
                    "type": "structure",
                    "required": [
                      "enabled"
                    ],
                    "members": {
                      "enabled": {
                        "type": "boolean"
                      },
                      "logGroup": {}
                    }
                  },
                  "firehose": {
                    "type": "structure",
                    "required": [
                      "enabled"
                    ],
                    "members": {
                      "deliveryStream": {},
                      "enabled": {
                        "type": "boolean"
                      }
                    }
                  },
                  "s3": {
                    "type": "structure",
                    "required": [
                      "enabled"
                    ],
                    "members": {
                      "bucket": {},
                      "enabled": {
                        "type": "boolean"
                      },
                      "prefix": {}
                    }
                  }
                }
              }
            }
          },
          "plugins": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "customPlugin"
              ],
              "members": {
                "customPlugin": {
                  "type": "structure",
                  "required": [
                    "customPluginArn",
                    "revision"
                  ],
                  "members": {
                    "customPluginArn": {},
                    "revision": {
                      "type": "long"
                    }
                  }
                }
              }
            }
          },
          "serviceExecutionRoleArn": {},
          "workerConfiguration": {
            "type": "structure",
            "required": [
              "revision",
              "workerConfigurationArn"
            ],
            "members": {
              "revision": {
                "type": "long"
              },
              "workerConfigurationArn": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectorArn": {},
          "connectorName": {},
          "connectorState": {}
        }
      }
    },
    "CreateCustomPlugin": {
      "http": {
        "requestUri": "/v1/custom-plugins",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "contentType",
          "location",
          "name"
        ],
        "members": {
          "contentType": {},
          "description": {},
          "location": {
            "type": "structure",
            "required": [
              "s3Location"
            ],
            "members": {
              "s3Location": {
                "type": "structure",
                "required": [
                  "bucketArn",
                  "fileKey"
                ],
                "members": {
                  "bucketArn": {},
                  "fileKey": {},
                  "objectVersion": {}
                }
              }
            }
          },
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "customPluginArn": {},
          "customPluginState": {},
          "name": {},
          "revision": {
            "type": "long"
          }
        }
      }
    },
    "CreateWorkerConfiguration": {
      "http": {
        "requestUri": "/v1/worker-configurations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "propertiesFileContent"
        ],
        "members": {
          "description": {},
          "name": {},
          "propertiesFileContent": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "creationTime": {
            "shape": "S19"
          },
          "latestRevision": {
            "shape": "S1a"
          },
          "name": {},
          "workerConfigurationArn": {}
        }
      }
    },
    "DeleteConnector": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/connectors/{connectorArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "connectorArn"
        ],
        "members": {
          "connectorArn": {
            "location": "uri",
            "locationName": "connectorArn"
          },
          "currentVersion": {
            "location": "querystring",
            "locationName": "currentVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectorArn": {},
          "connectorState": {}
        }
      },
      "idempotent": true
    },
    "DeleteCustomPlugin": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/custom-plugins/{customPluginArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "customPluginArn"
        ],
        "members": {
          "customPluginArn": {
            "location": "uri",
            "locationName": "customPluginArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "customPluginArn": {},
          "customPluginState": {}
        }
      },
      "idempotent": true
    },
    "DescribeConnector": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/connectors/{connectorArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "connectorArn"
        ],
        "members": {
          "connectorArn": {
            "location": "uri",
            "locationName": "connectorArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "capacity": {
            "shape": "S1h"
          },
          "connectorArn": {},
          "connectorConfiguration": {
            "type": "map",
            "key": {},
            "value": {},
            "sensitive": true
          },
          "connectorDescription": {},
          "connectorName": {},
          "connectorState": {},
          "creationTime": {
            "shape": "S19"
          },
          "currentVersion": {},
          "kafkaCluster": {
            "shape": "S1o"
          },
          "kafkaClusterClientAuthentication": {
            "shape": "S1r"
          },
          "kafkaClusterEncryptionInTransit": {
            "shape": "S1s"
          },
          "kafkaConnectVersion": {},
          "logDelivery": {
            "shape": "S1t"
          },
          "plugins": {
            "shape": "S1y"
          },
          "serviceExecutionRoleArn": {},
          "stateDescription": {
            "shape": "S21"
          },
          "workerConfiguration": {
            "shape": "S22"
          }
        }
      }
    },
    "DescribeCustomPlugin": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/custom-plugins/{customPluginArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "customPluginArn"
        ],
        "members": {
          "customPluginArn": {
            "location": "uri",
            "locationName": "customPluginArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "creationTime": {
            "shape": "S19"
          },
          "customPluginArn": {},
          "customPluginState": {},
          "description": {},
          "latestRevision": {
            "shape": "S25"
          },
          "name": {},
          "stateDescription": {
            "shape": "S21"
          }
        }
      }
    },
    "DescribeWorkerConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/worker-configurations/{workerConfigurationArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workerConfigurationArn"
        ],
        "members": {
          "workerConfigurationArn": {
            "location": "uri",
            "locationName": "workerConfigurationArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "creationTime": {
            "shape": "S19"
          },
          "description": {},
          "latestRevision": {
            "type": "structure",
            "members": {
              "creationTime": {
                "shape": "S19"
              },
              "description": {},
              "propertiesFileContent": {
                "type": "string",
                "sensitive": true
              },
              "revision": {
                "type": "long"
              }
            }
          },
          "name": {},
          "workerConfigurationArn": {}
        }
      }
    },
    "ListConnectors": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/connectors",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "connectorNamePrefix": {
            "location": "querystring",
            "locationName": "connectorNamePrefix"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "capacity": {
                  "shape": "S1h"
                },
                "connectorArn": {},
                "connectorDescription": {},
                "connectorName": {},
                "connectorState": {},
                "creationTime": {
                  "shape": "S19"
                },
                "currentVersion": {},
                "kafkaCluster": {
                  "shape": "S1o"
                },
                "kafkaClusterClientAuthentication": {
                  "shape": "S1r"
                },
                "kafkaClusterEncryptionInTransit": {
                  "shape": "S1s"
                },
                "kafkaConnectVersion": {},
                "logDelivery": {
                  "shape": "S1t"
                },
                "plugins": {
                  "shape": "S1y"
                },
                "serviceExecutionRoleArn": {},
                "workerConfiguration": {
                  "shape": "S22"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListCustomPlugins": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/custom-plugins",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "customPlugins": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "creationTime": {
                  "shape": "S19"
                },
                "customPluginArn": {},
                "customPluginState": {},
                "description": {},
                "latestRevision": {
                  "shape": "S25"
                },
                "name": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListWorkerConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/worker-configurations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "workerConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "creationTime": {
                  "shape": "S19"
                },
                "description": {},
                "latestRevision": {
                  "shape": "S1a"
                },
                "name": {},
                "workerConfigurationArn": {}
              }
            }
          }
        }
      }
    },
    "UpdateConnector": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/connectors/{connectorArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "capacity",
          "connectorArn",
          "currentVersion"
        ],
        "members": {
          "capacity": {
            "type": "structure",
            "members": {
              "autoScaling": {
                "type": "structure",
                "required": [
                  "maxWorkerCount",
                  "mcuCount",
                  "minWorkerCount",
                  "scaleInPolicy",
                  "scaleOutPolicy"
                ],
                "members": {
                  "maxWorkerCount": {
                    "type": "integer"
                  },
                  "mcuCount": {
                    "type": "integer"
                  },
                  "minWorkerCount": {
                    "type": "integer"
                  },
                  "scaleInPolicy": {
                    "type": "structure",
                    "required": [
                      "cpuUtilizationPercentage"
                    ],
                    "members": {
                      "cpuUtilizationPercentage": {
                        "type": "integer"
                      }
                    }
                  },
                  "scaleOutPolicy": {
                    "type": "structure",
                    "required": [
                      "cpuUtilizationPercentage"
                    ],
                    "members": {
                      "cpuUtilizationPercentage": {
                        "type": "integer"
                      }
                    }
                  }
                }
              },
              "provisionedCapacity": {
                "type": "structure",
                "required": [
                  "mcuCount",
                  "workerCount"
                ],
                "members": {
                  "mcuCount": {
                    "type": "integer"
                  },
                  "workerCount": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "connectorArn": {
            "location": "uri",
            "locationName": "connectorArn"
          },
          "currentVersion": {
            "location": "querystring",
            "locationName": "currentVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectorArn": {},
          "connectorState": {}
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "Sh": {
      "type": "list",
      "member": {}
    },
    "S19": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S1a": {
      "type": "structure",
      "members": {
        "creationTime": {
          "shape": "S19"
        },
        "description": {},
        "revision": {
          "type": "long"
        }
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "autoScaling": {
          "type": "structure",
          "members": {
            "maxWorkerCount": {
              "type": "integer"
            },
            "mcuCount": {
              "type": "integer"
            },
            "minWorkerCount": {
              "type": "integer"
            },
            "scaleInPolicy": {
              "type": "structure",
              "members": {
                "cpuUtilizationPercentage": {
                  "type": "integer"
                }
              }
            },
            "scaleOutPolicy": {
              "type": "structure",
              "members": {
                "cpuUtilizationPercentage": {
                  "type": "integer"
                }
              }
            }
          }
        },
        "provisionedCapacity": {
          "type": "structure",
          "members": {
            "mcuCount": {
              "type": "integer"
            },
            "workerCount": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S1o": {
      "type": "structure",
      "members": {
        "apacheKafkaCluster": {
          "type": "structure",
          "members": {
            "bootstrapServers": {},
            "vpc": {
              "type": "structure",
              "members": {
                "securityGroups": {
                  "shape": "Sh"
                },
                "subnets": {
                  "shape": "Sh"
                }
              }
            }
          }
        }
      }
    },
    "S1r": {
      "type": "structure",
      "members": {
        "authenticationType": {}
      }
    },
    "S1s": {
      "type": "structure",
      "members": {
        "encryptionType": {}
      }
    },
    "S1t": {
      "type": "structure",
      "members": {
        "workerLogDelivery": {
          "type": "structure",
          "members": {
            "cloudWatchLogs": {
              "type": "structure",
              "members": {
                "enabled": {
                  "type": "boolean"
                },
                "logGroup": {}
              }
            },
            "firehose": {
              "type": "structure",
              "members": {
                "deliveryStream": {},
                "enabled": {
                  "type": "boolean"
                }
              }
            },
            "s3": {
              "type": "structure",
              "members": {
                "bucket": {},
                "enabled": {
                  "type": "boolean"
                },
                "prefix": {}
              }
            }
          }
        }
      }
    },
    "S1y": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "customPlugin": {
            "type": "structure",
            "members": {
              "customPluginArn": {},
              "revision": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "S21": {
      "type": "structure",
      "members": {
        "code": {},
        "message": {}
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "revision": {
          "type": "long"
        },
        "workerConfigurationArn": {}
      }
    },
    "S25": {
      "type": "structure",
      "members": {
        "contentType": {},
        "creationTime": {
          "shape": "S19"
        },
        "description": {},
        "fileDescription": {
          "type": "structure",
          "members": {
            "fileMd5": {},
            "fileSize": {
              "type": "long"
            }
          }
        },
        "location": {
          "type": "structure",
          "members": {
            "s3Location": {
              "type": "structure",
              "members": {
                "bucketArn": {},
                "fileKey": {},
                "objectVersion": {}
              }
            }
          }
        },
        "revision": {
          "type": "long"
        }
      }
    }
  }
}