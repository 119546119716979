{
  "pagination": {
    "DescribeConnectorProfiles": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "DescribeConnectors": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "DescribeFlowExecutionRecords": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListConnectors": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListFlows": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    }
  }
}
