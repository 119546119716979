{
  "pagination": {
    "DescribeAccountAttributes": {
      "result_key": "AccountAttributes"
    },
    "DescribeAddressTransfers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AddressTransfers"
    },
    "DescribeAddresses": {
      "result_key": "Addresses"
    },
    "DescribeAddressesAttribute": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Addresses"
    },
    "DescribeAvailabilityZones": {
      "result_key": "AvailabilityZones"
    },
    "DescribeBundleTasks": {
      "result_key": "BundleTasks"
    },
    "DescribeByoipCidrs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ByoipCidrs"
    },
    "DescribeCapacityReservationFleets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "CapacityReservationFleets"
    },
    "DescribeCapacityReservations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "CapacityReservations"
    },
    "DescribeCarrierGateways": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "CarrierGateways"
    },
    "DescribeClassicLinkInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Instances"
    },
    "DescribeClientVpnAuthorizationRules": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AuthorizationRules"
    },
    "DescribeClientVpnConnections": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Connections"
    },
    "DescribeClientVpnEndpoints": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ClientVpnEndpoints"
    },
    "DescribeClientVpnRoutes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Routes"
    },
    "DescribeClientVpnTargetNetworks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ClientVpnTargetNetworks"
    },
    "DescribeCoipPools": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "CoipPools"
    },
    "DescribeConversionTasks": {
      "result_key": "ConversionTasks"
    },
    "DescribeCustomerGateways": {
      "result_key": "CustomerGateways"
    },
    "DescribeDhcpOptions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "DhcpOptions"
    },
    "DescribeEgressOnlyInternetGateways": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "EgressOnlyInternetGateways"
    },
    "DescribeExportImageTasks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ExportImageTasks"
    },
    "DescribeExportTasks": {
      "result_key": "ExportTasks"
    },
    "DescribeFastLaunchImages": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "FastLaunchImages"
    },
    "DescribeFastSnapshotRestores": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "FastSnapshotRestores"
    },
    "DescribeFleets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Fleets"
    },
    "DescribeFlowLogs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "FlowLogs"
    },
    "DescribeFpgaImages": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "FpgaImages"
    },
    "DescribeHostReservationOfferings": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "OfferingSet"
    },
    "DescribeHostReservations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "HostReservationSet"
    },
    "DescribeHosts": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Hosts"
    },
    "DescribeIamInstanceProfileAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "IamInstanceProfileAssociations"
    },
    "DescribeImages": {
      "result_key": "Images"
    },
    "DescribeImportImageTasks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ImportImageTasks"
    },
    "DescribeImportSnapshotTasks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ImportSnapshotTasks"
    },
    "DescribeInstanceCreditSpecifications": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceCreditSpecifications"
    },
    "DescribeInstanceEventWindows": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceEventWindows"
    },
    "DescribeInstanceStatus": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceStatuses"
    },
    "DescribeInstanceTypeOfferings": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceTypeOfferings"
    },
    "DescribeInstanceTypes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceTypes"
    },
    "DescribeInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Reservations"
    },
    "DescribeInternetGateways": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InternetGateways"
    },
    "DescribeIpamPools": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "IpamPools"
    },
    "DescribeIpamScopes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "IpamScopes"
    },
    "DescribeIpams": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Ipams"
    },
    "DescribeIpv6Pools": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Ipv6Pools"
    },
    "DescribeKeyPairs": {
      "result_key": "KeyPairs"
    },
    "DescribeLaunchTemplateVersions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LaunchTemplateVersions"
    },
    "DescribeLaunchTemplates": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LaunchTemplates"
    },
    "DescribeLocalGatewayRouteTableVirtualInterfaceGroupAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LocalGatewayRouteTableVirtualInterfaceGroupAssociations"
    },
    "DescribeLocalGatewayRouteTableVpcAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LocalGatewayRouteTableVpcAssociations"
    },
    "DescribeLocalGatewayRouteTables": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LocalGatewayRouteTables"
    },
    "DescribeLocalGatewayVirtualInterfaceGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LocalGatewayVirtualInterfaceGroups"
    },
    "DescribeLocalGatewayVirtualInterfaces": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LocalGatewayVirtualInterfaces"
    },
    "DescribeLocalGateways": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LocalGateways"
    },
    "DescribeManagedPrefixLists": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PrefixLists"
    },
    "DescribeMovingAddresses": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "MovingAddressStatuses"
    },
    "DescribeNatGateways": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "NatGateways"
    },
    "DescribeNetworkAcls": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "NetworkAcls"
    },
    "DescribeNetworkInsightsAccessScopeAnalyses": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "NetworkInsightsAccessScopeAnalyses"
    },
    "DescribeNetworkInsightsAccessScopes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "NetworkInsightsAccessScopes"
    },
    "DescribeNetworkInsightsAnalyses": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "NetworkInsightsAnalyses"
    },
    "DescribeNetworkInsightsPaths": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "NetworkInsightsPaths"
    },
    "DescribeNetworkInterfacePermissions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "NetworkInterfacePermissions"
    },
    "DescribeNetworkInterfaces": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "NetworkInterfaces"
    },
    "DescribePlacementGroups": {
      "result_key": "PlacementGroups"
    },
    "DescribePrefixLists": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PrefixLists"
    },
    "DescribePrincipalIdFormat": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Principals"
    },
    "DescribePublicIpv4Pools": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PublicIpv4Pools"
    },
    "DescribeRegions": {
      "result_key": "Regions"
    },
    "DescribeReplaceRootVolumeTasks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ReplaceRootVolumeTasks"
    },
    "DescribeReservedInstances": {
      "result_key": "ReservedInstances"
    },
    "DescribeReservedInstancesListings": {
      "result_key": "ReservedInstancesListings"
    },
    "DescribeReservedInstancesModifications": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "ReservedInstancesModifications"
    },
    "DescribeReservedInstancesOfferings": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ReservedInstancesOfferings"
    },
    "DescribeRouteTables": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "RouteTables"
    },
    "DescribeScheduledInstanceAvailability": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ScheduledInstanceAvailabilitySet"
    },
    "DescribeScheduledInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ScheduledInstanceSet"
    },
    "DescribeSecurityGroupRules": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SecurityGroupRules"
    },
    "DescribeSecurityGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SecurityGroups"
    },
    "DescribeSnapshotTierStatus": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SnapshotTierStatuses"
    },
    "DescribeSnapshots": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Snapshots"
    },
    "DescribeSpotFleetRequests": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SpotFleetRequestConfigs"
    },
    "DescribeSpotInstanceRequests": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SpotInstanceRequests"
    },
    "DescribeSpotPriceHistory": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SpotPriceHistory"
    },
    "DescribeStaleSecurityGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "StaleSecurityGroupSet"
    },
    "DescribeStoreImageTasks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "StoreImageTaskResults"
    },
    "DescribeSubnets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Subnets"
    },
    "DescribeTags": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Tags"
    },
    "DescribeTrafficMirrorFilters": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TrafficMirrorFilters"
    },
    "DescribeTrafficMirrorSessions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TrafficMirrorSessions"
    },
    "DescribeTrafficMirrorTargets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TrafficMirrorTargets"
    },
    "DescribeTransitGatewayAttachments": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayAttachments"
    },
    "DescribeTransitGatewayConnectPeers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayConnectPeers"
    },
    "DescribeTransitGatewayConnects": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayConnects"
    },
    "DescribeTransitGatewayMulticastDomains": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayMulticastDomains"
    },
    "DescribeTransitGatewayPeeringAttachments": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayPeeringAttachments"
    },
    "DescribeTransitGatewayPolicyTables": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayPolicyTables"
    },
    "DescribeTransitGatewayRouteTableAnnouncements": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayRouteTableAnnouncements"
    },
    "DescribeTransitGatewayRouteTables": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayRouteTables"
    },
    "DescribeTransitGatewayVpcAttachments": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayVpcAttachments"
    },
    "DescribeTransitGateways": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGateways"
    },
    "DescribeTrunkInterfaceAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InterfaceAssociations"
    },
    "DescribeVolumeStatus": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VolumeStatuses"
    },
    "DescribeVolumes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Volumes"
    },
    "DescribeVolumesModifications": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VolumesModifications"
    },
    "DescribeVpcClassicLinkDnsSupport": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Vpcs"
    },
    "DescribeVpcEndpointConnectionNotifications": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ConnectionNotificationSet"
    },
    "DescribeVpcEndpointConnections": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VpcEndpointConnections"
    },
    "DescribeVpcEndpointServiceConfigurations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ServiceConfigurations"
    },
    "DescribeVpcEndpointServicePermissions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AllowedPrincipals"
    },
    "DescribeVpcEndpoints": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VpcEndpoints"
    },
    "DescribeVpcPeeringConnections": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VpcPeeringConnections"
    },
    "DescribeVpcs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Vpcs"
    },
    "DescribeVpnConnections": {
      "result_key": "VpnConnections"
    },
    "DescribeVpnGateways": {
      "result_key": "VpnGateways"
    },
    "GetAssociatedIpv6PoolCidrs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Ipv6CidrAssociations"
    },
    "GetGroupsForCapacityReservation": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "CapacityReservationGroups"
    },
    "GetInstanceTypesFromInstanceRequirements": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceTypes"
    },
    "GetIpamAddressHistory": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "HistoryRecords"
    },
    "GetIpamPoolAllocations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "IpamPoolAllocations"
    },
    "GetIpamPoolCidrs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "IpamPoolCidrs"
    },
    "GetIpamResourceCidrs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "IpamResourceCidrs"
    },
    "GetManagedPrefixListAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PrefixListAssociations"
    },
    "GetManagedPrefixListEntries": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Entries"
    },
    "GetSpotPlacementScores": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SpotPlacementScores"
    },
    "GetTransitGatewayAttachmentPropagations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayAttachmentPropagations"
    },
    "GetTransitGatewayMulticastDomainAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "MulticastDomainAssociations"
    },
    "GetTransitGatewayPolicyTableAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Associations"
    },
    "GetTransitGatewayPrefixListReferences": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayPrefixListReferences"
    },
    "GetTransitGatewayRouteTableAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Associations"
    },
    "GetTransitGatewayRouteTablePropagations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayRouteTablePropagations"
    },
    "GetVpnConnectionDeviceTypes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VpnConnectionDeviceTypes"
    },
    "ListImagesInRecycleBin": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Images"
    },
    "ListSnapshotsInRecycleBin": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Snapshots"
    },
    "SearchLocalGatewayRoutes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Routes"
    },
    "SearchTransitGatewayMulticastGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "MulticastGroups"
    }
  }
}