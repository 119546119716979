{
  "metadata": {
    "apiVersion": "2016-12-01",
    "endpointPrefix": "pinpoint",
    "signingName": "mobiletargeting",
    "serviceFullName": "Amazon Pinpoint",
    "serviceId": "Pinpoint",
    "protocol": "rest-json",
    "jsonVersion": "1.1",
    "uid": "pinpoint-2016-12-01",
    "signatureVersion": "v4"
  },
  "operations": {
    "CreateApp": {
      "http": {
        "requestUri": "/v1/apps",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "CreateApplicationRequest": {
            "type": "structure",
            "members": {
              "Name": {},
              "tags": {
                "shape": "S4",
                "locationName": "tags"
              }
            },
            "required": [
              "Name"
            ]
          }
        },
        "required": [
          "CreateApplicationRequest"
        ],
        "payload": "CreateApplicationRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationResponse": {
            "shape": "S6"
          }
        },
        "required": [
          "ApplicationResponse"
        ],
        "payload": "ApplicationResponse"
      }
    },
    "CreateCampaign": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/campaigns",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "WriteCampaignRequest": {
            "shape": "S8"
          }
        },
        "required": [
          "ApplicationId",
          "WriteCampaignRequest"
        ],
        "payload": "WriteCampaignRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignResponse": {
            "shape": "S1j"
          }
        },
        "required": [
          "CampaignResponse"
        ],
        "payload": "CampaignResponse"
      }
    },
    "CreateEmailTemplate": {
      "http": {
        "requestUri": "/v1/templates/{template-name}/email",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "EmailTemplateRequest": {
            "shape": "S1p"
          },
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          }
        },
        "required": [
          "TemplateName",
          "EmailTemplateRequest"
        ],
        "payload": "EmailTemplateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "CreateTemplateMessageBody": {
            "shape": "S1r"
          }
        },
        "required": [
          "CreateTemplateMessageBody"
        ],
        "payload": "CreateTemplateMessageBody"
      }
    },
    "CreateExportJob": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/jobs/export",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "ExportJobRequest": {
            "type": "structure",
            "members": {
              "RoleArn": {},
              "S3UrlPrefix": {},
              "SegmentId": {},
              "SegmentVersion": {
                "type": "integer"
              }
            },
            "required": [
              "S3UrlPrefix",
              "RoleArn"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "ExportJobRequest"
        ],
        "payload": "ExportJobRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportJobResponse": {
            "shape": "S1v"
          }
        },
        "required": [
          "ExportJobResponse"
        ],
        "payload": "ExportJobResponse"
      }
    },
    "CreateImportJob": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/jobs/import",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "ImportJobRequest": {
            "type": "structure",
            "members": {
              "DefineSegment": {
                "type": "boolean"
              },
              "ExternalId": {},
              "Format": {},
              "RegisterEndpoints": {
                "type": "boolean"
              },
              "RoleArn": {},
              "S3Url": {},
              "SegmentId": {},
              "SegmentName": {}
            },
            "required": [
              "Format",
              "S3Url",
              "RoleArn"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "ImportJobRequest"
        ],
        "payload": "ImportJobRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportJobResponse": {
            "shape": "S22"
          }
        },
        "required": [
          "ImportJobResponse"
        ],
        "payload": "ImportJobResponse"
      }
    },
    "CreateInAppTemplate": {
      "http": {
        "requestUri": "/v1/templates/{template-name}/inapp",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "InAppTemplateRequest": {
            "shape": "S25"
          },
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          }
        },
        "required": [
          "TemplateName",
          "InAppTemplateRequest"
        ],
        "payload": "InAppTemplateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateCreateMessageBody": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Message": {},
              "RequestID": {}
            }
          }
        },
        "required": [
          "TemplateCreateMessageBody"
        ],
        "payload": "TemplateCreateMessageBody"
      }
    },
    "CreateJourney": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/journeys",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "WriteJourneyRequest": {
            "shape": "S29"
          }
        },
        "required": [
          "ApplicationId",
          "WriteJourneyRequest"
        ],
        "payload": "WriteJourneyRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "JourneyResponse": {
            "shape": "S3r"
          }
        },
        "required": [
          "JourneyResponse"
        ],
        "payload": "JourneyResponse"
      }
    },
    "CreatePushTemplate": {
      "http": {
        "requestUri": "/v1/templates/{template-name}/push",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "PushNotificationTemplateRequest": {
            "shape": "S3t"
          },
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          }
        },
        "required": [
          "TemplateName",
          "PushNotificationTemplateRequest"
        ],
        "payload": "PushNotificationTemplateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "CreateTemplateMessageBody": {
            "shape": "S1r"
          }
        },
        "required": [
          "CreateTemplateMessageBody"
        ],
        "payload": "CreateTemplateMessageBody"
      }
    },
    "CreateRecommenderConfiguration": {
      "http": {
        "requestUri": "/v1/recommenders",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "CreateRecommenderConfiguration": {
            "type": "structure",
            "members": {
              "Attributes": {
                "shape": "S4"
              },
              "Description": {},
              "Name": {},
              "RecommendationProviderIdType": {},
              "RecommendationProviderRoleArn": {},
              "RecommendationProviderUri": {},
              "RecommendationTransformerUri": {},
              "RecommendationsDisplayName": {},
              "RecommendationsPerMessage": {
                "type": "integer"
              }
            },
            "required": [
              "RecommendationProviderUri",
              "RecommendationProviderRoleArn"
            ]
          }
        },
        "required": [
          "CreateRecommenderConfiguration"
        ],
        "payload": "CreateRecommenderConfiguration"
      },
      "output": {
        "type": "structure",
        "members": {
          "RecommenderConfigurationResponse": {
            "shape": "S41"
          }
        },
        "required": [
          "RecommenderConfigurationResponse"
        ],
        "payload": "RecommenderConfigurationResponse"
      }
    },
    "CreateSegment": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/segments",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "WriteSegmentRequest": {
            "shape": "S43"
          }
        },
        "required": [
          "ApplicationId",
          "WriteSegmentRequest"
        ],
        "payload": "WriteSegmentRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentResponse": {
            "shape": "S4e"
          }
        },
        "required": [
          "SegmentResponse"
        ],
        "payload": "SegmentResponse"
      }
    },
    "CreateSmsTemplate": {
      "http": {
        "requestUri": "/v1/templates/{template-name}/sms",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "SMSTemplateRequest": {
            "shape": "S4j"
          },
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          }
        },
        "required": [
          "TemplateName",
          "SMSTemplateRequest"
        ],
        "payload": "SMSTemplateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "CreateTemplateMessageBody": {
            "shape": "S1r"
          }
        },
        "required": [
          "CreateTemplateMessageBody"
        ],
        "payload": "CreateTemplateMessageBody"
      }
    },
    "CreateVoiceTemplate": {
      "http": {
        "requestUri": "/v1/templates/{template-name}/voice",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "VoiceTemplateRequest": {
            "shape": "S4m"
          }
        },
        "required": [
          "TemplateName",
          "VoiceTemplateRequest"
        ],
        "payload": "VoiceTemplateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "CreateTemplateMessageBody": {
            "shape": "S1r"
          }
        },
        "required": [
          "CreateTemplateMessageBody"
        ],
        "payload": "CreateTemplateMessageBody"
      }
    },
    "DeleteAdmChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/adm",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ADMChannelResponse": {
            "shape": "S4q"
          }
        },
        "required": [
          "ADMChannelResponse"
        ],
        "payload": "ADMChannelResponse"
      }
    },
    "DeleteApnsChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/apns",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSChannelResponse": {
            "shape": "S4t"
          }
        },
        "required": [
          "APNSChannelResponse"
        ],
        "payload": "APNSChannelResponse"
      }
    },
    "DeleteApnsSandboxChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/apns_sandbox",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSSandboxChannelResponse": {
            "shape": "S4w"
          }
        },
        "required": [
          "APNSSandboxChannelResponse"
        ],
        "payload": "APNSSandboxChannelResponse"
      }
    },
    "DeleteApnsVoipChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/apns_voip",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSVoipChannelResponse": {
            "shape": "S4z"
          }
        },
        "required": [
          "APNSVoipChannelResponse"
        ],
        "payload": "APNSVoipChannelResponse"
      }
    },
    "DeleteApnsVoipSandboxChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/apns_voip_sandbox",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSVoipSandboxChannelResponse": {
            "shape": "S52"
          }
        },
        "required": [
          "APNSVoipSandboxChannelResponse"
        ],
        "payload": "APNSVoipSandboxChannelResponse"
      }
    },
    "DeleteApp": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationResponse": {
            "shape": "S6"
          }
        },
        "required": [
          "ApplicationResponse"
        ],
        "payload": "ApplicationResponse"
      }
    },
    "DeleteBaiduChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/baidu",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "BaiduChannelResponse": {
            "shape": "S57"
          }
        },
        "required": [
          "BaiduChannelResponse"
        ],
        "payload": "BaiduChannelResponse"
      }
    },
    "DeleteCampaign": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          }
        },
        "required": [
          "CampaignId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignResponse": {
            "shape": "S1j"
          }
        },
        "required": [
          "CampaignResponse"
        ],
        "payload": "CampaignResponse"
      }
    },
    "DeleteEmailChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/email",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EmailChannelResponse": {
            "shape": "S5c"
          }
        },
        "required": [
          "EmailChannelResponse"
        ],
        "payload": "EmailChannelResponse"
      }
    },
    "DeleteEmailTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/templates/{template-name}/email",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "DeleteEndpoint": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/endpoints/{endpoint-id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EndpointId": {
            "location": "uri",
            "locationName": "endpoint-id"
          }
        },
        "required": [
          "ApplicationId",
          "EndpointId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointResponse": {
            "shape": "S5i"
          }
        },
        "required": [
          "EndpointResponse"
        ],
        "payload": "EndpointResponse"
      }
    },
    "DeleteEventStream": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/eventstream",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EventStream": {
            "shape": "S5r"
          }
        },
        "required": [
          "EventStream"
        ],
        "payload": "EventStream"
      }
    },
    "DeleteGcmChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/gcm",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "GCMChannelResponse": {
            "shape": "S5u"
          }
        },
        "required": [
          "GCMChannelResponse"
        ],
        "payload": "GCMChannelResponse"
      }
    },
    "DeleteInAppTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/templates/{template-name}/inapp",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "DeleteJourney": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/journeys/{journey-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "JourneyId": {
            "location": "uri",
            "locationName": "journey-id"
          }
        },
        "required": [
          "JourneyId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "JourneyResponse": {
            "shape": "S3r"
          }
        },
        "required": [
          "JourneyResponse"
        ],
        "payload": "JourneyResponse"
      }
    },
    "DeletePushTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/templates/{template-name}/push",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "DeleteRecommenderConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/recommenders/{recommender-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "RecommenderId": {
            "location": "uri",
            "locationName": "recommender-id"
          }
        },
        "required": [
          "RecommenderId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "RecommenderConfigurationResponse": {
            "shape": "S41"
          }
        },
        "required": [
          "RecommenderConfigurationResponse"
        ],
        "payload": "RecommenderConfigurationResponse"
      }
    },
    "DeleteSegment": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          }
        },
        "required": [
          "SegmentId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentResponse": {
            "shape": "S4e"
          }
        },
        "required": [
          "SegmentResponse"
        ],
        "payload": "SegmentResponse"
      }
    },
    "DeleteSmsChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/sms",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SMSChannelResponse": {
            "shape": "S67"
          }
        },
        "required": [
          "SMSChannelResponse"
        ],
        "payload": "SMSChannelResponse"
      }
    },
    "DeleteSmsTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/templates/{template-name}/sms",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "DeleteUserEndpoints": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/users/{user-id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "UserId": {
            "location": "uri",
            "locationName": "user-id"
          }
        },
        "required": [
          "ApplicationId",
          "UserId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointsResponse": {
            "shape": "S6c"
          }
        },
        "required": [
          "EndpointsResponse"
        ],
        "payload": "EndpointsResponse"
      }
    },
    "DeleteVoiceChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/voice",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "VoiceChannelResponse": {
            "shape": "S6g"
          }
        },
        "required": [
          "VoiceChannelResponse"
        ],
        "payload": "VoiceChannelResponse"
      }
    },
    "DeleteVoiceTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/templates/{template-name}/voice",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "GetAdmChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/adm",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ADMChannelResponse": {
            "shape": "S4q"
          }
        },
        "required": [
          "ADMChannelResponse"
        ],
        "payload": "ADMChannelResponse"
      }
    },
    "GetApnsChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/apns",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSChannelResponse": {
            "shape": "S4t"
          }
        },
        "required": [
          "APNSChannelResponse"
        ],
        "payload": "APNSChannelResponse"
      }
    },
    "GetApnsSandboxChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/apns_sandbox",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSSandboxChannelResponse": {
            "shape": "S4w"
          }
        },
        "required": [
          "APNSSandboxChannelResponse"
        ],
        "payload": "APNSSandboxChannelResponse"
      }
    },
    "GetApnsVoipChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/apns_voip",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSVoipChannelResponse": {
            "shape": "S4z"
          }
        },
        "required": [
          "APNSVoipChannelResponse"
        ],
        "payload": "APNSVoipChannelResponse"
      }
    },
    "GetApnsVoipSandboxChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/apns_voip_sandbox",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSVoipSandboxChannelResponse": {
            "shape": "S52"
          }
        },
        "required": [
          "APNSVoipSandboxChannelResponse"
        ],
        "payload": "APNSVoipSandboxChannelResponse"
      }
    },
    "GetApp": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationResponse": {
            "shape": "S6"
          }
        },
        "required": [
          "ApplicationResponse"
        ],
        "payload": "ApplicationResponse"
      }
    },
    "GetApplicationDateRangeKpi": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/kpis/daterange/{kpi-name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EndTime": {
            "shape": "S3c",
            "location": "querystring",
            "locationName": "end-time"
          },
          "KpiName": {
            "location": "uri",
            "locationName": "kpi-name"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "StartTime": {
            "shape": "S3c",
            "location": "querystring",
            "locationName": "start-time"
          }
        },
        "required": [
          "ApplicationId",
          "KpiName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationDateRangeKpiResponse": {
            "type": "structure",
            "members": {
              "ApplicationId": {},
              "EndTime": {
                "shape": "S3c"
              },
              "KpiName": {},
              "KpiResult": {
                "shape": "S6y"
              },
              "NextToken": {},
              "StartTime": {
                "shape": "S3c"
              }
            },
            "required": [
              "KpiResult",
              "KpiName",
              "EndTime",
              "StartTime",
              "ApplicationId"
            ]
          }
        },
        "required": [
          "ApplicationDateRangeKpiResponse"
        ],
        "payload": "ApplicationDateRangeKpiResponse"
      }
    },
    "GetApplicationSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/settings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationSettingsResource": {
            "shape": "S75"
          }
        },
        "required": [
          "ApplicationSettingsResource"
        ],
        "payload": "ApplicationSettingsResource"
      }
    },
    "GetApps": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationsResponse": {
            "type": "structure",
            "members": {
              "Item": {
                "type": "list",
                "member": {
                  "shape": "S6"
                }
              },
              "NextToken": {}
            }
          }
        },
        "required": [
          "ApplicationsResponse"
        ],
        "payload": "ApplicationsResponse"
      }
    },
    "GetBaiduChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/baidu",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "BaiduChannelResponse": {
            "shape": "S57"
          }
        },
        "required": [
          "BaiduChannelResponse"
        ],
        "payload": "BaiduChannelResponse"
      }
    },
    "GetCampaign": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          }
        },
        "required": [
          "CampaignId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignResponse": {
            "shape": "S1j"
          }
        },
        "required": [
          "CampaignResponse"
        ],
        "payload": "CampaignResponse"
      }
    },
    "GetCampaignActivities": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}/activities",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId",
          "CampaignId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ActivitiesResponse": {
            "type": "structure",
            "members": {
              "Item": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "ApplicationId": {},
                    "CampaignId": {},
                    "End": {},
                    "Id": {},
                    "Result": {},
                    "ScheduledStart": {},
                    "Start": {},
                    "State": {},
                    "SuccessfulEndpointCount": {
                      "type": "integer"
                    },
                    "TimezonesCompletedCount": {
                      "type": "integer"
                    },
                    "TimezonesTotalCount": {
                      "type": "integer"
                    },
                    "TotalEndpointCount": {
                      "type": "integer"
                    },
                    "TreatmentId": {}
                  },
                  "required": [
                    "CampaignId",
                    "Id",
                    "ApplicationId"
                  ]
                }
              },
              "NextToken": {}
            },
            "required": [
              "Item"
            ]
          }
        },
        "required": [
          "ActivitiesResponse"
        ],
        "payload": "ActivitiesResponse"
      }
    },
    "GetCampaignDateRangeKpi": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}/kpis/daterange/{kpi-name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          },
          "EndTime": {
            "shape": "S3c",
            "location": "querystring",
            "locationName": "end-time"
          },
          "KpiName": {
            "location": "uri",
            "locationName": "kpi-name"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "StartTime": {
            "shape": "S3c",
            "location": "querystring",
            "locationName": "start-time"
          }
        },
        "required": [
          "ApplicationId",
          "KpiName",
          "CampaignId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignDateRangeKpiResponse": {
            "type": "structure",
            "members": {
              "ApplicationId": {},
              "CampaignId": {},
              "EndTime": {
                "shape": "S3c"
              },
              "KpiName": {},
              "KpiResult": {
                "shape": "S6y"
              },
              "NextToken": {},
              "StartTime": {
                "shape": "S3c"
              }
            },
            "required": [
              "KpiResult",
              "KpiName",
              "EndTime",
              "CampaignId",
              "StartTime",
              "ApplicationId"
            ]
          }
        },
        "required": [
          "CampaignDateRangeKpiResponse"
        ],
        "payload": "CampaignDateRangeKpiResponse"
      }
    },
    "GetCampaignVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}/versions/{version}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          },
          "Version": {
            "location": "uri",
            "locationName": "version"
          }
        },
        "required": [
          "Version",
          "ApplicationId",
          "CampaignId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignResponse": {
            "shape": "S1j"
          }
        },
        "required": [
          "CampaignResponse"
        ],
        "payload": "CampaignResponse"
      }
    },
    "GetCampaignVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId",
          "CampaignId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignsResponse": {
            "shape": "S7q"
          }
        },
        "required": [
          "CampaignsResponse"
        ],
        "payload": "CampaignsResponse"
      }
    },
    "GetCampaigns": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/campaigns",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignsResponse": {
            "shape": "S7q"
          }
        },
        "required": [
          "CampaignsResponse"
        ],
        "payload": "CampaignsResponse"
      }
    },
    "GetChannels": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelsResponse": {
            "type": "structure",
            "members": {
              "Channels": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "ApplicationId": {},
                    "CreationDate": {},
                    "Enabled": {
                      "type": "boolean"
                    },
                    "HasCredential": {
                      "type": "boolean"
                    },
                    "Id": {},
                    "IsArchived": {
                      "type": "boolean"
                    },
                    "LastModifiedBy": {},
                    "LastModifiedDate": {},
                    "Version": {
                      "type": "integer"
                    }
                  }
                }
              }
            },
            "required": [
              "Channels"
            ]
          }
        },
        "required": [
          "ChannelsResponse"
        ],
        "payload": "ChannelsResponse"
      }
    },
    "GetEmailChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/email",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EmailChannelResponse": {
            "shape": "S5c"
          }
        },
        "required": [
          "EmailChannelResponse"
        ],
        "payload": "EmailChannelResponse"
      }
    },
    "GetEmailTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/templates/{template-name}/email",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EmailTemplateResponse": {
            "type": "structure",
            "members": {
              "Arn": {},
              "CreationDate": {},
              "DefaultSubstitutions": {},
              "HtmlPart": {},
              "LastModifiedDate": {},
              "RecommenderId": {},
              "Subject": {},
              "tags": {
                "shape": "S4",
                "locationName": "tags"
              },
              "TemplateDescription": {},
              "TemplateName": {},
              "TemplateType": {},
              "TextPart": {},
              "Version": {}
            },
            "required": [
              "LastModifiedDate",
              "CreationDate",
              "TemplateName",
              "TemplateType"
            ]
          }
        },
        "required": [
          "EmailTemplateResponse"
        ],
        "payload": "EmailTemplateResponse"
      }
    },
    "GetEndpoint": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/endpoints/{endpoint-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EndpointId": {
            "location": "uri",
            "locationName": "endpoint-id"
          }
        },
        "required": [
          "ApplicationId",
          "EndpointId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointResponse": {
            "shape": "S5i"
          }
        },
        "required": [
          "EndpointResponse"
        ],
        "payload": "EndpointResponse"
      }
    },
    "GetEventStream": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/eventstream",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EventStream": {
            "shape": "S5r"
          }
        },
        "required": [
          "EventStream"
        ],
        "payload": "EventStream"
      }
    },
    "GetExportJob": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/jobs/export/{job-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "job-id"
          }
        },
        "required": [
          "ApplicationId",
          "JobId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportJobResponse": {
            "shape": "S1v"
          }
        },
        "required": [
          "ExportJobResponse"
        ],
        "payload": "ExportJobResponse"
      }
    },
    "GetExportJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/jobs/export",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportJobsResponse": {
            "shape": "S8d"
          }
        },
        "required": [
          "ExportJobsResponse"
        ],
        "payload": "ExportJobsResponse"
      }
    },
    "GetGcmChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/gcm",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "GCMChannelResponse": {
            "shape": "S5u"
          }
        },
        "required": [
          "GCMChannelResponse"
        ],
        "payload": "GCMChannelResponse"
      }
    },
    "GetImportJob": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/jobs/import/{job-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "job-id"
          }
        },
        "required": [
          "ApplicationId",
          "JobId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportJobResponse": {
            "shape": "S22"
          }
        },
        "required": [
          "ImportJobResponse"
        ],
        "payload": "ImportJobResponse"
      }
    },
    "GetImportJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/jobs/import",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportJobsResponse": {
            "shape": "S8l"
          }
        },
        "required": [
          "ImportJobsResponse"
        ],
        "payload": "ImportJobsResponse"
      }
    },
    "GetInAppMessages": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/endpoints/{endpoint-id}/inappmessages",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EndpointId": {
            "location": "uri",
            "locationName": "endpoint-id"
          }
        },
        "required": [
          "ApplicationId",
          "EndpointId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "InAppMessagesResponse": {
            "type": "structure",
            "members": {
              "InAppMessageCampaigns": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "CampaignId": {},
                    "DailyCap": {
                      "type": "integer"
                    },
                    "InAppMessage": {
                      "type": "structure",
                      "members": {
                        "Content": {
                          "shape": "So"
                        },
                        "CustomConfig": {
                          "shape": "S4"
                        },
                        "Layout": {}
                      }
                    },
                    "Priority": {
                      "type": "integer"
                    },
                    "Schedule": {
                      "type": "structure",
                      "members": {
                        "EndDate": {},
                        "EventFilter": {
                          "shape": "Sz"
                        },
                        "QuietTime": {
                          "shape": "S1c"
                        }
                      }
                    },
                    "SessionCap": {
                      "type": "integer"
                    },
                    "TotalCap": {
                      "type": "integer"
                    },
                    "TreatmentId": {}
                  }
                }
              }
            }
          }
        },
        "required": [
          "InAppMessagesResponse"
        ],
        "payload": "InAppMessagesResponse"
      }
    },
    "GetInAppTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/templates/{template-name}/inapp",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "InAppTemplateResponse": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Content": {
                "shape": "So"
              },
              "CreationDate": {},
              "CustomConfig": {
                "shape": "S4"
              },
              "LastModifiedDate": {},
              "Layout": {},
              "tags": {
                "shape": "S4",
                "locationName": "tags"
              },
              "TemplateDescription": {},
              "TemplateName": {},
              "TemplateType": {},
              "Version": {}
            },
            "required": [
              "LastModifiedDate",
              "CreationDate",
              "TemplateName",
              "TemplateType"
            ]
          }
        },
        "required": [
          "InAppTemplateResponse"
        ],
        "payload": "InAppTemplateResponse"
      }
    },
    "GetJourney": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/journeys/{journey-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "JourneyId": {
            "location": "uri",
            "locationName": "journey-id"
          }
        },
        "required": [
          "JourneyId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "JourneyResponse": {
            "shape": "S3r"
          }
        },
        "required": [
          "JourneyResponse"
        ],
        "payload": "JourneyResponse"
      }
    },
    "GetJourneyDateRangeKpi": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/journeys/{journey-id}/kpis/daterange/{kpi-name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EndTime": {
            "shape": "S3c",
            "location": "querystring",
            "locationName": "end-time"
          },
          "JourneyId": {
            "location": "uri",
            "locationName": "journey-id"
          },
          "KpiName": {
            "location": "uri",
            "locationName": "kpi-name"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "StartTime": {
            "shape": "S3c",
            "location": "querystring",
            "locationName": "start-time"
          }
        },
        "required": [
          "JourneyId",
          "ApplicationId",
          "KpiName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "JourneyDateRangeKpiResponse": {
            "type": "structure",
            "members": {
              "ApplicationId": {},
              "EndTime": {
                "shape": "S3c"
              },
              "JourneyId": {},
              "KpiName": {},
              "KpiResult": {
                "shape": "S6y"
              },
              "NextToken": {},
              "StartTime": {
                "shape": "S3c"
              }
            },
            "required": [
              "KpiResult",
              "KpiName",
              "JourneyId",
              "EndTime",
              "StartTime",
              "ApplicationId"
            ]
          }
        },
        "required": [
          "JourneyDateRangeKpiResponse"
        ],
        "payload": "JourneyDateRangeKpiResponse"
      }
    },
    "GetJourneyExecutionActivityMetrics": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/journeys/{journey-id}/activities/{journey-activity-id}/execution-metrics",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "JourneyActivityId": {
            "location": "uri",
            "locationName": "journey-activity-id"
          },
          "JourneyId": {
            "location": "uri",
            "locationName": "journey-id"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          }
        },
        "required": [
          "JourneyActivityId",
          "ApplicationId",
          "JourneyId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "JourneyExecutionActivityMetricsResponse": {
            "type": "structure",
            "members": {
              "ActivityType": {},
              "ApplicationId": {},
              "JourneyActivityId": {},
              "JourneyId": {},
              "LastEvaluatedTime": {},
              "Metrics": {
                "shape": "S4"
              }
            },
            "required": [
              "Metrics",
              "JourneyId",
              "LastEvaluatedTime",
              "JourneyActivityId",
              "ActivityType",
              "ApplicationId"
            ]
          }
        },
        "required": [
          "JourneyExecutionActivityMetricsResponse"
        ],
        "payload": "JourneyExecutionActivityMetricsResponse"
      }
    },
    "GetJourneyExecutionMetrics": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/journeys/{journey-id}/execution-metrics",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "JourneyId": {
            "location": "uri",
            "locationName": "journey-id"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          }
        },
        "required": [
          "ApplicationId",
          "JourneyId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "JourneyExecutionMetricsResponse": {
            "type": "structure",
            "members": {
              "ApplicationId": {},
              "JourneyId": {},
              "LastEvaluatedTime": {},
              "Metrics": {
                "shape": "S4"
              }
            },
            "required": [
              "Metrics",
              "JourneyId",
              "LastEvaluatedTime",
              "ApplicationId"
            ]
          }
        },
        "required": [
          "JourneyExecutionMetricsResponse"
        ],
        "payload": "JourneyExecutionMetricsResponse"
      }
    },
    "GetPushTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/templates/{template-name}/push",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "PushNotificationTemplateResponse": {
            "type": "structure",
            "members": {
              "ADM": {
                "shape": "S3u"
              },
              "APNS": {
                "shape": "S3v"
              },
              "Arn": {},
              "Baidu": {
                "shape": "S3u"
              },
              "CreationDate": {},
              "Default": {
                "shape": "S3w"
              },
              "DefaultSubstitutions": {},
              "GCM": {
                "shape": "S3u"
              },
              "LastModifiedDate": {},
              "RecommenderId": {},
              "tags": {
                "shape": "S4",
                "locationName": "tags"
              },
              "TemplateDescription": {},
              "TemplateName": {},
              "TemplateType": {},
              "Version": {}
            },
            "required": [
              "LastModifiedDate",
              "CreationDate",
              "TemplateType",
              "TemplateName"
            ]
          }
        },
        "required": [
          "PushNotificationTemplateResponse"
        ],
        "payload": "PushNotificationTemplateResponse"
      }
    },
    "GetRecommenderConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/recommenders/{recommender-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "RecommenderId": {
            "location": "uri",
            "locationName": "recommender-id"
          }
        },
        "required": [
          "RecommenderId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "RecommenderConfigurationResponse": {
            "shape": "S41"
          }
        },
        "required": [
          "RecommenderConfigurationResponse"
        ],
        "payload": "RecommenderConfigurationResponse"
      }
    },
    "GetRecommenderConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/recommenders",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ListRecommenderConfigurationsResponse": {
            "type": "structure",
            "members": {
              "Item": {
                "type": "list",
                "member": {
                  "shape": "S41"
                }
              },
              "NextToken": {}
            },
            "required": [
              "Item"
            ]
          }
        },
        "required": [
          "ListRecommenderConfigurationsResponse"
        ],
        "payload": "ListRecommenderConfigurationsResponse"
      }
    },
    "GetSegment": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          }
        },
        "required": [
          "SegmentId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentResponse": {
            "shape": "S4e"
          }
        },
        "required": [
          "SegmentResponse"
        ],
        "payload": "SegmentResponse"
      }
    },
    "GetSegmentExportJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}/jobs/export",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "SegmentId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportJobsResponse": {
            "shape": "S8d"
          }
        },
        "required": [
          "ExportJobsResponse"
        ],
        "payload": "ExportJobsResponse"
      }
    },
    "GetSegmentImportJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}/jobs/import",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "SegmentId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportJobsResponse": {
            "shape": "S8l"
          }
        },
        "required": [
          "ImportJobsResponse"
        ],
        "payload": "ImportJobsResponse"
      }
    },
    "GetSegmentVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}/versions/{version}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          },
          "Version": {
            "location": "uri",
            "locationName": "version"
          }
        },
        "required": [
          "SegmentId",
          "Version",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentResponse": {
            "shape": "S4e"
          }
        },
        "required": [
          "SegmentResponse"
        ],
        "payload": "SegmentResponse"
      }
    },
    "GetSegmentVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "SegmentId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentsResponse": {
            "shape": "S9r"
          }
        },
        "required": [
          "SegmentsResponse"
        ],
        "payload": "SegmentsResponse"
      }
    },
    "GetSegments": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/segments",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentsResponse": {
            "shape": "S9r"
          }
        },
        "required": [
          "SegmentsResponse"
        ],
        "payload": "SegmentsResponse"
      }
    },
    "GetSmsChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/sms",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SMSChannelResponse": {
            "shape": "S67"
          }
        },
        "required": [
          "SMSChannelResponse"
        ],
        "payload": "SMSChannelResponse"
      }
    },
    "GetSmsTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/templates/{template-name}/sms",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SMSTemplateResponse": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Body": {},
              "CreationDate": {},
              "DefaultSubstitutions": {},
              "LastModifiedDate": {},
              "RecommenderId": {},
              "tags": {
                "shape": "S4",
                "locationName": "tags"
              },
              "TemplateDescription": {},
              "TemplateName": {},
              "TemplateType": {},
              "Version": {}
            },
            "required": [
              "LastModifiedDate",
              "CreationDate",
              "TemplateName",
              "TemplateType"
            ]
          }
        },
        "required": [
          "SMSTemplateResponse"
        ],
        "payload": "SMSTemplateResponse"
      }
    },
    "GetUserEndpoints": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/users/{user-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "UserId": {
            "location": "uri",
            "locationName": "user-id"
          }
        },
        "required": [
          "ApplicationId",
          "UserId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointsResponse": {
            "shape": "S6c"
          }
        },
        "required": [
          "EndpointsResponse"
        ],
        "payload": "EndpointsResponse"
      }
    },
    "GetVoiceChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/voice",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "VoiceChannelResponse": {
            "shape": "S6g"
          }
        },
        "required": [
          "VoiceChannelResponse"
        ],
        "payload": "VoiceChannelResponse"
      }
    },
    "GetVoiceTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/templates/{template-name}/voice",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "VoiceTemplateResponse": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Body": {},
              "CreationDate": {},
              "DefaultSubstitutions": {},
              "LanguageCode": {},
              "LastModifiedDate": {},
              "tags": {
                "shape": "S4",
                "locationName": "tags"
              },
              "TemplateDescription": {},
              "TemplateName": {},
              "TemplateType": {},
              "Version": {},
              "VoiceId": {}
            },
            "required": [
              "LastModifiedDate",
              "CreationDate",
              "TemplateName",
              "TemplateType"
            ]
          }
        },
        "required": [
          "VoiceTemplateResponse"
        ],
        "payload": "VoiceTemplateResponse"
      }
    },
    "ListJourneys": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/journeys",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "JourneysResponse": {
            "type": "structure",
            "members": {
              "Item": {
                "type": "list",
                "member": {
                  "shape": "S3r"
                }
              },
              "NextToken": {}
            },
            "required": [
              "Item"
            ]
          }
        },
        "required": [
          "JourneysResponse"
        ],
        "payload": "JourneysResponse"
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/tags/{resource-arn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resource-arn"
          }
        },
        "required": [
          "ResourceArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "TagsModel": {
            "shape": "Sad"
          }
        },
        "required": [
          "TagsModel"
        ],
        "payload": "TagsModel"
      }
    },
    "ListTemplateVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/templates/{template-name}/{template-type}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "TemplateType": {
            "location": "uri",
            "locationName": "template-type"
          }
        },
        "required": [
          "TemplateName",
          "TemplateType"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateVersionsResponse": {
            "type": "structure",
            "members": {
              "Item": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "CreationDate": {},
                    "DefaultSubstitutions": {},
                    "LastModifiedDate": {},
                    "TemplateDescription": {},
                    "TemplateName": {},
                    "TemplateType": {},
                    "Version": {}
                  },
                  "required": [
                    "LastModifiedDate",
                    "CreationDate",
                    "TemplateName",
                    "TemplateType"
                  ]
                }
              },
              "Message": {},
              "NextToken": {},
              "RequestID": {}
            },
            "required": [
              "Item"
            ]
          }
        },
        "required": [
          "TemplateVersionsResponse"
        ],
        "payload": "TemplateVersionsResponse"
      }
    },
    "ListTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/templates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Prefix": {
            "location": "querystring",
            "locationName": "prefix"
          },
          "TemplateType": {
            "location": "querystring",
            "locationName": "template-type"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplatesResponse": {
            "type": "structure",
            "members": {
              "Item": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Arn": {},
                    "CreationDate": {},
                    "DefaultSubstitutions": {},
                    "LastModifiedDate": {},
                    "tags": {
                      "shape": "S4",
                      "locationName": "tags"
                    },
                    "TemplateDescription": {},
                    "TemplateName": {},
                    "TemplateType": {},
                    "Version": {}
                  },
                  "required": [
                    "LastModifiedDate",
                    "CreationDate",
                    "TemplateName",
                    "TemplateType"
                  ]
                }
              },
              "NextToken": {}
            },
            "required": [
              "Item"
            ]
          }
        },
        "required": [
          "TemplatesResponse"
        ],
        "payload": "TemplatesResponse"
      }
    },
    "PhoneNumberValidate": {
      "http": {
        "requestUri": "/v1/phone/number/validate",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NumberValidateRequest": {
            "type": "structure",
            "members": {
              "IsoCountryCode": {},
              "PhoneNumber": {}
            }
          }
        },
        "required": [
          "NumberValidateRequest"
        ],
        "payload": "NumberValidateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "NumberValidateResponse": {
            "type": "structure",
            "members": {
              "Carrier": {},
              "City": {},
              "CleansedPhoneNumberE164": {},
              "CleansedPhoneNumberNational": {},
              "Country": {},
              "CountryCodeIso2": {},
              "CountryCodeNumeric": {},
              "County": {},
              "OriginalCountryCodeIso2": {},
              "OriginalPhoneNumber": {},
              "PhoneType": {},
              "PhoneTypeCode": {
                "type": "integer"
              },
              "Timezone": {},
              "ZipCode": {}
            }
          }
        },
        "required": [
          "NumberValidateResponse"
        ],
        "payload": "NumberValidateResponse"
      }
    },
    "PutEventStream": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/eventstream",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "WriteEventStream": {
            "type": "structure",
            "members": {
              "DestinationStreamArn": {},
              "RoleArn": {}
            },
            "required": [
              "RoleArn",
              "DestinationStreamArn"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "WriteEventStream"
        ],
        "payload": "WriteEventStream"
      },
      "output": {
        "type": "structure",
        "members": {
          "EventStream": {
            "shape": "S5r"
          }
        },
        "required": [
          "EventStream"
        ],
        "payload": "EventStream"
      }
    },
    "PutEvents": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/events",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EventsRequest": {
            "type": "structure",
            "members": {
              "BatchItem": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "Endpoint": {
                      "type": "structure",
                      "members": {
                        "Address": {},
                        "Attributes": {
                          "shape": "S5j"
                        },
                        "ChannelType": {},
                        "Demographic": {
                          "shape": "S5l"
                        },
                        "EffectiveDate": {},
                        "EndpointStatus": {},
                        "Location": {
                          "shape": "S5m"
                        },
                        "Metrics": {
                          "shape": "S5n"
                        },
                        "OptOut": {},
                        "RequestId": {},
                        "User": {
                          "shape": "S5o"
                        }
                      }
                    },
                    "Events": {
                      "type": "map",
                      "key": {},
                      "value": {
                        "type": "structure",
                        "members": {
                          "AppPackageName": {},
                          "AppTitle": {},
                          "AppVersionCode": {},
                          "Attributes": {
                            "shape": "S4"
                          },
                          "ClientSdkVersion": {},
                          "EventType": {},
                          "Metrics": {
                            "shape": "S5n"
                          },
                          "SdkName": {},
                          "Session": {
                            "type": "structure",
                            "members": {
                              "Duration": {
                                "type": "integer"
                              },
                              "Id": {},
                              "StartTimestamp": {},
                              "StopTimestamp": {}
                            },
                            "required": [
                              "StartTimestamp",
                              "Id"
                            ]
                          },
                          "Timestamp": {}
                        },
                        "required": [
                          "EventType",
                          "Timestamp"
                        ]
                      }
                    }
                  },
                  "required": [
                    "Endpoint",
                    "Events"
                  ]
                }
              }
            },
            "required": [
              "BatchItem"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "EventsRequest"
        ],
        "payload": "EventsRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "EventsResponse": {
            "type": "structure",
            "members": {
              "Results": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "EndpointItemResponse": {
                      "type": "structure",
                      "members": {
                        "Message": {},
                        "StatusCode": {
                          "type": "integer"
                        }
                      }
                    },
                    "EventsItemResponse": {
                      "type": "map",
                      "key": {},
                      "value": {
                        "type": "structure",
                        "members": {
                          "Message": {},
                          "StatusCode": {
                            "type": "integer"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "required": [
          "EventsResponse"
        ],
        "payload": "EventsResponse"
      }
    },
    "RemoveAttributes": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/attributes/{attribute-type}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "AttributeType": {
            "location": "uri",
            "locationName": "attribute-type"
          },
          "UpdateAttributesRequest": {
            "type": "structure",
            "members": {
              "Blacklist": {
                "shape": "S14"
              }
            }
          }
        },
        "required": [
          "AttributeType",
          "ApplicationId",
          "UpdateAttributesRequest"
        ],
        "payload": "UpdateAttributesRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "AttributesResource": {
            "type": "structure",
            "members": {
              "ApplicationId": {},
              "AttributeType": {},
              "Attributes": {
                "shape": "S14"
              }
            },
            "required": [
              "AttributeType",
              "ApplicationId"
            ]
          }
        },
        "required": [
          "AttributesResource"
        ],
        "payload": "AttributesResource"
      }
    },
    "SendMessages": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/messages",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "MessageRequest": {
            "type": "structure",
            "members": {
              "Addresses": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "BodyOverride": {},
                    "ChannelType": {},
                    "Context": {
                      "shape": "S4"
                    },
                    "RawContent": {},
                    "Substitutions": {
                      "shape": "S5j"
                    },
                    "TitleOverride": {}
                  }
                }
              },
              "Context": {
                "shape": "S4"
              },
              "Endpoints": {
                "shape": "Sbi"
              },
              "MessageConfiguration": {
                "shape": "Sbk"
              },
              "TemplateConfiguration": {
                "shape": "S1d"
              },
              "TraceId": {}
            },
            "required": [
              "MessageConfiguration"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "MessageRequest"
        ],
        "payload": "MessageRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageResponse": {
            "shape": "Sbz"
          }
        },
        "required": [
          "MessageResponse"
        ],
        "payload": "MessageResponse"
      }
    },
    "SendOTPMessage": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/otp",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SendOTPMessageRequestParameters": {
            "type": "structure",
            "members": {
              "AllowedAttempts": {
                "type": "integer"
              },
              "BrandName": {},
              "Channel": {},
              "CodeLength": {
                "type": "integer"
              },
              "DestinationIdentity": {},
              "EntityId": {},
              "Language": {},
              "OriginationIdentity": {},
              "ReferenceId": {},
              "TemplateId": {},
              "ValidityPeriod": {
                "type": "integer"
              }
            },
            "required": [
              "BrandName",
              "ReferenceId",
              "Channel",
              "DestinationIdentity",
              "OriginationIdentity"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "SendOTPMessageRequestParameters"
        ],
        "payload": "SendOTPMessageRequestParameters"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageResponse": {
            "shape": "Sbz"
          }
        },
        "required": [
          "MessageResponse"
        ],
        "payload": "MessageResponse"
      }
    },
    "SendUsersMessages": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/users-messages",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SendUsersMessageRequest": {
            "type": "structure",
            "members": {
              "Context": {
                "shape": "S4"
              },
              "MessageConfiguration": {
                "shape": "Sbk"
              },
              "TemplateConfiguration": {
                "shape": "S1d"
              },
              "TraceId": {},
              "Users": {
                "shape": "Sbi"
              }
            },
            "required": [
              "MessageConfiguration",
              "Users"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "SendUsersMessageRequest"
        ],
        "payload": "SendUsersMessageRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "SendUsersMessageResponse": {
            "type": "structure",
            "members": {
              "ApplicationId": {},
              "RequestId": {},
              "Result": {
                "type": "map",
                "key": {},
                "value": {
                  "shape": "Sc0"
                }
              }
            },
            "required": [
              "ApplicationId"
            ]
          }
        },
        "required": [
          "SendUsersMessageResponse"
        ],
        "payload": "SendUsersMessageResponse"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/v1/tags/{resource-arn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resource-arn"
          },
          "TagsModel": {
            "shape": "Sad"
          }
        },
        "required": [
          "ResourceArn",
          "TagsModel"
        ],
        "payload": "TagsModel"
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/tags/{resource-arn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resource-arn"
          },
          "TagKeys": {
            "shape": "S14",
            "location": "querystring",
            "locationName": "tagKeys"
          }
        },
        "required": [
          "TagKeys",
          "ResourceArn"
        ]
      }
    },
    "UpdateAdmChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/adm",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ADMChannelRequest": {
            "type": "structure",
            "members": {
              "ClientId": {},
              "ClientSecret": {},
              "Enabled": {
                "type": "boolean"
              }
            },
            "required": [
              "ClientSecret",
              "ClientId"
            ]
          },
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId",
          "ADMChannelRequest"
        ],
        "payload": "ADMChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "ADMChannelResponse": {
            "shape": "S4q"
          }
        },
        "required": [
          "ADMChannelResponse"
        ],
        "payload": "ADMChannelResponse"
      }
    },
    "UpdateApnsChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/apns",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "APNSChannelRequest": {
            "type": "structure",
            "members": {
              "BundleId": {},
              "Certificate": {},
              "DefaultAuthenticationMethod": {},
              "Enabled": {
                "type": "boolean"
              },
              "PrivateKey": {},
              "TeamId": {},
              "TokenKey": {},
              "TokenKeyId": {}
            }
          },
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId",
          "APNSChannelRequest"
        ],
        "payload": "APNSChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSChannelResponse": {
            "shape": "S4t"
          }
        },
        "required": [
          "APNSChannelResponse"
        ],
        "payload": "APNSChannelResponse"
      }
    },
    "UpdateApnsSandboxChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/apns_sandbox",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "APNSSandboxChannelRequest": {
            "type": "structure",
            "members": {
              "BundleId": {},
              "Certificate": {},
              "DefaultAuthenticationMethod": {},
              "Enabled": {
                "type": "boolean"
              },
              "PrivateKey": {},
              "TeamId": {},
              "TokenKey": {},
              "TokenKeyId": {}
            }
          },
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId",
          "APNSSandboxChannelRequest"
        ],
        "payload": "APNSSandboxChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSSandboxChannelResponse": {
            "shape": "S4w"
          }
        },
        "required": [
          "APNSSandboxChannelResponse"
        ],
        "payload": "APNSSandboxChannelResponse"
      }
    },
    "UpdateApnsVoipChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/apns_voip",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "APNSVoipChannelRequest": {
            "type": "structure",
            "members": {
              "BundleId": {},
              "Certificate": {},
              "DefaultAuthenticationMethod": {},
              "Enabled": {
                "type": "boolean"
              },
              "PrivateKey": {},
              "TeamId": {},
              "TokenKey": {},
              "TokenKeyId": {}
            }
          },
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId",
          "APNSVoipChannelRequest"
        ],
        "payload": "APNSVoipChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSVoipChannelResponse": {
            "shape": "S4z"
          }
        },
        "required": [
          "APNSVoipChannelResponse"
        ],
        "payload": "APNSVoipChannelResponse"
      }
    },
    "UpdateApnsVoipSandboxChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/apns_voip_sandbox",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "APNSVoipSandboxChannelRequest": {
            "type": "structure",
            "members": {
              "BundleId": {},
              "Certificate": {},
              "DefaultAuthenticationMethod": {},
              "Enabled": {
                "type": "boolean"
              },
              "PrivateKey": {},
              "TeamId": {},
              "TokenKey": {},
              "TokenKeyId": {}
            }
          },
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId",
          "APNSVoipSandboxChannelRequest"
        ],
        "payload": "APNSVoipSandboxChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSVoipSandboxChannelResponse": {
            "shape": "S52"
          }
        },
        "required": [
          "APNSVoipSandboxChannelResponse"
        ],
        "payload": "APNSVoipSandboxChannelResponse"
      }
    },
    "UpdateApplicationSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/settings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "WriteApplicationSettingsRequest": {
            "type": "structure",
            "members": {
              "CampaignHook": {
                "shape": "S1f"
              },
              "CloudWatchMetricsEnabled": {
                "type": "boolean"
              },
              "EventTaggingEnabled": {
                "type": "boolean"
              },
              "Limits": {
                "shape": "S1h"
              },
              "QuietTime": {
                "shape": "S1c"
              }
            }
          }
        },
        "required": [
          "ApplicationId",
          "WriteApplicationSettingsRequest"
        ],
        "payload": "WriteApplicationSettingsRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationSettingsResource": {
            "shape": "S75"
          }
        },
        "required": [
          "ApplicationSettingsResource"
        ],
        "payload": "ApplicationSettingsResource"
      }
    },
    "UpdateBaiduChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/baidu",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "BaiduChannelRequest": {
            "type": "structure",
            "members": {
              "ApiKey": {},
              "Enabled": {
                "type": "boolean"
              },
              "SecretKey": {}
            },
            "required": [
              "SecretKey",
              "ApiKey"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "BaiduChannelRequest"
        ],
        "payload": "BaiduChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "BaiduChannelResponse": {
            "shape": "S57"
          }
        },
        "required": [
          "BaiduChannelResponse"
        ],
        "payload": "BaiduChannelResponse"
      }
    },
    "UpdateCampaign": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          },
          "WriteCampaignRequest": {
            "shape": "S8"
          }
        },
        "required": [
          "CampaignId",
          "ApplicationId",
          "WriteCampaignRequest"
        ],
        "payload": "WriteCampaignRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignResponse": {
            "shape": "S1j"
          }
        },
        "required": [
          "CampaignResponse"
        ],
        "payload": "CampaignResponse"
      }
    },
    "UpdateEmailChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/email",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EmailChannelRequest": {
            "type": "structure",
            "members": {
              "ConfigurationSet": {},
              "Enabled": {
                "type": "boolean"
              },
              "FromAddress": {},
              "Identity": {},
              "RoleArn": {}
            },
            "required": [
              "FromAddress",
              "Identity"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "EmailChannelRequest"
        ],
        "payload": "EmailChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "EmailChannelResponse": {
            "shape": "S5c"
          }
        },
        "required": [
          "EmailChannelResponse"
        ],
        "payload": "EmailChannelResponse"
      }
    },
    "UpdateEmailTemplate": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/templates/{template-name}/email",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "CreateNewVersion": {
            "location": "querystring",
            "locationName": "create-new-version",
            "type": "boolean"
          },
          "EmailTemplateRequest": {
            "shape": "S1p"
          },
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName",
          "EmailTemplateRequest"
        ],
        "payload": "EmailTemplateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "UpdateEndpoint": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/endpoints/{endpoint-id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EndpointId": {
            "location": "uri",
            "locationName": "endpoint-id"
          },
          "EndpointRequest": {
            "type": "structure",
            "members": {
              "Address": {},
              "Attributes": {
                "shape": "S5j"
              },
              "ChannelType": {},
              "Demographic": {
                "shape": "S5l"
              },
              "EffectiveDate": {},
              "EndpointStatus": {},
              "Location": {
                "shape": "S5m"
              },
              "Metrics": {
                "shape": "S5n"
              },
              "OptOut": {},
              "RequestId": {},
              "User": {
                "shape": "S5o"
              }
            }
          }
        },
        "required": [
          "ApplicationId",
          "EndpointId",
          "EndpointRequest"
        ],
        "payload": "EndpointRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "UpdateEndpointsBatch": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/endpoints",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EndpointBatchRequest": {
            "type": "structure",
            "members": {
              "Item": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Address": {},
                    "Attributes": {
                      "shape": "S5j"
                    },
                    "ChannelType": {},
                    "Demographic": {
                      "shape": "S5l"
                    },
                    "EffectiveDate": {},
                    "EndpointStatus": {},
                    "Id": {},
                    "Location": {
                      "shape": "S5m"
                    },
                    "Metrics": {
                      "shape": "S5n"
                    },
                    "OptOut": {},
                    "RequestId": {},
                    "User": {
                      "shape": "S5o"
                    }
                  }
                }
              }
            },
            "required": [
              "Item"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "EndpointBatchRequest"
        ],
        "payload": "EndpointBatchRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "UpdateGcmChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/gcm",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "GCMChannelRequest": {
            "type": "structure",
            "members": {
              "ApiKey": {},
              "Enabled": {
                "type": "boolean"
              }
            },
            "required": [
              "ApiKey"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "GCMChannelRequest"
        ],
        "payload": "GCMChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "GCMChannelResponse": {
            "shape": "S5u"
          }
        },
        "required": [
          "GCMChannelResponse"
        ],
        "payload": "GCMChannelResponse"
      }
    },
    "UpdateInAppTemplate": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/templates/{template-name}/inapp",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "CreateNewVersion": {
            "location": "querystring",
            "locationName": "create-new-version",
            "type": "boolean"
          },
          "InAppTemplateRequest": {
            "shape": "S25"
          },
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName",
          "InAppTemplateRequest"
        ],
        "payload": "InAppTemplateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "UpdateJourney": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/journeys/{journey-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "JourneyId": {
            "location": "uri",
            "locationName": "journey-id"
          },
          "WriteJourneyRequest": {
            "shape": "S29"
          }
        },
        "required": [
          "JourneyId",
          "ApplicationId",
          "WriteJourneyRequest"
        ],
        "payload": "WriteJourneyRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "JourneyResponse": {
            "shape": "S3r"
          }
        },
        "required": [
          "JourneyResponse"
        ],
        "payload": "JourneyResponse"
      }
    },
    "UpdateJourneyState": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/journeys/{journey-id}/state",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "JourneyId": {
            "location": "uri",
            "locationName": "journey-id"
          },
          "JourneyStateRequest": {
            "type": "structure",
            "members": {
              "State": {}
            }
          }
        },
        "required": [
          "JourneyId",
          "ApplicationId",
          "JourneyStateRequest"
        ],
        "payload": "JourneyStateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "JourneyResponse": {
            "shape": "S3r"
          }
        },
        "required": [
          "JourneyResponse"
        ],
        "payload": "JourneyResponse"
      }
    },
    "UpdatePushTemplate": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/templates/{template-name}/push",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "CreateNewVersion": {
            "location": "querystring",
            "locationName": "create-new-version",
            "type": "boolean"
          },
          "PushNotificationTemplateRequest": {
            "shape": "S3t"
          },
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName",
          "PushNotificationTemplateRequest"
        ],
        "payload": "PushNotificationTemplateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "UpdateRecommenderConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/recommenders/{recommender-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "RecommenderId": {
            "location": "uri",
            "locationName": "recommender-id"
          },
          "UpdateRecommenderConfiguration": {
            "type": "structure",
            "members": {
              "Attributes": {
                "shape": "S4"
              },
              "Description": {},
              "Name": {},
              "RecommendationProviderIdType": {},
              "RecommendationProviderRoleArn": {},
              "RecommendationProviderUri": {},
              "RecommendationTransformerUri": {},
              "RecommendationsDisplayName": {},
              "RecommendationsPerMessage": {
                "type": "integer"
              }
            },
            "required": [
              "RecommendationProviderUri",
              "RecommendationProviderRoleArn"
            ]
          }
        },
        "required": [
          "RecommenderId",
          "UpdateRecommenderConfiguration"
        ],
        "payload": "UpdateRecommenderConfiguration"
      },
      "output": {
        "type": "structure",
        "members": {
          "RecommenderConfigurationResponse": {
            "shape": "S41"
          }
        },
        "required": [
          "RecommenderConfigurationResponse"
        ],
        "payload": "RecommenderConfigurationResponse"
      }
    },
    "UpdateSegment": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          },
          "WriteSegmentRequest": {
            "shape": "S43"
          }
        },
        "required": [
          "SegmentId",
          "ApplicationId",
          "WriteSegmentRequest"
        ],
        "payload": "WriteSegmentRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentResponse": {
            "shape": "S4e"
          }
        },
        "required": [
          "SegmentResponse"
        ],
        "payload": "SegmentResponse"
      }
    },
    "UpdateSmsChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/sms",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SMSChannelRequest": {
            "type": "structure",
            "members": {
              "Enabled": {
                "type": "boolean"
              },
              "SenderId": {},
              "ShortCode": {}
            }
          }
        },
        "required": [
          "ApplicationId",
          "SMSChannelRequest"
        ],
        "payload": "SMSChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "SMSChannelResponse": {
            "shape": "S67"
          }
        },
        "required": [
          "SMSChannelResponse"
        ],
        "payload": "SMSChannelResponse"
      }
    },
    "UpdateSmsTemplate": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/templates/{template-name}/sms",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "CreateNewVersion": {
            "location": "querystring",
            "locationName": "create-new-version",
            "type": "boolean"
          },
          "SMSTemplateRequest": {
            "shape": "S4j"
          },
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          }
        },
        "required": [
          "TemplateName",
          "SMSTemplateRequest"
        ],
        "payload": "SMSTemplateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "UpdateTemplateActiveVersion": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/templates/{template-name}/{template-type}/active-version",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "TemplateActiveVersionRequest": {
            "type": "structure",
            "members": {
              "Version": {}
            }
          },
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "TemplateType": {
            "location": "uri",
            "locationName": "template-type"
          }
        },
        "required": [
          "TemplateName",
          "TemplateType",
          "TemplateActiveVersionRequest"
        ],
        "payload": "TemplateActiveVersionRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "UpdateVoiceChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/voice",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "VoiceChannelRequest": {
            "type": "structure",
            "members": {
              "Enabled": {
                "type": "boolean"
              }
            }
          }
        },
        "required": [
          "ApplicationId",
          "VoiceChannelRequest"
        ],
        "payload": "VoiceChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "VoiceChannelResponse": {
            "shape": "S6g"
          }
        },
        "required": [
          "VoiceChannelResponse"
        ],
        "payload": "VoiceChannelResponse"
      }
    },
    "UpdateVoiceTemplate": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/templates/{template-name}/voice",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "CreateNewVersion": {
            "location": "querystring",
            "locationName": "create-new-version",
            "type": "boolean"
          },
          "TemplateName": {
            "location": "uri",
            "locationName": "template-name"
          },
          "Version": {
            "location": "querystring",
            "locationName": "version"
          },
          "VoiceTemplateRequest": {
            "shape": "S4m"
          }
        },
        "required": [
          "TemplateName",
          "VoiceTemplateRequest"
        ],
        "payload": "VoiceTemplateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S5f"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "VerifyOTPMessage": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/verify-otp",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "VerifyOTPMessageRequestParameters": {
            "type": "structure",
            "members": {
              "DestinationIdentity": {},
              "Otp": {},
              "ReferenceId": {}
            },
            "required": [
              "ReferenceId",
              "Otp",
              "DestinationIdentity"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "VerifyOTPMessageRequestParameters"
        ],
        "payload": "VerifyOTPMessageRequestParameters"
      },
      "output": {
        "type": "structure",
        "members": {
          "VerificationResponse": {
            "type": "structure",
            "members": {
              "Valid": {
                "type": "boolean"
              }
            }
          }
        },
        "required": [
          "VerificationResponse"
        ],
        "payload": "VerificationResponse"
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S6": {
      "type": "structure",
      "members": {
        "Arn": {},
        "Id": {},
        "Name": {},
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "CreationDate": {}
      },
      "required": [
        "Id",
        "Arn",
        "Name"
      ]
    },
    "S8": {
      "type": "structure",
      "members": {
        "AdditionalTreatments": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "CustomDeliveryConfiguration": {
                "shape": "Sb"
              },
              "MessageConfiguration": {
                "shape": "Se"
              },
              "Schedule": {
                "shape": "Sy"
              },
              "SizePercent": {
                "type": "integer"
              },
              "TemplateConfiguration": {
                "shape": "S1d"
              },
              "TreatmentDescription": {},
              "TreatmentName": {}
            },
            "required": [
              "SizePercent"
            ]
          }
        },
        "CustomDeliveryConfiguration": {
          "shape": "Sb"
        },
        "Description": {},
        "HoldoutPercent": {
          "type": "integer"
        },
        "Hook": {
          "shape": "S1f"
        },
        "IsPaused": {
          "type": "boolean"
        },
        "Limits": {
          "shape": "S1h"
        },
        "MessageConfiguration": {
          "shape": "Se"
        },
        "Name": {},
        "Schedule": {
          "shape": "Sy"
        },
        "SegmentId": {},
        "SegmentVersion": {
          "type": "integer"
        },
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "TemplateConfiguration": {
          "shape": "S1d"
        },
        "TreatmentDescription": {},
        "TreatmentName": {},
        "Priority": {
          "type": "integer"
        }
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "DeliveryUri": {},
        "EndpointTypes": {
          "shape": "Sc"
        }
      },
      "required": [
        "DeliveryUri"
      ]
    },
    "Sc": {
      "type": "list",
      "member": {}
    },
    "Se": {
      "type": "structure",
      "members": {
        "ADMMessage": {
          "shape": "Sf"
        },
        "APNSMessage": {
          "shape": "Sf"
        },
        "BaiduMessage": {
          "shape": "Sf"
        },
        "CustomMessage": {
          "type": "structure",
          "members": {
            "Data": {}
          }
        },
        "DefaultMessage": {
          "shape": "Sf"
        },
        "EmailMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "FromAddress": {},
            "HtmlBody": {},
            "Title": {}
          }
        },
        "GCMMessage": {
          "shape": "Sf"
        },
        "SMSMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "MessageType": {},
            "OriginationNumber": {},
            "SenderId": {},
            "EntityId": {},
            "TemplateId": {}
          }
        },
        "InAppMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "Content": {
              "shape": "So"
            },
            "CustomConfig": {
              "shape": "S4"
            },
            "Layout": {}
          }
        }
      }
    },
    "Sf": {
      "type": "structure",
      "members": {
        "Action": {},
        "Body": {},
        "ImageIconUrl": {},
        "ImageSmallIconUrl": {},
        "ImageUrl": {},
        "JsonBody": {},
        "MediaUrl": {},
        "RawContent": {},
        "SilentPush": {
          "type": "boolean"
        },
        "TimeToLive": {
          "type": "integer"
        },
        "Title": {},
        "Url": {}
      }
    },
    "So": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "BackgroundColor": {},
          "BodyConfig": {
            "type": "structure",
            "members": {
              "Alignment": {},
              "Body": {},
              "TextColor": {}
            },
            "required": [
              "Alignment",
              "TextColor",
              "Body"
            ]
          },
          "HeaderConfig": {
            "type": "structure",
            "members": {
              "Alignment": {},
              "Header": {},
              "TextColor": {}
            },
            "required": [
              "Alignment",
              "Header",
              "TextColor"
            ]
          },
          "ImageUrl": {},
          "PrimaryBtn": {
            "shape": "St"
          },
          "SecondaryBtn": {
            "shape": "St"
          }
        }
      }
    },
    "St": {
      "type": "structure",
      "members": {
        "Android": {
          "shape": "Su"
        },
        "DefaultConfig": {
          "type": "structure",
          "members": {
            "BackgroundColor": {},
            "BorderRadius": {
              "type": "integer"
            },
            "ButtonAction": {},
            "Link": {},
            "Text": {},
            "TextColor": {}
          },
          "required": [
            "ButtonAction",
            "Text"
          ]
        },
        "IOS": {
          "shape": "Su"
        },
        "Web": {
          "shape": "Su"
        }
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "ButtonAction": {},
        "Link": {}
      },
      "required": [
        "ButtonAction"
      ]
    },
    "Sy": {
      "type": "structure",
      "members": {
        "EndTime": {},
        "EventFilter": {
          "shape": "Sz"
        },
        "Frequency": {},
        "IsLocalTime": {
          "type": "boolean"
        },
        "QuietTime": {
          "shape": "S1c"
        },
        "StartTime": {},
        "Timezone": {}
      },
      "required": [
        "StartTime"
      ]
    },
    "Sz": {
      "type": "structure",
      "members": {
        "Dimensions": {
          "shape": "S10"
        },
        "FilterType": {}
      },
      "required": [
        "FilterType",
        "Dimensions"
      ]
    },
    "S10": {
      "type": "structure",
      "members": {
        "Attributes": {
          "shape": "S11"
        },
        "EventType": {
          "shape": "S15"
        },
        "Metrics": {
          "shape": "S17"
        }
      }
    },
    "S11": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "AttributeType": {},
          "Values": {
            "shape": "S14"
          }
        },
        "required": [
          "Values"
        ]
      }
    },
    "S14": {
      "type": "list",
      "member": {}
    },
    "S15": {
      "type": "structure",
      "members": {
        "DimensionType": {},
        "Values": {
          "shape": "S14"
        }
      },
      "required": [
        "Values"
      ]
    },
    "S17": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "ComparisonOperator": {},
          "Value": {
            "type": "double"
          }
        },
        "required": [
          "ComparisonOperator",
          "Value"
        ]
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "End": {},
        "Start": {}
      }
    },
    "S1d": {
      "type": "structure",
      "members": {
        "EmailTemplate": {
          "shape": "S1e"
        },
        "PushTemplate": {
          "shape": "S1e"
        },
        "SMSTemplate": {
          "shape": "S1e"
        },
        "VoiceTemplate": {
          "shape": "S1e"
        }
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "Name": {},
        "Version": {}
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "LambdaFunctionName": {},
        "Mode": {},
        "WebUrl": {}
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "Daily": {
          "type": "integer"
        },
        "MaximumDuration": {
          "type": "integer"
        },
        "MessagesPerSecond": {
          "type": "integer"
        },
        "Total": {
          "type": "integer"
        },
        "Session": {
          "type": "integer"
        }
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "AdditionalTreatments": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "CustomDeliveryConfiguration": {
                "shape": "Sb"
              },
              "Id": {},
              "MessageConfiguration": {
                "shape": "Se"
              },
              "Schedule": {
                "shape": "Sy"
              },
              "SizePercent": {
                "type": "integer"
              },
              "State": {
                "shape": "S1m"
              },
              "TemplateConfiguration": {
                "shape": "S1d"
              },
              "TreatmentDescription": {},
              "TreatmentName": {}
            },
            "required": [
              "Id",
              "SizePercent"
            ]
          }
        },
        "ApplicationId": {},
        "Arn": {},
        "CreationDate": {},
        "CustomDeliveryConfiguration": {
          "shape": "Sb"
        },
        "DefaultState": {
          "shape": "S1m"
        },
        "Description": {},
        "HoldoutPercent": {
          "type": "integer"
        },
        "Hook": {
          "shape": "S1f"
        },
        "Id": {},
        "IsPaused": {
          "type": "boolean"
        },
        "LastModifiedDate": {},
        "Limits": {
          "shape": "S1h"
        },
        "MessageConfiguration": {
          "shape": "Se"
        },
        "Name": {},
        "Schedule": {
          "shape": "Sy"
        },
        "SegmentId": {},
        "SegmentVersion": {
          "type": "integer"
        },
        "State": {
          "shape": "S1m"
        },
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "TemplateConfiguration": {
          "shape": "S1d"
        },
        "TreatmentDescription": {},
        "TreatmentName": {},
        "Version": {
          "type": "integer"
        },
        "Priority": {
          "type": "integer"
        }
      },
      "required": [
        "LastModifiedDate",
        "CreationDate",
        "SegmentId",
        "SegmentVersion",
        "Id",
        "Arn",
        "ApplicationId"
      ]
    },
    "S1m": {
      "type": "structure",
      "members": {
        "CampaignStatus": {}
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "DefaultSubstitutions": {},
        "HtmlPart": {},
        "RecommenderId": {},
        "Subject": {},
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "TemplateDescription": {},
        "TextPart": {}
      }
    },
    "S1r": {
      "type": "structure",
      "members": {
        "Arn": {},
        "Message": {},
        "RequestID": {}
      }
    },
    "S1v": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CompletedPieces": {
          "type": "integer"
        },
        "CompletionDate": {},
        "CreationDate": {},
        "Definition": {
          "type": "structure",
          "members": {
            "RoleArn": {},
            "S3UrlPrefix": {},
            "SegmentId": {},
            "SegmentVersion": {
              "type": "integer"
            }
          },
          "required": [
            "S3UrlPrefix",
            "RoleArn"
          ]
        },
        "FailedPieces": {
          "type": "integer"
        },
        "Failures": {
          "shape": "S14"
        },
        "Id": {},
        "JobStatus": {},
        "TotalFailures": {
          "type": "integer"
        },
        "TotalPieces": {
          "type": "integer"
        },
        "TotalProcessed": {
          "type": "integer"
        },
        "Type": {}
      },
      "required": [
        "JobStatus",
        "CreationDate",
        "Type",
        "Definition",
        "Id",
        "ApplicationId"
      ]
    },
    "S22": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CompletedPieces": {
          "type": "integer"
        },
        "CompletionDate": {},
        "CreationDate": {},
        "Definition": {
          "type": "structure",
          "members": {
            "DefineSegment": {
              "type": "boolean"
            },
            "ExternalId": {},
            "Format": {},
            "RegisterEndpoints": {
              "type": "boolean"
            },
            "RoleArn": {},
            "S3Url": {},
            "SegmentId": {},
            "SegmentName": {}
          },
          "required": [
            "Format",
            "S3Url",
            "RoleArn"
          ]
        },
        "FailedPieces": {
          "type": "integer"
        },
        "Failures": {
          "shape": "S14"
        },
        "Id": {},
        "JobStatus": {},
        "TotalFailures": {
          "type": "integer"
        },
        "TotalPieces": {
          "type": "integer"
        },
        "TotalProcessed": {
          "type": "integer"
        },
        "Type": {}
      },
      "required": [
        "JobStatus",
        "CreationDate",
        "Type",
        "Definition",
        "Id",
        "ApplicationId"
      ]
    },
    "S25": {
      "type": "structure",
      "members": {
        "Content": {
          "shape": "So"
        },
        "CustomConfig": {
          "shape": "S4"
        },
        "Layout": {},
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "TemplateDescription": {}
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "Activities": {
          "shape": "S2a"
        },
        "CreationDate": {},
        "LastModifiedDate": {},
        "Limits": {
          "shape": "S3a"
        },
        "LocalTime": {
          "type": "boolean"
        },
        "Name": {},
        "QuietTime": {
          "shape": "S1c"
        },
        "RefreshFrequency": {},
        "Schedule": {
          "shape": "S3b"
        },
        "StartActivity": {},
        "StartCondition": {
          "shape": "S3d"
        },
        "State": {},
        "WaitForQuietTime": {
          "type": "boolean"
        },
        "RefreshOnSegmentUpdate": {
          "type": "boolean"
        },
        "JourneyChannelSettings": {
          "shape": "S3h"
        },
        "SendingSchedule": {
          "type": "boolean"
        },
        "OpenHours": {
          "shape": "S3i"
        },
        "ClosedDays": {
          "shape": "S3n"
        }
      },
      "required": [
        "Name"
      ]
    },
    "S2a": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "CUSTOM": {
            "type": "structure",
            "members": {
              "DeliveryUri": {},
              "EndpointTypes": {
                "shape": "Sc"
              },
              "MessageConfig": {
                "type": "structure",
                "members": {
                  "Data": {}
                }
              },
              "NextActivity": {},
              "TemplateName": {},
              "TemplateVersion": {}
            }
          },
          "ConditionalSplit": {
            "type": "structure",
            "members": {
              "Condition": {
                "type": "structure",
                "members": {
                  "Conditions": {
                    "type": "list",
                    "member": {
                      "shape": "S2h"
                    }
                  },
                  "Operator": {}
                }
              },
              "EvaluationWaitTime": {
                "shape": "S2u"
              },
              "FalseActivity": {},
              "TrueActivity": {}
            }
          },
          "Description": {},
          "EMAIL": {
            "type": "structure",
            "members": {
              "MessageConfig": {
                "type": "structure",
                "members": {
                  "FromAddress": {}
                }
              },
              "NextActivity": {},
              "TemplateName": {},
              "TemplateVersion": {}
            }
          },
          "Holdout": {
            "type": "structure",
            "members": {
              "NextActivity": {},
              "Percentage": {
                "type": "integer"
              }
            },
            "required": [
              "Percentage"
            ]
          },
          "MultiCondition": {
            "type": "structure",
            "members": {
              "Branches": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Condition": {
                      "shape": "S2h"
                    },
                    "NextActivity": {}
                  }
                }
              },
              "DefaultActivity": {},
              "EvaluationWaitTime": {
                "shape": "S2u"
              }
            }
          },
          "PUSH": {
            "type": "structure",
            "members": {
              "MessageConfig": {
                "type": "structure",
                "members": {
                  "TimeToLive": {}
                }
              },
              "NextActivity": {},
              "TemplateName": {},
              "TemplateVersion": {}
            }
          },
          "RandomSplit": {
            "type": "structure",
            "members": {
              "Branches": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "NextActivity": {},
                    "Percentage": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          },
          "SMS": {
            "type": "structure",
            "members": {
              "MessageConfig": {
                "type": "structure",
                "members": {
                  "MessageType": {},
                  "OriginationNumber": {},
                  "SenderId": {},
                  "EntityId": {},
                  "TemplateId": {}
                }
              },
              "NextActivity": {},
              "TemplateName": {},
              "TemplateVersion": {}
            }
          },
          "Wait": {
            "type": "structure",
            "members": {
              "NextActivity": {},
              "WaitTime": {
                "shape": "S2u"
              }
            }
          },
          "ContactCenter": {
            "type": "structure",
            "members": {
              "NextActivity": {}
            }
          }
        }
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "EventCondition": {
          "type": "structure",
          "members": {
            "Dimensions": {
              "shape": "S10"
            },
            "MessageActivity": {}
          }
        },
        "SegmentCondition": {
          "shape": "S2j"
        },
        "SegmentDimensions": {
          "shape": "S2k",
          "locationName": "segmentDimensions"
        }
      }
    },
    "S2j": {
      "type": "structure",
      "members": {
        "SegmentId": {}
      },
      "required": [
        "SegmentId"
      ]
    },
    "S2k": {
      "type": "structure",
      "members": {
        "Attributes": {
          "shape": "S11"
        },
        "Behavior": {
          "type": "structure",
          "members": {
            "Recency": {
              "type": "structure",
              "members": {
                "Duration": {},
                "RecencyType": {}
              },
              "required": [
                "Duration",
                "RecencyType"
              ]
            }
          }
        },
        "Demographic": {
          "type": "structure",
          "members": {
            "AppVersion": {
              "shape": "S15"
            },
            "Channel": {
              "shape": "S15"
            },
            "DeviceType": {
              "shape": "S15"
            },
            "Make": {
              "shape": "S15"
            },
            "Model": {
              "shape": "S15"
            },
            "Platform": {
              "shape": "S15"
            }
          }
        },
        "Location": {
          "type": "structure",
          "members": {
            "Country": {
              "shape": "S15"
            },
            "GPSPoint": {
              "type": "structure",
              "members": {
                "Coordinates": {
                  "type": "structure",
                  "members": {
                    "Latitude": {
                      "type": "double"
                    },
                    "Longitude": {
                      "type": "double"
                    }
                  },
                  "required": [
                    "Latitude",
                    "Longitude"
                  ]
                },
                "RangeInKilometers": {
                  "type": "double"
                }
              },
              "required": [
                "Coordinates"
              ]
            }
          }
        },
        "Metrics": {
          "shape": "S17"
        },
        "UserAttributes": {
          "shape": "S11"
        }
      }
    },
    "S2u": {
      "type": "structure",
      "members": {
        "WaitFor": {},
        "WaitUntil": {}
      }
    },
    "S3a": {
      "type": "structure",
      "members": {
        "DailyCap": {
          "type": "integer"
        },
        "EndpointReentryCap": {
          "type": "integer"
        },
        "MessagesPerSecond": {
          "type": "integer"
        },
        "EndpointReentryInterval": {}
      }
    },
    "S3b": {
      "type": "structure",
      "members": {
        "EndTime": {
          "shape": "S3c"
        },
        "StartTime": {
          "shape": "S3c"
        },
        "Timezone": {}
      }
    },
    "S3c": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S3d": {
      "type": "structure",
      "members": {
        "Description": {},
        "EventStartCondition": {
          "type": "structure",
          "members": {
            "EventFilter": {
              "type": "structure",
              "members": {
                "Dimensions": {
                  "shape": "S10"
                },
                "FilterType": {}
              },
              "required": [
                "FilterType",
                "Dimensions"
              ]
            },
            "SegmentId": {}
          }
        },
        "SegmentStartCondition": {
          "shape": "S2j"
        }
      }
    },
    "S3h": {
      "type": "structure",
      "members": {
        "ConnectCampaignArn": {},
        "ConnectCampaignExecutionRoleArn": {}
      }
    },
    "S3i": {
      "type": "structure",
      "members": {
        "EMAIL": {
          "shape": "S3j"
        },
        "SMS": {
          "shape": "S3j"
        },
        "PUSH": {
          "shape": "S3j"
        },
        "VOICE": {
          "shape": "S3j"
        },
        "CUSTOM": {
          "shape": "S3j"
        }
      }
    },
    "S3j": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {
          "type": "structure",
          "members": {
            "StartTime": {},
            "EndTime": {}
          }
        }
      }
    },
    "S3n": {
      "type": "structure",
      "members": {
        "EMAIL": {
          "shape": "S3o"
        },
        "SMS": {
          "shape": "S3o"
        },
        "PUSH": {
          "shape": "S3o"
        },
        "VOICE": {
          "shape": "S3o"
        },
        "CUSTOM": {
          "shape": "S3o"
        }
      }
    },
    "S3o": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "StartDateTime": {},
          "EndDateTime": {}
        }
      }
    },
    "S3r": {
      "type": "structure",
      "members": {
        "Activities": {
          "shape": "S2a"
        },
        "ApplicationId": {},
        "CreationDate": {},
        "Id": {},
        "LastModifiedDate": {},
        "Limits": {
          "shape": "S3a"
        },
        "LocalTime": {
          "type": "boolean"
        },
        "Name": {},
        "QuietTime": {
          "shape": "S1c"
        },
        "RefreshFrequency": {},
        "Schedule": {
          "shape": "S3b"
        },
        "StartActivity": {},
        "StartCondition": {
          "shape": "S3d"
        },
        "State": {},
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "WaitForQuietTime": {
          "type": "boolean"
        },
        "RefreshOnSegmentUpdate": {
          "type": "boolean"
        },
        "JourneyChannelSettings": {
          "shape": "S3h"
        },
        "SendingSchedule": {
          "type": "boolean"
        },
        "OpenHours": {
          "shape": "S3i"
        },
        "ClosedDays": {
          "shape": "S3n"
        }
      },
      "required": [
        "Name",
        "Id",
        "ApplicationId"
      ]
    },
    "S3t": {
      "type": "structure",
      "members": {
        "ADM": {
          "shape": "S3u"
        },
        "APNS": {
          "shape": "S3v"
        },
        "Baidu": {
          "shape": "S3u"
        },
        "Default": {
          "shape": "S3w"
        },
        "DefaultSubstitutions": {},
        "GCM": {
          "shape": "S3u"
        },
        "RecommenderId": {},
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "TemplateDescription": {}
      }
    },
    "S3u": {
      "type": "structure",
      "members": {
        "Action": {},
        "Body": {},
        "ImageIconUrl": {},
        "ImageUrl": {},
        "RawContent": {},
        "SmallImageIconUrl": {},
        "Sound": {},
        "Title": {},
        "Url": {}
      }
    },
    "S3v": {
      "type": "structure",
      "members": {
        "Action": {},
        "Body": {},
        "MediaUrl": {},
        "RawContent": {},
        "Sound": {},
        "Title": {},
        "Url": {}
      }
    },
    "S3w": {
      "type": "structure",
      "members": {
        "Action": {},
        "Body": {},
        "Sound": {},
        "Title": {},
        "Url": {}
      }
    },
    "S41": {
      "type": "structure",
      "members": {
        "Attributes": {
          "shape": "S4"
        },
        "CreationDate": {},
        "Description": {},
        "Id": {},
        "LastModifiedDate": {},
        "Name": {},
        "RecommendationProviderIdType": {},
        "RecommendationProviderRoleArn": {},
        "RecommendationProviderUri": {},
        "RecommendationTransformerUri": {},
        "RecommendationsDisplayName": {},
        "RecommendationsPerMessage": {
          "type": "integer"
        }
      },
      "required": [
        "RecommendationProviderUri",
        "LastModifiedDate",
        "CreationDate",
        "RecommendationProviderRoleArn",
        "Id"
      ]
    },
    "S43": {
      "type": "structure",
      "members": {
        "Dimensions": {
          "shape": "S2k"
        },
        "Name": {},
        "SegmentGroups": {
          "shape": "S44"
        },
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        }
      }
    },
    "S44": {
      "type": "structure",
      "members": {
        "Groups": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Dimensions": {
                "type": "list",
                "member": {
                  "shape": "S2k"
                }
              },
              "SourceSegments": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Id": {},
                    "Version": {
                      "type": "integer"
                    }
                  },
                  "required": [
                    "Id"
                  ]
                }
              },
              "SourceType": {},
              "Type": {}
            }
          }
        },
        "Include": {}
      }
    },
    "S4e": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "Arn": {},
        "CreationDate": {},
        "Dimensions": {
          "shape": "S2k"
        },
        "Id": {},
        "ImportDefinition": {
          "type": "structure",
          "members": {
            "ChannelCounts": {
              "type": "map",
              "key": {},
              "value": {
                "type": "integer"
              }
            },
            "ExternalId": {},
            "Format": {},
            "RoleArn": {},
            "S3Url": {},
            "Size": {
              "type": "integer"
            }
          },
          "required": [
            "Format",
            "S3Url",
            "Size",
            "ExternalId",
            "RoleArn"
          ]
        },
        "LastModifiedDate": {},
        "Name": {},
        "SegmentGroups": {
          "shape": "S44"
        },
        "SegmentType": {},
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "SegmentType",
        "CreationDate",
        "Id",
        "Arn",
        "ApplicationId"
      ]
    },
    "S4j": {
      "type": "structure",
      "members": {
        "Body": {},
        "DefaultSubstitutions": {},
        "RecommenderId": {},
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "TemplateDescription": {}
      }
    },
    "S4m": {
      "type": "structure",
      "members": {
        "Body": {},
        "DefaultSubstitutions": {},
        "LanguageCode": {},
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "TemplateDescription": {},
        "VoiceId": {}
      }
    },
    "S4q": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S4t": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "DefaultAuthenticationMethod": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "HasTokenKey": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S4w": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "DefaultAuthenticationMethod": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "HasTokenKey": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S4z": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "DefaultAuthenticationMethod": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "HasTokenKey": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S52": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "DefaultAuthenticationMethod": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "HasTokenKey": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S57": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "Credential": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Credential",
        "Platform"
      ]
    },
    "S5c": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "ConfigurationSet": {},
        "CreationDate": {},
        "Enabled": {
          "type": "boolean"
        },
        "FromAddress": {},
        "HasCredential": {
          "type": "boolean"
        },
        "Id": {},
        "Identity": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "MessagesPerSecond": {
          "type": "integer"
        },
        "Platform": {},
        "RoleArn": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S5f": {
      "type": "structure",
      "members": {
        "Message": {},
        "RequestID": {}
      }
    },
    "S5i": {
      "type": "structure",
      "members": {
        "Address": {},
        "ApplicationId": {},
        "Attributes": {
          "shape": "S5j"
        },
        "ChannelType": {},
        "CohortId": {},
        "CreationDate": {},
        "Demographic": {
          "shape": "S5l"
        },
        "EffectiveDate": {},
        "EndpointStatus": {},
        "Id": {},
        "Location": {
          "shape": "S5m"
        },
        "Metrics": {
          "shape": "S5n"
        },
        "OptOut": {},
        "RequestId": {},
        "User": {
          "shape": "S5o"
        }
      }
    },
    "S5j": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S14"
      }
    },
    "S5l": {
      "type": "structure",
      "members": {
        "AppVersion": {},
        "Locale": {},
        "Make": {},
        "Model": {},
        "ModelVersion": {},
        "Platform": {},
        "PlatformVersion": {},
        "Timezone": {}
      }
    },
    "S5m": {
      "type": "structure",
      "members": {
        "City": {},
        "Country": {},
        "Latitude": {
          "type": "double"
        },
        "Longitude": {
          "type": "double"
        },
        "PostalCode": {},
        "Region": {}
      }
    },
    "S5n": {
      "type": "map",
      "key": {},
      "value": {
        "type": "double"
      }
    },
    "S5o": {
      "type": "structure",
      "members": {
        "UserAttributes": {
          "shape": "S5j"
        },
        "UserId": {}
      }
    },
    "S5r": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "DestinationStreamArn": {},
        "ExternalId": {},
        "LastModifiedDate": {},
        "LastUpdatedBy": {},
        "RoleArn": {}
      },
      "required": [
        "ApplicationId",
        "RoleArn",
        "DestinationStreamArn"
      ]
    },
    "S5u": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "Credential": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Credential",
        "Platform"
      ]
    },
    "S67": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "PromotionalMessagesPerSecond": {
          "type": "integer"
        },
        "SenderId": {},
        "ShortCode": {},
        "TransactionalMessagesPerSecond": {
          "type": "integer"
        },
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S6c": {
      "type": "structure",
      "members": {
        "Item": {
          "type": "list",
          "member": {
            "shape": "S5i"
          }
        }
      },
      "required": [
        "Item"
      ]
    },
    "S6g": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S6y": {
      "type": "structure",
      "members": {
        "Rows": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "GroupedBys": {
                "shape": "S71"
              },
              "Values": {
                "shape": "S71"
              }
            },
            "required": [
              "GroupedBys",
              "Values"
            ]
          }
        }
      },
      "required": [
        "Rows"
      ]
    },
    "S71": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Type": {},
          "Value": {}
        },
        "required": [
          "Type",
          "Value",
          "Key"
        ]
      }
    },
    "S75": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CampaignHook": {
          "shape": "S1f"
        },
        "LastModifiedDate": {},
        "Limits": {
          "shape": "S1h"
        },
        "QuietTime": {
          "shape": "S1c"
        }
      },
      "required": [
        "ApplicationId"
      ]
    },
    "S7q": {
      "type": "structure",
      "members": {
        "Item": {
          "type": "list",
          "member": {
            "shape": "S1j"
          }
        },
        "NextToken": {}
      },
      "required": [
        "Item"
      ]
    },
    "S8d": {
      "type": "structure",
      "members": {
        "Item": {
          "type": "list",
          "member": {
            "shape": "S1v"
          }
        },
        "NextToken": {}
      },
      "required": [
        "Item"
      ]
    },
    "S8l": {
      "type": "structure",
      "members": {
        "Item": {
          "type": "list",
          "member": {
            "shape": "S22"
          }
        },
        "NextToken": {}
      },
      "required": [
        "Item"
      ]
    },
    "S9r": {
      "type": "structure",
      "members": {
        "Item": {
          "type": "list",
          "member": {
            "shape": "S4e"
          }
        },
        "NextToken": {}
      },
      "required": [
        "Item"
      ]
    },
    "Sad": {
      "type": "structure",
      "members": {
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        }
      },
      "required": [
        "tags"
      ]
    },
    "Sbi": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "BodyOverride": {},
          "Context": {
            "shape": "S4"
          },
          "RawContent": {},
          "Substitutions": {
            "shape": "S5j"
          },
          "TitleOverride": {}
        }
      }
    },
    "Sbk": {
      "type": "structure",
      "members": {
        "ADMMessage": {
          "type": "structure",
          "members": {
            "Action": {},
            "Body": {},
            "ConsolidationKey": {},
            "Data": {
              "shape": "S4"
            },
            "ExpiresAfter": {},
            "IconReference": {},
            "ImageIconUrl": {},
            "ImageUrl": {},
            "MD5": {},
            "RawContent": {},
            "SilentPush": {
              "type": "boolean"
            },
            "SmallImageIconUrl": {},
            "Sound": {},
            "Substitutions": {
              "shape": "S5j"
            },
            "Title": {},
            "Url": {}
          }
        },
        "APNSMessage": {
          "type": "structure",
          "members": {
            "APNSPushType": {},
            "Action": {},
            "Badge": {
              "type": "integer"
            },
            "Body": {},
            "Category": {},
            "CollapseId": {},
            "Data": {
              "shape": "S4"
            },
            "MediaUrl": {},
            "PreferredAuthenticationMethod": {},
            "Priority": {},
            "RawContent": {},
            "SilentPush": {
              "type": "boolean"
            },
            "Sound": {},
            "Substitutions": {
              "shape": "S5j"
            },
            "ThreadId": {},
            "TimeToLive": {
              "type": "integer"
            },
            "Title": {},
            "Url": {}
          }
        },
        "BaiduMessage": {
          "type": "structure",
          "members": {
            "Action": {},
            "Body": {},
            "Data": {
              "shape": "S4"
            },
            "IconReference": {},
            "ImageIconUrl": {},
            "ImageUrl": {},
            "RawContent": {},
            "SilentPush": {
              "type": "boolean"
            },
            "SmallImageIconUrl": {},
            "Sound": {},
            "Substitutions": {
              "shape": "S5j"
            },
            "TimeToLive": {
              "type": "integer"
            },
            "Title": {},
            "Url": {}
          }
        },
        "DefaultMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "Substitutions": {
              "shape": "S5j"
            }
          }
        },
        "DefaultPushNotificationMessage": {
          "type": "structure",
          "members": {
            "Action": {},
            "Body": {},
            "Data": {
              "shape": "S4"
            },
            "SilentPush": {
              "type": "boolean"
            },
            "Substitutions": {
              "shape": "S5j"
            },
            "Title": {},
            "Url": {}
          }
        },
        "EmailMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "FeedbackForwardingAddress": {},
            "FromAddress": {},
            "RawEmail": {
              "type": "structure",
              "members": {
                "Data": {
                  "type": "blob"
                }
              }
            },
            "ReplyToAddresses": {
              "shape": "S14"
            },
            "SimpleEmail": {
              "type": "structure",
              "members": {
                "HtmlPart": {
                  "shape": "Sbu"
                },
                "Subject": {
                  "shape": "Sbu"
                },
                "TextPart": {
                  "shape": "Sbu"
                }
              }
            },
            "Substitutions": {
              "shape": "S5j"
            }
          }
        },
        "GCMMessage": {
          "type": "structure",
          "members": {
            "Action": {},
            "Body": {},
            "CollapseKey": {},
            "Data": {
              "shape": "S4"
            },
            "IconReference": {},
            "ImageIconUrl": {},
            "ImageUrl": {},
            "Priority": {},
            "RawContent": {},
            "RestrictedPackageName": {},
            "SilentPush": {
              "type": "boolean"
            },
            "SmallImageIconUrl": {},
            "Sound": {},
            "Substitutions": {
              "shape": "S5j"
            },
            "TimeToLive": {
              "type": "integer"
            },
            "Title": {},
            "Url": {}
          }
        },
        "SMSMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "Keyword": {},
            "MediaUrl": {},
            "MessageType": {},
            "OriginationNumber": {},
            "SenderId": {},
            "Substitutions": {
              "shape": "S5j"
            },
            "EntityId": {},
            "TemplateId": {}
          }
        },
        "VoiceMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "LanguageCode": {},
            "OriginationNumber": {},
            "Substitutions": {
              "shape": "S5j"
            },
            "VoiceId": {}
          }
        }
      }
    },
    "Sbu": {
      "type": "structure",
      "members": {
        "Charset": {},
        "Data": {}
      }
    },
    "Sbz": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "EndpointResult": {
          "shape": "Sc0"
        },
        "RequestId": {},
        "Result": {
          "type": "map",
          "key": {},
          "value": {
            "type": "structure",
            "members": {
              "DeliveryStatus": {},
              "MessageId": {},
              "StatusCode": {
                "type": "integer"
              },
              "StatusMessage": {},
              "UpdatedToken": {}
            },
            "required": [
              "DeliveryStatus",
              "StatusCode"
            ]
          }
        }
      },
      "required": [
        "ApplicationId"
      ]
    },
    "Sc0": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Address": {},
          "DeliveryStatus": {},
          "MessageId": {},
          "StatusCode": {
            "type": "integer"
          },
          "StatusMessage": {},
          "UpdatedToken": {}
        },
        "required": [
          "DeliveryStatus",
          "StatusCode"
        ]
      }
    }
  }
}