{
  "pagination": {
    "ListConnectors": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "connectors"
    },
    "ListCustomPlugins": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "customPlugins"
    },
    "ListWorkerConfigurations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "workerConfigurations"
    }
  }
}
