const environment = process.env.ENV;

const devEndpoints = {
  directoryUrl: "https://api.therejecteddev.org/dev/directory/api/Directory",
  expenseReportUrl: "https://api.therejecteddev.org/dev/expense-report/api/Report",
  expensesUrl: "https://api.therejecteddev.org/dev/expense-report/v0/expenses",
  inventoryUrl: "https://api.therejecteddev.org/dev/inventory/api/Inventory",
  worshipUrl: "https://api.therejecteddev.org/dev/worship/api/Worship",
  documentUrl: "https://api.therejecteddev.org/dev/worship/api/Document"
};

const prodEndpoints = {
  directoryUrl: "https://api.therejecteddev.org/directory/api/Directory",
  expenseReportUrl: "https://api.therejecteddev.org/expense-report/api/Report",
  expensesUrl: "https://api.therejecteddev.org/expense-report/v0/expenses",
  inventoryUrl: " https://api.therejecteddev.org/inventory/api/Inventory",
  worshipUrl: "https://api.therejecteddev.org/worship/api/Worship",
  documentUrl: "https://api.therejecteddev.org/worship/api/Document"
};

const endpointConfig = environment === "prod" ? prodEndpoints : devEndpoints;
const completeConfig = Object.assign({}, endpointConfig);

module.exports = completeConfig;
