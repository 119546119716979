{
  "metadata": {
    "apiVersion": "2018-11-14",
    "endpointPrefix": "kafka",
    "signingName": "kafka",
    "serviceFullName": "Managed Streaming for Kafka",
    "serviceAbbreviation": "Kafka",
    "serviceId": "Kafka",
    "protocol": "rest-json",
    "jsonVersion": "1.1",
    "uid": "kafka-2018-11-14",
    "signatureVersion": "v4"
  },
  "operations": {
    "BatchAssociateScramSecret": {
      "http": {
        "requestUri": "/v1/clusters/{clusterArn}/scram-secrets",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "SecretArnList": {
            "shape": "S3",
            "locationName": "secretArnList"
          }
        },
        "required": [
          "ClusterArn",
          "SecretArnList"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "UnprocessedScramSecrets": {
            "shape": "S5",
            "locationName": "unprocessedScramSecrets"
          }
        }
      }
    },
    "CreateCluster": {
      "http": {
        "requestUri": "/v1/clusters",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "BrokerNodeGroupInfo": {
            "shape": "S8",
            "locationName": "brokerNodeGroupInfo"
          },
          "ClientAuthentication": {
            "shape": "Sj",
            "locationName": "clientAuthentication"
          },
          "ClusterName": {
            "locationName": "clusterName"
          },
          "ConfigurationInfo": {
            "shape": "Sq",
            "locationName": "configurationInfo"
          },
          "EncryptionInfo": {
            "shape": "Ss",
            "locationName": "encryptionInfo"
          },
          "EnhancedMonitoring": {
            "locationName": "enhancedMonitoring"
          },
          "OpenMonitoring": {
            "shape": "Sx",
            "locationName": "openMonitoring"
          },
          "KafkaVersion": {
            "locationName": "kafkaVersion"
          },
          "LoggingInfo": {
            "shape": "S12",
            "locationName": "loggingInfo"
          },
          "NumberOfBrokerNodes": {
            "locationName": "numberOfBrokerNodes",
            "type": "integer"
          },
          "Tags": {
            "shape": "S18",
            "locationName": "tags"
          },
          "StorageMode": {
            "locationName": "storageMode"
          }
        },
        "required": [
          "BrokerNodeGroupInfo",
          "KafkaVersion",
          "NumberOfBrokerNodes",
          "ClusterName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterName": {
            "locationName": "clusterName"
          },
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "CreateClusterV2": {
      "http": {
        "requestUri": "/api/v2/clusters",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterName": {
            "locationName": "clusterName"
          },
          "Tags": {
            "shape": "S18",
            "locationName": "tags"
          },
          "Provisioned": {
            "locationName": "provisioned",
            "type": "structure",
            "members": {
              "BrokerNodeGroupInfo": {
                "shape": "S8",
                "locationName": "brokerNodeGroupInfo"
              },
              "ClientAuthentication": {
                "shape": "Sj",
                "locationName": "clientAuthentication"
              },
              "ConfigurationInfo": {
                "shape": "Sq",
                "locationName": "configurationInfo"
              },
              "EncryptionInfo": {
                "shape": "Ss",
                "locationName": "encryptionInfo"
              },
              "EnhancedMonitoring": {
                "locationName": "enhancedMonitoring"
              },
              "OpenMonitoring": {
                "shape": "Sx",
                "locationName": "openMonitoring"
              },
              "KafkaVersion": {
                "locationName": "kafkaVersion"
              },
              "LoggingInfo": {
                "shape": "S12",
                "locationName": "loggingInfo"
              },
              "NumberOfBrokerNodes": {
                "locationName": "numberOfBrokerNodes",
                "type": "integer"
              },
              "StorageMode": {
                "locationName": "storageMode"
              }
            },
            "required": [
              "BrokerNodeGroupInfo",
              "KafkaVersion",
              "NumberOfBrokerNodes"
            ]
          },
          "Serverless": {
            "locationName": "serverless",
            "type": "structure",
            "members": {
              "VpcConfigs": {
                "shape": "S1f",
                "locationName": "vpcConfigs"
              },
              "ClientAuthentication": {
                "shape": "S1h",
                "locationName": "clientAuthentication"
              }
            },
            "required": [
              "VpcConfigs"
            ]
          }
        },
        "required": [
          "ClusterName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterName": {
            "locationName": "clusterName"
          },
          "State": {
            "locationName": "state"
          },
          "ClusterType": {
            "locationName": "clusterType"
          }
        }
      }
    },
    "CreateConfiguration": {
      "http": {
        "requestUri": "/v1/configurations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "KafkaVersions": {
            "shape": "S3",
            "locationName": "kafkaVersions"
          },
          "Name": {
            "locationName": "name"
          },
          "ServerProperties": {
            "locationName": "serverProperties",
            "type": "blob"
          }
        },
        "required": [
          "ServerProperties",
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "CreationTime": {
            "shape": "S1o",
            "locationName": "creationTime"
          },
          "LatestRevision": {
            "shape": "S1p",
            "locationName": "latestRevision"
          },
          "Name": {
            "locationName": "name"
          },
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "DeleteCluster": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/clusters/{clusterArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "CurrentVersion": {
            "location": "querystring",
            "locationName": "currentVersion"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "DeleteConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/configurations/{arn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "arn"
          }
        },
        "required": [
          "Arn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "DescribeCluster": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/clusters/{clusterArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterInfo": {
            "shape": "S1x",
            "locationName": "clusterInfo"
          }
        }
      }
    },
    "DescribeClusterV2": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v2/clusters/{clusterArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterInfo": {
            "shape": "S26",
            "locationName": "clusterInfo"
          }
        }
      }
    },
    "DescribeClusterOperation": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/operations/{clusterOperationArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterOperationArn": {
            "location": "uri",
            "locationName": "clusterOperationArn"
          }
        },
        "required": [
          "ClusterOperationArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterOperationInfo": {
            "shape": "S2b",
            "locationName": "clusterOperationInfo"
          }
        }
      }
    },
    "DescribeConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/configurations/{arn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "arn"
          }
        },
        "required": [
          "Arn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "CreationTime": {
            "shape": "S1o",
            "locationName": "creationTime"
          },
          "Description": {
            "locationName": "description"
          },
          "KafkaVersions": {
            "shape": "S3",
            "locationName": "kafkaVersions"
          },
          "LatestRevision": {
            "shape": "S1p",
            "locationName": "latestRevision"
          },
          "Name": {
            "locationName": "name"
          },
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "DescribeConfigurationRevision": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/configurations/{arn}/revisions/{revision}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "arn"
          },
          "Revision": {
            "location": "uri",
            "locationName": "revision",
            "type": "long"
          }
        },
        "required": [
          "Revision",
          "Arn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "CreationTime": {
            "shape": "S1o",
            "locationName": "creationTime"
          },
          "Description": {
            "locationName": "description"
          },
          "Revision": {
            "locationName": "revision",
            "type": "long"
          },
          "ServerProperties": {
            "locationName": "serverProperties",
            "type": "blob"
          }
        }
      }
    },
    "BatchDisassociateScramSecret": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v1/clusters/{clusterArn}/scram-secrets",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "SecretArnList": {
            "shape": "S3",
            "locationName": "secretArnList"
          }
        },
        "required": [
          "ClusterArn",
          "SecretArnList"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "UnprocessedScramSecrets": {
            "shape": "S5",
            "locationName": "unprocessedScramSecrets"
          }
        }
      }
    },
    "GetBootstrapBrokers": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/clusters/{clusterArn}/bootstrap-brokers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "BootstrapBrokerString": {
            "locationName": "bootstrapBrokerString"
          },
          "BootstrapBrokerStringTls": {
            "locationName": "bootstrapBrokerStringTls"
          },
          "BootstrapBrokerStringSaslScram": {
            "locationName": "bootstrapBrokerStringSaslScram"
          },
          "BootstrapBrokerStringSaslIam": {
            "locationName": "bootstrapBrokerStringSaslIam"
          },
          "BootstrapBrokerStringPublicTls": {
            "locationName": "bootstrapBrokerStringPublicTls"
          },
          "BootstrapBrokerStringPublicSaslScram": {
            "locationName": "bootstrapBrokerStringPublicSaslScram"
          },
          "BootstrapBrokerStringPublicSaslIam": {
            "locationName": "bootstrapBrokerStringPublicSaslIam"
          }
        }
      }
    },
    "GetCompatibleKafkaVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/compatible-kafka-versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "querystring",
            "locationName": "clusterArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CompatibleKafkaVersions": {
            "locationName": "compatibleKafkaVersions",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SourceVersion": {
                  "locationName": "sourceVersion"
                },
                "TargetVersions": {
                  "shape": "S3",
                  "locationName": "targetVersions"
                }
              }
            }
          }
        }
      }
    },
    "ListClusterOperations": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/clusters/{clusterArn}/operations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterOperationInfoList": {
            "locationName": "clusterOperationInfoList",
            "type": "list",
            "member": {
              "shape": "S2b"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListClusters": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/clusters",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterNameFilter": {
            "location": "querystring",
            "locationName": "clusterNameFilter"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterInfoList": {
            "locationName": "clusterInfoList",
            "type": "list",
            "member": {
              "shape": "S1x"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListClustersV2": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v2/clusters",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterNameFilter": {
            "location": "querystring",
            "locationName": "clusterNameFilter"
          },
          "ClusterTypeFilter": {
            "location": "querystring",
            "locationName": "clusterTypeFilter"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterInfoList": {
            "locationName": "clusterInfoList",
            "type": "list",
            "member": {
              "shape": "S26"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListConfigurationRevisions": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/configurations/{arn}/revisions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "arn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "required": [
          "Arn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Revisions": {
            "locationName": "revisions",
            "type": "list",
            "member": {
              "shape": "S1p"
            }
          }
        }
      }
    },
    "ListConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/configurations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configurations": {
            "locationName": "configurations",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {
                  "locationName": "arn"
                },
                "CreationTime": {
                  "shape": "S1o",
                  "locationName": "creationTime"
                },
                "Description": {
                  "locationName": "description"
                },
                "KafkaVersions": {
                  "shape": "S3",
                  "locationName": "kafkaVersions"
                },
                "LatestRevision": {
                  "shape": "S1p",
                  "locationName": "latestRevision"
                },
                "Name": {
                  "locationName": "name"
                },
                "State": {
                  "locationName": "state"
                }
              },
              "required": [
                "Description",
                "LatestRevision",
                "CreationTime",
                "KafkaVersions",
                "Arn",
                "Name",
                "State"
              ]
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListKafkaVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/kafka-versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KafkaVersions": {
            "locationName": "kafkaVersions",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Version": {
                  "locationName": "version"
                },
                "Status": {
                  "locationName": "status"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListNodes": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/clusters/{clusterArn}/nodes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "NodeInfoList": {
            "locationName": "nodeInfoList",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AddedToClusterTime": {
                  "locationName": "addedToClusterTime"
                },
                "BrokerNodeInfo": {
                  "locationName": "brokerNodeInfo",
                  "type": "structure",
                  "members": {
                    "AttachedENIId": {
                      "locationName": "attachedENIId"
                    },
                    "BrokerId": {
                      "locationName": "brokerId",
                      "type": "double"
                    },
                    "ClientSubnet": {
                      "locationName": "clientSubnet"
                    },
                    "ClientVpcIpAddress": {
                      "locationName": "clientVpcIpAddress"
                    },
                    "CurrentBrokerSoftwareInfo": {
                      "shape": "S1y",
                      "locationName": "currentBrokerSoftwareInfo"
                    },
                    "Endpoints": {
                      "shape": "S3",
                      "locationName": "endpoints"
                    }
                  }
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "NodeARN": {
                  "locationName": "nodeARN"
                },
                "NodeType": {
                  "locationName": "nodeType"
                },
                "ZookeeperNodeInfo": {
                  "locationName": "zookeeperNodeInfo",
                  "type": "structure",
                  "members": {
                    "AttachedENIId": {
                      "locationName": "attachedENIId"
                    },
                    "ClientVpcIpAddress": {
                      "locationName": "clientVpcIpAddress"
                    },
                    "Endpoints": {
                      "shape": "S3",
                      "locationName": "endpoints"
                    },
                    "ZookeeperId": {
                      "locationName": "zookeeperId",
                      "type": "double"
                    },
                    "ZookeeperVersion": {
                      "locationName": "zookeeperVersion"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "ListScramSecrets": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/clusters/{clusterArn}/scram-secrets",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "SecretArnList": {
            "shape": "S3",
            "locationName": "secretArnList"
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        },
        "required": [
          "ResourceArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S18",
            "locationName": "tags"
          }
        }
      }
    },
    "RebootBroker": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/clusters/{clusterArn}/reboot-broker",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "BrokerIds": {
            "shape": "S3",
            "locationName": "brokerIds"
          },
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          }
        },
        "required": [
          "ClusterArn",
          "BrokerIds"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterOperationArn": {
            "locationName": "clusterOperationArn"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/v1/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "Tags": {
            "shape": "S18",
            "locationName": "tags"
          }
        },
        "required": [
          "ResourceArn",
          "Tags"
        ]
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "TagKeys": {
            "shape": "S3",
            "location": "querystring",
            "locationName": "tagKeys"
          }
        },
        "required": [
          "TagKeys",
          "ResourceArn"
        ]
      }
    },
    "UpdateBrokerCount": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/clusters/{clusterArn}/nodes/count",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "CurrentVersion": {
            "locationName": "currentVersion"
          },
          "TargetNumberOfBrokerNodes": {
            "locationName": "targetNumberOfBrokerNodes",
            "type": "integer"
          }
        },
        "required": [
          "ClusterArn",
          "CurrentVersion",
          "TargetNumberOfBrokerNodes"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterOperationArn": {
            "locationName": "clusterOperationArn"
          }
        }
      }
    },
    "UpdateBrokerType": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/clusters/{clusterArn}/nodes/type",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "CurrentVersion": {
            "locationName": "currentVersion"
          },
          "TargetInstanceType": {
            "locationName": "targetInstanceType"
          }
        },
        "required": [
          "ClusterArn",
          "CurrentVersion",
          "TargetInstanceType"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterOperationArn": {
            "locationName": "clusterOperationArn"
          }
        }
      }
    },
    "UpdateBrokerStorage": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/clusters/{clusterArn}/nodes/storage",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "CurrentVersion": {
            "locationName": "currentVersion"
          },
          "TargetBrokerEBSVolumeInfo": {
            "shape": "S2h",
            "locationName": "targetBrokerEBSVolumeInfo"
          }
        },
        "required": [
          "ClusterArn",
          "TargetBrokerEBSVolumeInfo",
          "CurrentVersion"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterOperationArn": {
            "locationName": "clusterOperationArn"
          }
        }
      }
    },
    "UpdateConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/configurations/{arn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "arn"
          },
          "Description": {
            "locationName": "description"
          },
          "ServerProperties": {
            "locationName": "serverProperties",
            "type": "blob"
          }
        },
        "required": [
          "Arn",
          "ServerProperties"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "LatestRevision": {
            "shape": "S1p",
            "locationName": "latestRevision"
          }
        }
      }
    },
    "UpdateConnectivity": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/clusters/{clusterArn}/connectivity",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "ConnectivityInfo": {
            "shape": "Sh",
            "locationName": "connectivityInfo"
          },
          "CurrentVersion": {
            "locationName": "currentVersion"
          }
        },
        "required": [
          "ClusterArn",
          "ConnectivityInfo",
          "CurrentVersion"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterOperationArn": {
            "locationName": "clusterOperationArn"
          }
        }
      }
    },
    "UpdateClusterConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/clusters/{clusterArn}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "ConfigurationInfo": {
            "shape": "Sq",
            "locationName": "configurationInfo"
          },
          "CurrentVersion": {
            "locationName": "currentVersion"
          }
        },
        "required": [
          "ClusterArn",
          "CurrentVersion",
          "ConfigurationInfo"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterOperationArn": {
            "locationName": "clusterOperationArn"
          }
        }
      }
    },
    "UpdateClusterKafkaVersion": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/clusters/{clusterArn}/version",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "ConfigurationInfo": {
            "shape": "Sq",
            "locationName": "configurationInfo"
          },
          "CurrentVersion": {
            "locationName": "currentVersion"
          },
          "TargetKafkaVersion": {
            "locationName": "targetKafkaVersion"
          }
        },
        "required": [
          "ClusterArn",
          "TargetKafkaVersion",
          "CurrentVersion"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterOperationArn": {
            "locationName": "clusterOperationArn"
          }
        }
      }
    },
    "UpdateMonitoring": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/clusters/{clusterArn}/monitoring",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "CurrentVersion": {
            "locationName": "currentVersion"
          },
          "EnhancedMonitoring": {
            "locationName": "enhancedMonitoring"
          },
          "OpenMonitoring": {
            "shape": "Sx",
            "locationName": "openMonitoring"
          },
          "LoggingInfo": {
            "shape": "S12",
            "locationName": "loggingInfo"
          }
        },
        "required": [
          "ClusterArn",
          "CurrentVersion"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterOperationArn": {
            "locationName": "clusterOperationArn"
          }
        }
      }
    },
    "UpdateSecurity": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v1/clusters/{clusterArn}/security",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClientAuthentication": {
            "shape": "Sj",
            "locationName": "clientAuthentication"
          },
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "CurrentVersion": {
            "locationName": "currentVersion"
          },
          "EncryptionInfo": {
            "shape": "Ss",
            "locationName": "encryptionInfo"
          }
        },
        "required": [
          "ClusterArn",
          "CurrentVersion"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterOperationArn": {
            "locationName": "clusterOperationArn"
          }
        }
      }
    },
    "UpdateStorage": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/clusters/{clusterArn}/storage",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "CurrentVersion": {
            "locationName": "currentVersion"
          },
          "ProvisionedThroughput": {
            "shape": "Sd",
            "locationName": "provisionedThroughput"
          },
          "StorageMode": {
            "locationName": "storageMode"
          },
          "VolumeSizeGB": {
            "locationName": "volumeSizeGB",
            "type": "integer"
          }
        },
        "required": [
          "ClusterArn",
          "CurrentVersion"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterOperationArn": {
            "locationName": "clusterOperationArn"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "S5": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ErrorCode": {
            "locationName": "errorCode"
          },
          "ErrorMessage": {
            "locationName": "errorMessage"
          },
          "SecretArn": {
            "locationName": "secretArn"
          }
        }
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "BrokerAZDistribution": {
          "locationName": "brokerAZDistribution"
        },
        "ClientSubnets": {
          "shape": "S3",
          "locationName": "clientSubnets"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "SecurityGroups": {
          "shape": "S3",
          "locationName": "securityGroups"
        },
        "StorageInfo": {
          "locationName": "storageInfo",
          "type": "structure",
          "members": {
            "EbsStorageInfo": {
              "locationName": "ebsStorageInfo",
              "type": "structure",
              "members": {
                "ProvisionedThroughput": {
                  "shape": "Sd",
                  "locationName": "provisionedThroughput"
                },
                "VolumeSize": {
                  "locationName": "volumeSize",
                  "type": "integer"
                }
              }
            }
          }
        },
        "ConnectivityInfo": {
          "shape": "Sh",
          "locationName": "connectivityInfo"
        }
      },
      "required": [
        "ClientSubnets",
        "InstanceType"
      ]
    },
    "Sd": {
      "type": "structure",
      "members": {
        "Enabled": {
          "locationName": "enabled",
          "type": "boolean"
        },
        "VolumeThroughput": {
          "locationName": "volumeThroughput",
          "type": "integer"
        }
      }
    },
    "Sh": {
      "type": "structure",
      "members": {
        "PublicAccess": {
          "locationName": "publicAccess",
          "type": "structure",
          "members": {
            "Type": {
              "locationName": "type"
            }
          }
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "Sasl": {
          "locationName": "sasl",
          "type": "structure",
          "members": {
            "Scram": {
              "locationName": "scram",
              "type": "structure",
              "members": {
                "Enabled": {
                  "locationName": "enabled",
                  "type": "boolean"
                }
              }
            },
            "Iam": {
              "shape": "Sm",
              "locationName": "iam"
            }
          }
        },
        "Tls": {
          "locationName": "tls",
          "type": "structure",
          "members": {
            "CertificateAuthorityArnList": {
              "shape": "S3",
              "locationName": "certificateAuthorityArnList"
            },
            "Enabled": {
              "locationName": "enabled",
              "type": "boolean"
            }
          }
        },
        "Unauthenticated": {
          "locationName": "unauthenticated",
          "type": "structure",
          "members": {
            "Enabled": {
              "locationName": "enabled",
              "type": "boolean"
            }
          }
        }
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "Enabled": {
          "locationName": "enabled",
          "type": "boolean"
        }
      }
    },
    "Sq": {
      "type": "structure",
      "members": {
        "Arn": {
          "locationName": "arn"
        },
        "Revision": {
          "locationName": "revision",
          "type": "long"
        }
      },
      "required": [
        "Revision",
        "Arn"
      ]
    },
    "Ss": {
      "type": "structure",
      "members": {
        "EncryptionAtRest": {
          "locationName": "encryptionAtRest",
          "type": "structure",
          "members": {
            "DataVolumeKMSKeyId": {
              "locationName": "dataVolumeKMSKeyId"
            }
          },
          "required": [
            "DataVolumeKMSKeyId"
          ]
        },
        "EncryptionInTransit": {
          "locationName": "encryptionInTransit",
          "type": "structure",
          "members": {
            "ClientBroker": {
              "locationName": "clientBroker"
            },
            "InCluster": {
              "locationName": "inCluster",
              "type": "boolean"
            }
          }
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "Prometheus": {
          "locationName": "prometheus",
          "type": "structure",
          "members": {
            "JmxExporter": {
              "locationName": "jmxExporter",
              "type": "structure",
              "members": {
                "EnabledInBroker": {
                  "locationName": "enabledInBroker",
                  "type": "boolean"
                }
              },
              "required": [
                "EnabledInBroker"
              ]
            },
            "NodeExporter": {
              "locationName": "nodeExporter",
              "type": "structure",
              "members": {
                "EnabledInBroker": {
                  "locationName": "enabledInBroker",
                  "type": "boolean"
                }
              },
              "required": [
                "EnabledInBroker"
              ]
            }
          }
        }
      },
      "required": [
        "Prometheus"
      ]
    },
    "S12": {
      "type": "structure",
      "members": {
        "BrokerLogs": {
          "locationName": "brokerLogs",
          "type": "structure",
          "members": {
            "CloudWatchLogs": {
              "locationName": "cloudWatchLogs",
              "type": "structure",
              "members": {
                "Enabled": {
                  "locationName": "enabled",
                  "type": "boolean"
                },
                "LogGroup": {
                  "locationName": "logGroup"
                }
              },
              "required": [
                "Enabled"
              ]
            },
            "Firehose": {
              "locationName": "firehose",
              "type": "structure",
              "members": {
                "DeliveryStream": {
                  "locationName": "deliveryStream"
                },
                "Enabled": {
                  "locationName": "enabled",
                  "type": "boolean"
                }
              },
              "required": [
                "Enabled"
              ]
            },
            "S3": {
              "locationName": "s3",
              "type": "structure",
              "members": {
                "Bucket": {
                  "locationName": "bucket"
                },
                "Enabled": {
                  "locationName": "enabled",
                  "type": "boolean"
                },
                "Prefix": {
                  "locationName": "prefix"
                }
              },
              "required": [
                "Enabled"
              ]
            }
          }
        }
      },
      "required": [
        "BrokerLogs"
      ]
    },
    "S18": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "SubnetIds": {
            "shape": "S3",
            "locationName": "subnetIds"
          },
          "SecurityGroupIds": {
            "shape": "S3",
            "locationName": "securityGroupIds"
          }
        },
        "required": [
          "SubnetIds"
        ]
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "Sasl": {
          "locationName": "sasl",
          "type": "structure",
          "members": {
            "Iam": {
              "shape": "Sm",
              "locationName": "iam"
            }
          }
        }
      }
    },
    "S1o": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S1p": {
      "type": "structure",
      "members": {
        "CreationTime": {
          "shape": "S1o",
          "locationName": "creationTime"
        },
        "Description": {
          "locationName": "description"
        },
        "Revision": {
          "locationName": "revision",
          "type": "long"
        }
      },
      "required": [
        "Revision",
        "CreationTime"
      ]
    },
    "S1x": {
      "type": "structure",
      "members": {
        "ActiveOperationArn": {
          "locationName": "activeOperationArn"
        },
        "BrokerNodeGroupInfo": {
          "shape": "S8",
          "locationName": "brokerNodeGroupInfo"
        },
        "ClientAuthentication": {
          "shape": "Sj",
          "locationName": "clientAuthentication"
        },
        "ClusterArn": {
          "locationName": "clusterArn"
        },
        "ClusterName": {
          "locationName": "clusterName"
        },
        "CreationTime": {
          "shape": "S1o",
          "locationName": "creationTime"
        },
        "CurrentBrokerSoftwareInfo": {
          "shape": "S1y",
          "locationName": "currentBrokerSoftwareInfo"
        },
        "CurrentVersion": {
          "locationName": "currentVersion"
        },
        "EncryptionInfo": {
          "shape": "Ss",
          "locationName": "encryptionInfo"
        },
        "EnhancedMonitoring": {
          "locationName": "enhancedMonitoring"
        },
        "OpenMonitoring": {
          "shape": "S1z",
          "locationName": "openMonitoring"
        },
        "LoggingInfo": {
          "shape": "S12",
          "locationName": "loggingInfo"
        },
        "NumberOfBrokerNodes": {
          "locationName": "numberOfBrokerNodes",
          "type": "integer"
        },
        "State": {
          "locationName": "state"
        },
        "StateInfo": {
          "shape": "S23",
          "locationName": "stateInfo"
        },
        "Tags": {
          "shape": "S18",
          "locationName": "tags"
        },
        "ZookeeperConnectString": {
          "locationName": "zookeeperConnectString"
        },
        "ZookeeperConnectStringTls": {
          "locationName": "zookeeperConnectStringTls"
        },
        "StorageMode": {
          "locationName": "storageMode"
        }
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "ConfigurationArn": {
          "locationName": "configurationArn"
        },
        "ConfigurationRevision": {
          "locationName": "configurationRevision",
          "type": "long"
        },
        "KafkaVersion": {
          "locationName": "kafkaVersion"
        }
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "Prometheus": {
          "locationName": "prometheus",
          "type": "structure",
          "members": {
            "JmxExporter": {
              "locationName": "jmxExporter",
              "type": "structure",
              "members": {
                "EnabledInBroker": {
                  "locationName": "enabledInBroker",
                  "type": "boolean"
                }
              },
              "required": [
                "EnabledInBroker"
              ]
            },
            "NodeExporter": {
              "locationName": "nodeExporter",
              "type": "structure",
              "members": {
                "EnabledInBroker": {
                  "locationName": "enabledInBroker",
                  "type": "boolean"
                }
              },
              "required": [
                "EnabledInBroker"
              ]
            }
          }
        }
      },
      "required": [
        "Prometheus"
      ]
    },
    "S23": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S26": {
      "type": "structure",
      "members": {
        "ActiveOperationArn": {
          "locationName": "activeOperationArn"
        },
        "ClusterType": {
          "locationName": "clusterType"
        },
        "ClusterArn": {
          "locationName": "clusterArn"
        },
        "ClusterName": {
          "locationName": "clusterName"
        },
        "CreationTime": {
          "shape": "S1o",
          "locationName": "creationTime"
        },
        "CurrentVersion": {
          "locationName": "currentVersion"
        },
        "State": {
          "locationName": "state"
        },
        "StateInfo": {
          "shape": "S23",
          "locationName": "stateInfo"
        },
        "Tags": {
          "shape": "S18",
          "locationName": "tags"
        },
        "Provisioned": {
          "locationName": "provisioned",
          "type": "structure",
          "members": {
            "BrokerNodeGroupInfo": {
              "shape": "S8",
              "locationName": "brokerNodeGroupInfo"
            },
            "CurrentBrokerSoftwareInfo": {
              "shape": "S1y",
              "locationName": "currentBrokerSoftwareInfo"
            },
            "ClientAuthentication": {
              "shape": "Sj",
              "locationName": "clientAuthentication"
            },
            "EncryptionInfo": {
              "shape": "Ss",
              "locationName": "encryptionInfo"
            },
            "EnhancedMonitoring": {
              "locationName": "enhancedMonitoring"
            },
            "OpenMonitoring": {
              "shape": "Sx",
              "locationName": "openMonitoring"
            },
            "LoggingInfo": {
              "shape": "S12",
              "locationName": "loggingInfo"
            },
            "NumberOfBrokerNodes": {
              "locationName": "numberOfBrokerNodes",
              "type": "integer"
            },
            "ZookeeperConnectString": {
              "locationName": "zookeeperConnectString"
            },
            "ZookeeperConnectStringTls": {
              "locationName": "zookeeperConnectStringTls"
            },
            "StorageMode": {
              "locationName": "storageMode"
            }
          },
          "required": [
            "BrokerNodeGroupInfo",
            "NumberOfBrokerNodes"
          ]
        },
        "Serverless": {
          "locationName": "serverless",
          "type": "structure",
          "members": {
            "VpcConfigs": {
              "shape": "S1f",
              "locationName": "vpcConfigs"
            },
            "ClientAuthentication": {
              "shape": "S1h",
              "locationName": "clientAuthentication"
            }
          },
          "required": [
            "VpcConfigs"
          ]
        }
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "ClientRequestId": {
          "locationName": "clientRequestId"
        },
        "ClusterArn": {
          "locationName": "clusterArn"
        },
        "CreationTime": {
          "shape": "S1o",
          "locationName": "creationTime"
        },
        "EndTime": {
          "shape": "S1o",
          "locationName": "endTime"
        },
        "ErrorInfo": {
          "locationName": "errorInfo",
          "type": "structure",
          "members": {
            "ErrorCode": {
              "locationName": "errorCode"
            },
            "ErrorString": {
              "locationName": "errorString"
            }
          }
        },
        "OperationArn": {
          "locationName": "operationArn"
        },
        "OperationState": {
          "locationName": "operationState"
        },
        "OperationSteps": {
          "locationName": "operationSteps",
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "StepInfo": {
                "locationName": "stepInfo",
                "type": "structure",
                "members": {
                  "StepStatus": {
                    "locationName": "stepStatus"
                  }
                }
              },
              "StepName": {
                "locationName": "stepName"
              }
            }
          }
        },
        "OperationType": {
          "locationName": "operationType"
        },
        "SourceClusterInfo": {
          "shape": "S2g",
          "locationName": "sourceClusterInfo"
        },
        "TargetClusterInfo": {
          "shape": "S2g",
          "locationName": "targetClusterInfo"
        }
      }
    },
    "S2g": {
      "type": "structure",
      "members": {
        "BrokerEBSVolumeInfo": {
          "shape": "S2h",
          "locationName": "brokerEBSVolumeInfo"
        },
        "ConfigurationInfo": {
          "shape": "Sq",
          "locationName": "configurationInfo"
        },
        "NumberOfBrokerNodes": {
          "locationName": "numberOfBrokerNodes",
          "type": "integer"
        },
        "EnhancedMonitoring": {
          "locationName": "enhancedMonitoring"
        },
        "OpenMonitoring": {
          "shape": "S1z",
          "locationName": "openMonitoring"
        },
        "KafkaVersion": {
          "locationName": "kafkaVersion"
        },
        "LoggingInfo": {
          "shape": "S12",
          "locationName": "loggingInfo"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "ClientAuthentication": {
          "shape": "Sj",
          "locationName": "clientAuthentication"
        },
        "EncryptionInfo": {
          "shape": "Ss",
          "locationName": "encryptionInfo"
        },
        "ConnectivityInfo": {
          "shape": "Sh",
          "locationName": "connectivityInfo"
        },
        "StorageMode": {
          "locationName": "storageMode"
        }
      }
    },
    "S2h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "KafkaBrokerNodeId": {
            "locationName": "kafkaBrokerNodeId"
          },
          "ProvisionedThroughput": {
            "shape": "Sd",
            "locationName": "provisionedThroughput"
          },
          "VolumeSizeGB": {
            "locationName": "volumeSizeGB",
            "type": "integer"
          }
        },
        "required": [
          "KafkaBrokerNodeId"
        ]
      }
    }
  }
}