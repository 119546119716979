{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-02-26",
    "endpointPrefix": "mgn",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "mgn",
    "serviceFullName": "Application Migration Service",
    "serviceId": "mgn",
    "signatureVersion": "v4",
    "signingName": "mgn",
    "uid": "mgn-2020-02-26"
  },
  "operations": {
    "ChangeServerLifeCycleState": {
      "http": {
        "requestUri": "/ChangeServerLifeCycleState",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "lifeCycle",
          "sourceServerID"
        ],
        "members": {
          "lifeCycle": {
            "type": "structure",
            "required": [
              "state"
            ],
            "members": {
              "state": {}
            }
          },
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S5"
      }
    },
    "CreateLaunchConfigurationTemplate": {
      "http": {
        "requestUri": "/CreateLaunchConfigurationTemplate",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "postLaunchActions": {
            "shape": "S1j"
          },
          "tags": {
            "shape": "S1e"
          }
        }
      },
      "output": {
        "shape": "S1x"
      }
    },
    "CreateReplicationConfigurationTemplate": {
      "http": {
        "requestUri": "/CreateReplicationConfigurationTemplate",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "associateDefaultSecurityGroup",
          "bandwidthThrottling",
          "createPublicIP",
          "dataPlaneRouting",
          "defaultLargeStagingDiskType",
          "ebsEncryption",
          "replicationServerInstanceType",
          "replicationServersSecurityGroupsIDs",
          "stagingAreaSubnetId",
          "stagingAreaTags",
          "useDedicatedReplicationServer"
        ],
        "members": {
          "associateDefaultSecurityGroup": {
            "type": "boolean"
          },
          "bandwidthThrottling": {
            "type": "long"
          },
          "createPublicIP": {
            "type": "boolean"
          },
          "dataPlaneRouting": {},
          "defaultLargeStagingDiskType": {},
          "ebsEncryption": {},
          "ebsEncryptionKeyArn": {},
          "replicationServerInstanceType": {},
          "replicationServersSecurityGroupsIDs": {
            "shape": "S23"
          },
          "stagingAreaSubnetId": {},
          "stagingAreaTags": {
            "shape": "S1e"
          },
          "tags": {
            "shape": "S1e"
          },
          "useDedicatedReplicationServer": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S26"
      }
    },
    "DeleteJob": {
      "http": {
        "requestUri": "/DeleteJob",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "jobID"
        ],
        "members": {
          "jobID": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteLaunchConfigurationTemplate": {
      "http": {
        "requestUri": "/DeleteLaunchConfigurationTemplate",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "launchConfigurationTemplateID"
        ],
        "members": {
          "launchConfigurationTemplateID": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteReplicationConfigurationTemplate": {
      "http": {
        "requestUri": "/DeleteReplicationConfigurationTemplate",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "replicationConfigurationTemplateID"
        ],
        "members": {
          "replicationConfigurationTemplateID": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteSourceServer": {
      "http": {
        "requestUri": "/DeleteSourceServer",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteVcenterClient": {
      "http": {
        "requestUri": "/DeleteVcenterClient",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "vcenterClientID"
        ],
        "members": {
          "vcenterClientID": {}
        }
      },
      "idempotent": true
    },
    "DescribeJobLogItems": {
      "http": {
        "requestUri": "/DescribeJobLogItems",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "jobID"
        ],
        "members": {
          "jobID": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "event": {},
                "eventData": {
                  "type": "structure",
                  "members": {
                    "conversionServerID": {},
                    "rawError": {},
                    "sourceServerID": {},
                    "targetInstanceID": {}
                  }
                },
                "logDateTime": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeJobs": {
      "http": {
        "requestUri": "/DescribeJobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "filters": {
            "type": "structure",
            "members": {
              "fromDate": {},
              "jobIDs": {
                "type": "list",
                "member": {}
              },
              "toDate": {}
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "shape": "S2t"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeLaunchConfigurationTemplates": {
      "http": {
        "requestUri": "/DescribeLaunchConfigurationTemplates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "launchConfigurationTemplateIDs": {
            "type": "list",
            "member": {}
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "shape": "S1x"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeReplicationConfigurationTemplates": {
      "http": {
        "requestUri": "/DescribeReplicationConfigurationTemplates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "replicationConfigurationTemplateIDs": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "shape": "S26"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeSourceServers": {
      "http": {
        "requestUri": "/DescribeSourceServers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "filters": {
            "type": "structure",
            "members": {
              "isArchived": {
                "type": "boolean"
              },
              "lifeCycleStates": {
                "type": "list",
                "member": {}
              },
              "replicationTypes": {
                "type": "list",
                "member": {}
              },
              "sourceServerIDs": {
                "type": "list",
                "member": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "shape": "S5"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeVcenterClients": {
      "http": {
        "method": "GET",
        "requestUri": "/DescribeVcenterClients",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "datacenterName": {},
                "hostname": {},
                "lastSeenDatetime": {},
                "sourceServerTags": {
                  "shape": "S1e"
                },
                "tags": {
                  "shape": "S1e"
                },
                "vcenterClientID": {},
                "vcenterUUID": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DisconnectFromService": {
      "http": {
        "requestUri": "/DisconnectFromService",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S5"
      }
    },
    "FinalizeCutover": {
      "http": {
        "requestUri": "/FinalizeCutover",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S5"
      }
    },
    "GetLaunchConfiguration": {
      "http": {
        "requestUri": "/GetLaunchConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S3r"
      }
    },
    "GetReplicationConfiguration": {
      "http": {
        "requestUri": "/GetReplicationConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S3y"
      }
    },
    "InitializeService": {
      "http": {
        "requestUri": "/InitializeService",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S1e"
          }
        }
      }
    },
    "MarkAsArchived": {
      "http": {
        "requestUri": "/MarkAsArchived",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S5"
      }
    },
    "RetryDataReplication": {
      "http": {
        "requestUri": "/RetryDataReplication",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S5"
      }
    },
    "StartCutover": {
      "http": {
        "requestUri": "/StartCutover",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerIDs"
        ],
        "members": {
          "sourceServerIDs": {
            "type": "list",
            "member": {}
          },
          "tags": {
            "shape": "S1e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "job": {
            "shape": "S2t"
          }
        }
      }
    },
    "StartReplication": {
      "http": {
        "requestUri": "/StartReplication",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S5"
      }
    },
    "StartTest": {
      "http": {
        "requestUri": "/StartTest",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerIDs"
        ],
        "members": {
          "sourceServerIDs": {
            "type": "list",
            "member": {}
          },
          "tags": {
            "shape": "S1e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "job": {
            "shape": "S2t"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S1e"
          }
        }
      },
      "idempotent": true
    },
    "TerminateTargetInstances": {
      "http": {
        "requestUri": "/TerminateTargetInstances",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerIDs"
        ],
        "members": {
          "sourceServerIDs": {
            "type": "list",
            "member": {}
          },
          "tags": {
            "shape": "S1e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "job": {
            "shape": "S2t"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {},
            "sensitive": true
          }
        }
      },
      "idempotent": true
    },
    "UpdateLaunchConfiguration": {
      "http": {
        "requestUri": "/UpdateLaunchConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "bootMode": {},
          "copyPrivateIp": {
            "type": "boolean"
          },
          "copyTags": {
            "type": "boolean"
          },
          "launchDisposition": {},
          "licensing": {
            "shape": "S3u"
          },
          "name": {},
          "postLaunchActions": {
            "shape": "S1j"
          },
          "sourceServerID": {},
          "targetInstanceTypeRightSizingMethod": {}
        }
      },
      "output": {
        "shape": "S3r"
      },
      "idempotent": true
    },
    "UpdateLaunchConfigurationTemplate": {
      "http": {
        "requestUri": "/UpdateLaunchConfigurationTemplate",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "launchConfigurationTemplateID"
        ],
        "members": {
          "launchConfigurationTemplateID": {},
          "postLaunchActions": {
            "shape": "S1j"
          }
        }
      },
      "output": {
        "shape": "S1x"
      }
    },
    "UpdateReplicationConfiguration": {
      "http": {
        "requestUri": "/UpdateReplicationConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "associateDefaultSecurityGroup": {
            "type": "boolean"
          },
          "bandwidthThrottling": {
            "type": "long"
          },
          "createPublicIP": {
            "type": "boolean"
          },
          "dataPlaneRouting": {},
          "defaultLargeStagingDiskType": {},
          "ebsEncryption": {},
          "ebsEncryptionKeyArn": {},
          "name": {},
          "replicatedDisks": {
            "shape": "S3z"
          },
          "replicationServerInstanceType": {},
          "replicationServersSecurityGroupsIDs": {
            "shape": "S23"
          },
          "sourceServerID": {},
          "stagingAreaSubnetId": {},
          "stagingAreaTags": {
            "shape": "S1e"
          },
          "useDedicatedReplicationServer": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S3y"
      },
      "idempotent": true
    },
    "UpdateReplicationConfigurationTemplate": {
      "http": {
        "requestUri": "/UpdateReplicationConfigurationTemplate",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "replicationConfigurationTemplateID"
        ],
        "members": {
          "arn": {},
          "associateDefaultSecurityGroup": {
            "type": "boolean"
          },
          "bandwidthThrottling": {
            "type": "long"
          },
          "createPublicIP": {
            "type": "boolean"
          },
          "dataPlaneRouting": {},
          "defaultLargeStagingDiskType": {},
          "ebsEncryption": {},
          "ebsEncryptionKeyArn": {},
          "replicationConfigurationTemplateID": {},
          "replicationServerInstanceType": {},
          "replicationServersSecurityGroupsIDs": {
            "shape": "S23"
          },
          "stagingAreaSubnetId": {},
          "stagingAreaTags": {
            "shape": "S1e"
          },
          "useDedicatedReplicationServer": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S26"
      }
    },
    "UpdateSourceServerReplicationType": {
      "http": {
        "requestUri": "/UpdateSourceServerReplicationType",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "replicationType",
          "sourceServerID"
        ],
        "members": {
          "replicationType": {},
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S5"
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "arn": {},
        "dataReplicationInfo": {
          "type": "structure",
          "members": {
            "dataReplicationError": {
              "type": "structure",
              "members": {
                "error": {},
                "rawError": {}
              }
            },
            "dataReplicationInitiation": {
              "type": "structure",
              "members": {
                "nextAttemptDateTime": {},
                "startDateTime": {},
                "steps": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "status": {}
                    }
                  }
                }
              }
            },
            "dataReplicationState": {},
            "etaDateTime": {},
            "lagDuration": {},
            "lastSnapshotDateTime": {},
            "replicatedDisks": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "backloggedStorageBytes": {
                    "type": "long"
                  },
                  "deviceName": {},
                  "replicatedStorageBytes": {
                    "type": "long"
                  },
                  "rescannedStorageBytes": {
                    "type": "long"
                  },
                  "totalStorageBytes": {
                    "type": "long"
                  }
                }
              }
            }
          }
        },
        "isArchived": {
          "type": "boolean"
        },
        "launchedInstance": {
          "type": "structure",
          "members": {
            "ec2InstanceID": {},
            "firstBoot": {},
            "jobID": {}
          }
        },
        "lifeCycle": {
          "type": "structure",
          "members": {
            "addedToServiceDateTime": {},
            "elapsedReplicationDuration": {},
            "firstByteDateTime": {},
            "lastCutover": {
              "type": "structure",
              "members": {
                "finalized": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {}
                  }
                },
                "initiated": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {},
                    "jobID": {}
                  }
                },
                "reverted": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {}
                  }
                }
              }
            },
            "lastSeenByServiceDateTime": {},
            "lastTest": {
              "type": "structure",
              "members": {
                "finalized": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {}
                  }
                },
                "initiated": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {},
                    "jobID": {}
                  }
                },
                "reverted": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {}
                  }
                }
              }
            },
            "state": {}
          }
        },
        "replicationType": {},
        "sourceProperties": {
          "type": "structure",
          "members": {
            "cpus": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "cores": {
                    "type": "long"
                  },
                  "modelName": {}
                }
              }
            },
            "disks": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "bytes": {
                    "type": "long"
                  },
                  "deviceName": {}
                }
              }
            },
            "identificationHints": {
              "type": "structure",
              "members": {
                "awsInstanceID": {},
                "fqdn": {},
                "hostname": {},
                "vmPath": {},
                "vmWareUuid": {}
              }
            },
            "lastUpdatedDateTime": {},
            "networkInterfaces": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "ips": {
                    "type": "list",
                    "member": {}
                  },
                  "isPrimary": {
                    "type": "boolean"
                  },
                  "macAddress": {}
                }
              }
            },
            "os": {
              "type": "structure",
              "members": {
                "fullString": {}
              }
            },
            "ramBytes": {
              "type": "long"
            },
            "recommendedInstanceType": {}
          }
        },
        "sourceServerID": {},
        "tags": {
          "shape": "S1e"
        },
        "vcenterClientID": {}
      }
    },
    "S1e": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    },
    "S1j": {
      "type": "structure",
      "members": {
        "cloudWatchLogGroupName": {},
        "deployment": {},
        "s3LogBucket": {},
        "s3OutputKeyPrefix": {},
        "ssmDocuments": {
          "type": "list",
          "member": {
            "shape": "S1o"
          }
        }
      }
    },
    "S1o": {
      "type": "structure",
      "required": [
        "actionName",
        "ssmDocumentName"
      ],
      "members": {
        "actionName": {},
        "mustSucceedForCutover": {
          "type": "boolean"
        },
        "parameters": {
          "type": "map",
          "key": {},
          "value": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "parameterName",
                "parameterType"
              ],
              "members": {
                "parameterName": {},
                "parameterType": {}
              }
            }
          }
        },
        "ssmDocumentName": {},
        "timeoutSeconds": {
          "type": "integer"
        }
      }
    },
    "S1x": {
      "type": "structure",
      "required": [
        "launchConfigurationTemplateID"
      ],
      "members": {
        "arn": {},
        "launchConfigurationTemplateID": {},
        "postLaunchActions": {
          "shape": "S1j"
        },
        "tags": {
          "shape": "S1e"
        }
      }
    },
    "S23": {
      "type": "list",
      "member": {}
    },
    "S26": {
      "type": "structure",
      "required": [
        "replicationConfigurationTemplateID"
      ],
      "members": {
        "arn": {},
        "associateDefaultSecurityGroup": {
          "type": "boolean"
        },
        "bandwidthThrottling": {
          "type": "long"
        },
        "createPublicIP": {
          "type": "boolean"
        },
        "dataPlaneRouting": {},
        "defaultLargeStagingDiskType": {},
        "ebsEncryption": {},
        "ebsEncryptionKeyArn": {},
        "replicationConfigurationTemplateID": {},
        "replicationServerInstanceType": {},
        "replicationServersSecurityGroupsIDs": {
          "shape": "S23"
        },
        "stagingAreaSubnetId": {},
        "stagingAreaTags": {
          "shape": "S1e"
        },
        "tags": {
          "shape": "S1e"
        },
        "useDedicatedReplicationServer": {
          "type": "boolean"
        }
      }
    },
    "S2t": {
      "type": "structure",
      "required": [
        "jobID"
      ],
      "members": {
        "arn": {},
        "creationDateTime": {},
        "endDateTime": {},
        "initiatedBy": {},
        "jobID": {},
        "participatingServers": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "sourceServerID"
            ],
            "members": {
              "launchStatus": {},
              "launchedEc2InstanceID": {},
              "postLaunchActionsStatus": {
                "type": "structure",
                "members": {
                  "postLaunchActionsLaunchStatusList": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "executionID": {},
                        "executionStatus": {},
                        "failureReason": {},
                        "ssmDocument": {
                          "shape": "S1o"
                        },
                        "ssmDocumentType": {}
                      }
                    }
                  },
                  "ssmAgentDiscoveryDatetime": {}
                }
              },
              "sourceServerID": {}
            }
          }
        },
        "status": {},
        "tags": {
          "shape": "S1e"
        },
        "type": {}
      }
    },
    "S3r": {
      "type": "structure",
      "members": {
        "bootMode": {},
        "copyPrivateIp": {
          "type": "boolean"
        },
        "copyTags": {
          "type": "boolean"
        },
        "ec2LaunchTemplateID": {},
        "launchDisposition": {},
        "licensing": {
          "shape": "S3u"
        },
        "name": {},
        "postLaunchActions": {
          "shape": "S1j"
        },
        "sourceServerID": {},
        "targetInstanceTypeRightSizingMethod": {}
      }
    },
    "S3u": {
      "type": "structure",
      "members": {
        "osByol": {
          "type": "boolean"
        }
      }
    },
    "S3y": {
      "type": "structure",
      "members": {
        "associateDefaultSecurityGroup": {
          "type": "boolean"
        },
        "bandwidthThrottling": {
          "type": "long"
        },
        "createPublicIP": {
          "type": "boolean"
        },
        "dataPlaneRouting": {},
        "defaultLargeStagingDiskType": {},
        "ebsEncryption": {},
        "ebsEncryptionKeyArn": {},
        "name": {},
        "replicatedDisks": {
          "shape": "S3z"
        },
        "replicationServerInstanceType": {},
        "replicationServersSecurityGroupsIDs": {
          "shape": "S23"
        },
        "sourceServerID": {},
        "stagingAreaSubnetId": {},
        "stagingAreaTags": {
          "shape": "S1e"
        },
        "useDedicatedReplicationServer": {
          "type": "boolean"
        }
      }
    },
    "S3z": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "deviceName": {},
          "iops": {
            "type": "long"
          },
          "isBootDisk": {
            "type": "boolean"
          },
          "stagingDiskType": {},
          "throughput": {
            "type": "long"
          }
        }
      }
    }
  }
}