{
  "pagination": {
    "DescribeCertificates": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Certificates"
    },
    "DescribeDBClusterBacktracks": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBClusterBacktracks"
    },
    "DescribeDBClusterEndpoints": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBClusterEndpoints"
    },
    "DescribeDBClusterParameterGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBClusterParameterGroups"
    },
    "DescribeDBClusterParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Parameters"
    },
    "DescribeDBClusterSnapshots": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBClusterSnapshots"
    },
    "DescribeDBClusters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBClusters"
    },
    "DescribeDBEngineVersions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBEngineVersions"
    },
    "DescribeDBInstanceAutomatedBackups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBInstanceAutomatedBackups"
    },
    "DescribeDBInstances": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBInstances"
    },
    "DescribeDBLogFiles": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DescribeDBLogFiles"
    },
    "DescribeDBParameterGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBParameterGroups"
    },
    "DescribeDBParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Parameters"
    },
    "DescribeDBProxies": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBProxies"
    },
    "DescribeDBProxyEndpoints": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBProxyEndpoints"
    },
    "DescribeDBProxyTargetGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "TargetGroups"
    },
    "DescribeDBProxyTargets": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Targets"
    },
    "DescribeDBSecurityGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSecurityGroups"
    },
    "DescribeDBSnapshots": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSnapshots"
    },
    "DescribeDBSubnetGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSubnetGroups"
    },
    "DescribeEngineDefaultParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "EngineDefaults.Marker",
      "result_key": "EngineDefaults.Parameters"
    },
    "DescribeEventSubscriptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "EventSubscriptionsList"
    },
    "DescribeEvents": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Events"
    },
    "DescribeExportTasks": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ExportTasks"
    },
    "DescribeGlobalClusters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "GlobalClusters"
    },
    "DescribeOptionGroupOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OptionGroupOptions"
    },
    "DescribeOptionGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OptionGroupsList"
    },
    "DescribeOrderableDBInstanceOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OrderableDBInstanceOptions"
    },
    "DescribePendingMaintenanceActions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "PendingMaintenanceActions"
    },
    "DescribeReservedDBInstances": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedDBInstances"
    },
    "DescribeReservedDBInstancesOfferings": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedDBInstancesOfferings"
    },
    "DescribeSourceRegions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "SourceRegions"
    },
    "DownloadDBLogFilePortion": {
      "input_token": "Marker",
      "limit_key": "NumberOfLines",
      "more_results": "AdditionalDataPending",
      "output_token": "Marker",
      "result_key": "LogFileData"
    },
    "ListTagsForResource": {
      "result_key": "TagList"
    }
  }
}