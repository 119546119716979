{
  "pagination": {
    "ListAccesses": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "non_aggregate_keys": [
        "ServerId"
      ],
      "output_token": "NextToken",
      "result_key": "Accesses"
    },
    "ListAgreements": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Agreements"
    },
    "ListCertificates": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Certificates"
    },
    "ListConnectors": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Connectors"
    },
    "ListExecutions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "non_aggregate_keys": [
        "WorkflowId"
      ],
      "output_token": "NextToken",
      "result_key": "Executions"
    },
    "ListProfiles": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Profiles"
    },
    "ListSecurityPolicies": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SecurityPolicyNames"
    },
    "ListServers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Servers"
    },
    "ListTagsForResource": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "non_aggregate_keys": [
        "Arn"
      ],
      "output_token": "NextToken",
      "result_key": "Tags"
    },
    "ListUsers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "non_aggregate_keys": [
        "ServerId"
      ],
      "output_token": "NextToken",
      "result_key": "Users"
    },
    "ListWorkflows": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Workflows"
    }
  }
}