{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-09-30",
    "endpointPrefix": "cloudcontrolapi",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceAbbreviation": "CloudControlApi",
    "serviceFullName": "AWS Cloud Control API",
    "serviceId": "CloudControl",
    "signatureVersion": "v4",
    "signingName": "cloudcontrolapi",
    "targetPrefix": "CloudApiService",
    "uid": "cloudcontrol-2021-09-30"
  },
  "operations": {
    "CancelResourceRequest": {
      "input": {
        "type": "structure",
        "required": [
          "RequestToken"
        ],
        "members": {
          "RequestToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProgressEvent": {
            "shape": "S4"
          }
        }
      },
      "idempotent": true
    },
    "CreateResource": {
      "input": {
        "type": "structure",
        "required": [
          "TypeName",
          "DesiredState"
        ],
        "members": {
          "TypeName": {},
          "TypeVersionId": {},
          "RoleArn": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DesiredState": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProgressEvent": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteResource": {
      "input": {
        "type": "structure",
        "required": [
          "TypeName",
          "Identifier"
        ],
        "members": {
          "TypeName": {},
          "TypeVersionId": {},
          "RoleArn": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "Identifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProgressEvent": {
            "shape": "S4"
          }
        }
      }
    },
    "GetResource": {
      "input": {
        "type": "structure",
        "required": [
          "TypeName",
          "Identifier"
        ],
        "members": {
          "TypeName": {},
          "TypeVersionId": {},
          "RoleArn": {},
          "Identifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TypeName": {},
          "ResourceDescription": {
            "shape": "Sm"
          }
        }
      }
    },
    "GetResourceRequestStatus": {
      "input": {
        "type": "structure",
        "required": [
          "RequestToken"
        ],
        "members": {
          "RequestToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProgressEvent": {
            "shape": "S4"
          }
        }
      }
    },
    "ListResourceRequests": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "ResourceRequestStatusFilter": {
            "type": "structure",
            "members": {
              "Operations": {
                "type": "list",
                "member": {}
              },
              "OperationStatuses": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceRequestStatusSummaries": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListResources": {
      "input": {
        "type": "structure",
        "required": [
          "TypeName"
        ],
        "members": {
          "TypeName": {},
          "TypeVersionId": {},
          "RoleArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "ResourceModel": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TypeName": {},
          "ResourceDescriptions": {
            "type": "list",
            "member": {
              "shape": "Sm"
            }
          },
          "NextToken": {}
        }
      }
    },
    "UpdateResource": {
      "input": {
        "type": "structure",
        "required": [
          "TypeName",
          "Identifier",
          "PatchDocument"
        ],
        "members": {
          "TypeName": {},
          "TypeVersionId": {},
          "RoleArn": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "Identifier": {},
          "PatchDocument": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProgressEvent": {
            "shape": "S4"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "TypeName": {},
        "Identifier": {},
        "RequestToken": {},
        "Operation": {},
        "OperationStatus": {},
        "EventTime": {
          "type": "timestamp"
        },
        "ResourceModel": {
          "shape": "Sa"
        },
        "StatusMessage": {},
        "ErrorCode": {},
        "RetryAfter": {
          "type": "timestamp"
        }
      }
    },
    "Sa": {
      "type": "string",
      "sensitive": true
    },
    "Sm": {
      "type": "structure",
      "members": {
        "Identifier": {},
        "Properties": {
          "shape": "Sa"
        }
      }
    }
  }
}