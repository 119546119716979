{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-07-20",
    "endpointPrefix": "proton",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceFullName": "AWS Proton",
    "serviceId": "Proton",
    "signatureVersion": "v4",
    "signingName": "proton",
    "targetPrefix": "AwsProton20200720",
    "uid": "proton-2020-07-20"
  },
  "operations": {
    "AcceptEnvironmentAccountConnection": {
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environmentAccountConnection"
        ],
        "members": {
          "environmentAccountConnection": {
            "shape": "S4"
          }
        }
      },
      "idempotent": true
    },
    "CancelComponentDeployment": {
      "input": {
        "type": "structure",
        "required": [
          "componentName"
        ],
        "members": {
          "componentName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "component"
        ],
        "members": {
          "component": {
            "shape": "Sd"
          }
        }
      }
    },
    "CancelEnvironmentDeployment": {
      "input": {
        "type": "structure",
        "required": [
          "environmentName"
        ],
        "members": {
          "environmentName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environment"
        ],
        "members": {
          "environment": {
            "shape": "Sl"
          }
        }
      }
    },
    "CancelServiceInstanceDeployment": {
      "input": {
        "type": "structure",
        "required": [
          "serviceInstanceName",
          "serviceName"
        ],
        "members": {
          "serviceInstanceName": {},
          "serviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "serviceInstance"
        ],
        "members": {
          "serviceInstance": {
            "shape": "Sw"
          }
        }
      }
    },
    "CancelServicePipelineDeployment": {
      "input": {
        "type": "structure",
        "required": [
          "serviceName"
        ],
        "members": {
          "serviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "pipeline"
        ],
        "members": {
          "pipeline": {
            "shape": "S10"
          }
        }
      }
    },
    "CreateComponent": {
      "input": {
        "type": "structure",
        "required": [
          "manifest",
          "name",
          "templateFile"
        ],
        "members": {
          "description": {
            "shape": "Sh"
          },
          "environmentName": {},
          "manifest": {
            "type": "string",
            "sensitive": true
          },
          "name": {},
          "serviceInstanceName": {},
          "serviceName": {},
          "serviceSpec": {
            "shape": "Si"
          },
          "tags": {
            "shape": "S13"
          },
          "templateFile": {
            "shape": "S17"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "component"
        ],
        "members": {
          "component": {
            "shape": "Sd"
          }
        }
      },
      "idempotent": true
    },
    "CreateEnvironment": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "spec",
          "templateMajorVersion",
          "templateName"
        ],
        "members": {
          "componentRoleArn": {},
          "description": {
            "shape": "Sh"
          },
          "environmentAccountConnectionId": {},
          "name": {},
          "protonServiceRoleArn": {},
          "provisioningRepository": {
            "shape": "S1a"
          },
          "spec": {
            "shape": "Si"
          },
          "tags": {
            "shape": "S13"
          },
          "templateMajorVersion": {},
          "templateMinorVersion": {},
          "templateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environment"
        ],
        "members": {
          "environment": {
            "shape": "Sl"
          }
        }
      },
      "idempotent": true
    },
    "CreateEnvironmentAccountConnection": {
      "input": {
        "type": "structure",
        "required": [
          "environmentName",
          "managementAccountId",
          "roleArn"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "componentRoleArn": {},
          "environmentName": {},
          "managementAccountId": {},
          "roleArn": {},
          "tags": {
            "shape": "S13"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environmentAccountConnection"
        ],
        "members": {
          "environmentAccountConnection": {
            "shape": "S4"
          }
        }
      },
      "idempotent": true
    },
    "CreateEnvironmentTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "description": {
            "shape": "Sh"
          },
          "displayName": {
            "shape": "S1g"
          },
          "encryptionKey": {},
          "name": {},
          "provisioning": {},
          "tags": {
            "shape": "S13"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environmentTemplate"
        ],
        "members": {
          "environmentTemplate": {
            "shape": "S1i"
          }
        }
      },
      "idempotent": true
    },
    "CreateEnvironmentTemplateVersion": {
      "input": {
        "type": "structure",
        "required": [
          "source",
          "templateName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {
            "shape": "Sh"
          },
          "majorVersion": {},
          "source": {
            "shape": "S1m"
          },
          "tags": {
            "shape": "S13"
          },
          "templateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environmentTemplateVersion"
        ],
        "members": {
          "environmentTemplateVersion": {
            "shape": "S1r"
          }
        }
      },
      "idempotent": true
    },
    "CreateRepository": {
      "input": {
        "type": "structure",
        "required": [
          "connectionArn",
          "name",
          "provider"
        ],
        "members": {
          "connectionArn": {},
          "encryptionKey": {},
          "name": {},
          "provider": {},
          "tags": {
            "shape": "S13"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "repository"
        ],
        "members": {
          "repository": {
            "shape": "S1x"
          }
        }
      },
      "idempotent": true
    },
    "CreateService": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "spec",
          "templateMajorVersion",
          "templateName"
        ],
        "members": {
          "branchName": {},
          "description": {
            "shape": "Sh"
          },
          "name": {},
          "repositoryConnectionArn": {},
          "repositoryId": {},
          "spec": {
            "shape": "Si"
          },
          "tags": {
            "shape": "S13"
          },
          "templateMajorVersion": {},
          "templateMinorVersion": {},
          "templateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "service"
        ],
        "members": {
          "service": {
            "shape": "S21"
          }
        }
      },
      "idempotent": true
    },
    "CreateServiceTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "description": {
            "shape": "Sh"
          },
          "displayName": {
            "shape": "S1g"
          },
          "encryptionKey": {},
          "name": {},
          "pipelineProvisioning": {},
          "tags": {
            "shape": "S13"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "serviceTemplate"
        ],
        "members": {
          "serviceTemplate": {
            "shape": "S26"
          }
        }
      },
      "idempotent": true
    },
    "CreateServiceTemplateVersion": {
      "input": {
        "type": "structure",
        "required": [
          "compatibleEnvironmentTemplates",
          "source",
          "templateName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "compatibleEnvironmentTemplates": {
            "shape": "S29"
          },
          "description": {
            "shape": "Sh"
          },
          "majorVersion": {},
          "source": {
            "shape": "S1m"
          },
          "supportedComponentSources": {
            "shape": "S2b"
          },
          "tags": {
            "shape": "S13"
          },
          "templateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "serviceTemplateVersion"
        ],
        "members": {
          "serviceTemplateVersion": {
            "shape": "S2e"
          }
        }
      },
      "idempotent": true
    },
    "CreateTemplateSyncConfig": {
      "input": {
        "type": "structure",
        "required": [
          "branch",
          "repositoryName",
          "repositoryProvider",
          "templateName",
          "templateType"
        ],
        "members": {
          "branch": {},
          "repositoryName": {},
          "repositoryProvider": {},
          "subdirectory": {},
          "templateName": {},
          "templateType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templateSyncConfig": {
            "shape": "S2m"
          }
        }
      },
      "idempotent": true
    },
    "DeleteComponent": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "component": {
            "shape": "Sd"
          }
        }
      },
      "idempotent": true
    },
    "DeleteEnvironment": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environment": {
            "shape": "Sl"
          }
        }
      },
      "idempotent": true
    },
    "DeleteEnvironmentAccountConnection": {
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environmentAccountConnection": {
            "shape": "S4"
          }
        }
      },
      "idempotent": true
    },
    "DeleteEnvironmentTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environmentTemplate": {
            "shape": "S1i"
          }
        }
      },
      "idempotent": true
    },
    "DeleteEnvironmentTemplateVersion": {
      "input": {
        "type": "structure",
        "required": [
          "majorVersion",
          "minorVersion",
          "templateName"
        ],
        "members": {
          "majorVersion": {},
          "minorVersion": {},
          "templateName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environmentTemplateVersion": {
            "shape": "S1r"
          }
        }
      },
      "idempotent": true
    },
    "DeleteRepository": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "provider"
        ],
        "members": {
          "name": {},
          "provider": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repository": {
            "shape": "S1x"
          }
        }
      },
      "idempotent": true
    },
    "DeleteService": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "service": {
            "shape": "S21"
          }
        }
      },
      "idempotent": true
    },
    "DeleteServiceTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "serviceTemplate": {
            "shape": "S26"
          }
        }
      },
      "idempotent": true
    },
    "DeleteServiceTemplateVersion": {
      "input": {
        "type": "structure",
        "required": [
          "majorVersion",
          "minorVersion",
          "templateName"
        ],
        "members": {
          "majorVersion": {},
          "minorVersion": {},
          "templateName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "serviceTemplateVersion": {
            "shape": "S2e"
          }
        }
      },
      "idempotent": true
    },
    "DeleteTemplateSyncConfig": {
      "input": {
        "type": "structure",
        "required": [
          "templateName",
          "templateType"
        ],
        "members": {
          "templateName": {},
          "templateType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templateSyncConfig": {
            "shape": "S2m"
          }
        }
      },
      "idempotent": true
    },
    "GetAccountSettings": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "accountSettings": {
            "shape": "S39"
          }
        }
      }
    },
    "GetComponent": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "component": {
            "shape": "Sd"
          }
        }
      }
    },
    "GetEnvironment": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environment"
        ],
        "members": {
          "environment": {
            "shape": "Sl"
          }
        }
      }
    },
    "GetEnvironmentAccountConnection": {
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environmentAccountConnection"
        ],
        "members": {
          "environmentAccountConnection": {
            "shape": "S4"
          }
        }
      }
    },
    "GetEnvironmentTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environmentTemplate"
        ],
        "members": {
          "environmentTemplate": {
            "shape": "S1i"
          }
        }
      }
    },
    "GetEnvironmentTemplateVersion": {
      "input": {
        "type": "structure",
        "required": [
          "majorVersion",
          "minorVersion",
          "templateName"
        ],
        "members": {
          "majorVersion": {},
          "minorVersion": {},
          "templateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environmentTemplateVersion"
        ],
        "members": {
          "environmentTemplateVersion": {
            "shape": "S1r"
          }
        }
      }
    },
    "GetRepository": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "provider"
        ],
        "members": {
          "name": {},
          "provider": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "repository"
        ],
        "members": {
          "repository": {
            "shape": "S1x"
          }
        }
      }
    },
    "GetRepositorySyncStatus": {
      "input": {
        "type": "structure",
        "required": [
          "branch",
          "repositoryName",
          "repositoryProvider",
          "syncType"
        ],
        "members": {
          "branch": {},
          "repositoryName": {},
          "repositoryProvider": {},
          "syncType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "latestSync": {
            "type": "structure",
            "required": [
              "events",
              "startedAt",
              "status"
            ],
            "members": {
              "events": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "event",
                    "time",
                    "type"
                  ],
                  "members": {
                    "event": {},
                    "externalId": {},
                    "time": {
                      "type": "timestamp"
                    },
                    "type": {}
                  }
                }
              },
              "startedAt": {
                "type": "timestamp"
              },
              "status": {}
            }
          }
        }
      }
    },
    "GetService": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "service": {
            "shape": "S21"
          }
        }
      }
    },
    "GetServiceInstance": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "serviceName"
        ],
        "members": {
          "name": {},
          "serviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "serviceInstance"
        ],
        "members": {
          "serviceInstance": {
            "shape": "Sw"
          }
        }
      }
    },
    "GetServiceTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "serviceTemplate"
        ],
        "members": {
          "serviceTemplate": {
            "shape": "S26"
          }
        }
      }
    },
    "GetServiceTemplateVersion": {
      "input": {
        "type": "structure",
        "required": [
          "majorVersion",
          "minorVersion",
          "templateName"
        ],
        "members": {
          "majorVersion": {},
          "minorVersion": {},
          "templateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "serviceTemplateVersion"
        ],
        "members": {
          "serviceTemplateVersion": {
            "shape": "S2e"
          }
        }
      }
    },
    "GetTemplateSyncConfig": {
      "input": {
        "type": "structure",
        "required": [
          "templateName",
          "templateType"
        ],
        "members": {
          "templateName": {},
          "templateType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templateSyncConfig": {
            "shape": "S2m"
          }
        }
      }
    },
    "GetTemplateSyncStatus": {
      "input": {
        "type": "structure",
        "required": [
          "templateName",
          "templateType",
          "templateVersion"
        ],
        "members": {
          "templateName": {},
          "templateType": {},
          "templateVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "desiredState": {
            "shape": "S47"
          },
          "latestSuccessfulSync": {
            "shape": "S49"
          },
          "latestSync": {
            "shape": "S49"
          }
        }
      }
    },
    "ListComponentOutputs": {
      "input": {
        "type": "structure",
        "required": [
          "componentName"
        ],
        "members": {
          "componentName": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "outputs"
        ],
        "members": {
          "nextToken": {},
          "outputs": {
            "shape": "S4g"
          }
        }
      }
    },
    "ListComponentProvisionedResources": {
      "input": {
        "type": "structure",
        "required": [
          "componentName"
        ],
        "members": {
          "componentName": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "provisionedResources"
        ],
        "members": {
          "nextToken": {},
          "provisionedResources": {
            "shape": "S4m"
          }
        }
      }
    },
    "ListComponents": {
      "input": {
        "type": "structure",
        "members": {
          "environmentName": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "serviceInstanceName": {},
          "serviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "components"
        ],
        "members": {
          "components": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "deploymentStatus",
                "environmentName",
                "lastModifiedAt",
                "name"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "deploymentStatus": {},
                "deploymentStatusMessage": {
                  "shape": "Sg"
                },
                "environmentName": {},
                "lastDeploymentAttemptedAt": {
                  "type": "timestamp"
                },
                "lastDeploymentSucceededAt": {
                  "type": "timestamp"
                },
                "lastModifiedAt": {
                  "type": "timestamp"
                },
                "name": {},
                "serviceInstanceName": {},
                "serviceName": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListEnvironmentAccountConnections": {
      "input": {
        "type": "structure",
        "required": [
          "requestedBy"
        ],
        "members": {
          "environmentName": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "requestedBy": {},
          "statuses": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environmentAccountConnections"
        ],
        "members": {
          "environmentAccountConnections": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "environmentAccountId",
                "environmentName",
                "id",
                "lastModifiedAt",
                "managementAccountId",
                "requestedAt",
                "roleArn",
                "status"
              ],
              "members": {
                "arn": {},
                "componentRoleArn": {},
                "environmentAccountId": {},
                "environmentName": {},
                "id": {},
                "lastModifiedAt": {
                  "type": "timestamp"
                },
                "managementAccountId": {},
                "requestedAt": {
                  "type": "timestamp"
                },
                "roleArn": {},
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListEnvironmentOutputs": {
      "input": {
        "type": "structure",
        "required": [
          "environmentName"
        ],
        "members": {
          "environmentName": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "outputs"
        ],
        "members": {
          "nextToken": {},
          "outputs": {
            "shape": "S4g"
          }
        }
      }
    },
    "ListEnvironmentProvisionedResources": {
      "input": {
        "type": "structure",
        "required": [
          "environmentName"
        ],
        "members": {
          "environmentName": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "provisionedResources"
        ],
        "members": {
          "nextToken": {},
          "provisionedResources": {
            "shape": "S4m"
          }
        }
      }
    },
    "ListEnvironmentTemplateVersions": {
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "majorVersion": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "templateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "templateVersions"
        ],
        "members": {
          "nextToken": {},
          "templateVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "lastModifiedAt",
                "majorVersion",
                "minorVersion",
                "status",
                "templateName"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "description": {
                  "shape": "Sh"
                },
                "lastModifiedAt": {
                  "type": "timestamp"
                },
                "majorVersion": {},
                "minorVersion": {},
                "recommendedMinorVersion": {},
                "status": {},
                "statusMessage": {
                  "shape": "Sg"
                },
                "templateName": {}
              }
            }
          }
        }
      }
    },
    "ListEnvironmentTemplates": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "templates"
        ],
        "members": {
          "nextToken": {},
          "templates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "lastModifiedAt",
                "name"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "description": {
                  "shape": "Sh"
                },
                "displayName": {
                  "shape": "S1g"
                },
                "lastModifiedAt": {
                  "type": "timestamp"
                },
                "name": {},
                "provisioning": {},
                "recommendedVersion": {}
              }
            }
          }
        }
      }
    },
    "ListEnvironments": {
      "input": {
        "type": "structure",
        "members": {
          "environmentTemplates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "majorVersion",
                "templateName"
              ],
              "members": {
                "majorVersion": {},
                "templateName": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environments"
        ],
        "members": {
          "environments": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "deploymentStatus",
                "lastDeploymentAttemptedAt",
                "lastDeploymentSucceededAt",
                "name",
                "templateMajorVersion",
                "templateMinorVersion",
                "templateName"
              ],
              "members": {
                "arn": {},
                "componentRoleArn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "deploymentStatus": {},
                "deploymentStatusMessage": {
                  "shape": "Sg"
                },
                "description": {
                  "shape": "Sh"
                },
                "environmentAccountConnectionId": {},
                "environmentAccountId": {},
                "lastDeploymentAttemptedAt": {
                  "type": "timestamp"
                },
                "lastDeploymentSucceededAt": {
                  "type": "timestamp"
                },
                "name": {},
                "protonServiceRoleArn": {},
                "provisioning": {},
                "templateMajorVersion": {},
                "templateMinorVersion": {},
                "templateName": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRepositories": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "repositories"
        ],
        "members": {
          "nextToken": {},
          "repositories": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "name",
                "provider"
              ],
              "members": {
                "arn": {},
                "name": {},
                "provider": {}
              }
            }
          }
        }
      }
    },
    "ListRepositorySyncDefinitions": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "repositoryProvider",
          "syncType"
        ],
        "members": {
          "nextToken": {},
          "repositoryName": {},
          "repositoryProvider": {},
          "syncType": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "syncDefinitions"
        ],
        "members": {
          "nextToken": {},
          "syncDefinitions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "branch",
                "directory",
                "parent",
                "target"
              ],
              "members": {
                "branch": {},
                "directory": {},
                "parent": {},
                "target": {}
              }
            }
          }
        }
      }
    },
    "ListServiceInstanceOutputs": {
      "input": {
        "type": "structure",
        "required": [
          "serviceInstanceName",
          "serviceName"
        ],
        "members": {
          "nextToken": {},
          "serviceInstanceName": {},
          "serviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "outputs"
        ],
        "members": {
          "nextToken": {},
          "outputs": {
            "shape": "S4g"
          }
        }
      }
    },
    "ListServiceInstanceProvisionedResources": {
      "input": {
        "type": "structure",
        "required": [
          "serviceInstanceName",
          "serviceName"
        ],
        "members": {
          "nextToken": {},
          "serviceInstanceName": {},
          "serviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "provisionedResources"
        ],
        "members": {
          "nextToken": {},
          "provisionedResources": {
            "shape": "S4m"
          }
        }
      }
    },
    "ListServiceInstances": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "serviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "serviceInstances"
        ],
        "members": {
          "nextToken": {},
          "serviceInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "deploymentStatus",
                "environmentName",
                "lastDeploymentAttemptedAt",
                "lastDeploymentSucceededAt",
                "name",
                "serviceName",
                "templateMajorVersion",
                "templateMinorVersion",
                "templateName"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "deploymentStatus": {},
                "deploymentStatusMessage": {
                  "shape": "Sg"
                },
                "environmentName": {},
                "lastDeploymentAttemptedAt": {
                  "type": "timestamp"
                },
                "lastDeploymentSucceededAt": {
                  "type": "timestamp"
                },
                "name": {},
                "serviceName": {},
                "templateMajorVersion": {},
                "templateMinorVersion": {},
                "templateName": {}
              }
            }
          }
        }
      }
    },
    "ListServicePipelineOutputs": {
      "input": {
        "type": "structure",
        "required": [
          "serviceName"
        ],
        "members": {
          "nextToken": {},
          "serviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "outputs"
        ],
        "members": {
          "nextToken": {},
          "outputs": {
            "shape": "S4g"
          }
        }
      }
    },
    "ListServicePipelineProvisionedResources": {
      "input": {
        "type": "structure",
        "required": [
          "serviceName"
        ],
        "members": {
          "nextToken": {},
          "serviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "provisionedResources"
        ],
        "members": {
          "nextToken": {},
          "provisionedResources": {
            "shape": "S4m"
          }
        }
      }
    },
    "ListServiceTemplateVersions": {
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "majorVersion": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "templateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "templateVersions"
        ],
        "members": {
          "nextToken": {},
          "templateVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "lastModifiedAt",
                "majorVersion",
                "minorVersion",
                "status",
                "templateName"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "description": {
                  "shape": "Sh"
                },
                "lastModifiedAt": {
                  "type": "timestamp"
                },
                "majorVersion": {},
                "minorVersion": {},
                "recommendedMinorVersion": {},
                "status": {},
                "statusMessage": {
                  "shape": "Sg"
                },
                "templateName": {}
              }
            }
          }
        }
      }
    },
    "ListServiceTemplates": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "templates"
        ],
        "members": {
          "nextToken": {},
          "templates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "lastModifiedAt",
                "name"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "description": {
                  "shape": "Sh"
                },
                "displayName": {
                  "shape": "S1g"
                },
                "lastModifiedAt": {
                  "type": "timestamp"
                },
                "name": {},
                "pipelineProvisioning": {},
                "recommendedVersion": {}
              }
            }
          }
        }
      }
    },
    "ListServices": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "services"
        ],
        "members": {
          "nextToken": {},
          "services": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "lastModifiedAt",
                "name",
                "status",
                "templateName"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "description": {
                  "shape": "Sh"
                },
                "lastModifiedAt": {
                  "type": "timestamp"
                },
                "name": {},
                "status": {},
                "statusMessage": {
                  "shape": "Sg"
                },
                "templateName": {}
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tags"
        ],
        "members": {
          "nextToken": {},
          "tags": {
            "shape": "S13"
          }
        }
      }
    },
    "NotifyResourceDeploymentStatusChange": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "status"
        ],
        "members": {
          "deploymentId": {},
          "outputs": {
            "type": "list",
            "member": {
              "shape": "S4h"
            }
          },
          "resourceArn": {},
          "status": {},
          "statusMessage": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RejectEnvironmentAccountConnection": {
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environmentAccountConnection"
        ],
        "members": {
          "environmentAccountConnection": {
            "shape": "S4"
          }
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "S13"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateAccountSettings": {
      "input": {
        "type": "structure",
        "members": {
          "deletePipelineProvisioningRepository": {
            "type": "boolean"
          },
          "pipelineProvisioningRepository": {
            "shape": "S1a"
          },
          "pipelineServiceRoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "accountSettings"
        ],
        "members": {
          "accountSettings": {
            "shape": "S39"
          }
        }
      }
    },
    "UpdateComponent": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentType",
          "name"
        ],
        "members": {
          "deploymentType": {},
          "description": {
            "shape": "Sh"
          },
          "name": {},
          "serviceInstanceName": {},
          "serviceName": {},
          "serviceSpec": {
            "shape": "Si"
          },
          "templateFile": {
            "shape": "S17"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "component"
        ],
        "members": {
          "component": {
            "shape": "Sd"
          }
        }
      }
    },
    "UpdateEnvironment": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentType",
          "name"
        ],
        "members": {
          "componentRoleArn": {},
          "deploymentType": {},
          "description": {
            "shape": "Sh"
          },
          "environmentAccountConnectionId": {},
          "name": {},
          "protonServiceRoleArn": {},
          "provisioningRepository": {
            "shape": "S1a"
          },
          "spec": {
            "shape": "Si"
          },
          "templateMajorVersion": {},
          "templateMinorVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environment"
        ],
        "members": {
          "environment": {
            "shape": "Sl"
          }
        }
      }
    },
    "UpdateEnvironmentAccountConnection": {
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "componentRoleArn": {},
          "id": {},
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environmentAccountConnection"
        ],
        "members": {
          "environmentAccountConnection": {
            "shape": "S4"
          }
        }
      },
      "idempotent": true
    },
    "UpdateEnvironmentTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "description": {
            "shape": "Sh"
          },
          "displayName": {
            "shape": "S1g"
          },
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environmentTemplate"
        ],
        "members": {
          "environmentTemplate": {
            "shape": "S1i"
          }
        }
      }
    },
    "UpdateEnvironmentTemplateVersion": {
      "input": {
        "type": "structure",
        "required": [
          "majorVersion",
          "minorVersion",
          "templateName"
        ],
        "members": {
          "description": {
            "shape": "Sh"
          },
          "majorVersion": {},
          "minorVersion": {},
          "status": {},
          "templateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "environmentTemplateVersion"
        ],
        "members": {
          "environmentTemplateVersion": {
            "shape": "S1r"
          }
        }
      }
    },
    "UpdateService": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "description": {
            "shape": "Sh"
          },
          "name": {},
          "spec": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "service"
        ],
        "members": {
          "service": {
            "shape": "S21"
          }
        }
      }
    },
    "UpdateServiceInstance": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentType",
          "name",
          "serviceName"
        ],
        "members": {
          "deploymentType": {},
          "name": {},
          "serviceName": {},
          "spec": {
            "shape": "Si"
          },
          "templateMajorVersion": {},
          "templateMinorVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "serviceInstance"
        ],
        "members": {
          "serviceInstance": {
            "shape": "Sw"
          }
        }
      }
    },
    "UpdateServicePipeline": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentType",
          "serviceName",
          "spec"
        ],
        "members": {
          "deploymentType": {},
          "serviceName": {},
          "spec": {
            "shape": "Si"
          },
          "templateMajorVersion": {},
          "templateMinorVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "pipeline"
        ],
        "members": {
          "pipeline": {
            "shape": "S10"
          }
        }
      }
    },
    "UpdateServiceTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "description": {
            "shape": "Sh"
          },
          "displayName": {
            "shape": "S1g"
          },
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "serviceTemplate"
        ],
        "members": {
          "serviceTemplate": {
            "shape": "S26"
          }
        }
      }
    },
    "UpdateServiceTemplateVersion": {
      "input": {
        "type": "structure",
        "required": [
          "majorVersion",
          "minorVersion",
          "templateName"
        ],
        "members": {
          "compatibleEnvironmentTemplates": {
            "shape": "S29"
          },
          "description": {
            "shape": "Sh"
          },
          "majorVersion": {},
          "minorVersion": {},
          "status": {},
          "supportedComponentSources": {
            "shape": "S2b"
          },
          "templateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "serviceTemplateVersion"
        ],
        "members": {
          "serviceTemplateVersion": {
            "shape": "S2e"
          }
        }
      }
    },
    "UpdateTemplateSyncConfig": {
      "input": {
        "type": "structure",
        "required": [
          "branch",
          "repositoryName",
          "repositoryProvider",
          "templateName",
          "templateType"
        ],
        "members": {
          "branch": {},
          "repositoryName": {},
          "repositoryProvider": {},
          "subdirectory": {},
          "templateName": {},
          "templateType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templateSyncConfig": {
            "shape": "S2m"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "required": [
        "arn",
        "environmentAccountId",
        "environmentName",
        "id",
        "lastModifiedAt",
        "managementAccountId",
        "requestedAt",
        "roleArn",
        "status"
      ],
      "members": {
        "arn": {},
        "componentRoleArn": {},
        "environmentAccountId": {},
        "environmentName": {},
        "id": {},
        "lastModifiedAt": {
          "type": "timestamp"
        },
        "managementAccountId": {},
        "requestedAt": {
          "type": "timestamp"
        },
        "roleArn": {},
        "status": {}
      }
    },
    "Sd": {
      "type": "structure",
      "required": [
        "arn",
        "createdAt",
        "deploymentStatus",
        "environmentName",
        "lastModifiedAt",
        "name"
      ],
      "members": {
        "arn": {},
        "createdAt": {
          "type": "timestamp"
        },
        "deploymentStatus": {},
        "deploymentStatusMessage": {
          "shape": "Sg"
        },
        "description": {
          "shape": "Sh"
        },
        "environmentName": {},
        "lastDeploymentAttemptedAt": {
          "type": "timestamp"
        },
        "lastDeploymentSucceededAt": {
          "type": "timestamp"
        },
        "lastModifiedAt": {
          "type": "timestamp"
        },
        "name": {},
        "serviceInstanceName": {},
        "serviceName": {},
        "serviceSpec": {
          "shape": "Si"
        }
      }
    },
    "Sg": {
      "type": "string",
      "sensitive": true
    },
    "Sh": {
      "type": "string",
      "sensitive": true
    },
    "Si": {
      "type": "string",
      "sensitive": true
    },
    "Sl": {
      "type": "structure",
      "required": [
        "arn",
        "createdAt",
        "deploymentStatus",
        "lastDeploymentAttemptedAt",
        "lastDeploymentSucceededAt",
        "name",
        "templateMajorVersion",
        "templateMinorVersion",
        "templateName"
      ],
      "members": {
        "arn": {},
        "componentRoleArn": {},
        "createdAt": {
          "type": "timestamp"
        },
        "deploymentStatus": {},
        "deploymentStatusMessage": {
          "shape": "Sg"
        },
        "description": {
          "shape": "Sh"
        },
        "environmentAccountConnectionId": {},
        "environmentAccountId": {},
        "lastDeploymentAttemptedAt": {
          "type": "timestamp"
        },
        "lastDeploymentSucceededAt": {
          "type": "timestamp"
        },
        "name": {},
        "protonServiceRoleArn": {},
        "provisioning": {},
        "provisioningRepository": {
          "shape": "So"
        },
        "spec": {
          "shape": "Si"
        },
        "templateMajorVersion": {},
        "templateMinorVersion": {},
        "templateName": {}
      }
    },
    "So": {
      "type": "structure",
      "required": [
        "arn",
        "branch",
        "name",
        "provider"
      ],
      "members": {
        "arn": {},
        "branch": {},
        "name": {},
        "provider": {}
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "arn",
        "createdAt",
        "deploymentStatus",
        "environmentName",
        "lastDeploymentAttemptedAt",
        "lastDeploymentSucceededAt",
        "name",
        "serviceName",
        "templateMajorVersion",
        "templateMinorVersion",
        "templateName"
      ],
      "members": {
        "arn": {},
        "createdAt": {
          "type": "timestamp"
        },
        "deploymentStatus": {},
        "deploymentStatusMessage": {
          "shape": "Sg"
        },
        "environmentName": {},
        "lastDeploymentAttemptedAt": {
          "type": "timestamp"
        },
        "lastDeploymentSucceededAt": {
          "type": "timestamp"
        },
        "name": {},
        "serviceName": {},
        "spec": {
          "shape": "Si"
        },
        "templateMajorVersion": {},
        "templateMinorVersion": {},
        "templateName": {}
      }
    },
    "S10": {
      "type": "structure",
      "required": [
        "arn",
        "createdAt",
        "deploymentStatus",
        "lastDeploymentAttemptedAt",
        "lastDeploymentSucceededAt",
        "templateMajorVersion",
        "templateMinorVersion",
        "templateName"
      ],
      "members": {
        "arn": {},
        "createdAt": {
          "type": "timestamp"
        },
        "deploymentStatus": {},
        "deploymentStatusMessage": {
          "shape": "Sg"
        },
        "lastDeploymentAttemptedAt": {
          "type": "timestamp"
        },
        "lastDeploymentSucceededAt": {
          "type": "timestamp"
        },
        "spec": {
          "shape": "Si"
        },
        "templateMajorVersion": {},
        "templateMinorVersion": {},
        "templateName": {}
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S17": {
      "type": "string",
      "sensitive": true
    },
    "S1a": {
      "type": "structure",
      "required": [
        "branch",
        "name",
        "provider"
      ],
      "members": {
        "branch": {},
        "name": {},
        "provider": {}
      }
    },
    "S1g": {
      "type": "string",
      "sensitive": true
    },
    "S1i": {
      "type": "structure",
      "required": [
        "arn",
        "createdAt",
        "lastModifiedAt",
        "name"
      ],
      "members": {
        "arn": {},
        "createdAt": {
          "type": "timestamp"
        },
        "description": {
          "shape": "Sh"
        },
        "displayName": {
          "shape": "S1g"
        },
        "encryptionKey": {},
        "lastModifiedAt": {
          "type": "timestamp"
        },
        "name": {},
        "provisioning": {},
        "recommendedVersion": {}
      }
    },
    "S1m": {
      "type": "structure",
      "members": {
        "s3": {
          "type": "structure",
          "required": [
            "bucket",
            "key"
          ],
          "members": {
            "bucket": {},
            "key": {}
          }
        }
      },
      "union": true
    },
    "S1r": {
      "type": "structure",
      "required": [
        "arn",
        "createdAt",
        "lastModifiedAt",
        "majorVersion",
        "minorVersion",
        "status",
        "templateName"
      ],
      "members": {
        "arn": {},
        "createdAt": {
          "type": "timestamp"
        },
        "description": {
          "shape": "Sh"
        },
        "lastModifiedAt": {
          "type": "timestamp"
        },
        "majorVersion": {},
        "minorVersion": {},
        "recommendedMinorVersion": {},
        "schema": {
          "shape": "S1t"
        },
        "status": {},
        "statusMessage": {
          "shape": "Sg"
        },
        "templateName": {}
      }
    },
    "S1t": {
      "type": "string",
      "sensitive": true
    },
    "S1x": {
      "type": "structure",
      "required": [
        "arn",
        "connectionArn",
        "name",
        "provider"
      ],
      "members": {
        "arn": {},
        "connectionArn": {},
        "encryptionKey": {},
        "name": {},
        "provider": {}
      }
    },
    "S21": {
      "type": "structure",
      "required": [
        "arn",
        "createdAt",
        "lastModifiedAt",
        "name",
        "spec",
        "status",
        "templateName"
      ],
      "members": {
        "arn": {},
        "branchName": {},
        "createdAt": {
          "type": "timestamp"
        },
        "description": {
          "shape": "Sh"
        },
        "lastModifiedAt": {
          "type": "timestamp"
        },
        "name": {},
        "pipeline": {
          "shape": "S10"
        },
        "repositoryConnectionArn": {},
        "repositoryId": {},
        "spec": {
          "shape": "Si"
        },
        "status": {},
        "statusMessage": {
          "shape": "Sg"
        },
        "templateName": {}
      }
    },
    "S26": {
      "type": "structure",
      "required": [
        "arn",
        "createdAt",
        "lastModifiedAt",
        "name"
      ],
      "members": {
        "arn": {},
        "createdAt": {
          "type": "timestamp"
        },
        "description": {
          "shape": "Sh"
        },
        "displayName": {
          "shape": "S1g"
        },
        "encryptionKey": {},
        "lastModifiedAt": {
          "type": "timestamp"
        },
        "name": {},
        "pipelineProvisioning": {},
        "recommendedVersion": {}
      }
    },
    "S29": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "majorVersion",
          "templateName"
        ],
        "members": {
          "majorVersion": {},
          "templateName": {}
        }
      }
    },
    "S2b": {
      "type": "list",
      "member": {}
    },
    "S2e": {
      "type": "structure",
      "required": [
        "arn",
        "compatibleEnvironmentTemplates",
        "createdAt",
        "lastModifiedAt",
        "majorVersion",
        "minorVersion",
        "status",
        "templateName"
      ],
      "members": {
        "arn": {},
        "compatibleEnvironmentTemplates": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "majorVersion",
              "templateName"
            ],
            "members": {
              "majorVersion": {},
              "templateName": {}
            }
          }
        },
        "createdAt": {
          "type": "timestamp"
        },
        "description": {
          "shape": "Sh"
        },
        "lastModifiedAt": {
          "type": "timestamp"
        },
        "majorVersion": {},
        "minorVersion": {},
        "recommendedMinorVersion": {},
        "schema": {
          "shape": "S1t"
        },
        "status": {},
        "statusMessage": {
          "shape": "Sg"
        },
        "supportedComponentSources": {
          "shape": "S2b"
        },
        "templateName": {}
      }
    },
    "S2m": {
      "type": "structure",
      "required": [
        "branch",
        "repositoryName",
        "repositoryProvider",
        "templateName",
        "templateType"
      ],
      "members": {
        "branch": {},
        "repositoryName": {},
        "repositoryProvider": {},
        "subdirectory": {},
        "templateName": {},
        "templateType": {}
      }
    },
    "S39": {
      "type": "structure",
      "members": {
        "pipelineProvisioningRepository": {
          "shape": "So"
        },
        "pipelineServiceRoleArn": {}
      }
    },
    "S47": {
      "type": "structure",
      "required": [
        "branch",
        "directory",
        "repositoryName",
        "repositoryProvider",
        "sha"
      ],
      "members": {
        "branch": {},
        "directory": {},
        "repositoryName": {},
        "repositoryProvider": {},
        "sha": {}
      }
    },
    "S49": {
      "type": "structure",
      "required": [
        "events",
        "initialRevision",
        "startedAt",
        "status",
        "target",
        "targetRevision"
      ],
      "members": {
        "events": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "event",
              "time",
              "type"
            ],
            "members": {
              "event": {},
              "externalId": {},
              "time": {
                "type": "timestamp"
              },
              "type": {}
            }
          }
        },
        "initialRevision": {
          "shape": "S47"
        },
        "startedAt": {
          "type": "timestamp"
        },
        "status": {},
        "target": {},
        "targetRevision": {
          "shape": "S47"
        }
      }
    },
    "S4g": {
      "type": "list",
      "member": {
        "shape": "S4h"
      }
    },
    "S4h": {
      "type": "structure",
      "members": {
        "key": {},
        "valueString": {}
      },
      "sensitive": true
    },
    "S4m": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "identifier": {},
          "name": {},
          "provisioningEngine": {}
        }
      }
    }
  }
}