{
  "pagination": {
    "DescribeClientAuthenticationSettings": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "ClientAuthenticationSettingsInfo"
    },
    "DescribeDirectories": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "DirectoryDescriptions"
    },
    "DescribeDomainControllers": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken"
    },
    "DescribeLDAPSSettings": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "LDAPSSettingsInfo"
    },
    "DescribeRegions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "RegionsDescription"
    },
    "DescribeSharedDirectories": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "SharedDirectories"
    },
    "DescribeSnapshots": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "Snapshots"
    },
    "DescribeTrusts": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "Trusts"
    },
    "DescribeUpdateDirectory": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "UpdateActivities"
    },
    "ListCertificates": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "CertificatesInfo"
    },
    "ListIpRoutes": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "IpRoutesInfo"
    },
    "ListLogSubscriptions": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "LogSubscriptions"
    },
    "ListSchemaExtensions": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "SchemaExtensionsInfo"
    },
    "ListTagsForResource": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "Tags"
    }
  }
}