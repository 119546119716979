function isValidText(input) {
  return input.length >= 5
}

function isValidDate(input) {
  return new Date(input) <= new Date()
}

function isValidDecimalGranularity(input) {
  var decomposed = String(input).split(".")
  if (decomposed.length == 2 && decomposed[1].length > 2)
    return false
  return true
}

function isValidAmount(input) {
  return !isNaN(input) && parseFloat(input) > 0 && isValidDecimalGranularity(input);
}

function isValidReceipts(urls) {
  return urls.length >= 1 && 
    urls.every(url => url !== '' && url !== undefined && url !== null);
}

function isAllValidated(value) {
  return value === true
}

export {
  isValidText,
  isValidDate,
  isValidAmount,
  isValidReceipts,
  isAllValidated
};
