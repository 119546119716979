{
  "pagination": {
    "DescribeBudgetActionHistories": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ActionHistories"
    },
    "DescribeBudgetActionsForAccount": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Actions"
    },
    "DescribeBudgetActionsForBudget": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Actions"
    },
    "DescribeBudgetNotificationsForAccount": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "BudgetNotificationsForAccount"
    },
    "DescribeBudgetPerformanceHistory": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "BudgetPerformanceHistory"
    },
    "DescribeBudgets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Budgets"
    },
    "DescribeNotificationsForBudget": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Notifications"
    },
    "DescribeSubscribersForNotification": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Subscribers"
    }
  }
}