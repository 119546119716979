{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-10-17",
    "endpointPrefix": "secretsmanager",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Secrets Manager",
    "serviceId": "Secrets Manager",
    "signatureVersion": "v4",
    "signingName": "secretsmanager",
    "targetPrefix": "secretsmanager",
    "uid": "secretsmanager-2017-10-17"
  },
  "operations": {
    "CancelRotateSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {}
        }
      }
    },
    "CreateSecret": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Description": {},
          "KmsKeyId": {},
          "SecretBinary": {
            "shape": "Sc"
          },
          "SecretString": {
            "shape": "Sd"
          },
          "Tags": {
            "shape": "Se"
          },
          "AddReplicaRegions": {
            "shape": "Si"
          },
          "ForceOverwriteReplicaSecret": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {},
          "ReplicationStatus": {
            "shape": "Sn"
          }
        }
      }
    },
    "DeleteResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {}
        }
      }
    },
    "DeleteSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "RecoveryWindowInDays": {
            "type": "long"
          },
          "ForceDeleteWithoutRecovery": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "DeletionDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "Description": {},
          "KmsKeyId": {},
          "RotationEnabled": {
            "type": "boolean"
          },
          "RotationLambdaARN": {},
          "RotationRules": {
            "shape": "S12"
          },
          "LastRotatedDate": {
            "type": "timestamp"
          },
          "LastChangedDate": {
            "type": "timestamp"
          },
          "LastAccessedDate": {
            "type": "timestamp"
          },
          "DeletedDate": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "Se"
          },
          "VersionIdsToStages": {
            "shape": "S19"
          },
          "OwningService": {},
          "CreatedDate": {
            "type": "timestamp"
          },
          "PrimaryRegion": {},
          "ReplicationStatus": {
            "shape": "Sn"
          }
        }
      }
    },
    "GetRandomPassword": {
      "input": {
        "type": "structure",
        "members": {
          "PasswordLength": {
            "type": "long"
          },
          "ExcludeCharacters": {},
          "ExcludeNumbers": {
            "type": "boolean"
          },
          "ExcludePunctuation": {
            "type": "boolean"
          },
          "ExcludeUppercase": {
            "type": "boolean"
          },
          "ExcludeLowercase": {
            "type": "boolean"
          },
          "IncludeSpace": {
            "type": "boolean"
          },
          "RequireEachIncludedType": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RandomPassword": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "GetResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "ResourcePolicy": {}
        }
      }
    },
    "GetSecretValue": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "VersionId": {},
          "VersionStage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {},
          "SecretBinary": {
            "shape": "Sc"
          },
          "SecretString": {
            "shape": "Sd"
          },
          "VersionStages": {
            "shape": "S1a"
          },
          "CreatedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "ListSecretVersionIds": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "IncludeDeprecated": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Versions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VersionId": {},
                "VersionStages": {
                  "shape": "S1a"
                },
                "LastAccessedDate": {
                  "type": "timestamp"
                },
                "CreatedDate": {
                  "type": "timestamp"
                },
                "KmsKeyIds": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "NextToken": {},
          "ARN": {},
          "Name": {}
        }
      }
    },
    "ListSecrets": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecretList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ARN": {},
                "Name": {},
                "Description": {},
                "KmsKeyId": {},
                "RotationEnabled": {
                  "type": "boolean"
                },
                "RotationLambdaARN": {},
                "RotationRules": {
                  "shape": "S12"
                },
                "LastRotatedDate": {
                  "type": "timestamp"
                },
                "LastChangedDate": {
                  "type": "timestamp"
                },
                "LastAccessedDate": {
                  "type": "timestamp"
                },
                "DeletedDate": {
                  "type": "timestamp"
                },
                "Tags": {
                  "shape": "Se"
                },
                "SecretVersionsToStages": {
                  "shape": "S19"
                },
                "OwningService": {},
                "CreatedDate": {
                  "type": "timestamp"
                },
                "PrimaryRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "ResourcePolicy"
        ],
        "members": {
          "SecretId": {},
          "ResourcePolicy": {},
          "BlockPublicPolicy": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {}
        }
      }
    },
    "PutSecretValue": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SecretBinary": {
            "shape": "Sc"
          },
          "SecretString": {
            "shape": "Sd"
          },
          "VersionStages": {
            "shape": "S1a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {},
          "VersionStages": {
            "shape": "S1a"
          }
        }
      }
    },
    "RemoveRegionsFromReplication": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "RemoveReplicaRegions"
        ],
        "members": {
          "SecretId": {},
          "RemoveReplicaRegions": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "ReplicationStatus": {
            "shape": "Sn"
          }
        }
      }
    },
    "ReplicateSecretToRegions": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "AddReplicaRegions"
        ],
        "members": {
          "SecretId": {},
          "AddReplicaRegions": {
            "shape": "Si"
          },
          "ForceOverwriteReplicaSecret": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "ReplicationStatus": {
            "shape": "Sn"
          }
        }
      }
    },
    "RestoreSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {}
        }
      }
    },
    "RotateSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "RotationLambdaARN": {},
          "RotationRules": {
            "shape": "S12"
          },
          "RotateImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {}
        }
      }
    },
    "StopReplicationToReplica": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "Tags"
        ],
        "members": {
          "SecretId": {},
          "Tags": {
            "shape": "Se"
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "TagKeys"
        ],
        "members": {
          "SecretId": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Description": {},
          "KmsKeyId": {},
          "SecretBinary": {
            "shape": "Sc"
          },
          "SecretString": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {}
        }
      }
    },
    "UpdateSecretVersionStage": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "VersionStage"
        ],
        "members": {
          "SecretId": {},
          "VersionStage": {},
          "RemoveFromVersionId": {},
          "MoveToVersionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {}
        }
      }
    },
    "ValidateResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ResourcePolicy"
        ],
        "members": {
          "SecretId": {},
          "ResourcePolicy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyValidationPassed": {
            "type": "boolean"
          },
          "ValidationErrors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CheckName": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    }
  },
  "shapes": {
    "Sc": {
      "type": "blob",
      "sensitive": true
    },
    "Sd": {
      "type": "string",
      "sensitive": true
    },
    "Se": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Region": {},
          "KmsKeyId": {}
        }
      }
    },
    "Sn": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Region": {},
          "KmsKeyId": {},
          "Status": {},
          "StatusMessage": {},
          "LastAccessedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "AutomaticallyAfterDays": {
          "type": "long"
        },
        "Duration": {},
        "ScheduleExpression": {}
      }
    },
    "S19": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S1a"
      }
    },
    "S1a": {
      "type": "list",
      "member": {}
    }
  }
}