{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-06-08",
    "endpointPrefix": "inspector2",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Inspector2",
    "serviceFullName": "Inspector2",
    "serviceId": "Inspector2",
    "signatureVersion": "v4",
    "signingName": "inspector2",
    "uid": "inspector2-2020-06-08"
  },
  "operations": {
    "AssociateMember": {
      "http": {
        "requestUri": "/members/associate",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "accountId"
        ],
        "members": {
          "accountId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "accountId"
        ],
        "members": {
          "accountId": {}
        }
      }
    },
    "BatchGetAccountStatus": {
      "http": {
        "requestUri": "/status/batch/get",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "accountIds": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "accounts"
        ],
        "members": {
          "accounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "accountId",
                "resourceState",
                "state"
              ],
              "members": {
                "accountId": {},
                "resourceState": {
                  "type": "structure",
                  "required": [
                    "ec2",
                    "ecr"
                  ],
                  "members": {
                    "ec2": {
                      "shape": "Sa"
                    },
                    "ecr": {
                      "shape": "Sa"
                    }
                  }
                },
                "state": {
                  "shape": "Sa"
                }
              }
            }
          },
          "failedAccounts": {
            "shape": "Se"
          }
        }
      }
    },
    "BatchGetFreeTrialInfo": {
      "http": {
        "requestUri": "/freetrialinfo/batchget",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "accountIds"
        ],
        "members": {
          "accountIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "accounts",
          "failedAccounts"
        ],
        "members": {
          "accounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "accountId",
                "freeTrialInfo"
              ],
              "members": {
                "accountId": {},
                "freeTrialInfo": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "end",
                      "start",
                      "status",
                      "type"
                    ],
                    "members": {
                      "end": {
                        "type": "timestamp"
                      },
                      "start": {
                        "type": "timestamp"
                      },
                      "status": {},
                      "type": {}
                    }
                  }
                }
              }
            }
          },
          "failedAccounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "accountId",
                "code",
                "message"
              ],
              "members": {
                "accountId": {},
                "code": {},
                "message": {}
              }
            }
          }
        }
      }
    },
    "CancelFindingsReport": {
      "http": {
        "requestUri": "/reporting/cancel",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "reportId"
        ],
        "members": {
          "reportId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "reportId"
        ],
        "members": {
          "reportId": {}
        }
      }
    },
    "CreateFilter": {
      "http": {
        "requestUri": "/filters/create",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "action",
          "filterCriteria",
          "name"
        ],
        "members": {
          "action": {},
          "description": {},
          "filterCriteria": {
            "shape": "S12"
          },
          "name": {},
          "reason": {},
          "tags": {
            "shape": "S1o"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      }
    },
    "CreateFindingsReport": {
      "http": {
        "requestUri": "/reporting/create",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "reportFormat",
          "s3Destination"
        ],
        "members": {
          "filterCriteria": {
            "shape": "S12"
          },
          "reportFormat": {},
          "s3Destination": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "reportId": {}
        }
      }
    },
    "DeleteFilter": {
      "http": {
        "requestUri": "/filters/delete",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      }
    },
    "DescribeOrganizationConfiguration": {
      "http": {
        "requestUri": "/organizationconfiguration/describe",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "autoEnable": {
            "shape": "S1z"
          },
          "maxAccountLimitReached": {
            "type": "boolean"
          }
        }
      }
    },
    "Disable": {
      "http": {
        "requestUri": "/disable",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "accountIds": {
            "shape": "S5"
          },
          "resourceTypes": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "accounts"
        ],
        "members": {
          "accounts": {
            "shape": "S25"
          },
          "failedAccounts": {
            "shape": "Se"
          }
        }
      }
    },
    "DisableDelegatedAdminAccount": {
      "http": {
        "requestUri": "/delegatedadminaccounts/disable",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "delegatedAdminAccountId"
        ],
        "members": {
          "delegatedAdminAccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "delegatedAdminAccountId"
        ],
        "members": {
          "delegatedAdminAccountId": {}
        }
      }
    },
    "DisassociateMember": {
      "http": {
        "requestUri": "/members/disassociate",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "accountId"
        ],
        "members": {
          "accountId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "accountId"
        ],
        "members": {
          "accountId": {}
        }
      }
    },
    "Enable": {
      "http": {
        "requestUri": "/enable",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceTypes"
        ],
        "members": {
          "accountIds": {
            "shape": "S5"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "resourceTypes": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "accounts"
        ],
        "members": {
          "accounts": {
            "shape": "S25"
          },
          "failedAccounts": {
            "shape": "Se"
          }
        }
      }
    },
    "EnableDelegatedAdminAccount": {
      "http": {
        "requestUri": "/delegatedadminaccounts/enable",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "delegatedAdminAccountId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "delegatedAdminAccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "delegatedAdminAccountId"
        ],
        "members": {
          "delegatedAdminAccountId": {}
        }
      }
    },
    "GetConfiguration": {
      "http": {
        "requestUri": "/configuration/get",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "ecrConfiguration": {
            "type": "structure",
            "members": {
              "rescanDurationState": {
                "type": "structure",
                "members": {
                  "rescanDuration": {},
                  "status": {},
                  "updatedAt": {
                    "type": "timestamp"
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetDelegatedAdminAccount": {
      "http": {
        "requestUri": "/delegatedadminaccounts/get",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "delegatedAdmin": {
            "type": "structure",
            "members": {
              "accountId": {},
              "relationshipStatus": {}
            }
          }
        }
      }
    },
    "GetFindingsReportStatus": {
      "http": {
        "requestUri": "/reporting/status/get",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "reportId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "destination": {
            "shape": "S1t"
          },
          "errorCode": {},
          "errorMessage": {},
          "filterCriteria": {
            "shape": "S12"
          },
          "reportId": {},
          "status": {}
        }
      }
    },
    "GetMember": {
      "http": {
        "requestUri": "/members/get",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "accountId"
        ],
        "members": {
          "accountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "member": {
            "shape": "S2z"
          }
        }
      }
    },
    "ListAccountPermissions": {
      "http": {
        "requestUri": "/accountpermissions/list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "service": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "permissions"
        ],
        "members": {
          "nextToken": {},
          "permissions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "operation",
                "service"
              ],
              "members": {
                "operation": {},
                "service": {}
              }
            }
          }
        }
      }
    },
    "ListCoverage": {
      "http": {
        "requestUri": "/coverage/list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "filterCriteria": {
            "shape": "S39"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "coveredResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "accountId",
                "resourceId",
                "resourceType",
                "scanType"
              ],
              "members": {
                "accountId": {},
                "resourceId": {},
                "resourceMetadata": {
                  "type": "structure",
                  "members": {
                    "ec2": {
                      "type": "structure",
                      "members": {
                        "amiId": {},
                        "platform": {},
                        "tags": {
                          "shape": "S1o"
                        }
                      }
                    },
                    "ecrImage": {
                      "type": "structure",
                      "members": {
                        "tags": {
                          "type": "list",
                          "member": {}
                        }
                      }
                    },
                    "ecrRepository": {
                      "type": "structure",
                      "members": {
                        "name": {},
                        "scanFrequency": {}
                      }
                    }
                  }
                },
                "resourceType": {},
                "scanStatus": {
                  "type": "structure",
                  "required": [
                    "reason",
                    "statusCode"
                  ],
                  "members": {
                    "reason": {},
                    "statusCode": {}
                  }
                },
                "scanType": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListCoverageStatistics": {
      "http": {
        "requestUri": "/coverage/statistics/list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "filterCriteria": {
            "shape": "S39"
          },
          "groupBy": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "totalCounts"
        ],
        "members": {
          "countsByGroup": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "count": {
                  "default": 0,
                  "type": "long"
                },
                "groupKey": {}
              }
            }
          },
          "nextToken": {},
          "totalCounts": {
            "type": "long"
          }
        }
      }
    },
    "ListDelegatedAdminAccounts": {
      "http": {
        "requestUri": "/delegatedadminaccounts/list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "delegatedAdminAccounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "accountId": {},
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListFilters": {
      "http": {
        "requestUri": "/filters/list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "action": {},
          "arns": {
            "type": "list",
            "member": {}
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "filters"
        ],
        "members": {
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "action",
                "arn",
                "createdAt",
                "criteria",
                "name",
                "ownerId",
                "updatedAt"
              ],
              "members": {
                "action": {},
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "criteria": {
                  "shape": "S12"
                },
                "description": {},
                "name": {},
                "ownerId": {},
                "reason": {},
                "tags": {
                  "shape": "S1o"
                },
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListFindingAggregations": {
      "http": {
        "requestUri": "/findings/aggregation/list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "aggregationType"
        ],
        "members": {
          "accountIds": {
            "shape": "S13"
          },
          "aggregationRequest": {
            "type": "structure",
            "members": {
              "accountAggregation": {
                "type": "structure",
                "members": {
                  "findingType": {},
                  "resourceType": {},
                  "sortBy": {},
                  "sortOrder": {}
                }
              },
              "amiAggregation": {
                "type": "structure",
                "members": {
                  "amis": {
                    "shape": "S13"
                  },
                  "sortBy": {},
                  "sortOrder": {}
                }
              },
              "awsEcrContainerAggregation": {
                "type": "structure",
                "members": {
                  "architectures": {
                    "shape": "S13"
                  },
                  "imageShas": {
                    "shape": "S13"
                  },
                  "imageTags": {
                    "shape": "S13"
                  },
                  "repositories": {
                    "shape": "S13"
                  },
                  "resourceIds": {
                    "shape": "S13"
                  },
                  "sortBy": {},
                  "sortOrder": {}
                }
              },
              "ec2InstanceAggregation": {
                "type": "structure",
                "members": {
                  "amis": {
                    "shape": "S13"
                  },
                  "instanceIds": {
                    "shape": "S13"
                  },
                  "instanceTags": {
                    "shape": "S1f"
                  },
                  "operatingSystems": {
                    "shape": "S13"
                  },
                  "sortBy": {},
                  "sortOrder": {}
                }
              },
              "findingTypeAggregation": {
                "type": "structure",
                "members": {
                  "findingType": {},
                  "resourceType": {},
                  "sortBy": {},
                  "sortOrder": {}
                }
              },
              "imageLayerAggregation": {
                "type": "structure",
                "members": {
                  "layerHashes": {
                    "shape": "S13"
                  },
                  "repositories": {
                    "shape": "S13"
                  },
                  "resourceIds": {
                    "shape": "S13"
                  },
                  "sortBy": {},
                  "sortOrder": {}
                }
              },
              "packageAggregation": {
                "type": "structure",
                "members": {
                  "packageNames": {
                    "shape": "S13"
                  },
                  "sortBy": {},
                  "sortOrder": {}
                }
              },
              "repositoryAggregation": {
                "type": "structure",
                "members": {
                  "repositories": {
                    "shape": "S13"
                  },
                  "sortBy": {},
                  "sortOrder": {}
                }
              },
              "titleAggregation": {
                "type": "structure",
                "members": {
                  "resourceType": {},
                  "sortBy": {},
                  "sortOrder": {},
                  "titles": {
                    "shape": "S13"
                  },
                  "vulnerabilityIds": {
                    "shape": "S13"
                  }
                }
              }
            },
            "union": true
          },
          "aggregationType": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "aggregationType"
        ],
        "members": {
          "aggregationType": {},
          "nextToken": {},
          "responses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "accountAggregation": {
                  "type": "structure",
                  "members": {
                    "accountId": {},
                    "severityCounts": {
                      "shape": "S5c"
                    }
                  }
                },
                "amiAggregation": {
                  "type": "structure",
                  "required": [
                    "ami"
                  ],
                  "members": {
                    "accountId": {},
                    "affectedInstances": {
                      "type": "long"
                    },
                    "ami": {},
                    "severityCounts": {
                      "shape": "S5c"
                    }
                  }
                },
                "awsEcrContainerAggregation": {
                  "type": "structure",
                  "required": [
                    "resourceId"
                  ],
                  "members": {
                    "accountId": {},
                    "architecture": {},
                    "imageSha": {},
                    "imageTags": {
                      "type": "list",
                      "member": {}
                    },
                    "repository": {},
                    "resourceId": {},
                    "severityCounts": {
                      "shape": "S5c"
                    }
                  }
                },
                "ec2InstanceAggregation": {
                  "type": "structure",
                  "required": [
                    "instanceId"
                  ],
                  "members": {
                    "accountId": {},
                    "ami": {},
                    "instanceId": {},
                    "instanceTags": {
                      "shape": "S1o"
                    },
                    "networkFindings": {
                      "type": "long"
                    },
                    "operatingSystem": {},
                    "severityCounts": {
                      "shape": "S5c"
                    }
                  }
                },
                "findingTypeAggregation": {
                  "type": "structure",
                  "members": {
                    "accountId": {},
                    "severityCounts": {
                      "shape": "S5c"
                    }
                  }
                },
                "imageLayerAggregation": {
                  "type": "structure",
                  "required": [
                    "accountId",
                    "layerHash",
                    "repository",
                    "resourceId"
                  ],
                  "members": {
                    "accountId": {},
                    "layerHash": {},
                    "repository": {},
                    "resourceId": {},
                    "severityCounts": {
                      "shape": "S5c"
                    }
                  }
                },
                "packageAggregation": {
                  "type": "structure",
                  "required": [
                    "packageName"
                  ],
                  "members": {
                    "accountId": {},
                    "packageName": {},
                    "severityCounts": {
                      "shape": "S5c"
                    }
                  }
                },
                "repositoryAggregation": {
                  "type": "structure",
                  "required": [
                    "repository"
                  ],
                  "members": {
                    "accountId": {},
                    "affectedImages": {
                      "type": "long"
                    },
                    "repository": {},
                    "severityCounts": {
                      "shape": "S5c"
                    }
                  }
                },
                "titleAggregation": {
                  "type": "structure",
                  "required": [
                    "title"
                  ],
                  "members": {
                    "accountId": {},
                    "severityCounts": {
                      "shape": "S5c"
                    },
                    "title": {},
                    "vulnerabilityId": {}
                  }
                }
              },
              "union": true
            }
          }
        }
      }
    },
    "ListFindings": {
      "http": {
        "requestUri": "/findings/list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "filterCriteria": {
            "shape": "S12"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "sortCriteria": {
            "type": "structure",
            "required": [
              "field",
              "sortOrder"
            ],
            "members": {
              "field": {},
              "sortOrder": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "findings": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "awsAccountId",
                "description",
                "findingArn",
                "firstObservedAt",
                "lastObservedAt",
                "remediation",
                "resources",
                "severity",
                "status",
                "type"
              ],
              "members": {
                "awsAccountId": {},
                "description": {},
                "findingArn": {},
                "firstObservedAt": {
                  "type": "timestamp"
                },
                "fixAvailable": {},
                "inspectorScore": {
                  "type": "double"
                },
                "inspectorScoreDetails": {
                  "type": "structure",
                  "members": {
                    "adjustedCvss": {
                      "type": "structure",
                      "required": [
                        "score",
                        "scoreSource",
                        "scoringVector",
                        "version"
                      ],
                      "members": {
                        "adjustments": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "required": [
                              "metric",
                              "reason"
                            ],
                            "members": {
                              "metric": {},
                              "reason": {}
                            }
                          }
                        },
                        "cvssSource": {},
                        "score": {
                          "type": "double"
                        },
                        "scoreSource": {},
                        "scoringVector": {},
                        "version": {}
                      }
                    }
                  }
                },
                "lastObservedAt": {
                  "type": "timestamp"
                },
                "networkReachabilityDetails": {
                  "type": "structure",
                  "required": [
                    "networkPath",
                    "openPortRange",
                    "protocol"
                  ],
                  "members": {
                    "networkPath": {
                      "type": "structure",
                      "members": {
                        "steps": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "required": [
                              "componentId",
                              "componentType"
                            ],
                            "members": {
                              "componentId": {},
                              "componentType": {}
                            }
                          }
                        }
                      }
                    },
                    "openPortRange": {
                      "type": "structure",
                      "required": [
                        "begin",
                        "end"
                      ],
                      "members": {
                        "begin": {
                          "type": "integer"
                        },
                        "end": {
                          "type": "integer"
                        }
                      }
                    },
                    "protocol": {}
                  }
                },
                "packageVulnerabilityDetails": {
                  "type": "structure",
                  "required": [
                    "source",
                    "vulnerabilityId"
                  ],
                  "members": {
                    "cvss": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "baseScore",
                          "scoringVector",
                          "source",
                          "version"
                        ],
                        "members": {
                          "baseScore": {
                            "type": "double"
                          },
                          "scoringVector": {},
                          "source": {},
                          "version": {}
                        }
                      }
                    },
                    "referenceUrls": {
                      "type": "list",
                      "member": {}
                    },
                    "relatedVulnerabilities": {
                      "type": "list",
                      "member": {}
                    },
                    "source": {},
                    "sourceUrl": {},
                    "vendorCreatedAt": {
                      "type": "timestamp"
                    },
                    "vendorSeverity": {},
                    "vendorUpdatedAt": {
                      "type": "timestamp"
                    },
                    "vulnerabilityId": {},
                    "vulnerablePackages": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "name",
                          "version"
                        ],
                        "members": {
                          "arch": {},
                          "epoch": {
                            "default": 0,
                            "type": "integer"
                          },
                          "filePath": {},
                          "fixedInVersion": {},
                          "name": {},
                          "packageManager": {},
                          "release": {},
                          "remediation": {},
                          "sourceLayerHash": {},
                          "version": {}
                        }
                      }
                    }
                  }
                },
                "remediation": {
                  "type": "structure",
                  "members": {
                    "recommendation": {
                      "type": "structure",
                      "members": {
                        "Url": {},
                        "text": {}
                      }
                    }
                  }
                },
                "resources": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "id",
                      "type"
                    ],
                    "members": {
                      "details": {
                        "type": "structure",
                        "members": {
                          "awsEc2Instance": {
                            "type": "structure",
                            "members": {
                              "iamInstanceProfileArn": {},
                              "imageId": {},
                              "ipV4Addresses": {
                                "type": "list",
                                "member": {}
                              },
                              "ipV6Addresses": {
                                "type": "list",
                                "member": {}
                              },
                              "keyName": {},
                              "launchedAt": {
                                "type": "timestamp"
                              },
                              "platform": {},
                              "subnetId": {},
                              "type": {},
                              "vpcId": {}
                            }
                          },
                          "awsEcrContainerImage": {
                            "type": "structure",
                            "required": [
                              "imageHash",
                              "registry",
                              "repositoryName"
                            ],
                            "members": {
                              "architecture": {},
                              "author": {},
                              "imageHash": {},
                              "imageTags": {
                                "type": "list",
                                "member": {}
                              },
                              "platform": {},
                              "pushedAt": {
                                "type": "timestamp"
                              },
                              "registry": {},
                              "repositoryName": {}
                            }
                          }
                        }
                      },
                      "id": {},
                      "partition": {},
                      "region": {},
                      "tags": {
                        "shape": "S1o"
                      },
                      "type": {}
                    }
                  }
                },
                "severity": {},
                "status": {},
                "title": {},
                "type": {},
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListMembers": {
      "http": {
        "requestUri": "/members/list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "onlyAssociated": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "members": {
            "type": "list",
            "member": {
              "shape": "S2z"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S1o"
          }
        }
      }
    },
    "ListUsageTotals": {
      "http": {
        "requestUri": "/usage/list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "accountIds": {
            "type": "list",
            "member": {}
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "totals": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "accountId": {},
                "usage": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "currency": {},
                      "estimatedMonthlyCost": {
                        "default": 0,
                        "type": "double"
                      },
                      "total": {
                        "default": 0,
                        "type": "double"
                      },
                      "type": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S1o"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateConfiguration": {
      "http": {
        "requestUri": "/configuration/update",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ecrConfiguration"
        ],
        "members": {
          "ecrConfiguration": {
            "type": "structure",
            "required": [
              "rescanDuration"
            ],
            "members": {
              "rescanDuration": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateFilter": {
      "http": {
        "requestUri": "/filters/update",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "filterArn"
        ],
        "members": {
          "action": {},
          "description": {},
          "filterArn": {},
          "filterCriteria": {
            "shape": "S12"
          },
          "name": {},
          "reason": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      }
    },
    "UpdateOrganizationConfiguration": {
      "http": {
        "requestUri": "/organizationconfiguration/update",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "autoEnable"
        ],
        "members": {
          "autoEnable": {
            "shape": "S1z"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "autoEnable"
        ],
        "members": {
          "autoEnable": {
            "shape": "S1z"
          }
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "structure",
      "required": [
        "errorCode",
        "errorMessage",
        "status"
      ],
      "members": {
        "errorCode": {},
        "errorMessage": {},
        "status": {}
      }
    },
    "Se": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "accountId",
          "errorCode",
          "errorMessage"
        ],
        "members": {
          "accountId": {},
          "errorCode": {},
          "errorMessage": {},
          "resourceStatus": {
            "shape": "Sg"
          },
          "status": {}
        }
      }
    },
    "Sg": {
      "type": "structure",
      "required": [
        "ec2",
        "ecr"
      ],
      "members": {
        "ec2": {},
        "ecr": {}
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "awsAccountId": {
          "shape": "S13"
        },
        "componentId": {
          "shape": "S13"
        },
        "componentType": {
          "shape": "S13"
        },
        "ec2InstanceImageId": {
          "shape": "S13"
        },
        "ec2InstanceSubnetId": {
          "shape": "S13"
        },
        "ec2InstanceVpcId": {
          "shape": "S13"
        },
        "ecrImageArchitecture": {
          "shape": "S13"
        },
        "ecrImageHash": {
          "shape": "S13"
        },
        "ecrImagePushedAt": {
          "shape": "S17"
        },
        "ecrImageRegistry": {
          "shape": "S13"
        },
        "ecrImageRepositoryName": {
          "shape": "S13"
        },
        "ecrImageTags": {
          "shape": "S13"
        },
        "findingArn": {
          "shape": "S13"
        },
        "findingStatus": {
          "shape": "S13"
        },
        "findingType": {
          "shape": "S13"
        },
        "firstObservedAt": {
          "shape": "S17"
        },
        "fixAvailable": {
          "shape": "S13"
        },
        "inspectorScore": {
          "type": "list",
          "member": {
            "shape": "S1a"
          }
        },
        "lastObservedAt": {
          "shape": "S17"
        },
        "networkProtocol": {
          "shape": "S13"
        },
        "portRange": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "beginInclusive": {
                "type": "integer"
              },
              "endInclusive": {
                "type": "integer"
              }
            }
          }
        },
        "relatedVulnerabilities": {
          "shape": "S13"
        },
        "resourceId": {
          "shape": "S13"
        },
        "resourceTags": {
          "shape": "S1f"
        },
        "resourceType": {
          "shape": "S13"
        },
        "severity": {
          "shape": "S13"
        },
        "title": {
          "shape": "S13"
        },
        "updatedAt": {
          "shape": "S17"
        },
        "vendorSeverity": {
          "shape": "S13"
        },
        "vulnerabilityId": {
          "shape": "S13"
        },
        "vulnerabilitySource": {
          "shape": "S13"
        },
        "vulnerablePackages": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "architecture": {
                "shape": "S14"
              },
              "epoch": {
                "shape": "S1a"
              },
              "name": {
                "shape": "S14"
              },
              "release": {
                "shape": "S14"
              },
              "sourceLayerHash": {
                "shape": "S14"
              },
              "version": {
                "shape": "S14"
              }
            }
          }
        }
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "shape": "S14"
      }
    },
    "S14": {
      "type": "structure",
      "required": [
        "comparison",
        "value"
      ],
      "members": {
        "comparison": {},
        "value": {}
      }
    },
    "S17": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "endInclusive": {
            "type": "timestamp"
          },
          "startInclusive": {
            "type": "timestamp"
          }
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "lowerInclusive": {
          "type": "double"
        },
        "upperInclusive": {
          "type": "double"
        }
      }
    },
    "S1f": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "comparison",
          "key"
        ],
        "members": {
          "comparison": {},
          "key": {},
          "value": {}
        }
      }
    },
    "S1o": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1t": {
      "type": "structure",
      "required": [
        "bucketName",
        "kmsKeyArn"
      ],
      "members": {
        "bucketName": {},
        "keyPrefix": {},
        "kmsKeyArn": {}
      }
    },
    "S1z": {
      "type": "structure",
      "required": [
        "ec2",
        "ecr"
      ],
      "members": {
        "ec2": {
          "type": "boolean"
        },
        "ecr": {
          "type": "boolean"
        }
      }
    },
    "S25": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "accountId",
          "resourceStatus",
          "status"
        ],
        "members": {
          "accountId": {},
          "resourceStatus": {
            "shape": "Sg"
          },
          "status": {}
        }
      }
    },
    "S2z": {
      "type": "structure",
      "members": {
        "accountId": {},
        "delegatedAdminAccountId": {},
        "relationshipStatus": {},
        "updatedAt": {
          "type": "timestamp"
        }
      }
    },
    "S39": {
      "type": "structure",
      "members": {
        "accountId": {
          "shape": "S3a"
        },
        "ec2InstanceTags": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "comparison",
              "key"
            ],
            "members": {
              "comparison": {},
              "key": {},
              "value": {}
            }
          }
        },
        "ecrImageTags": {
          "shape": "S3a"
        },
        "ecrRepositoryName": {
          "shape": "S3a"
        },
        "resourceId": {
          "shape": "S3a"
        },
        "resourceType": {
          "shape": "S3a"
        },
        "scanStatusCode": {
          "shape": "S3a"
        },
        "scanStatusReason": {
          "shape": "S3a"
        },
        "scanType": {
          "shape": "S3a"
        }
      }
    },
    "S3a": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "comparison",
          "value"
        ],
        "members": {
          "comparison": {},
          "value": {}
        }
      }
    },
    "S5c": {
      "type": "structure",
      "members": {
        "all": {
          "type": "long"
        },
        "critical": {
          "type": "long"
        },
        "high": {
          "type": "long"
        },
        "medium": {
          "type": "long"
        }
      }
    }
  }
}