{
  "pagination": {
    "GetVehicleStatus": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "campaigns"
    },
    "ListCampaigns": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "campaignSummaries"
    },
    "ListDecoderManifestNetworkInterfaces": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "networkInterfaces"
    },
    "ListDecoderManifestSignals": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "signalDecoders"
    },
    "ListDecoderManifests": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "summaries"
    },
    "ListFleets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "fleetSummaries"
    },
    "ListFleetsForVehicle": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "fleets"
    },
    "ListModelManifestNodes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "nodes"
    },
    "ListModelManifests": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "summaries"
    },
    "ListSignalCatalogNodes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "nodes"
    },
    "ListSignalCatalogs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "summaries"
    },
    "ListVehicles": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "vehicleSummaries"
    },
    "ListVehiclesInFleet": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "vehicles"
    }
  }
}
