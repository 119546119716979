{
  "pagination": {
    "ListEndpoints": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Endpoints"
    },
    "ListSharedEndpoints": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Endpoints"
    }
  }
}