{
  "pagination": {
    "ListApplicationInstanceDependencies": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListApplicationInstanceNodeInstances": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListApplicationInstances": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListDevices": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListDevicesJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListNodeFromTemplateJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListNodes": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListPackageImportJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListPackages": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}
