import {createTheme, makeStyles} from "@material-ui/core/styles";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#1976d2",
        },
    },
    typography: {
        fontSize: 12,
        fontFamily: "Montserrat"
    },
});

export const styles = makeStyles({
    searchBar: {
        padding: "8px 8px 0px 8px",
        width: "100%"
    },
    after: {
        marginTop: "2em",
        marginRight: "5em",
        width: "100%"
    },
    searchIconButton: {
        color: theme.palette.action.active,
        transform: "scale(1, 1)",
        transition: theme.transitions.create(["transform", "color"], {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeInOut,
        }),
        marginRight: theme.spacing(0),
        paddingRight: theme.spacing(0)
    },
    icon: {
        transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    list: {
        flexGrow: 1,
        padding: 0,
        songListIndex: {
            paddingLeft: "2em"
        }
    },
    holyGrail: {
        display: "flex",
        flexDirection: "row",
    },
    sideBar: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: "70%",
        display: "flex",
        flexDirection: "row",
        overflow: "auto",
        height: "92vh",
    },
    tab: {
        width:"10%",
        display: 'flex',
        "& button": {
            minWidth: 0
        },
        minWidth:"5vh"
    },
    index: {
        width:"10%",
        minWidth:"fit-content"
    },
    search: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column"
    },
    holyGrailContent: {
        width: "100%",
        overflow: "auto",
        height: "92vh",
    },
    loadingBar: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    drawer: {
        zIndex: 1100,
    },
    enterAnimation: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    leaveAnimation: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerContainer: {
        display: 'flex',
    },
    drawerContent: {
        overflow:'auto',
    },
    listContainer: {
        flex: '1'
    },
    listLoading: {
        color:'green',
        margin: '8px'
    },
    main: {
        width: '100%'
    },
    dragger: {
        width: "5px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
        backgroundColor: "#f4f7f9"
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabDoc: {
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 1000,
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    drawerToggle: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    category: {
        backgroundColor: "#f5f5f5",
        padding: '4px',
        paddingLeft: '8px',
        border: '1px solid #d7d7d7'
    },
    categoryWrapper: {
        padding: '0px'
    }
})