{
  "pagination": {
    "DescribeAccountAttributes": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "AccountAttributes"
    },
    "DescribeAccountLimits": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "AccountLimits"
    },
    "DescribeConfigurationSets": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ConfigurationSets"
    },
    "DescribeKeywords": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Keywords"
    },
    "DescribeOptOutLists": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "OptOutLists"
    },
    "DescribeOptedOutNumbers": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "OptedOutNumbers"
    },
    "DescribePhoneNumbers": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "PhoneNumbers"
    },
    "DescribePools": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Pools"
    },
    "DescribeSenderIds": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "SenderIds"
    },
    "DescribeSpendLimits": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "SpendLimits"
    },
    "ListPoolOriginationIdentities": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "OriginationIdentities"
    }
  }
}
