{
  "version": 2,
  "waiters": {
    "NodeAssociated": {
      "delay": 15,
      "maxAttempts": 15,
      "operation": "DescribeNodeAssociationStatus",
      "description": "Wait until node is associated or disassociated.",
      "acceptors": [
        {
          "expected": "SUCCESS",
          "state": "success",
          "matcher": "path",
          "argument": "NodeAssociationStatus"
        },
        {
          "expected": "FAILED",
          "state": "failure",
          "matcher": "path",
          "argument": "NodeAssociationStatus"
        }
      ]
    }
  }
}
