import React from "react";
import {addPhoto} from './s3'
import imageCompression from 'browser-image-compression';
import './Uploader.css'

export default class Uploader extends React.Component {

    onFileSelect = async (event) => {
        if (event.target.files.length > 0) {
            let file = event.target.files[0];
            let maxSizeMB = 1;

            if (file && file.type !== "application/pdf") {
                file = await imageCompression(file, {maxSizeMB: maxSizeMB});
            }

            let promise = addPhoto(file);

            this.props.onUpload(promise, this.props.index);
        }
    }

    // TODO: There is a bug in the rendering of the uploader
    //       where removing file in between will show as if
    //       the last file is removed instead. However, the
    //       uploaded files are actually correct.
    render() {
        return (
            <input
                type="file"
                accept=".png,.jpeg,.jpg,.pdf"
                onChange={this.onFileSelect}
            />
        );
    }
}
