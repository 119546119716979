{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-08-11",
    "endpointPrefix": "amplifyuibuilder",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS Amplify UI Builder",
    "serviceId": "AmplifyUIBuilder",
    "signatureVersion": "v4",
    "signingName": "amplifyuibuilder",
    "uid": "amplifyuibuilder-2021-08-11"
  },
  "operations": {
    "CreateComponent": {
      "http": {
        "requestUri": "/app/{appId}/environment/{environmentName}/components",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "componentToCreate",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "componentToCreate": {
            "type": "structure",
            "required": [
              "bindingProperties",
              "componentType",
              "name",
              "overrides",
              "properties",
              "variants"
            ],
            "members": {
              "bindingProperties": {
                "shape": "S4"
              },
              "children": {
                "shape": "S9"
              },
              "collectionProperties": {
                "shape": "Sn"
              },
              "componentType": {},
              "events": {
                "shape": "Sb"
              },
              "name": {},
              "overrides": {
                "shape": "Sv"
              },
              "properties": {
                "shape": "Sl"
              },
              "schemaVersion": {},
              "sourceId": {},
              "tags": {
                "shape": "Sx"
              },
              "variants": {
                "shape": "S10"
              }
            }
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          }
        },
        "payload": "componentToCreate"
      },
      "output": {
        "type": "structure",
        "members": {
          "entity": {
            "shape": "S14"
          }
        },
        "payload": "entity"
      },
      "idempotent": true
    },
    "CreateForm": {
      "http": {
        "requestUri": "/app/{appId}/environment/{environmentName}/forms",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "formToCreate"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "formToCreate": {
            "type": "structure",
            "required": [
              "dataType",
              "fields",
              "formActionType",
              "name",
              "schemaVersion",
              "sectionalElements",
              "style"
            ],
            "members": {
              "cta": {
                "shape": "S19"
              },
              "dataType": {
                "shape": "S1e"
              },
              "fields": {
                "shape": "S1g"
              },
              "formActionType": {},
              "name": {},
              "schemaVersion": {},
              "sectionalElements": {
                "shape": "S1v"
              },
              "style": {
                "shape": "S1x"
              },
              "tags": {
                "shape": "Sx"
              }
            }
          }
        },
        "payload": "formToCreate"
      },
      "output": {
        "type": "structure",
        "members": {
          "entity": {
            "shape": "S20"
          }
        },
        "payload": "entity"
      },
      "idempotent": true
    },
    "CreateTheme": {
      "http": {
        "requestUri": "/app/{appId}/environment/{environmentName}/themes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "themeToCreate"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "themeToCreate": {
            "type": "structure",
            "required": [
              "name",
              "values"
            ],
            "members": {
              "name": {},
              "overrides": {
                "shape": "S24"
              },
              "tags": {
                "shape": "Sx"
              },
              "values": {
                "shape": "S24"
              }
            }
          }
        },
        "payload": "themeToCreate"
      },
      "output": {
        "type": "structure",
        "members": {
          "entity": {
            "shape": "S28"
          }
        },
        "payload": "entity"
      },
      "idempotent": true
    },
    "DeleteComponent": {
      "http": {
        "method": "DELETE",
        "requestUri": "/app/{appId}/environment/{environmentName}/components/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "idempotent": true
    },
    "DeleteForm": {
      "http": {
        "method": "DELETE",
        "requestUri": "/app/{appId}/environment/{environmentName}/forms/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "idempotent": true
    },
    "DeleteTheme": {
      "http": {
        "method": "DELETE",
        "requestUri": "/app/{appId}/environment/{environmentName}/themes/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "idempotent": true
    },
    "ExchangeCodeForToken": {
      "http": {
        "requestUri": "/tokens/{provider}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "provider",
          "request"
        ],
        "members": {
          "provider": {
            "location": "uri",
            "locationName": "provider"
          },
          "request": {
            "type": "structure",
            "required": [
              "code",
              "redirectUri"
            ],
            "members": {
              "code": {
                "shape": "S2f"
              },
              "redirectUri": {}
            }
          }
        },
        "payload": "request"
      },
      "output": {
        "type": "structure",
        "required": [
          "accessToken",
          "expiresIn",
          "refreshToken"
        ],
        "members": {
          "accessToken": {
            "shape": "S2f"
          },
          "expiresIn": {
            "type": "integer"
          },
          "refreshToken": {
            "shape": "S2f"
          }
        }
      }
    },
    "ExportComponents": {
      "http": {
        "method": "GET",
        "requestUri": "/export/app/{appId}/environment/{environmentName}/components",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "entities"
        ],
        "members": {
          "entities": {
            "type": "list",
            "member": {
              "shape": "S14"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ExportForms": {
      "http": {
        "method": "GET",
        "requestUri": "/export/app/{appId}/environment/{environmentName}/forms",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "entities"
        ],
        "members": {
          "entities": {
            "type": "list",
            "member": {
              "shape": "S20"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ExportThemes": {
      "http": {
        "method": "GET",
        "requestUri": "/export/app/{appId}/environment/{environmentName}/themes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "entities"
        ],
        "members": {
          "entities": {
            "type": "list",
            "member": {
              "shape": "S28"
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetComponent": {
      "http": {
        "method": "GET",
        "requestUri": "/app/{appId}/environment/{environmentName}/components/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "component": {
            "shape": "S14"
          }
        },
        "payload": "component"
      }
    },
    "GetForm": {
      "http": {
        "method": "GET",
        "requestUri": "/app/{appId}/environment/{environmentName}/forms/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "form": {
            "shape": "S20"
          }
        },
        "payload": "form"
      }
    },
    "GetMetadata": {
      "http": {
        "method": "GET",
        "requestUri": "/app/{appId}/environment/{environmentName}/metadata",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "features"
        ],
        "members": {
          "features": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "GetTheme": {
      "http": {
        "method": "GET",
        "requestUri": "/app/{appId}/environment/{environmentName}/themes/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "theme": {
            "shape": "S28"
          }
        },
        "payload": "theme"
      }
    },
    "ListComponents": {
      "http": {
        "method": "GET",
        "requestUri": "/app/{appId}/environment/{environmentName}/components",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "entities"
        ],
        "members": {
          "entities": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "appId",
                "componentType",
                "environmentName",
                "id",
                "name"
              ],
              "members": {
                "appId": {},
                "componentType": {},
                "environmentName": {},
                "id": {},
                "name": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListForms": {
      "http": {
        "method": "GET",
        "requestUri": "/app/{appId}/environment/{environmentName}/forms",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "entities"
        ],
        "members": {
          "entities": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "appId",
                "dataType",
                "environmentName",
                "formActionType",
                "id",
                "name"
              ],
              "members": {
                "appId": {},
                "dataType": {
                  "shape": "S1e"
                },
                "environmentName": {},
                "formActionType": {},
                "id": {},
                "name": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListThemes": {
      "http": {
        "method": "GET",
        "requestUri": "/app/{appId}/environment/{environmentName}/themes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "entities"
        ],
        "members": {
          "entities": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "appId",
                "environmentName",
                "id",
                "name"
              ],
              "members": {
                "appId": {},
                "environmentName": {},
                "id": {},
                "name": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "PutMetadataFlag": {
      "http": {
        "method": "PUT",
        "requestUri": "/app/{appId}/environment/{environmentName}/metadata/features/{featureName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "body",
          "environmentName",
          "featureName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "body": {
            "type": "structure",
            "required": [
              "newValue"
            ],
            "members": {
              "newValue": {}
            }
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "featureName": {
            "location": "uri",
            "locationName": "featureName"
          }
        },
        "payload": "body"
      }
    },
    "RefreshToken": {
      "http": {
        "requestUri": "/tokens/{provider}/refresh",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "provider",
          "refreshTokenBody"
        ],
        "members": {
          "provider": {
            "location": "uri",
            "locationName": "provider"
          },
          "refreshTokenBody": {
            "type": "structure",
            "required": [
              "token"
            ],
            "members": {
              "token": {
                "shape": "S2f"
              }
            }
          }
        },
        "payload": "refreshTokenBody"
      },
      "output": {
        "type": "structure",
        "required": [
          "accessToken",
          "expiresIn"
        ],
        "members": {
          "accessToken": {
            "shape": "S2f"
          },
          "expiresIn": {
            "type": "integer"
          }
        }
      }
    },
    "UpdateComponent": {
      "http": {
        "method": "PATCH",
        "requestUri": "/app/{appId}/environment/{environmentName}/components/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id",
          "updatedComponent"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "updatedComponent": {
            "type": "structure",
            "members": {
              "bindingProperties": {
                "shape": "S4"
              },
              "children": {
                "shape": "S9"
              },
              "collectionProperties": {
                "shape": "Sn"
              },
              "componentType": {},
              "events": {
                "shape": "Sb"
              },
              "id": {},
              "name": {},
              "overrides": {
                "shape": "Sv"
              },
              "properties": {
                "shape": "Sl"
              },
              "schemaVersion": {},
              "sourceId": {},
              "variants": {
                "shape": "S10"
              }
            }
          }
        },
        "payload": "updatedComponent"
      },
      "output": {
        "type": "structure",
        "members": {
          "entity": {
            "shape": "S14"
          }
        },
        "payload": "entity"
      },
      "idempotent": true
    },
    "UpdateForm": {
      "http": {
        "method": "PATCH",
        "requestUri": "/app/{appId}/environment/{environmentName}/forms/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id",
          "updatedForm"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "updatedForm": {
            "type": "structure",
            "members": {
              "cta": {
                "shape": "S19"
              },
              "dataType": {
                "shape": "S1e"
              },
              "fields": {
                "shape": "S1g"
              },
              "formActionType": {},
              "name": {},
              "schemaVersion": {},
              "sectionalElements": {
                "shape": "S1v"
              },
              "style": {
                "shape": "S1x"
              }
            }
          }
        },
        "payload": "updatedForm"
      },
      "output": {
        "type": "structure",
        "members": {
          "entity": {
            "shape": "S20"
          }
        },
        "payload": "entity"
      },
      "idempotent": true
    },
    "UpdateTheme": {
      "http": {
        "method": "PATCH",
        "requestUri": "/app/{appId}/environment/{environmentName}/themes/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id",
          "updatedTheme"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "updatedTheme": {
            "type": "structure",
            "required": [
              "values"
            ],
            "members": {
              "id": {},
              "name": {},
              "overrides": {
                "shape": "S24"
              },
              "values": {
                "shape": "S24"
              }
            }
          }
        },
        "payload": "updatedTheme"
      },
      "output": {
        "type": "structure",
        "members": {
          "entity": {
            "shape": "S28"
          }
        },
        "payload": "entity"
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S4": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "bindingProperties": {
            "type": "structure",
            "members": {
              "bucket": {},
              "defaultValue": {},
              "field": {},
              "key": {},
              "model": {},
              "predicates": {
                "shape": "S7"
              },
              "slotName": {},
              "userAttribute": {}
            }
          },
          "defaultValue": {},
          "type": {}
        }
      }
    },
    "S7": {
      "type": "list",
      "member": {
        "shape": "S8"
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "and": {
          "shape": "S7"
        },
        "field": {},
        "operand": {},
        "operator": {},
        "or": {
          "shape": "S7"
        }
      }
    },
    "S9": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "componentType",
          "name",
          "properties"
        ],
        "members": {
          "children": {
            "shape": "S9"
          },
          "componentType": {},
          "events": {
            "shape": "Sb"
          },
          "name": {},
          "properties": {
            "shape": "Sl"
          },
          "sourceId": {}
        }
      }
    },
    "Sb": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "action": {},
          "bindingEvent": {},
          "parameters": {
            "type": "structure",
            "members": {
              "anchor": {
                "shape": "Se"
              },
              "fields": {
                "shape": "Sl"
              },
              "global": {
                "shape": "Se"
              },
              "id": {
                "shape": "Se"
              },
              "model": {},
              "state": {
                "type": "structure",
                "required": [
                  "componentName",
                  "property",
                  "set"
                ],
                "members": {
                  "componentName": {},
                  "property": {},
                  "set": {
                    "shape": "Se"
                  }
                }
              },
              "target": {
                "shape": "Se"
              },
              "type": {
                "shape": "Se"
              },
              "url": {
                "shape": "Se"
              }
            }
          }
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "bindingProperties": {
          "shape": "Sf"
        },
        "bindings": {
          "type": "map",
          "key": {},
          "value": {
            "type": "structure",
            "required": [
              "element",
              "property"
            ],
            "members": {
              "element": {},
              "property": {}
            }
          }
        },
        "collectionBindingProperties": {
          "shape": "Sf"
        },
        "componentName": {},
        "concat": {
          "type": "list",
          "member": {
            "shape": "Se"
          }
        },
        "condition": {
          "type": "structure",
          "members": {
            "else": {
              "shape": "Se"
            },
            "field": {},
            "operand": {},
            "operandType": {},
            "operator": {},
            "property": {},
            "then": {
              "shape": "Se"
            }
          }
        },
        "configured": {
          "type": "boolean"
        },
        "defaultValue": {},
        "event": {},
        "importedValue": {},
        "model": {},
        "property": {},
        "type": {},
        "userAttribute": {},
        "value": {}
      }
    },
    "Sf": {
      "type": "structure",
      "required": [
        "property"
      ],
      "members": {
        "field": {},
        "property": {}
      }
    },
    "Sl": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "Se"
      }
    },
    "Sn": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "model"
        ],
        "members": {
          "identifiers": {
            "type": "list",
            "member": {}
          },
          "model": {},
          "predicate": {
            "shape": "S8"
          },
          "sort": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "direction",
                "field"
              ],
              "members": {
                "direction": {},
                "field": {}
              }
            }
          }
        }
      }
    },
    "Sv": {
      "type": "map",
      "key": {},
      "value": {
        "type": "map",
        "key": {},
        "value": {}
      }
    },
    "Sx": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S10": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "overrides": {
            "shape": "Sv"
          },
          "variantValues": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "S14": {
      "type": "structure",
      "required": [
        "appId",
        "bindingProperties",
        "componentType",
        "createdAt",
        "environmentName",
        "id",
        "name",
        "overrides",
        "properties",
        "variants"
      ],
      "members": {
        "appId": {},
        "bindingProperties": {
          "shape": "S4"
        },
        "children": {
          "shape": "S9"
        },
        "collectionProperties": {
          "shape": "Sn"
        },
        "componentType": {},
        "createdAt": {
          "shape": "S15"
        },
        "environmentName": {},
        "events": {
          "shape": "Sb"
        },
        "id": {},
        "modifiedAt": {
          "shape": "S15"
        },
        "name": {},
        "overrides": {
          "shape": "Sv"
        },
        "properties": {
          "shape": "Sl"
        },
        "schemaVersion": {},
        "sourceId": {},
        "tags": {
          "shape": "Sx"
        },
        "variants": {
          "shape": "S10"
        }
      }
    },
    "S15": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S19": {
      "type": "structure",
      "members": {
        "cancel": {
          "shape": "S1a"
        },
        "clear": {
          "shape": "S1a"
        },
        "position": {},
        "submit": {
          "shape": "S1a"
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "children": {},
        "excluded": {
          "type": "boolean"
        },
        "position": {
          "shape": "S1b"
        }
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "below": {},
        "fixed": {},
        "rightOf": {}
      },
      "union": true
    },
    "S1e": {
      "type": "structure",
      "required": [
        "dataSourceType",
        "dataTypeName"
      ],
      "members": {
        "dataSourceType": {},
        "dataTypeName": {}
      }
    },
    "S1g": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "excluded": {
            "type": "boolean"
          },
          "inputType": {
            "type": "structure",
            "required": [
              "type"
            ],
            "members": {
              "defaultChecked": {
                "type": "boolean"
              },
              "defaultCountryCode": {},
              "defaultValue": {},
              "descriptiveText": {},
              "isArray": {
                "type": "boolean"
              },
              "maxValue": {
                "type": "float"
              },
              "minValue": {
                "type": "float"
              },
              "name": {},
              "placeholder": {},
              "readOnly": {
                "type": "boolean"
              },
              "required": {
                "type": "boolean"
              },
              "step": {
                "type": "float"
              },
              "type": {},
              "value": {},
              "valueMappings": {
                "type": "structure",
                "required": [
                  "values"
                ],
                "members": {
                  "values": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "value"
                      ],
                      "members": {
                        "displayValue": {
                          "shape": "S1n"
                        },
                        "value": {
                          "shape": "S1n"
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "label": {},
          "position": {
            "shape": "S1b"
          },
          "validations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "type"
              ],
              "members": {
                "numValues": {
                  "type": "list",
                  "member": {
                    "type": "integer"
                  }
                },
                "strValues": {
                  "type": "list",
                  "member": {}
                },
                "type": {},
                "validationMessage": {}
              }
            }
          }
        }
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "value": {}
      }
    },
    "S1v": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "type"
        ],
        "members": {
          "level": {
            "type": "integer"
          },
          "orientation": {},
          "position": {
            "shape": "S1b"
          },
          "text": {},
          "type": {}
        }
      }
    },
    "S1x": {
      "type": "structure",
      "members": {
        "horizontalGap": {
          "shape": "S1y"
        },
        "outerPadding": {
          "shape": "S1y"
        },
        "verticalGap": {
          "shape": "S1y"
        }
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "tokenReference": {},
        "value": {}
      },
      "union": true
    },
    "S20": {
      "type": "structure",
      "required": [
        "appId",
        "dataType",
        "environmentName",
        "fields",
        "formActionType",
        "id",
        "name",
        "schemaVersion",
        "sectionalElements",
        "style"
      ],
      "members": {
        "appId": {},
        "cta": {
          "shape": "S19"
        },
        "dataType": {
          "shape": "S1e"
        },
        "environmentName": {},
        "fields": {
          "shape": "S1g"
        },
        "formActionType": {},
        "id": {},
        "name": {},
        "schemaVersion": {},
        "sectionalElements": {
          "shape": "S1v"
        },
        "style": {
          "shape": "S1x"
        },
        "tags": {
          "shape": "Sx"
        }
      }
    },
    "S24": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "key": {},
          "value": {
            "type": "structure",
            "members": {
              "children": {
                "shape": "S24"
              },
              "value": {}
            }
          }
        }
      }
    },
    "S28": {
      "type": "structure",
      "required": [
        "appId",
        "createdAt",
        "environmentName",
        "id",
        "name",
        "values"
      ],
      "members": {
        "appId": {},
        "createdAt": {
          "shape": "S15"
        },
        "environmentName": {},
        "id": {},
        "modifiedAt": {
          "shape": "S15"
        },
        "name": {},
        "overrides": {
          "shape": "S24"
        },
        "tags": {
          "shape": "Sx"
        },
        "values": {
          "shape": "S24"
        }
      }
    },
    "S2f": {
      "type": "string",
      "sensitive": true
    }
  }
}