import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {GoogleOAuthProvider, GoogleLogin, useGoogleOneTapLogin} from '@react-oauth/google';
import {CircularProgress, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {Auth} from './Auth';

const useStyles = makeStyles({
  login: {
    height: '90vh'
  }
});

function Login(props) {
  const classes = useStyles();
  const history = useHistory();
  const redirectPath = props.location.state?.from?.pathname || '/';
  const [isLoading, setIsLoading] = useState(false);

  const onSuccess = async (response) => {
    setIsLoading(true);
    const isLoginSusccessful = await Auth.handleLoginResponse(response);
    if (isLoginSusccessful) {
      history.push(redirectPath);
    } else {
      history.push('/unauthorized');
    }
  };

  const onError = (response) => {
    history.push('/unauthorized');
  };

  return (
    <Grid container
          justifyContent="center"
          alignItems="center"
          className={classes.login}>
      {isLoading ? (
        <CircularProgress style={{color: 'green'}}/>
      ) : (
        <GoogleOAuthProvider
            clientId = "675648525418-tmllumj8dnasb0ioomiimuf9q1pspove.apps.googleusercontent.com">
          <GoogleLogin onSuccess={onSuccess}
                       onError={onError}
                       useOneTap={true}
                       auto_select={true}
                       cancel_on_tap_outside={false}>
            Login with Google
          </GoogleLogin>
        </GoogleOAuthProvider>
      )}
    </Grid>
  );
}

export { Login };