{
  "pagination": {
    "ListJobRuns": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "jobRuns"
    },
    "ListJobTemplates": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "templates"
    },
    "ListManagedEndpoints": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "endpoints"
    },
    "ListVirtualClusters": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "virtualClusters"
    }
  }
}
