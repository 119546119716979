{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-05-18",
    "endpointPrefix": "athena",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Athena",
    "serviceId": "Athena",
    "signatureVersion": "v4",
    "targetPrefix": "AmazonAthena",
    "uid": "athena-2017-05-18"
  },
  "operations": {
    "BatchGetNamedQuery": {
      "input": {
        "type": "structure",
        "required": [
          "NamedQueryIds"
        ],
        "members": {
          "NamedQueryIds": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NamedQueries": {
            "type": "list",
            "member": {
              "shape": "S6"
            }
          },
          "UnprocessedNamedQueryIds": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "NamedQueryId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "BatchGetPreparedStatement": {
      "input": {
        "type": "structure",
        "required": [
          "PreparedStatementNames",
          "WorkGroup"
        ],
        "members": {
          "PreparedStatementNames": {
            "type": "list",
            "member": {}
          },
          "WorkGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PreparedStatements": {
            "type": "list",
            "member": {
              "shape": "Sl"
            }
          },
          "UnprocessedPreparedStatementNames": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StatementName": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "BatchGetQueryExecution": {
      "input": {
        "type": "structure",
        "required": [
          "QueryExecutionIds"
        ],
        "members": {
          "QueryExecutionIds": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryExecutions": {
            "type": "list",
            "member": {
              "shape": "Su"
            }
          },
          "UnprocessedQueryExecutionIds": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "QueryExecutionId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "CreateDataCatalog": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Type"
        ],
        "members": {
          "Name": {},
          "Type": {},
          "Description": {},
          "Parameters": {
            "shape": "S1p"
          },
          "Tags": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateNamedQuery": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Database",
          "QueryString"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Database": {},
          "QueryString": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "WorkGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NamedQueryId": {}
        }
      },
      "idempotent": true
    },
    "CreatePreparedStatement": {
      "input": {
        "type": "structure",
        "required": [
          "StatementName",
          "WorkGroup",
          "QueryStatement"
        ],
        "members": {
          "StatementName": {},
          "WorkGroup": {},
          "QueryStatement": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateWorkGroup": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Configuration": {
            "shape": "S23"
          },
          "Description": {},
          "Tags": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDataCatalog": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteNamedQuery": {
      "input": {
        "type": "structure",
        "required": [
          "NamedQueryId"
        ],
        "members": {
          "NamedQueryId": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeletePreparedStatement": {
      "input": {
        "type": "structure",
        "required": [
          "StatementName",
          "WorkGroup"
        ],
        "members": {
          "StatementName": {},
          "WorkGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteWorkGroup": {
      "input": {
        "type": "structure",
        "required": [
          "WorkGroup"
        ],
        "members": {
          "WorkGroup": {},
          "RecursiveDeleteOption": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetDataCatalog": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataCatalog": {
            "type": "structure",
            "required": [
              "Name",
              "Type"
            ],
            "members": {
              "Name": {},
              "Description": {},
              "Type": {},
              "Parameters": {
                "shape": "S1p"
              }
            }
          }
        }
      }
    },
    "GetDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "CatalogName",
          "DatabaseName"
        ],
        "members": {
          "CatalogName": {},
          "DatabaseName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Database": {
            "shape": "S2l"
          }
        }
      }
    },
    "GetNamedQuery": {
      "input": {
        "type": "structure",
        "required": [
          "NamedQueryId"
        ],
        "members": {
          "NamedQueryId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NamedQuery": {
            "shape": "S6"
          }
        }
      }
    },
    "GetPreparedStatement": {
      "input": {
        "type": "structure",
        "required": [
          "StatementName",
          "WorkGroup"
        ],
        "members": {
          "StatementName": {},
          "WorkGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PreparedStatement": {
            "shape": "Sl"
          }
        }
      }
    },
    "GetQueryExecution": {
      "input": {
        "type": "structure",
        "required": [
          "QueryExecutionId"
        ],
        "members": {
          "QueryExecutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryExecution": {
            "shape": "Su"
          }
        }
      }
    },
    "GetQueryResults": {
      "input": {
        "type": "structure",
        "required": [
          "QueryExecutionId"
        ],
        "members": {
          "QueryExecutionId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UpdateCount": {
            "type": "long"
          },
          "ResultSet": {
            "type": "structure",
            "members": {
              "Rows": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Data": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "VarCharValue": {}
                        }
                      }
                    }
                  }
                }
              },
              "ResultSetMetadata": {
                "type": "structure",
                "members": {
                  "ColumnInfo": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "Name",
                        "Type"
                      ],
                      "members": {
                        "CatalogName": {},
                        "SchemaName": {},
                        "TableName": {},
                        "Name": {},
                        "Label": {},
                        "Type": {},
                        "Precision": {
                          "type": "integer"
                        },
                        "Scale": {
                          "type": "integer"
                        },
                        "Nullable": {},
                        "CaseSensitive": {
                          "type": "boolean"
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetQueryRuntimeStatistics": {
      "input": {
        "type": "structure",
        "required": [
          "QueryExecutionId"
        ],
        "members": {
          "QueryExecutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryRuntimeStatistics": {
            "type": "structure",
            "members": {
              "Timeline": {
                "type": "structure",
                "members": {
                  "QueryQueueTimeInMillis": {
                    "type": "long"
                  },
                  "QueryPlanningTimeInMillis": {
                    "type": "long"
                  },
                  "EngineExecutionTimeInMillis": {
                    "type": "long"
                  },
                  "ServiceProcessingTimeInMillis": {
                    "type": "long"
                  },
                  "TotalExecutionTimeInMillis": {
                    "type": "long"
                  }
                }
              },
              "Rows": {
                "type": "structure",
                "members": {
                  "InputRows": {
                    "type": "long"
                  },
                  "InputBytes": {
                    "type": "long"
                  },
                  "OutputBytes": {
                    "type": "long"
                  },
                  "OutputRows": {
                    "type": "long"
                  }
                }
              },
              "OutputStage": {
                "shape": "S3c"
              }
            }
          }
        }
      }
    },
    "GetTableMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "CatalogName",
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "CatalogName": {},
          "DatabaseName": {},
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableMetadata": {
            "shape": "S3j"
          }
        }
      }
    },
    "GetWorkGroup": {
      "input": {
        "type": "structure",
        "required": [
          "WorkGroup"
        ],
        "members": {
          "WorkGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkGroup": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "State": {},
              "Configuration": {
                "shape": "S23"
              },
              "Description": {},
              "CreationTime": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "ListDataCatalogs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataCatalogsSummary": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CatalogName": {},
                "Type": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDatabases": {
      "input": {
        "type": "structure",
        "required": [
          "CatalogName"
        ],
        "members": {
          "CatalogName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatabaseList": {
            "type": "list",
            "member": {
              "shape": "S2l"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEngineVersions": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EngineVersions": {
            "type": "list",
            "member": {
              "shape": "S1i"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListNamedQueries": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "WorkGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NamedQueryIds": {
            "shape": "S2"
          },
          "NextToken": {}
        }
      }
    },
    "ListPreparedStatements": {
      "input": {
        "type": "structure",
        "required": [
          "WorkGroup"
        ],
        "members": {
          "WorkGroup": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PreparedStatements": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StatementName": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListQueryExecutions": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "WorkGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryExecutionIds": {
            "shape": "Sq"
          },
          "NextToken": {}
        }
      }
    },
    "ListTableMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "CatalogName",
          "DatabaseName"
        ],
        "members": {
          "CatalogName": {},
          "DatabaseName": {},
          "Expression": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableMetadataList": {
            "type": "list",
            "member": {
              "shape": "S3j"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S1s"
          },
          "NextToken": {}
        }
      }
    },
    "ListWorkGroups": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "State": {},
                "Description": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "EngineVersion": {
                  "shape": "S1i"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "StartQueryExecution": {
      "input": {
        "type": "structure",
        "required": [
          "QueryString"
        ],
        "members": {
          "QueryString": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "QueryExecutionContext": {
            "shape": "S18"
          },
          "ResultConfiguration": {
            "shape": "Sw"
          },
          "WorkGroup": {},
          "ExecutionParameters": {
            "shape": "S1j"
          },
          "ResultReuseConfiguration": {
            "shape": "S14"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryExecutionId": {}
        }
      },
      "idempotent": true
    },
    "StopQueryExecution": {
      "input": {
        "type": "structure",
        "required": [
          "QueryExecutionId"
        ],
        "members": {
          "QueryExecutionId": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDataCatalog": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Type"
        ],
        "members": {
          "Name": {},
          "Type": {},
          "Description": {},
          "Parameters": {
            "shape": "S1p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateNamedQuery": {
      "input": {
        "type": "structure",
        "required": [
          "NamedQueryId",
          "Name",
          "QueryString"
        ],
        "members": {
          "NamedQueryId": {},
          "Name": {},
          "Description": {},
          "QueryString": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdatePreparedStatement": {
      "input": {
        "type": "structure",
        "required": [
          "StatementName",
          "WorkGroup",
          "QueryStatement"
        ],
        "members": {
          "StatementName": {},
          "WorkGroup": {},
          "QueryStatement": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateWorkGroup": {
      "input": {
        "type": "structure",
        "required": [
          "WorkGroup"
        ],
        "members": {
          "WorkGroup": {},
          "Description": {},
          "ConfigurationUpdates": {
            "type": "structure",
            "members": {
              "EnforceWorkGroupConfiguration": {
                "type": "boolean"
              },
              "ResultConfigurationUpdates": {
                "type": "structure",
                "members": {
                  "OutputLocation": {},
                  "RemoveOutputLocation": {
                    "type": "boolean"
                  },
                  "EncryptionConfiguration": {
                    "shape": "Sy"
                  },
                  "RemoveEncryptionConfiguration": {
                    "type": "boolean"
                  },
                  "ExpectedBucketOwner": {},
                  "RemoveExpectedBucketOwner": {
                    "type": "boolean"
                  },
                  "AclConfiguration": {
                    "shape": "S12"
                  },
                  "RemoveAclConfiguration": {
                    "type": "boolean"
                  }
                }
              },
              "PublishCloudWatchMetricsEnabled": {
                "type": "boolean"
              },
              "BytesScannedCutoffPerQuery": {
                "type": "long"
              },
              "RemoveBytesScannedCutoffPerQuery": {
                "type": "boolean"
              },
              "RequesterPaysEnabled": {
                "type": "boolean"
              },
              "EngineVersion": {
                "shape": "S1i"
              }
            }
          },
          "State": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "S6": {
      "type": "structure",
      "required": [
        "Name",
        "Database",
        "QueryString"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "Database": {},
        "QueryString": {},
        "NamedQueryId": {},
        "WorkGroup": {}
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "StatementName": {},
        "QueryStatement": {},
        "WorkGroupName": {},
        "Description": {},
        "LastModifiedTime": {
          "type": "timestamp"
        }
      }
    },
    "Sq": {
      "type": "list",
      "member": {}
    },
    "Su": {
      "type": "structure",
      "members": {
        "QueryExecutionId": {},
        "Query": {},
        "StatementType": {},
        "ResultConfiguration": {
          "shape": "Sw"
        },
        "ResultReuseConfiguration": {
          "shape": "S14"
        },
        "QueryExecutionContext": {
          "shape": "S18"
        },
        "Status": {
          "type": "structure",
          "members": {
            "State": {},
            "StateChangeReason": {},
            "SubmissionDateTime": {
              "type": "timestamp"
            },
            "CompletionDateTime": {
              "type": "timestamp"
            },
            "AthenaError": {
              "type": "structure",
              "members": {
                "ErrorCategory": {
                  "type": "integer"
                },
                "ErrorType": {
                  "type": "integer"
                },
                "Retryable": {
                  "type": "boolean"
                },
                "ErrorMessage": {}
              }
            }
          }
        },
        "Statistics": {
          "type": "structure",
          "members": {
            "EngineExecutionTimeInMillis": {
              "type": "long"
            },
            "DataScannedInBytes": {
              "type": "long"
            },
            "DataManifestLocation": {},
            "TotalExecutionTimeInMillis": {
              "type": "long"
            },
            "QueryQueueTimeInMillis": {
              "type": "long"
            },
            "QueryPlanningTimeInMillis": {
              "type": "long"
            },
            "ServiceProcessingTimeInMillis": {
              "type": "long"
            },
            "ResultReuseInformation": {
              "type": "structure",
              "required": [
                "ReusedPreviousResult"
              ],
              "members": {
                "ReusedPreviousResult": {
                  "type": "boolean"
                }
              }
            }
          }
        },
        "WorkGroup": {},
        "EngineVersion": {
          "shape": "S1i"
        },
        "ExecutionParameters": {
          "shape": "S1j"
        }
      }
    },
    "Sw": {
      "type": "structure",
      "members": {
        "OutputLocation": {},
        "EncryptionConfiguration": {
          "shape": "Sy"
        },
        "ExpectedBucketOwner": {},
        "AclConfiguration": {
          "shape": "S12"
        }
      }
    },
    "Sy": {
      "type": "structure",
      "required": [
        "EncryptionOption"
      ],
      "members": {
        "EncryptionOption": {},
        "KmsKey": {}
      }
    },
    "S12": {
      "type": "structure",
      "required": [
        "S3AclOption"
      ],
      "members": {
        "S3AclOption": {}
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "ResultReuseByAgeConfiguration": {
          "type": "structure",
          "required": [
            "Enabled"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "MaxAgeInMinutes": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S18": {
      "type": "structure",
      "members": {
        "Database": {},
        "Catalog": {}
      }
    },
    "S1i": {
      "type": "structure",
      "members": {
        "SelectedEngineVersion": {},
        "EffectiveEngineVersion": {}
      }
    },
    "S1j": {
      "type": "list",
      "member": {}
    },
    "S1p": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1s": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S23": {
      "type": "structure",
      "members": {
        "ResultConfiguration": {
          "shape": "Sw"
        },
        "EnforceWorkGroupConfiguration": {
          "type": "boolean"
        },
        "PublishCloudWatchMetricsEnabled": {
          "type": "boolean"
        },
        "BytesScannedCutoffPerQuery": {
          "type": "long"
        },
        "RequesterPaysEnabled": {
          "type": "boolean"
        },
        "EngineVersion": {
          "shape": "S1i"
        }
      }
    },
    "S2l": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "Parameters": {
          "shape": "S1p"
        }
      }
    },
    "S3c": {
      "type": "structure",
      "members": {
        "StageId": {
          "type": "long"
        },
        "State": {},
        "OutputBytes": {
          "type": "long"
        },
        "OutputRows": {
          "type": "long"
        },
        "InputBytes": {
          "type": "long"
        },
        "InputRows": {
          "type": "long"
        },
        "ExecutionTime": {
          "type": "long"
        },
        "QueryStagePlan": {
          "shape": "S3d"
        },
        "SubStages": {
          "type": "list",
          "member": {
            "shape": "S3c"
          }
        }
      }
    },
    "S3d": {
      "type": "structure",
      "members": {
        "Name": {},
        "Identifier": {},
        "Children": {
          "type": "list",
          "member": {
            "shape": "S3d"
          }
        },
        "RemoteSources": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S3j": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "CreateTime": {
          "type": "timestamp"
        },
        "LastAccessTime": {
          "type": "timestamp"
        },
        "TableType": {},
        "Columns": {
          "shape": "S3m"
        },
        "PartitionKeys": {
          "shape": "S3m"
        },
        "Parameters": {
          "shape": "S1p"
        }
      }
    },
    "S3m": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Type": {},
          "Comment": {}
        }
      }
    }
  }
}