{
  "pagination": {
    "GetApiKeys": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetBasePathMappings": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetClientCertificates": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetDeployments": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetDomainNames": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetModels": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetResources": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetRestApis": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetUsage": {
      "input_token": "position",
      "limit_key": "limit",
      "non_aggregate_keys": [
        "usagePlanId",
        "startDate",
        "endDate"
      ],
      "output_token": "position",
      "result_key": "items"
    },
    "GetUsagePlanKeys": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetUsagePlans": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetVpcLinks": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    }
  }
}