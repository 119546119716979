{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-10-26",
    "endpointPrefix": "transcribe",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Transcribe Service",
    "serviceId": "Transcribe",
    "signatureVersion": "v4",
    "signingName": "transcribe",
    "targetPrefix": "Transcribe",
    "uid": "transcribe-2017-10-26"
  },
  "operations": {
    "CreateCallAnalyticsCategory": {
      "input": {
        "type": "structure",
        "required": [
          "CategoryName",
          "Rules"
        ],
        "members": {
          "CategoryName": {},
          "Rules": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CategoryProperties": {
            "shape": "Sl"
          }
        }
      }
    },
    "CreateLanguageModel": {
      "input": {
        "type": "structure",
        "required": [
          "LanguageCode",
          "BaseModelName",
          "ModelName",
          "InputDataConfig"
        ],
        "members": {
          "LanguageCode": {},
          "BaseModelName": {},
          "ModelName": {},
          "InputDataConfig": {
            "shape": "Sr"
          },
          "Tags": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LanguageCode": {},
          "BaseModelName": {},
          "ModelName": {},
          "InputDataConfig": {
            "shape": "Sr"
          },
          "ModelStatus": {}
        }
      }
    },
    "CreateMedicalVocabulary": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyName",
          "LanguageCode",
          "VocabularyFileUri"
        ],
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "VocabularyFileUri": {},
          "Tags": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "VocabularyState": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "FailureReason": {}
        }
      }
    },
    "CreateVocabulary": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyName",
          "LanguageCode"
        ],
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "Phrases": {
            "shape": "S17"
          },
          "VocabularyFileUri": {},
          "Tags": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "VocabularyState": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "FailureReason": {}
        }
      }
    },
    "CreateVocabularyFilter": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyFilterName",
          "LanguageCode"
        ],
        "members": {
          "VocabularyFilterName": {},
          "LanguageCode": {},
          "Words": {
            "shape": "S1c"
          },
          "VocabularyFilterFileUri": {},
          "Tags": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularyFilterName": {},
          "LanguageCode": {},
          "LastModifiedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DeleteCallAnalyticsCategory": {
      "input": {
        "type": "structure",
        "required": [
          "CategoryName"
        ],
        "members": {
          "CategoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteCallAnalyticsJob": {
      "input": {
        "type": "structure",
        "required": [
          "CallAnalyticsJobName"
        ],
        "members": {
          "CallAnalyticsJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteLanguageModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName"
        ],
        "members": {
          "ModelName": {}
        }
      }
    },
    "DeleteMedicalTranscriptionJob": {
      "input": {
        "type": "structure",
        "required": [
          "MedicalTranscriptionJobName"
        ],
        "members": {
          "MedicalTranscriptionJobName": {}
        }
      }
    },
    "DeleteMedicalVocabulary": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyName"
        ],
        "members": {
          "VocabularyName": {}
        }
      }
    },
    "DeleteTranscriptionJob": {
      "input": {
        "type": "structure",
        "required": [
          "TranscriptionJobName"
        ],
        "members": {
          "TranscriptionJobName": {}
        }
      }
    },
    "DeleteVocabulary": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyName"
        ],
        "members": {
          "VocabularyName": {}
        }
      }
    },
    "DeleteVocabularyFilter": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyFilterName"
        ],
        "members": {
          "VocabularyFilterName": {}
        }
      }
    },
    "DescribeLanguageModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName"
        ],
        "members": {
          "ModelName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LanguageModel": {
            "shape": "S1t"
          }
        }
      }
    },
    "GetCallAnalyticsCategory": {
      "input": {
        "type": "structure",
        "required": [
          "CategoryName"
        ],
        "members": {
          "CategoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CategoryProperties": {
            "shape": "Sl"
          }
        }
      }
    },
    "GetCallAnalyticsJob": {
      "input": {
        "type": "structure",
        "required": [
          "CallAnalyticsJobName"
        ],
        "members": {
          "CallAnalyticsJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CallAnalyticsJob": {
            "shape": "S1y"
          }
        }
      }
    },
    "GetMedicalTranscriptionJob": {
      "input": {
        "type": "structure",
        "required": [
          "MedicalTranscriptionJobName"
        ],
        "members": {
          "MedicalTranscriptionJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MedicalTranscriptionJob": {
            "shape": "S2k"
          }
        }
      }
    },
    "GetMedicalVocabulary": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyName"
        ],
        "members": {
          "VocabularyName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "VocabularyState": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "DownloadUri": {}
        }
      }
    },
    "GetTranscriptionJob": {
      "input": {
        "type": "structure",
        "required": [
          "TranscriptionJobName"
        ],
        "members": {
          "TranscriptionJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TranscriptionJob": {
            "shape": "S2y"
          }
        }
      }
    },
    "GetVocabulary": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyName"
        ],
        "members": {
          "VocabularyName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "VocabularyState": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "DownloadUri": {}
        }
      }
    },
    "GetVocabularyFilter": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyFilterName"
        ],
        "members": {
          "VocabularyFilterName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularyFilterName": {},
          "LanguageCode": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "DownloadUri": {}
        }
      }
    },
    "ListCallAnalyticsCategories": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Categories": {
            "type": "list",
            "member": {
              "shape": "Sl"
            }
          }
        }
      }
    },
    "ListCallAnalyticsJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Status": {},
          "JobNameContains": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {},
          "NextToken": {},
          "CallAnalyticsJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CallAnalyticsJobName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "StartTime": {
                  "type": "timestamp"
                },
                "CompletionTime": {
                  "type": "timestamp"
                },
                "LanguageCode": {},
                "CallAnalyticsJobStatus": {},
                "FailureReason": {}
              }
            }
          }
        }
      }
    },
    "ListLanguageModels": {
      "input": {
        "type": "structure",
        "members": {
          "StatusEquals": {},
          "NameContains": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Models": {
            "type": "list",
            "member": {
              "shape": "S1t"
            }
          }
        }
      }
    },
    "ListMedicalTranscriptionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Status": {},
          "JobNameContains": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {},
          "NextToken": {},
          "MedicalTranscriptionJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MedicalTranscriptionJobName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "StartTime": {
                  "type": "timestamp"
                },
                "CompletionTime": {
                  "type": "timestamp"
                },
                "LanguageCode": {},
                "TranscriptionJobStatus": {},
                "FailureReason": {},
                "OutputLocationType": {},
                "Specialty": {},
                "ContentIdentificationType": {},
                "Type": {}
              }
            }
          }
        }
      }
    },
    "ListMedicalVocabularies": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "StateEquals": {},
          "NameContains": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {},
          "NextToken": {},
          "Vocabularies": {
            "shape": "S3x"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "Su"
          }
        }
      }
    },
    "ListTranscriptionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Status": {},
          "JobNameContains": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {},
          "NextToken": {},
          "TranscriptionJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TranscriptionJobName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "StartTime": {
                  "type": "timestamp"
                },
                "CompletionTime": {
                  "type": "timestamp"
                },
                "LanguageCode": {},
                "TranscriptionJobStatus": {},
                "FailureReason": {},
                "OutputLocationType": {},
                "ContentRedaction": {
                  "shape": "S27"
                },
                "ModelSettings": {
                  "shape": "S30"
                },
                "IdentifyLanguage": {
                  "type": "boolean"
                },
                "IdentifyMultipleLanguages": {
                  "type": "boolean"
                },
                "IdentifiedLanguageScore": {
                  "type": "float"
                },
                "LanguageCodes": {
                  "shape": "S32"
                }
              }
            }
          }
        }
      }
    },
    "ListVocabularies": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "StateEquals": {},
          "NameContains": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {},
          "NextToken": {},
          "Vocabularies": {
            "shape": "S3x"
          }
        }
      }
    },
    "ListVocabularyFilters": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "VocabularyFilters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VocabularyFilterName": {},
                "LanguageCode": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "StartCallAnalyticsJob": {
      "input": {
        "type": "structure",
        "required": [
          "CallAnalyticsJobName",
          "Media"
        ],
        "members": {
          "CallAnalyticsJobName": {},
          "Media": {
            "shape": "S22"
          },
          "OutputLocation": {},
          "OutputEncryptionKMSKeyId": {},
          "DataAccessRoleArn": {},
          "Settings": {
            "shape": "S25"
          },
          "ChannelDefinitions": {
            "shape": "S2f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CallAnalyticsJob": {
            "shape": "S1y"
          }
        }
      }
    },
    "StartMedicalTranscriptionJob": {
      "input": {
        "type": "structure",
        "required": [
          "MedicalTranscriptionJobName",
          "LanguageCode",
          "Media",
          "OutputBucketName",
          "Specialty",
          "Type"
        ],
        "members": {
          "MedicalTranscriptionJobName": {},
          "LanguageCode": {},
          "MediaSampleRateHertz": {
            "type": "integer"
          },
          "MediaFormat": {},
          "Media": {
            "shape": "S22"
          },
          "OutputBucketName": {},
          "OutputKey": {},
          "OutputEncryptionKMSKeyId": {},
          "KMSEncryptionContext": {
            "shape": "S4i"
          },
          "Settings": {
            "shape": "S2o"
          },
          "ContentIdentificationType": {},
          "Specialty": {},
          "Type": {},
          "Tags": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MedicalTranscriptionJob": {
            "shape": "S2k"
          }
        }
      }
    },
    "StartTranscriptionJob": {
      "input": {
        "type": "structure",
        "required": [
          "TranscriptionJobName",
          "Media"
        ],
        "members": {
          "TranscriptionJobName": {},
          "LanguageCode": {},
          "MediaSampleRateHertz": {
            "type": "integer"
          },
          "MediaFormat": {},
          "Media": {
            "shape": "S22"
          },
          "OutputBucketName": {},
          "OutputKey": {},
          "OutputEncryptionKMSKeyId": {},
          "KMSEncryptionContext": {
            "shape": "S4i"
          },
          "Settings": {
            "shape": "S2z"
          },
          "ModelSettings": {
            "shape": "S30"
          },
          "JobExecutionSettings": {
            "shape": "S31"
          },
          "ContentRedaction": {
            "shape": "S27"
          },
          "IdentifyLanguage": {
            "type": "boolean"
          },
          "IdentifyMultipleLanguages": {
            "type": "boolean"
          },
          "LanguageOptions": {
            "shape": "S2c"
          },
          "Subtitles": {
            "type": "structure",
            "members": {
              "Formats": {
                "shape": "S36"
              },
              "OutputStartIndex": {
                "type": "integer"
              }
            }
          },
          "Tags": {
            "shape": "Su"
          },
          "LanguageIdSettings": {
            "shape": "S2d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TranscriptionJob": {
            "shape": "S2y"
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateCallAnalyticsCategory": {
      "input": {
        "type": "structure",
        "required": [
          "CategoryName",
          "Rules"
        ],
        "members": {
          "CategoryName": {},
          "Rules": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CategoryProperties": {
            "shape": "Sl"
          }
        }
      }
    },
    "UpdateMedicalVocabulary": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyName",
          "LanguageCode",
          "VocabularyFileUri"
        ],
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "VocabularyFileUri": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "VocabularyState": {}
        }
      }
    },
    "UpdateVocabulary": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyName",
          "LanguageCode"
        ],
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "Phrases": {
            "shape": "S17"
          },
          "VocabularyFileUri": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "VocabularyState": {}
        }
      }
    },
    "UpdateVocabularyFilter": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyFilterName"
        ],
        "members": {
          "VocabularyFilterName": {},
          "Words": {
            "shape": "S1c"
          },
          "VocabularyFilterFileUri": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularyFilterName": {},
          "LanguageCode": {},
          "LastModifiedTime": {
            "type": "timestamp"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "NonTalkTimeFilter": {
            "type": "structure",
            "members": {
              "Threshold": {
                "type": "long"
              },
              "AbsoluteTimeRange": {
                "shape": "S7"
              },
              "RelativeTimeRange": {
                "shape": "S8"
              },
              "Negate": {
                "type": "boolean"
              }
            }
          },
          "InterruptionFilter": {
            "type": "structure",
            "members": {
              "Threshold": {
                "type": "long"
              },
              "ParticipantRole": {},
              "AbsoluteTimeRange": {
                "shape": "S7"
              },
              "RelativeTimeRange": {
                "shape": "S8"
              },
              "Negate": {
                "type": "boolean"
              }
            }
          },
          "TranscriptFilter": {
            "type": "structure",
            "required": [
              "TranscriptFilterType",
              "Targets"
            ],
            "members": {
              "TranscriptFilterType": {},
              "AbsoluteTimeRange": {
                "shape": "S7"
              },
              "RelativeTimeRange": {
                "shape": "S8"
              },
              "ParticipantRole": {},
              "Negate": {
                "type": "boolean"
              },
              "Targets": {
                "type": "list",
                "member": {}
              }
            }
          },
          "SentimentFilter": {
            "type": "structure",
            "required": [
              "Sentiments"
            ],
            "members": {
              "Sentiments": {
                "type": "list",
                "member": {}
              },
              "AbsoluteTimeRange": {
                "shape": "S7"
              },
              "RelativeTimeRange": {
                "shape": "S8"
              },
              "ParticipantRole": {},
              "Negate": {
                "type": "boolean"
              }
            }
          }
        },
        "union": true
      }
    },
    "S7": {
      "type": "structure",
      "members": {
        "StartTime": {
          "type": "long"
        },
        "EndTime": {
          "type": "long"
        },
        "First": {
          "type": "long"
        },
        "Last": {
          "type": "long"
        }
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "StartPercentage": {
          "type": "integer"
        },
        "EndPercentage": {
          "type": "integer"
        },
        "First": {
          "type": "integer"
        },
        "Last": {
          "type": "integer"
        }
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "CategoryName": {},
        "Rules": {
          "shape": "S3"
        },
        "CreateTime": {
          "type": "timestamp"
        },
        "LastUpdateTime": {
          "type": "timestamp"
        }
      }
    },
    "Sr": {
      "type": "structure",
      "required": [
        "S3Uri",
        "DataAccessRoleArn"
      ],
      "members": {
        "S3Uri": {},
        "TuningDataS3Uri": {},
        "DataAccessRoleArn": {}
      }
    },
    "Su": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S17": {
      "type": "list",
      "member": {}
    },
    "S1c": {
      "type": "list",
      "member": {}
    },
    "S1t": {
      "type": "structure",
      "members": {
        "ModelName": {},
        "CreateTime": {
          "type": "timestamp"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "LanguageCode": {},
        "BaseModelName": {},
        "ModelStatus": {},
        "UpgradeAvailability": {
          "type": "boolean"
        },
        "FailureReason": {},
        "InputDataConfig": {
          "shape": "Sr"
        }
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "CallAnalyticsJobName": {},
        "CallAnalyticsJobStatus": {},
        "LanguageCode": {},
        "MediaSampleRateHertz": {
          "type": "integer"
        },
        "MediaFormat": {},
        "Media": {
          "shape": "S22"
        },
        "Transcript": {
          "shape": "S23"
        },
        "StartTime": {
          "type": "timestamp"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "CompletionTime": {
          "type": "timestamp"
        },
        "FailureReason": {},
        "DataAccessRoleArn": {},
        "IdentifiedLanguageScore": {
          "type": "float"
        },
        "Settings": {
          "shape": "S25"
        },
        "ChannelDefinitions": {
          "shape": "S2f"
        }
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "MediaFileUri": {},
        "RedactedMediaFileUri": {}
      }
    },
    "S23": {
      "type": "structure",
      "members": {
        "TranscriptFileUri": {},
        "RedactedTranscriptFileUri": {}
      }
    },
    "S25": {
      "type": "structure",
      "members": {
        "VocabularyName": {},
        "VocabularyFilterName": {},
        "VocabularyFilterMethod": {},
        "LanguageModelName": {},
        "ContentRedaction": {
          "shape": "S27"
        },
        "LanguageOptions": {
          "shape": "S2c"
        },
        "LanguageIdSettings": {
          "shape": "S2d"
        }
      }
    },
    "S27": {
      "type": "structure",
      "required": [
        "RedactionType",
        "RedactionOutput"
      ],
      "members": {
        "RedactionType": {},
        "RedactionOutput": {},
        "PiiEntityTypes": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S2c": {
      "type": "list",
      "member": {}
    },
    "S2d": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "VocabularyName": {},
          "VocabularyFilterName": {},
          "LanguageModelName": {}
        }
      }
    },
    "S2f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ChannelId": {
            "type": "integer"
          },
          "ParticipantRole": {}
        }
      }
    },
    "S2k": {
      "type": "structure",
      "members": {
        "MedicalTranscriptionJobName": {},
        "TranscriptionJobStatus": {},
        "LanguageCode": {},
        "MediaSampleRateHertz": {
          "type": "integer"
        },
        "MediaFormat": {},
        "Media": {
          "shape": "S22"
        },
        "Transcript": {
          "type": "structure",
          "members": {
            "TranscriptFileUri": {}
          }
        },
        "StartTime": {
          "type": "timestamp"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "CompletionTime": {
          "type": "timestamp"
        },
        "FailureReason": {},
        "Settings": {
          "shape": "S2o"
        },
        "ContentIdentificationType": {},
        "Specialty": {},
        "Type": {},
        "Tags": {
          "shape": "Su"
        }
      }
    },
    "S2o": {
      "type": "structure",
      "members": {
        "ShowSpeakerLabels": {
          "type": "boolean"
        },
        "MaxSpeakerLabels": {
          "type": "integer"
        },
        "ChannelIdentification": {
          "type": "boolean"
        },
        "ShowAlternatives": {
          "type": "boolean"
        },
        "MaxAlternatives": {
          "type": "integer"
        },
        "VocabularyName": {}
      }
    },
    "S2y": {
      "type": "structure",
      "members": {
        "TranscriptionJobName": {},
        "TranscriptionJobStatus": {},
        "LanguageCode": {},
        "MediaSampleRateHertz": {
          "type": "integer"
        },
        "MediaFormat": {},
        "Media": {
          "shape": "S22"
        },
        "Transcript": {
          "shape": "S23"
        },
        "StartTime": {
          "type": "timestamp"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "CompletionTime": {
          "type": "timestamp"
        },
        "FailureReason": {},
        "Settings": {
          "shape": "S2z"
        },
        "ModelSettings": {
          "shape": "S30"
        },
        "JobExecutionSettings": {
          "shape": "S31"
        },
        "ContentRedaction": {
          "shape": "S27"
        },
        "IdentifyLanguage": {
          "type": "boolean"
        },
        "IdentifyMultipleLanguages": {
          "type": "boolean"
        },
        "LanguageOptions": {
          "shape": "S2c"
        },
        "IdentifiedLanguageScore": {
          "type": "float"
        },
        "LanguageCodes": {
          "shape": "S32"
        },
        "Tags": {
          "shape": "Su"
        },
        "Subtitles": {
          "type": "structure",
          "members": {
            "Formats": {
              "shape": "S36"
            },
            "SubtitleFileUris": {
              "type": "list",
              "member": {}
            },
            "OutputStartIndex": {
              "type": "integer"
            }
          }
        },
        "LanguageIdSettings": {
          "shape": "S2d"
        }
      }
    },
    "S2z": {
      "type": "structure",
      "members": {
        "VocabularyName": {},
        "ShowSpeakerLabels": {
          "type": "boolean"
        },
        "MaxSpeakerLabels": {
          "type": "integer"
        },
        "ChannelIdentification": {
          "type": "boolean"
        },
        "ShowAlternatives": {
          "type": "boolean"
        },
        "MaxAlternatives": {
          "type": "integer"
        },
        "VocabularyFilterName": {},
        "VocabularyFilterMethod": {}
      }
    },
    "S30": {
      "type": "structure",
      "members": {
        "LanguageModelName": {}
      }
    },
    "S31": {
      "type": "structure",
      "members": {
        "AllowDeferredExecution": {
          "type": "boolean"
        },
        "DataAccessRoleArn": {}
      }
    },
    "S32": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "LanguageCode": {},
          "DurationInSeconds": {
            "type": "float"
          }
        }
      }
    },
    "S36": {
      "type": "list",
      "member": {}
    },
    "S3x": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "VocabularyState": {}
        }
      }
    },
    "S4i": {
      "type": "map",
      "key": {},
      "value": {}
    }
  }
}