{
  "pagination": {
    "DescribeBuckets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "buckets"
    },
    "GetUsageStatistics": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "records"
    },
    "ListClassificationJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListCustomDataIdentifiers": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListFindings": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "findingIds"
    },
    "ListFindingsFilters": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "findingsFilterListItems"
    },
    "ListInvitations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "invitations"
    },
    "ListMembers": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "members"
    },
    "ListOrganizationAdminAccounts": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "adminAccounts"
    },
    "SearchResources": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "matchingResources"
    }
  }
}
