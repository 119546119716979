import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';

const useStyles = makeStyles({
  main: {
    height: '90vh'
  }
});

function Unauthorized(props) {
  const classes = useStyles();
  return (
    <Grid container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.main}>
      <Typography variant="h6">
        Access to this website is restricted because you are not authorized.
      </Typography>
      <Typography variant="h6">
        To request access, please contact the website administrator.
      </Typography>
    </Grid>
  );
}

export { Unauthorized };