{
  "pagination": {
    "AdminListGroupsForUser": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "Groups"
    },
    "AdminListUserAuthEvents": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AuthEvents"
    },
    "ListGroups": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "Groups"
    },
    "ListIdentityProviders": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Providers"
    },
    "ListResourceServers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ResourceServers"
    },
    "ListUserPoolClients": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "UserPoolClients"
    },
    "ListUserPools": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "UserPools"
    },
    "ListUsers": {
      "input_token": "PaginationToken",
      "limit_key": "Limit",
      "output_token": "PaginationToken",
      "result_key": "Users"
    },
    "ListUsersInGroup": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "Users"
    }
  }
}