{
  "version": 2,
  "waiters": {
    "ResourceRecordSetsChanged": {
      "delay": 30,
      "maxAttempts": 60,
      "operation": "GetChange",
      "acceptors": [
        {
          "matcher": "path",
          "expected": "INSYNC",
          "argument": "ChangeInfo.Status",
          "state": "success"
        }
      ]
    }
  }
}
