import { Auth } from "../components/core/Auth";
import appconfig from "../appconfig";

const API_ADDRESS = appconfig.documentUrl;

// Default headers for POST requests
const headers = new Headers()
headers.append('Content-Type', 'application/json')

export const createDocument = async (data) => {
  const url = new URL(`${API_ADDRESS}/documents`);
  const response = await fetch(url, {
    credentials: 'include',
    method: "POST",
    body: JSON.stringify(data),
    headers,
  });

  return await Auth.handleResponse(response);
};

export const searchDocuments = async (searchText) => {
  const url = new URL(`${API_ADDRESS}/documents`);
  url.searchParams.append("filter", searchText);
  const response = await fetch(url, {
    credentials: 'include',
  });

  return await Auth.handleResponse(response);
};

export const getDocument = async (id) => {
  const url = new URL(`${API_ADDRESS}/documents/${id}`);
  const response = await fetch(url, {
    credentials: 'include',
  });

  return await Auth.handleResponse(response);
};

export const listDocumentVersions = async (id) => {
  const url = new URL(`${API_ADDRESS}/documents/${id}/versions`);
  const response = await fetch(url, {
    credentials: 'include',
  });

  return await Auth.handleResponse(response);
};

export const updateDocument = async (data) => {
  const url = new URL(`${API_ADDRESS}/documents`);
  const response = await fetch(url, {
    credentials: 'include',
    method: "PUT",
    body: JSON.stringify(data),
    headers
  });

  return await Auth.handleResponse(response);
};

export const deleteDocument = async (id) => {
  const url = new URL(`${API_ADDRESS}/documents/${id}`);
  const response = await fetch(url, {
    credentials: 'include',
    method: "DELETE",
  });

  return await Auth.handleResponse(response);
}