{
  "pagination": {
    "ListEnabledControls": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "enabledControls"
    }
  }
}
