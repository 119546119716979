{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-07-30",
    "endpointPrefix": "billingconductor",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWSBillingConductor",
    "serviceId": "billingconductor",
    "signatureVersion": "v4",
    "signingName": "billingconductor",
    "uid": "billingconductor-2021-07-30"
  },
  "operations": {
    "AssociateAccounts": {
      "http": {
        "requestUri": "/associate-accounts",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "AccountIds"
        ],
        "members": {
          "Arn": {},
          "AccountIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "idempotent": true
    },
    "AssociatePricingRules": {
      "http": {
        "method": "PUT",
        "requestUri": "/associate-pricing-rules",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "PricingRuleArns"
        ],
        "members": {
          "Arn": {},
          "PricingRuleArns": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "idempotent": true
    },
    "BatchAssociateResourcesToCustomLineItem": {
      "http": {
        "method": "PUT",
        "requestUri": "/batch-associate-resources-to-custom-line-item",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TargetArn",
          "ResourceArns"
        ],
        "members": {
          "TargetArn": {},
          "ResourceArns": {
            "type": "list",
            "member": {}
          },
          "BillingPeriodRange": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfullyAssociatedResources": {
            "shape": "Si"
          },
          "FailedAssociatedResources": {
            "shape": "Si"
          }
        }
      },
      "idempotent": true
    },
    "BatchDisassociateResourcesFromCustomLineItem": {
      "http": {
        "method": "PUT",
        "requestUri": "/batch-disassociate-resources-from-custom-line-item",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TargetArn",
          "ResourceArns"
        ],
        "members": {
          "TargetArn": {},
          "ResourceArns": {
            "type": "list",
            "member": {}
          },
          "BillingPeriodRange": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfullyDisassociatedResources": {
            "shape": "Sq"
          },
          "FailedDisassociatedResources": {
            "shape": "Sq"
          }
        }
      },
      "idempotent": true
    },
    "CreateBillingGroup": {
      "http": {
        "requestUri": "/create-billing-group",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "AccountGrouping",
          "ComputationPreference"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amzn-Client-Token"
          },
          "Name": {
            "shape": "Su"
          },
          "AccountGrouping": {
            "type": "structure",
            "required": [
              "LinkedAccountIds"
            ],
            "members": {
              "LinkedAccountIds": {
                "shape": "S3"
              }
            }
          },
          "ComputationPreference": {
            "shape": "Sw"
          },
          "PrimaryAccountId": {},
          "Description": {
            "shape": "Sy"
          },
          "Tags": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "idempotent": true
    },
    "CreateCustomLineItem": {
      "http": {
        "requestUri": "/create-custom-line-item",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Description",
          "BillingGroupArn",
          "ChargeDetails"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amzn-Client-Token"
          },
          "Name": {
            "shape": "S14"
          },
          "Description": {
            "shape": "S15"
          },
          "BillingGroupArn": {},
          "BillingPeriodRange": {
            "shape": "Sf"
          },
          "Tags": {
            "shape": "Sz"
          },
          "ChargeDetails": {
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Flat": {
                "type": "structure",
                "required": [
                  "ChargeValue"
                ],
                "members": {
                  "ChargeValue": {
                    "type": "double"
                  }
                }
              },
              "Percentage": {
                "type": "structure",
                "required": [
                  "PercentageValue"
                ],
                "members": {
                  "PercentageValue": {
                    "type": "double"
                  },
                  "AssociatedValues": {
                    "type": "list",
                    "member": {}
                  }
                }
              },
              "Type": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      }
    },
    "CreatePricingPlan": {
      "http": {
        "requestUri": "/create-pricing-plan",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amzn-Client-Token"
          },
          "Name": {
            "shape": "S1f"
          },
          "Description": {
            "shape": "S1g"
          },
          "PricingRuleArns": {
            "type": "list",
            "member": {}
          },
          "Tags": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "idempotent": true
    },
    "CreatePricingRule": {
      "http": {
        "requestUri": "/create-pricing-rule",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Scope",
          "Type",
          "ModifierPercentage"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amzn-Client-Token"
          },
          "Name": {
            "shape": "S1k"
          },
          "Description": {
            "shape": "S1l"
          },
          "Scope": {},
          "Type": {},
          "ModifierPercentage": {
            "type": "double"
          },
          "Service": {},
          "Tags": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "idempotent": true
    },
    "DeleteBillingGroup": {
      "http": {
        "requestUri": "/delete-billing-group",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "idempotent": true
    },
    "DeleteCustomLineItem": {
      "http": {
        "requestUri": "/delete-custom-line-item",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {},
          "BillingPeriodRange": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "idempotent": true
    },
    "DeletePricingPlan": {
      "http": {
        "requestUri": "/delete-pricing-plan",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "idempotent": true
    },
    "DeletePricingRule": {
      "http": {
        "requestUri": "/delete-pricing-rule",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "idempotent": true
    },
    "DisassociateAccounts": {
      "http": {
        "requestUri": "/disassociate-accounts",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "AccountIds"
        ],
        "members": {
          "Arn": {},
          "AccountIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "idempotent": true
    },
    "DisassociatePricingRules": {
      "http": {
        "method": "PUT",
        "requestUri": "/disassociate-pricing-rules",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "PricingRuleArns"
        ],
        "members": {
          "Arn": {},
          "PricingRuleArns": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      },
      "idempotent": true
    },
    "ListAccountAssociations": {
      "http": {
        "requestUri": "/list-account-associations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "BillingPeriod": {},
          "Filters": {
            "type": "structure",
            "members": {
              "Association": {},
              "AccountId": {}
            }
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LinkedAccounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountId": {},
                "BillingGroupArn": {},
                "AccountName": {
                  "type": "string",
                  "sensitive": true
                },
                "AccountEmail": {
                  "type": "string",
                  "sensitive": true
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListBillingGroupCostReports": {
      "http": {
        "requestUri": "/list-billing-group-cost-reports",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "BillingPeriod": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "type": "structure",
            "members": {
              "BillingGroupArns": {
                "shape": "S2f"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BillingGroupCostReports": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "AWSCost": {},
                "ProformaCost": {},
                "Margin": {},
                "MarginPercentage": {},
                "Currency": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListBillingGroups": {
      "http": {
        "requestUri": "/list-billing-groups",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "BillingPeriod": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "type": "structure",
            "members": {
              "Arns": {
                "shape": "S2f"
              },
              "PricingPlan": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BillingGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {
                  "shape": "Su"
                },
                "Arn": {},
                "Description": {
                  "shape": "Sy"
                },
                "PrimaryAccountId": {},
                "ComputationPreference": {
                  "shape": "Sw"
                },
                "Size": {
                  "type": "long"
                },
                "CreationTime": {
                  "type": "long"
                },
                "LastModifiedTime": {
                  "type": "long"
                },
                "Status": {},
                "StatusReason": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCustomLineItemVersions": {
      "http": {
        "requestUri": "/list-custom-line-item-versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "type": "structure",
            "members": {
              "BillingPeriodRange": {
                "type": "structure",
                "members": {
                  "StartBillingPeriod": {},
                  "EndBillingPeriod": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomLineItemVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {
                  "shape": "S14"
                },
                "ChargeDetails": {
                  "shape": "S34"
                },
                "CurrencyCode": {},
                "Description": {
                  "shape": "S15"
                },
                "ProductCode": {},
                "BillingGroupArn": {},
                "CreationTime": {
                  "type": "long"
                },
                "LastModifiedTime": {
                  "type": "long"
                },
                "AssociationSize": {
                  "type": "long"
                },
                "StartBillingPeriod": {},
                "EndBillingPeriod": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCustomLineItems": {
      "http": {
        "requestUri": "/list-custom-line-items",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "BillingPeriod": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "type": "structure",
            "members": {
              "Names": {
                "type": "list",
                "member": {
                  "shape": "S14"
                }
              },
              "BillingGroups": {
                "shape": "S2f"
              },
              "Arns": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomLineItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Name": {
                  "shape": "S14"
                },
                "ChargeDetails": {
                  "shape": "S34"
                },
                "CurrencyCode": {},
                "Description": {
                  "shape": "S15"
                },
                "ProductCode": {},
                "BillingGroupArn": {},
                "CreationTime": {
                  "type": "long"
                },
                "LastModifiedTime": {
                  "type": "long"
                },
                "AssociationSize": {
                  "type": "long"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPricingPlans": {
      "http": {
        "requestUri": "/list-pricing-plans",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "BillingPeriod": {},
          "Filters": {
            "type": "structure",
            "members": {
              "Arns": {
                "shape": "S3j"
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BillingPeriod": {},
          "PricingPlans": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {
                  "shape": "S1f"
                },
                "Arn": {},
                "Description": {
                  "shape": "S1g"
                },
                "Size": {
                  "type": "long"
                },
                "CreationTime": {
                  "type": "long"
                },
                "LastModifiedTime": {
                  "type": "long"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPricingPlansAssociatedWithPricingRule": {
      "http": {
        "requestUri": "/list-pricing-plans-associated-with-pricing-rule",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "PricingRuleArn"
        ],
        "members": {
          "BillingPeriod": {},
          "PricingRuleArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BillingPeriod": {},
          "PricingRuleArn": {},
          "PricingPlanArns": {
            "shape": "S3j"
          },
          "NextToken": {}
        }
      }
    },
    "ListPricingRules": {
      "http": {
        "requestUri": "/list-pricing-rules",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "BillingPeriod": {},
          "Filters": {
            "type": "structure",
            "members": {
              "Arns": {
                "shape": "S3u"
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BillingPeriod": {},
          "PricingRules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {
                  "shape": "S1k"
                },
                "Arn": {},
                "Description": {
                  "shape": "S1l"
                },
                "Scope": {},
                "Type": {},
                "ModifierPercentage": {
                  "type": "double"
                },
                "Service": {},
                "AssociatedPricingPlanCount": {
                  "type": "long"
                },
                "CreationTime": {
                  "type": "long"
                },
                "LastModifiedTime": {
                  "type": "long"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPricingRulesAssociatedToPricingPlan": {
      "http": {
        "requestUri": "/list-pricing-rules-associated-to-pricing-plan",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "PricingPlanArn"
        ],
        "members": {
          "BillingPeriod": {},
          "PricingPlanArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BillingPeriod": {},
          "PricingPlanArn": {},
          "PricingRuleArns": {
            "shape": "S3u"
          },
          "NextToken": {}
        }
      }
    },
    "ListResourcesAssociatedToCustomLineItem": {
      "http": {
        "requestUri": "/list-resources-associated-to-custom-line-item",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "BillingPeriod": {},
          "Arn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "type": "structure",
            "members": {
              "Relationship": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AssociatedResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Relationship": {},
                "EndBillingPeriod": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sz"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateBillingGroup": {
      "http": {
        "requestUri": "/update-billing-group",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {},
          "Name": {
            "shape": "Su"
          },
          "Status": {},
          "ComputationPreference": {
            "shape": "Sw"
          },
          "Description": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Name": {
            "shape": "Su"
          },
          "Description": {
            "shape": "Sy"
          },
          "PrimaryAccountId": {},
          "PricingPlanArn": {},
          "Size": {
            "type": "long"
          },
          "LastModifiedTime": {
            "type": "long"
          },
          "Status": {},
          "StatusReason": {}
        }
      },
      "idempotent": true
    },
    "UpdateCustomLineItem": {
      "http": {
        "requestUri": "/update-custom-line-item",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {},
          "Name": {
            "shape": "S14"
          },
          "Description": {
            "shape": "S15"
          },
          "ChargeDetails": {
            "type": "structure",
            "members": {
              "Flat": {
                "type": "structure",
                "required": [
                  "ChargeValue"
                ],
                "members": {
                  "ChargeValue": {
                    "type": "double"
                  }
                }
              },
              "Percentage": {
                "type": "structure",
                "required": [
                  "PercentageValue"
                ],
                "members": {
                  "PercentageValue": {
                    "type": "double"
                  }
                }
              }
            }
          },
          "BillingPeriodRange": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "BillingGroupArn": {},
          "Name": {
            "shape": "S14"
          },
          "Description": {
            "shape": "S15"
          },
          "ChargeDetails": {
            "shape": "S34"
          },
          "LastModifiedTime": {
            "type": "long"
          },
          "AssociationSize": {
            "type": "long"
          }
        }
      },
      "idempotent": true
    },
    "UpdatePricingPlan": {
      "http": {
        "method": "PUT",
        "requestUri": "/update-pricing-plan",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {},
          "Name": {
            "shape": "S1f"
          },
          "Description": {
            "shape": "S1g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Name": {
            "shape": "S1f"
          },
          "Description": {
            "shape": "S1g"
          },
          "Size": {
            "type": "long"
          },
          "LastModifiedTime": {
            "type": "long"
          }
        }
      },
      "idempotent": true
    },
    "UpdatePricingRule": {
      "http": {
        "method": "PUT",
        "requestUri": "/update-pricing-rule",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {},
          "Name": {
            "shape": "S1k"
          },
          "Description": {
            "shape": "S1l"
          },
          "Type": {},
          "ModifierPercentage": {
            "type": "double"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Name": {
            "shape": "S1k"
          },
          "Description": {
            "shape": "S1l"
          },
          "Scope": {},
          "Type": {},
          "ModifierPercentage": {
            "type": "double"
          },
          "Service": {},
          "AssociatedPricingPlanCount": {
            "type": "long"
          },
          "LastModifiedTime": {
            "type": "long"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "S8": {
      "type": "list",
      "member": {}
    },
    "Sf": {
      "type": "structure",
      "required": [
        "InclusiveStartBillingPeriod"
      ],
      "members": {
        "InclusiveStartBillingPeriod": {},
        "ExclusiveEndBillingPeriod": {}
      }
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Error": {
            "shape": "Sk"
          }
        }
      }
    },
    "Sk": {
      "type": "structure",
      "members": {
        "Message": {},
        "Reason": {}
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Error": {
            "shape": "Sk"
          }
        }
      }
    },
    "Su": {
      "type": "string",
      "sensitive": true
    },
    "Sw": {
      "type": "structure",
      "required": [
        "PricingPlanArn"
      ],
      "members": {
        "PricingPlanArn": {}
      }
    },
    "Sy": {
      "type": "string",
      "sensitive": true
    },
    "Sz": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S14": {
      "type": "string",
      "sensitive": true
    },
    "S15": {
      "type": "string",
      "sensitive": true
    },
    "S1f": {
      "type": "string",
      "sensitive": true
    },
    "S1g": {
      "type": "string",
      "sensitive": true
    },
    "S1k": {
      "type": "string",
      "sensitive": true
    },
    "S1l": {
      "type": "string",
      "sensitive": true
    },
    "S2f": {
      "type": "list",
      "member": {}
    },
    "S34": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "Flat": {
          "type": "structure",
          "required": [
            "ChargeValue"
          ],
          "members": {
            "ChargeValue": {
              "type": "double"
            }
          }
        },
        "Percentage": {
          "type": "structure",
          "required": [
            "PercentageValue"
          ],
          "members": {
            "PercentageValue": {
              "type": "double"
            }
          }
        },
        "Type": {}
      }
    },
    "S3j": {
      "type": "list",
      "member": {}
    },
    "S3u": {
      "type": "list",
      "member": {}
    }
  }
}