{
  "pagination": {
    "GetOfferingStatus": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": [
        "current",
        "nextPeriod"
      ]
    },
    "ListArtifacts": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "artifacts"
    },
    "ListDevicePools": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "devicePools"
    },
    "ListDevices": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "devices"
    },
    "ListJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "jobs"
    },
    "ListOfferingTransactions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "offeringTransactions"
    },
    "ListOfferings": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "offerings"
    },
    "ListProjects": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "projects"
    },
    "ListRuns": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "runs"
    },
    "ListSamples": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "samples"
    },
    "ListSuites": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "suites"
    },
    "ListTestGridProjects": {
      "input_token": "nextToken",
      "limit_key": "maxResult",
      "output_token": "nextToken"
    },
    "ListTestGridSessionActions": {
      "input_token": "nextToken",
      "limit_key": "maxResult",
      "output_token": "nextToken"
    },
    "ListTestGridSessionArtifacts": {
      "input_token": "nextToken",
      "limit_key": "maxResult",
      "output_token": "nextToken"
    },
    "ListTestGridSessions": {
      "input_token": "nextToken",
      "limit_key": "maxResult",
      "output_token": "nextToken"
    },
    "ListTests": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "tests"
    },
    "ListUniqueProblems": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "uniqueProblems"
    },
    "ListUploads": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "uploads"
    }
  }
}