import {Auth} from "../components/core";
import appconfig from "../appconfig";

const URI = appconfig.expensesUrl;

// Default headers for POST requests
const headers = new Headers()
headers.append('Content-Type', 'application/json')

// TODO: Create type for expense request and response
export const createExpense = async (data: any) => {
  const response = await fetch(URI, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers,
  });
  
  return await Auth.handleResponse(response);
}

// TODO: Create type for response
export const getExpenses = async (userId: number, startDate: string, endDate: string) => {
  const response = await fetch(`${URI}?userId=${userId}&startDate=${startDate}&endDate=${endDate}`);
  return await response.json();
}

