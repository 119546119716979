import {BrowserRouter as Router, Redirect, Route, useHistory} from "react-router-dom";
import {Login, MainAppBar, Unauthorized} from './components/core';
import Dashboard from './components/reimbursement/Dashboard';
import ReimbursementForm from './components/reimbursement/ReimbursementForm';
import Songs from './components/worship/songs/Songs';
import WorshipInsert from './components/worship/songs/Insert';
import Documents from './components/worship/documents/Documents';
import DocumentsCreate from './components/worship/documents/Create';
import CssBaseline from "@material-ui/core/CssBaseline";
import {createTheme, ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import ExpenseForm from "./components/expenseForm";
import ExpenseDashboard from "./components/expenseDashboard";
import Confirmation from "./components/confirmation/index.tsx";

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff'
    }
  },
  typography: {
    fontSize: 13,
    fontFamily: "Montserrat"
  }
});

const LandingPage = () => {
 return (
  <Redirect to="/reimbursement" />
 );
};

function App() {
  const history = useHistory();

  return (
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <MainAppBar />
        <Route path="/login" component={Login} />
        <Route path="/unauthorized" component={Unauthorized} />
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/reimbursement" component={Dashboard} />
        <Route exact path="/reimbursement/submit" component={ReimbursementForm} />
        <Route exact path="/expense" component={ExpenseDashboard} />
        <Route exact path="/expense/submit" component={ExpenseForm} />
        <Route path="/confirmation" component={Confirmation} />
        <Route exact path="/worship" component={Songs}/>
        <Route exact path="/worship/songs" component={Songs}/>
        <Route exact path="/worship/songs/insert" component={WorshipInsert} />
        <Route path="/worship/songs/update/:id" component={WorshipInsert}/>
        <Route exact path="/worship/documents" component={Documents}/>
        <Route exact path="/worship/documents/create" component={DocumentsCreate} />
        <Route path="/worship/documents/update/:id" component={DocumentsCreate}/>
      </MuiThemeProvider>
    </Router>
  );
}

export default App;
