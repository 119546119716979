import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {deleteDocument, listDocumentVersions, getDocument} from "../../../api/document";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {makeStyles} from "@material-ui/core/styles";
import {LoadingBar} from "../shared/Loading";

import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";

const documentStyles = makeStyles({
    content: {
        marginTop: "2em",
    },
    grid: {
        marginTop: "2em"
    },
    input: {
        fontSize: 14,
        lineHeight: 2, 
        color: "black"
    },
})

const CLOSING_TIME = 1000;

function RenderVersionDropdown(props: any) {
    const handleChange = (event: any) => {
        props.setDocumentVersion(props.id, event.target.value.version);
    }
    return (
        <FormControl size="small">
            <InputLabel>Version</InputLabel>
            <Select
                value={props.documentVersions.find((documentVersion: any) => documentVersion.version === props.version) || ''}
                onChange={handleChange}
                label="Version"
            >
                { props.documentVersions.map((documentVersion: any, index: any) =>
                    {
                    return (
                    <MenuItem
                        key={index}
                        value={documentVersion}
                    >
                        {documentVersion.version}
                    </MenuItem>)}
                )}
            </Select>
        </FormControl>
    );
}

function RenderDocumentVersion(props: any) {
    const styles = documentStyles()
    const documentVersion = props.documentVersions.find((documentVersion: any) => documentVersion.version === props.version);

    if (!documentVersion) {
        return null;
    }

    return (
        <div className={styles.content}>
            <Typography variant="h5" gutterBottom>
                {documentVersion.title}
                <CopyButton label="title" content={documentVersion.title} />
            </Typography>

            <Grid container spacing={2}>
                {documentVersion.source &&
                    <Grid item xs={12} sm={6}>
                        <Typography variant="caption">Source</Typography>
                        <Typography variant="body1" gutterBottom>
                            {documentVersion.source}
                            <CopyButton label="source" content={documentVersion.source} /> 
                        </Typography>
                    </Grid>
                }
                {documentVersion.link &&
                    <Grid item xs={12} sm={6}>
                        <Typography variant="caption">Link</Typography>
                        <Typography variant="body1" gutterBottom>
                            {documentVersion.link}
                            <CopyButton label="link" content={documentVersion.link} />
                        </Typography>
                    </Grid>
                }
            </Grid>

            <hr />

            <Typography variant="subtitle1">
                <b>Content</b>
                <CopyButton label="content" content={documentVersion.content} /> 
            </Typography>
            <TextField
                fullWidth
                inputProps={{className: styles.input}}
                InputProps={{disableUnderline: true}}
                multiline
                disabled
                value={documentVersion.content}
            />

            <hr />

            <Grid container spacing={2}>
                {documentVersion.scriptureReference && 
                    <Grid item xs={12} sm={6}>
                        <Typography variant="caption">Scriptures</Typography>
                        <Typography variant="body1" gutterBottom>
                            {documentVersion.scriptureReference}
                        </Typography>
                    </Grid>
                }
                {documentVersion.musicSheetLink && 
                    <Grid item xs={12} sm={6}>
                        <Typography variant="caption">Various Links</Typography>
                        <Typography variant="body1" gutterBottom>
                            <a href={documentVersion.musicSheetLink}
                                target="_blank">
                                Music Sheet
                            </a>
                        </Typography>
                    </Grid>
                }
            </Grid>
        </div>
    )
}

function CopyButton(props: any) {
    const [open, setOpen] = useState(false);
    const handleCopy = (content: any) => {
        navigator.clipboard.writeText(content)
        setOpen(true)
    }
    return (
        <Tooltip title={'Copy ' + props.label}>
            <IconButton size="small" onClick={() => handleCopy(props.content)} >
                <FileCopyIcon fontSize="small" />
                <Snackbar
                    open={open}
                    autoHideDuration={CLOSING_TIME}
                    message={`Copied ${props.label} to Clipboard`}
                    onClose={() => setOpen(false)}
                />
            </IconButton>
        </Tooltip>
    )
}

function RenderButton(props: any) {
    const styles = documentStyles()
    let [openDelete, setOpenDelete] = useState(false);

    const handleClickDelete = () => {
        setOpenDelete(true);
    }

    const handleDeleteConfirm = async () => {
        let response = await deleteDocument(props.id);
        if (response) {
            const url = new URL(window.location.origin + "/worship/documents");
            window.location.assign(url);
        }   
    }

    const handleDeleteCancel = () => {
        setOpenDelete(false);
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={true}>
                    <RenderVersionDropdown
                        setDocumentVersion={props.setDocumentVersion}
                        documentVersions={props.documentVersions}
                        version={props.version}
                        id={props.id}
                    />
                </Grid>
                <Grid item xs={12} sm='auto'>
                    <Button variant="contained"
                            component={Link}
                            to={"/worship/documents/update/" + props.id}
                            startIcon={<EditIcon />}>
                        Edit
                    </Button>
                </Grid>
                <Grid item xs={12} sm='auto'>
                    <Button variant="contained"
                            onClick={handleClickDelete}
                            startIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                </Grid>
            </Grid>
            <Dialog open={openDelete}
                    onClose={handleDeleteCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    WARNING! You are about to delete all versions of the document:
                    <ul>
                        {props.documentVersions.map((version:any) => <li key={version.title}>{version.title}</li>)}
                    </ul>
                </DialogTitle> 
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteConfirm}>
                        Yes
                    </Button>
                    <Button onClick={handleDeleteCancel}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function RenderLoading(props: any) {
    return (
        <Grid container justifyContent="center">
            <LoadingBar inProgress={props.state} />
        </Grid>
    )
}

export function _Document(props: any) {
    const styles = documentStyles()
    let [documentVersions, setDocumentVersions] = useState([] as any[]);
    const [loadingStatus, setLoadingStatus] = useState(true);

    useEffect(() => {
        async function getDocumentData() {
            setLoadingStatus(true)
            setDocumentVersions(await listDocumentVersions(props.id));
            setLoadingStatus(false);
        }

        if (props.id && props.version) getDocumentData();
    }, [props.id, props.version])
    
    if (!props.id || !props.version) {
        return null;
    }

    return (
        <Container className={styles.content}>
            {
                loadingStatus ?
                    <RenderLoading state={loadingStatus} />
                : <>
                    <RenderButton
                        id={props.id}
                        documentVersions={documentVersions}
                        version={props.version}
                        setDocumentVersion={props.setDocumentVersion}
                    />
                    <RenderDocumentVersion
                        documentVersions={documentVersions}
                        version={props.version}
                    />
                </>
            }
        </Container>
    );
}

export const Document = _Document