{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-06-10",
    "endpointPrefix": "oidc",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "SSO OIDC",
    "serviceFullName": "AWS SSO OIDC",
    "serviceId": "SSO OIDC",
    "signatureVersion": "v4",
    "signingName": "awsssooidc",
    "uid": "sso-oidc-2019-06-10"
  },
  "operations": {
    "CreateToken": {
      "http": {
        "requestUri": "/token"
      },
      "input": {
        "type": "structure",
        "required": [
          "clientId",
          "clientSecret",
          "grantType"
        ],
        "members": {
          "clientId": {},
          "clientSecret": {},
          "grantType": {},
          "deviceCode": {},
          "code": {},
          "refreshToken": {},
          "scope": {
            "shape": "S8"
          },
          "redirectUri": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "accessToken": {},
          "tokenType": {},
          "expiresIn": {
            "type": "integer"
          },
          "refreshToken": {},
          "idToken": {}
        }
      },
      "authtype": "none"
    },
    "RegisterClient": {
      "http": {
        "requestUri": "/client/register"
      },
      "input": {
        "type": "structure",
        "required": [
          "clientName",
          "clientType"
        ],
        "members": {
          "clientName": {},
          "clientType": {},
          "scopes": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "clientId": {},
          "clientSecret": {},
          "clientIdIssuedAt": {
            "type": "long"
          },
          "clientSecretExpiresAt": {
            "type": "long"
          },
          "authorizationEndpoint": {},
          "tokenEndpoint": {}
        }
      },
      "authtype": "none"
    },
    "StartDeviceAuthorization": {
      "http": {
        "requestUri": "/device_authorization"
      },
      "input": {
        "type": "structure",
        "required": [
          "clientId",
          "clientSecret",
          "startUrl"
        ],
        "members": {
          "clientId": {},
          "clientSecret": {},
          "startUrl": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deviceCode": {},
          "userCode": {},
          "verificationUri": {},
          "verificationUriComplete": {},
          "expiresIn": {
            "type": "integer"
          },
          "interval": {
            "type": "integer"
          }
        }
      },
      "authtype": "none"
    }
  },
  "shapes": {
    "S8": {
      "type": "list",
      "member": {}
    }
  }
}