{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-11-01",
    "endpointPrefix": "kms",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "KMS",
    "serviceFullName": "AWS Key Management Service",
    "serviceId": "KMS",
    "signatureVersion": "v4",
    "targetPrefix": "TrentService",
    "uid": "kms-2014-11-01"
  },
  "operations": {
    "CancelKeyDeletion": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {}
        }
      }
    },
    "ConnectCustomKeyStore": {
      "input": {
        "type": "structure",
        "required": [
          "CustomKeyStoreId"
        ],
        "members": {
          "CustomKeyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasName",
          "TargetKeyId"
        ],
        "members": {
          "AliasName": {},
          "TargetKeyId": {}
        }
      }
    },
    "CreateCustomKeyStore": {
      "input": {
        "type": "structure",
        "required": [
          "CustomKeyStoreName"
        ],
        "members": {
          "CustomKeyStoreName": {},
          "CloudHsmClusterId": {},
          "TrustAnchorCertificate": {},
          "KeyStorePassword": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomKeyStoreId": {}
        }
      }
    },
    "CreateGrant": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "GranteePrincipal",
          "Operations"
        ],
        "members": {
          "KeyId": {},
          "GranteePrincipal": {},
          "RetiringPrincipal": {},
          "Operations": {
            "shape": "Sh"
          },
          "Constraints": {
            "shape": "Sj"
          },
          "GrantTokens": {
            "shape": "Sn"
          },
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GrantToken": {},
          "GrantId": {}
        }
      }
    },
    "CreateKey": {
      "input": {
        "type": "structure",
        "members": {
          "Policy": {},
          "Description": {},
          "KeyUsage": {},
          "CustomerMasterKeySpec": {
            "shape": "Sw",
            "deprecated": true,
            "deprecatedMessage": "This parameter has been deprecated. Instead, use the KeySpec parameter."
          },
          "KeySpec": {},
          "Origin": {},
          "CustomKeyStoreId": {},
          "BypassPolicyLockoutSafetyCheck": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S10"
          },
          "MultiRegion": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyMetadata": {
            "shape": "S16"
          }
        }
      }
    },
    "Decrypt": {
      "input": {
        "type": "structure",
        "required": [
          "CiphertextBlob"
        ],
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "EncryptionContext": {
            "shape": "Sk"
          },
          "GrantTokens": {
            "shape": "Sn"
          },
          "KeyId": {},
          "EncryptionAlgorithm": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "Plaintext": {
            "shape": "S1s"
          },
          "EncryptionAlgorithm": {}
        }
      }
    },
    "DeleteAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasName"
        ],
        "members": {
          "AliasName": {}
        }
      }
    },
    "DeleteCustomKeyStore": {
      "input": {
        "type": "structure",
        "required": [
          "CustomKeyStoreId"
        ],
        "members": {
          "CustomKeyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteImportedKeyMaterial": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "DescribeCustomKeyStores": {
      "input": {
        "type": "structure",
        "members": {
          "CustomKeyStoreId": {},
          "CustomKeyStoreName": {},
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomKeyStores": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CustomKeyStoreId": {},
                "CustomKeyStoreName": {},
                "CloudHsmClusterId": {},
                "TrustAnchorCertificate": {},
                "ConnectionState": {},
                "ConnectionErrorCode": {},
                "CreationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyMetadata": {
            "shape": "S16"
          }
        }
      }
    },
    "DisableKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "DisableKeyRotation": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "DisconnectCustomKeyStore": {
      "input": {
        "type": "structure",
        "required": [
          "CustomKeyStoreId"
        ],
        "members": {
          "CustomKeyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "EnableKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "EnableKeyRotation": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "Encrypt": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "Plaintext"
        ],
        "members": {
          "KeyId": {},
          "Plaintext": {
            "shape": "S1s"
          },
          "EncryptionContext": {
            "shape": "Sk"
          },
          "GrantTokens": {
            "shape": "Sn"
          },
          "EncryptionAlgorithm": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "KeyId": {},
          "EncryptionAlgorithm": {}
        }
      }
    },
    "GenerateDataKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "EncryptionContext": {
            "shape": "Sk"
          },
          "NumberOfBytes": {
            "type": "integer"
          },
          "KeySpec": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "Plaintext": {
            "shape": "S1s"
          },
          "KeyId": {}
        }
      }
    },
    "GenerateDataKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "KeyPairSpec"
        ],
        "members": {
          "EncryptionContext": {
            "shape": "Sk"
          },
          "KeyId": {},
          "KeyPairSpec": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrivateKeyCiphertextBlob": {
            "type": "blob"
          },
          "PrivateKeyPlaintext": {
            "shape": "S1s"
          },
          "PublicKey": {
            "type": "blob"
          },
          "KeyId": {},
          "KeyPairSpec": {}
        }
      }
    },
    "GenerateDataKeyPairWithoutPlaintext": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "KeyPairSpec"
        ],
        "members": {
          "EncryptionContext": {
            "shape": "Sk"
          },
          "KeyId": {},
          "KeyPairSpec": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrivateKeyCiphertextBlob": {
            "type": "blob"
          },
          "PublicKey": {
            "type": "blob"
          },
          "KeyId": {},
          "KeyPairSpec": {}
        }
      }
    },
    "GenerateDataKeyWithoutPlaintext": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "EncryptionContext": {
            "shape": "Sk"
          },
          "KeySpec": {},
          "NumberOfBytes": {
            "type": "integer"
          },
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "KeyId": {}
        }
      }
    },
    "GenerateMac": {
      "input": {
        "type": "structure",
        "required": [
          "Message",
          "KeyId",
          "MacAlgorithm"
        ],
        "members": {
          "Message": {
            "shape": "S1s"
          },
          "KeyId": {},
          "MacAlgorithm": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Mac": {
            "type": "blob"
          },
          "MacAlgorithm": {},
          "KeyId": {}
        }
      }
    },
    "GenerateRandom": {
      "input": {
        "type": "structure",
        "members": {
          "NumberOfBytes": {
            "type": "integer"
          },
          "CustomKeyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Plaintext": {
            "shape": "S1s"
          }
        }
      }
    },
    "GetKeyPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "PolicyName"
        ],
        "members": {
          "KeyId": {},
          "PolicyName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        }
      }
    },
    "GetKeyRotationStatus": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyRotationEnabled": {
            "type": "boolean"
          }
        }
      }
    },
    "GetParametersForImport": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "WrappingAlgorithm",
          "WrappingKeySpec"
        ],
        "members": {
          "KeyId": {},
          "WrappingAlgorithm": {},
          "WrappingKeySpec": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "ImportToken": {
            "type": "blob"
          },
          "PublicKey": {
            "shape": "S1s"
          },
          "ParametersValidTo": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetPublicKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "PublicKey": {
            "type": "blob"
          },
          "CustomerMasterKeySpec": {
            "shape": "Sw",
            "deprecated": true,
            "deprecatedMessage": "This field has been deprecated. Instead, use the KeySpec field."
          },
          "KeySpec": {},
          "KeyUsage": {},
          "EncryptionAlgorithms": {
            "shape": "S1d"
          },
          "SigningAlgorithms": {
            "shape": "S1f"
          }
        }
      }
    },
    "ImportKeyMaterial": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "ImportToken",
          "EncryptedKeyMaterial"
        ],
        "members": {
          "KeyId": {},
          "ImportToken": {
            "type": "blob"
          },
          "EncryptedKeyMaterial": {
            "type": "blob"
          },
          "ValidTo": {
            "type": "timestamp"
          },
          "ExpirationModel": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListAliases": {
      "input": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AliasName": {},
                "AliasArn": {},
                "TargetKeyId": {},
                "CreationDate": {
                  "type": "timestamp"
                },
                "LastUpdatedDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "ListGrants": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "Limit": {
            "type": "integer"
          },
          "Marker": {},
          "KeyId": {},
          "GrantId": {},
          "GranteePrincipal": {}
        }
      },
      "output": {
        "shape": "S3d"
      }
    },
    "ListKeyPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyNames": {
            "type": "list",
            "member": {}
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "ListKeys": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Keys": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "KeyId": {},
                "KeyArn": {}
              }
            }
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "ListResourceTags": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S10"
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "ListRetirableGrants": {
      "input": {
        "type": "structure",
        "required": [
          "RetiringPrincipal"
        ],
        "members": {
          "Limit": {
            "type": "integer"
          },
          "Marker": {},
          "RetiringPrincipal": {}
        }
      },
      "output": {
        "shape": "S3d"
      }
    },
    "PutKeyPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "PolicyName",
          "Policy"
        ],
        "members": {
          "KeyId": {},
          "PolicyName": {},
          "Policy": {},
          "BypassPolicyLockoutSafetyCheck": {
            "type": "boolean"
          }
        }
      }
    },
    "ReEncrypt": {
      "input": {
        "type": "structure",
        "required": [
          "CiphertextBlob",
          "DestinationKeyId"
        ],
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "SourceEncryptionContext": {
            "shape": "Sk"
          },
          "SourceKeyId": {},
          "DestinationKeyId": {},
          "DestinationEncryptionContext": {
            "shape": "Sk"
          },
          "SourceEncryptionAlgorithm": {},
          "DestinationEncryptionAlgorithm": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "SourceKeyId": {},
          "KeyId": {},
          "SourceEncryptionAlgorithm": {},
          "DestinationEncryptionAlgorithm": {}
        }
      }
    },
    "ReplicateKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "ReplicaRegion"
        ],
        "members": {
          "KeyId": {},
          "ReplicaRegion": {},
          "Policy": {},
          "BypassPolicyLockoutSafetyCheck": {
            "type": "boolean"
          },
          "Description": {},
          "Tags": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicaKeyMetadata": {
            "shape": "S16"
          },
          "ReplicaPolicy": {},
          "ReplicaTags": {
            "shape": "S10"
          }
        }
      }
    },
    "RetireGrant": {
      "input": {
        "type": "structure",
        "members": {
          "GrantToken": {},
          "KeyId": {},
          "GrantId": {}
        }
      }
    },
    "RevokeGrant": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "GrantId"
        ],
        "members": {
          "KeyId": {},
          "GrantId": {}
        }
      }
    },
    "ScheduleKeyDeletion": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "PendingWindowInDays": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "DeletionDate": {
            "type": "timestamp"
          },
          "KeyState": {},
          "PendingWindowInDays": {
            "type": "integer"
          }
        }
      }
    },
    "Sign": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "Message",
          "SigningAlgorithm"
        ],
        "members": {
          "KeyId": {},
          "Message": {
            "shape": "S1s"
          },
          "MessageType": {},
          "GrantTokens": {
            "shape": "Sn"
          },
          "SigningAlgorithm": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "Signature": {
            "type": "blob"
          },
          "SigningAlgorithm": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "Tags"
        ],
        "members": {
          "KeyId": {},
          "Tags": {
            "shape": "S10"
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "TagKeys"
        ],
        "members": {
          "KeyId": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasName",
          "TargetKeyId"
        ],
        "members": {
          "AliasName": {},
          "TargetKeyId": {}
        }
      }
    },
    "UpdateCustomKeyStore": {
      "input": {
        "type": "structure",
        "required": [
          "CustomKeyStoreId"
        ],
        "members": {
          "CustomKeyStoreId": {},
          "NewCustomKeyStoreName": {},
          "KeyStorePassword": {
            "shape": "Sd"
          },
          "CloudHsmClusterId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateKeyDescription": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "Description"
        ],
        "members": {
          "KeyId": {},
          "Description": {}
        }
      }
    },
    "UpdatePrimaryRegion": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "PrimaryRegion"
        ],
        "members": {
          "KeyId": {},
          "PrimaryRegion": {}
        }
      }
    },
    "Verify": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "Message",
          "Signature",
          "SigningAlgorithm"
        ],
        "members": {
          "KeyId": {},
          "Message": {
            "shape": "S1s"
          },
          "MessageType": {},
          "Signature": {
            "type": "blob"
          },
          "SigningAlgorithm": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "SignatureValid": {
            "type": "boolean"
          },
          "SigningAlgorithm": {}
        }
      }
    },
    "VerifyMac": {
      "input": {
        "type": "structure",
        "required": [
          "Message",
          "KeyId",
          "MacAlgorithm",
          "Mac"
        ],
        "members": {
          "Message": {
            "shape": "S1s"
          },
          "KeyId": {},
          "MacAlgorithm": {},
          "Mac": {
            "type": "blob"
          },
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "MacValid": {
            "type": "boolean"
          },
          "MacAlgorithm": {}
        }
      }
    }
  },
  "shapes": {
    "Sd": {
      "type": "string",
      "sensitive": true
    },
    "Sh": {
      "type": "list",
      "member": {}
    },
    "Sj": {
      "type": "structure",
      "members": {
        "EncryptionContextSubset": {
          "shape": "Sk"
        },
        "EncryptionContextEquals": {
          "shape": "Sk"
        }
      }
    },
    "Sk": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sn": {
      "type": "list",
      "member": {}
    },
    "Sw": {
      "type": "string",
      "deprecated": true,
      "deprecatedMessage": "This enum has been deprecated. Instead, use the KeySpec enum."
    },
    "S10": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "TagKey",
          "TagValue"
        ],
        "members": {
          "TagKey": {},
          "TagValue": {}
        }
      }
    },
    "S16": {
      "type": "structure",
      "required": [
        "KeyId"
      ],
      "members": {
        "AWSAccountId": {},
        "KeyId": {},
        "Arn": {},
        "CreationDate": {
          "type": "timestamp"
        },
        "Enabled": {
          "type": "boolean"
        },
        "Description": {},
        "KeyUsage": {},
        "KeyState": {},
        "DeletionDate": {
          "type": "timestamp"
        },
        "ValidTo": {
          "type": "timestamp"
        },
        "Origin": {},
        "CustomKeyStoreId": {},
        "CloudHsmClusterId": {},
        "ExpirationModel": {},
        "KeyManager": {},
        "CustomerMasterKeySpec": {
          "shape": "Sw",
          "deprecated": true,
          "deprecatedMessage": "This field has been deprecated. Instead, use the KeySpec field."
        },
        "KeySpec": {},
        "EncryptionAlgorithms": {
          "shape": "S1d"
        },
        "SigningAlgorithms": {
          "shape": "S1f"
        },
        "MultiRegion": {
          "type": "boolean"
        },
        "MultiRegionConfiguration": {
          "type": "structure",
          "members": {
            "MultiRegionKeyType": {},
            "PrimaryKey": {
              "shape": "S1j"
            },
            "ReplicaKeys": {
              "type": "list",
              "member": {
                "shape": "S1j"
              }
            }
          }
        },
        "PendingDeletionWindowInDays": {
          "type": "integer"
        },
        "MacAlgorithms": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1d": {
      "type": "list",
      "member": {}
    },
    "S1f": {
      "type": "list",
      "member": {}
    },
    "S1j": {
      "type": "structure",
      "members": {
        "Arn": {},
        "Region": {}
      }
    },
    "S1s": {
      "type": "blob",
      "sensitive": true
    },
    "S3d": {
      "type": "structure",
      "members": {
        "Grants": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "KeyId": {},
              "GrantId": {},
              "Name": {},
              "CreationDate": {
                "type": "timestamp"
              },
              "GranteePrincipal": {},
              "RetiringPrincipal": {},
              "IssuingAccount": {},
              "Operations": {
                "shape": "Sh"
              },
              "Constraints": {
                "shape": "Sj"
              }
            }
          }
        },
        "NextMarker": {},
        "Truncated": {
          "type": "boolean"
        }
      }
    }
  }
}