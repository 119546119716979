import {KeyboardEvent, KeyboardEventHandler, useEffect, useState} from "react";
import {FormControl, IconButton, InputAdornment, TextField} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {styles} from "./styles";

function _SearchBar(props: any) {
    const classes = styles();

    const [searchText, setSearchText] = useState(props.searchText)

    // change value for search text
    const handleChange = (event: any) => {
        setSearchText((event.target as HTMLButtonElement).value);
    }

    const handleKeyUp: KeyboardEventHandler = (event: KeyboardEvent) => {
        if (event.charCode === 13 || event.key === "Enter") {
            handleSearch()
        }
    }

    // search the song
    const handleSearch = () => {
        props.setSearchText(searchText)
        window.sessionStorage.setItem('searchText', searchText)
    }

    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText]);

    return (
        <FormControl className={classes.searchBar}>
            <TextField
                variant='outlined'
                value={searchText}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
                placeholder={props.placeholder}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleSearch}
                                className={classes.searchIconButton}
                            >
                                <SearchIcon className={classes.icon}/>
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </FormControl>
    );
}

export const Search = _SearchBar;