{
  "pagination": {
    "ListActionExecutions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "actionExecutionDetails"
    },
    "ListActionTypes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "actionTypes"
    },
    "ListPipelineExecutions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "pipelineExecutionSummaries"
    },
    "ListPipelines": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "pipelines"
    },
    "ListTagsForResource": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "tags"
    },
    "ListWebhooks": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "webhooks"
    }
  }
}
