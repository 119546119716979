import AWS from 'aws-sdk'
import {v4 as uuid} from 'uuid'

const albumBucketName = 'reimbursement-receipts-tmp';
const bucketRegion = 'us-west-2';
const IdentityPoolId = 'us-west-2:6ded3337-0d9b-4cb3-8da6-e8d21d5c8d7c';

AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IdentityPoolId
  })
});

var s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {Bucket: albumBucketName}
});

function addPhoto(file) {
  const extension = getExtension(file)
  return s3.upload({
    Key: Date.now() + '-' + uuid() + '.' + extension,
    Body: file,
    ACL: 'public-read'
  }).promise();
}

  function getExtension(file) {
    return file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length)
  }

function deletePhoto(photoKey) {
  return s3.deleteObject({Key: photoKey}).promise();
}

export {
  addPhoto,
  deletePhoto
};
