import { useMemoizedFn, useUpdate } from 'ahooks';
import { parse, stringify } from 'query-string';
import { useMemo, useRef } from 'react';
import * as tmp from 'react-router';
// ignore waring `"export 'useNavigate' (imported as 'rc') was not found in 'react-router'`
const rc = tmp;
const baseParseConfig = {
  parseNumbers: false,
  parseBooleans: false
};
const baseStringifyConfig = {
  skipNull: false,
  skipEmptyString: false
};
const useUrlState = (initialState, options) => {
  var _a, _b;
  const {
    navigateMode = 'push',
    parseOptions,
    stringifyOptions
  } = options || {};
  const mergedParseOptions = Object.assign(Object.assign({}, baseParseConfig), parseOptions);
  const mergedStringifyOptions = Object.assign(Object.assign({}, baseStringifyConfig), stringifyOptions);
  const location = rc.useLocation();
  // react-router v5
  const history = (_a = rc.useHistory) === null || _a === void 0 ? void 0 : _a.call(rc);
  // react-router v6
  const navigate = (_b = rc.useNavigate) === null || _b === void 0 ? void 0 : _b.call(rc);
  const update = useUpdate();
  const initialStateRef = useRef(typeof initialState === 'function' ? initialState() : initialState || {});
  const queryFromUrl = useMemo(() => {
    return parse(location.search, mergedParseOptions);
  }, [location.search]);
  const targetQuery = useMemo(() => Object.assign(Object.assign({}, initialStateRef.current), queryFromUrl), [queryFromUrl]);
  const setState = s => {
    const newQuery = typeof s === 'function' ? s(targetQuery) : s;
    // 1. 如果 setState 后，search 没变化，就需要 update 来触发一次更新。比如 demo1 直接点击 clear，就需要 update 来触发更新。
    // 2. update 和 history 的更新会合并，不会造成多次更新
    update();
    if (history) {
      history[navigateMode]({
        hash: location.hash,
        search: stringify(Object.assign(Object.assign({}, queryFromUrl), newQuery), mergedStringifyOptions) || '?'
      }, location.state);
    }
    if (navigate) {
      navigate({
        hash: location.hash,
        search: stringify(Object.assign(Object.assign({}, queryFromUrl), newQuery), mergedStringifyOptions) || '?'
      }, {
        replace: navigateMode === 'replace',
        state: location.state
      });
    }
  };
  return [targetQuery, useMemoizedFn(setState)];
};
export default useUrlState;