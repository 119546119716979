{
  "pagination": {
    "ListCampaigns": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "campaignSummaryList"
    }
  }
}
