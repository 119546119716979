import React from 'react';
import './confirmation.css';

export default function Confirmation() {

    // TODO: Add dynamic messaging
    return (
        <div className="confirmation">
            <h1>Success!</h1>
            <h2>Thank you!</h2>
            <h3>Your submission has been received and you will receive an email soon.</h3>
        </div>
    )
}
