{
  "pagination": {
    "BatchGetTraces": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Traces"
    },
    "GetGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Groups"
    },
    "GetInsightEvents": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetInsightSummaries": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetSamplingRules": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "SamplingRuleRecords"
    },
    "GetSamplingStatisticSummaries": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "SamplingStatisticSummaries"
    },
    "GetServiceGraph": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Services"
    },
    "GetTimeSeriesServiceStatistics": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "TimeSeriesServiceStatistics"
    },
    "GetTraceGraph": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Services"
    },
    "GetTraceSummaries": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "TraceSummaries"
    }
  }
}