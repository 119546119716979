{
  "pagination": {
    "DescribeComputeEnvironments": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "computeEnvironments"
    },
    "DescribeJobDefinitions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "jobDefinitions"
    },
    "DescribeJobQueues": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "jobQueues"
    },
    "ListJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "jobSummaryList"
    },
    "ListSchedulingPolicies": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "schedulingPolicies"
    }
  }
}
