{
  "pagination": {
    "DescribeAffectedAccountsForOrganization": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "non_aggregate_keys": [
        "eventScopeCode"
      ],
      "output_token": "nextToken",
      "result_key": "affectedAccounts"
    },
    "DescribeAffectedEntities": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "entities"
    },
    "DescribeAffectedEntitiesForOrganization": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "non_aggregate_keys": [
        "failedSet"
      ],
      "output_token": "nextToken",
      "result_key": "entities"
    },
    "DescribeEntityAggregates": {
      "result_key": "entityAggregates"
    },
    "DescribeEventAggregates": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "eventAggregates"
    },
    "DescribeEventTypes": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "eventTypes"
    },
    "DescribeEvents": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "events"
    },
    "DescribeEventsForOrganization": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "events"
    }
  }
}