{
  "pagination": {
    "ListChangesets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "changesets"
    },
    "ListDataViews": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "dataViews"
    },
    "ListDatasets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "datasets"
    },
    "ListPermissionGroups": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "permissionGroups"
    },
    "ListUsers": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "users"
    }
  }
}
