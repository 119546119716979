{
  "pagination": {
    "ListApplications": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "applications"
    },
    "ListAssociatedAttributeGroups": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "attributeGroups"
    },
    "ListAssociatedResources": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "resources"
    },
    "ListAttributeGroups": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "attributeGroups"
    },
    "ListAttributeGroupsForApplication": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "attributeGroupsDetails"
    }
  }
}
