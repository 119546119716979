import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

function styled(Component: any) {
  return (style: any, options: any) => {
    function StyledComponent(props: any) {
      const { classes, className, ...other } = props;
      return <Component className={classNames(classes.root, className)} {...other} />;
    }
    StyledComponent.propTypes = {
      classes: PropTypes.object.isRequired,
      className: PropTypes.string,
    };
    const styles =
      typeof style === 'function' ? (theme: any) => ({
        root: style(theme)
      }) : { root: style };
    return withStyles(styles, options)(StyledComponent);
  };
}

// @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
const CircularIndeterminate = styled(CircularProgress)({
  color: 'green',
});

export function LoadingBar(props: any) {
    const inProgress = props.inProgress;
    // @ts-expect-error TS(2322): Type '{ size: number; thickness: number; }' is not... Remove this comment to see the full error message
    if (inProgress) { return <CircularIndeterminate size={100} thickness={5}/>; }
    else return null;
}