{
  "pagination": {
    "DescribeFleetAttributes": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "FleetAttributes"
    },
    "DescribeFleetCapacity": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "FleetCapacity"
    },
    "DescribeFleetEvents": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "Events"
    },
    "DescribeFleetLocationAttributes": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit"
    },
    "DescribeFleetUtilization": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "FleetUtilization"
    },
    "DescribeGameServerInstances": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "GameServerInstances"
    },
    "DescribeGameSessionDetails": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "GameSessionDetails"
    },
    "DescribeGameSessionQueues": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "GameSessionQueues"
    },
    "DescribeGameSessions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "GameSessions"
    },
    "DescribeInstances": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "Instances"
    },
    "DescribeMatchmakingConfigurations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "Configurations"
    },
    "DescribeMatchmakingRuleSets": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "RuleSets"
    },
    "DescribePlayerSessions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "PlayerSessions"
    },
    "DescribeScalingPolicies": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "ScalingPolicies"
    },
    "ListAliases": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "Aliases"
    },
    "ListBuilds": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "Builds"
    },
    "ListFleets": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "FleetIds"
    },
    "ListGameServerGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "GameServerGroups"
    },
    "ListGameServers": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "GameServers"
    },
    "ListScripts": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "Scripts"
    },
    "SearchGameSessions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "GameSessions"
    }
  }
}
