{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-01-30",
    "endpointPrefix": "connect-campaigns",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AmazonConnectCampaignService",
    "serviceId": "ConnectCampaigns",
    "signatureVersion": "v4",
    "signingName": "connect-campaigns",
    "uid": "connectcampaigns-2021-01-30"
  },
  "operations": {
    "CreateCampaign": {
      "http": {
        "method": "PUT",
        "requestUri": "/campaigns",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "connectInstanceId",
          "dialerConfig",
          "name",
          "outboundCallConfig"
        ],
        "members": {
          "connectInstanceId": {},
          "dialerConfig": {
            "shape": "S3"
          },
          "name": {},
          "outboundCallConfig": {
            "shape": "S8"
          },
          "tags": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "id": {},
          "tags": {
            "shape": "Se"
          }
        }
      },
      "idempotent": true
    },
    "DeleteCampaign": {
      "http": {
        "method": "DELETE",
        "requestUri": "/campaigns/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "idempotent": true
    },
    "DeleteConnectInstanceConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/connect-instance/{connectInstanceId}/config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "connectInstanceId"
        ],
        "members": {
          "connectInstanceId": {
            "location": "uri",
            "locationName": "connectInstanceId"
          }
        }
      },
      "idempotent": true
    },
    "DeleteInstanceOnboardingJob": {
      "http": {
        "method": "DELETE",
        "requestUri": "/connect-instance/{connectInstanceId}/onboarding",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "connectInstanceId"
        ],
        "members": {
          "connectInstanceId": {
            "location": "uri",
            "locationName": "connectInstanceId"
          }
        }
      },
      "idempotent": true
    },
    "DescribeCampaign": {
      "http": {
        "method": "GET",
        "requestUri": "/campaigns/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "campaign": {
            "type": "structure",
            "required": [
              "arn",
              "connectInstanceId",
              "dialerConfig",
              "id",
              "name",
              "outboundCallConfig"
            ],
            "members": {
              "arn": {},
              "connectInstanceId": {},
              "dialerConfig": {
                "shape": "S3"
              },
              "id": {},
              "name": {},
              "outboundCallConfig": {
                "shape": "S8"
              },
              "tags": {
                "shape": "Se"
              }
            }
          }
        }
      }
    },
    "GetCampaignState": {
      "http": {
        "method": "GET",
        "requestUri": "/campaigns/{id}/state",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "state": {}
        }
      }
    },
    "GetCampaignStateBatch": {
      "http": {
        "requestUri": "/campaigns-state",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "campaignIds"
        ],
        "members": {
          "campaignIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "failedRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "campaignId": {},
                "failureCode": {}
              }
            }
          },
          "successfulRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "campaignId": {},
                "state": {}
              }
            }
          }
        }
      }
    },
    "GetConnectInstanceConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/connect-instance/{connectInstanceId}/config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "connectInstanceId"
        ],
        "members": {
          "connectInstanceId": {
            "location": "uri",
            "locationName": "connectInstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectInstanceConfig": {
            "type": "structure",
            "required": [
              "connectInstanceId",
              "encryptionConfig",
              "serviceLinkedRoleArn"
            ],
            "members": {
              "connectInstanceId": {},
              "encryptionConfig": {
                "shape": "S14"
              },
              "serviceLinkedRoleArn": {}
            }
          }
        }
      }
    },
    "GetInstanceOnboardingJobStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/connect-instance/{connectInstanceId}/onboarding",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "connectInstanceId"
        ],
        "members": {
          "connectInstanceId": {
            "location": "uri",
            "locationName": "connectInstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectInstanceOnboardingJobStatus": {
            "shape": "S1b"
          }
        }
      }
    },
    "ListCampaigns": {
      "http": {
        "requestUri": "/campaigns-summary",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "filters": {
            "type": "structure",
            "members": {
              "instanceIdFilter": {
                "type": "structure",
                "required": [
                  "operator",
                  "value"
                ],
                "members": {
                  "operator": {},
                  "value": {}
                }
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "campaignSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "connectInstanceId",
                "id",
                "name"
              ],
              "members": {
                "arn": {},
                "connectInstanceId": {},
                "id": {},
                "name": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{arn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {
            "location": "uri",
            "locationName": "arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Se"
          }
        }
      },
      "idempotent": true
    },
    "PauseCampaign": {
      "http": {
        "requestUri": "/campaigns/{id}/pause",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      }
    },
    "PutDialRequestBatch": {
      "http": {
        "method": "PUT",
        "requestUri": "/campaigns/{id}/dial-requests",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "dialRequests",
          "id"
        ],
        "members": {
          "dialRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "attributes",
                "clientToken",
                "expirationTime",
                "phoneNumber"
              ],
              "members": {
                "attributes": {
                  "type": "map",
                  "key": {},
                  "value": {},
                  "sensitive": true
                },
                "clientToken": {},
                "expirationTime": {
                  "type": "timestamp",
                  "timestampFormat": "iso8601"
                },
                "phoneNumber": {
                  "type": "string",
                  "sensitive": true
                }
              }
            }
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "failedRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "clientToken": {},
                "failureCode": {},
                "id": {}
              }
            }
          },
          "successfulRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "clientToken": {},
                "id": {}
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "ResumeCampaign": {
      "http": {
        "requestUri": "/campaigns/{id}/resume",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      }
    },
    "StartCampaign": {
      "http": {
        "requestUri": "/campaigns/{id}/start",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      }
    },
    "StartInstanceOnboardingJob": {
      "http": {
        "method": "PUT",
        "requestUri": "/connect-instance/{connectInstanceId}/onboarding",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "connectInstanceId",
          "encryptionConfig"
        ],
        "members": {
          "connectInstanceId": {
            "location": "uri",
            "locationName": "connectInstanceId"
          },
          "encryptionConfig": {
            "shape": "S14"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectInstanceOnboardingJobStatus": {
            "shape": "S1b"
          }
        }
      },
      "idempotent": true
    },
    "StopCampaign": {
      "http": {
        "requestUri": "/campaigns/{id}/stop",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{arn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn",
          "tags"
        ],
        "members": {
          "arn": {
            "location": "uri",
            "locationName": "arn"
          },
          "tags": {
            "shape": "Se"
          }
        }
      },
      "idempotent": true
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{arn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn",
          "tagKeys"
        ],
        "members": {
          "arn": {
            "location": "uri",
            "locationName": "arn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "idempotent": true
    },
    "UpdateCampaignDialerConfig": {
      "http": {
        "requestUri": "/campaigns/{id}/dialer-config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "dialerConfig",
          "id"
        ],
        "members": {
          "dialerConfig": {
            "shape": "S3"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "idempotent": true
    },
    "UpdateCampaignName": {
      "http": {
        "requestUri": "/campaigns/{id}/name",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id",
          "name"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "name": {}
        }
      },
      "idempotent": true
    },
    "UpdateCampaignOutboundCallConfig": {
      "http": {
        "requestUri": "/campaigns/{id}/outbound-call-config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "answerMachineDetectionConfig": {
            "shape": "S9"
          },
          "connectContactFlowId": {},
          "connectSourcePhoneNumber": {},
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "members": {
        "predictiveDialerConfig": {
          "type": "structure",
          "required": [
            "bandwidthAllocation"
          ],
          "members": {
            "bandwidthAllocation": {
              "type": "double"
            }
          }
        },
        "progressiveDialerConfig": {
          "type": "structure",
          "required": [
            "bandwidthAllocation"
          ],
          "members": {
            "bandwidthAllocation": {
              "type": "double"
            }
          }
        }
      },
      "union": true
    },
    "S8": {
      "type": "structure",
      "required": [
        "connectContactFlowId",
        "connectQueueId"
      ],
      "members": {
        "answerMachineDetectionConfig": {
          "shape": "S9"
        },
        "connectContactFlowId": {},
        "connectQueueId": {},
        "connectSourcePhoneNumber": {}
      }
    },
    "S9": {
      "type": "structure",
      "required": [
        "enableAnswerMachineDetection"
      ],
      "members": {
        "enableAnswerMachineDetection": {
          "type": "boolean"
        }
      }
    },
    "Se": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S14": {
      "type": "structure",
      "required": [
        "enabled"
      ],
      "members": {
        "enabled": {
          "type": "boolean"
        },
        "encryptionType": {},
        "keyArn": {}
      }
    },
    "S1b": {
      "type": "structure",
      "required": [
        "connectInstanceId",
        "status"
      ],
      "members": {
        "connectInstanceId": {},
        "failureCode": {},
        "status": {}
      }
    }
  }
}