{
  "pagination": {
    "DescribeAccountLimits": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "AccountLimits"
    },
    "DescribeStackEvents": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "StackEvents"
    },
    "DescribeStackResourceDrifts": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "DescribeStackResources": {
      "result_key": "StackResources"
    },
    "DescribeStacks": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Stacks"
    },
    "ListChangeSets": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Summaries"
    },
    "ListExports": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Exports"
    },
    "ListImports": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Imports"
    },
    "ListStackInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Summaries"
    },
    "ListStackResources": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "StackResourceSummaries"
    },
    "ListStackSetOperationResults": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Summaries"
    },
    "ListStackSetOperations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Summaries"
    },
    "ListStackSets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Summaries"
    },
    "ListStacks": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "StackSummaries"
    },
    "ListTypeRegistrations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListTypeVersions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListTypes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TypeSummaries"
    }
  }
}