{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-07-29",
    "endpointPrefix": "app-integrations",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon AppIntegrations Service",
    "serviceId": "AppIntegrations",
    "signatureVersion": "v4",
    "signingName": "app-integrations",
    "uid": "appintegrations-2020-07-29"
  },
  "operations": {
    "CreateDataIntegration": {
      "http": {
        "requestUri": "/dataIntegrations"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "KmsKey": {},
          "SourceURI": {},
          "ScheduleConfig": {
            "shape": "S5"
          },
          "Tags": {
            "shape": "S8"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Id": {},
          "Name": {},
          "Description": {},
          "KmsKey": {},
          "SourceURI": {},
          "ScheduleConfiguration": {
            "shape": "S5"
          },
          "Tags": {
            "shape": "S8"
          },
          "ClientToken": {}
        }
      }
    },
    "CreateEventIntegration": {
      "http": {
        "requestUri": "/eventIntegrations"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "EventFilter",
          "EventBridgeBus"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "EventFilter": {
            "shape": "Sg"
          },
          "EventBridgeBus": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventIntegrationArn": {}
        }
      }
    },
    "DeleteDataIntegration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/dataIntegrations/{Identifier}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DataIntegrationIdentifier"
        ],
        "members": {
          "DataIntegrationIdentifier": {
            "location": "uri",
            "locationName": "Identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteEventIntegration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/eventIntegrations/{Name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetDataIntegration": {
      "http": {
        "method": "GET",
        "requestUri": "/dataIntegrations/{Identifier}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Identifier"
        ],
        "members": {
          "Identifier": {
            "location": "uri",
            "locationName": "Identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Id": {},
          "Name": {},
          "Description": {},
          "KmsKey": {},
          "SourceURI": {},
          "ScheduleConfiguration": {
            "shape": "S5"
          },
          "Tags": {
            "shape": "S8"
          }
        }
      }
    },
    "GetEventIntegration": {
      "http": {
        "method": "GET",
        "requestUri": "/eventIntegrations/{Name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Description": {},
          "EventIntegrationArn": {},
          "EventBridgeBus": {},
          "EventFilter": {
            "shape": "Sg"
          },
          "Tags": {
            "shape": "S8"
          }
        }
      }
    },
    "ListDataIntegrationAssociations": {
      "http": {
        "method": "GET",
        "requestUri": "/dataIntegrations/{Identifier}/associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "DataIntegrationIdentifier"
        ],
        "members": {
          "DataIntegrationIdentifier": {
            "location": "uri",
            "locationName": "Identifier"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataIntegrationAssociations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DataIntegrationAssociationArn": {},
                "DataIntegrationArn": {},
                "ClientId": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDataIntegrations": {
      "http": {
        "method": "GET",
        "requestUri": "/dataIntegrations"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataIntegrations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Name": {},
                "SourceURI": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEventIntegrationAssociations": {
      "http": {
        "method": "GET",
        "requestUri": "/eventIntegrations/{Name}/associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "EventIntegrationName"
        ],
        "members": {
          "EventIntegrationName": {
            "location": "uri",
            "locationName": "Name"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventIntegrationAssociations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventIntegrationAssociationArn": {},
                "EventIntegrationAssociationId": {},
                "EventIntegrationName": {},
                "ClientId": {},
                "EventBridgeRuleName": {},
                "ClientAssociationMetadata": {
                  "type": "map",
                  "key": {},
                  "value": {}
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEventIntegrations": {
      "http": {
        "method": "GET",
        "requestUri": "/eventIntegrations"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventIntegrations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventIntegrationArn": {},
                "Name": {},
                "Description": {},
                "EventFilter": {
                  "shape": "Sg"
                },
                "EventBridgeBus": {},
                "Tags": {
                  "shape": "S8"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S8"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDataIntegration": {
      "http": {
        "method": "PATCH",
        "requestUri": "/dataIntegrations/{Identifier}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Identifier"
        ],
        "members": {
          "Identifier": {
            "location": "uri",
            "locationName": "Identifier"
          },
          "Name": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateEventIntegration": {
      "http": {
        "method": "PATCH",
        "requestUri": "/eventIntegrations/{Name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "FirstExecutionFrom": {},
        "Object": {},
        "ScheduleExpression": {}
      }
    },
    "S8": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sg": {
      "type": "structure",
      "required": [
        "Source"
      ],
      "members": {
        "Source": {}
      }
    }
  }
}