{
  "pagination": {
    "ListAccountPermissions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "permissions"
    },
    "ListCoverage": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "coveredResources"
    },
    "ListCoverageStatistics": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "countsByGroup"
    },
    "ListDelegatedAdminAccounts": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "delegatedAdminAccounts"
    },
    "ListFilters": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "filters"
    },
    "ListFindingAggregations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "responses"
    },
    "ListFindings": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "findings"
    },
    "ListMembers": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "members"
    },
    "ListUsageTotals": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "totals"
    }
  }
}
