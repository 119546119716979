{
  "pagination": {
    "ListAttacks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AttackSummaries"
    },
    "ListProtectionGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListProtections": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Protections"
    },
    "ListResourcesInProtectionGroup": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    }
  }
}