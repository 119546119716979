import {Fragment} from 'react';
import {Typography, List, ListItem, ListItemText, useMediaQuery} from "@material-ui/core";
import {styles, theme} from "../shared/styles";

export function DocumentResults(props: any) {
    
    if (props.numDocuments > 0) {
        return (
            <>
                <List>
                    {props.documentResults.map((result: any) => DocumentResult(result, props))}
                </List>
                <div style={{ height: 70 }}> 
                </div>
            </>
        );
    } else {
        return (
            <List>
                <ListItem>
                    <ListItemText primary={<Typography>No documents found</Typography>}/>
                </ListItem>
            </List>
        );
    }
}

function DocumentResult(result: any, props: any) {
    const classes = styles();

    return (
        <Fragment key={result.category}>
            <ListItem key={result.category} className={classes.category}>{result.category}</ListItem>
            {result.searchDocumentsResults.map((document: any) => RenderDocumentLink(document, props))}
        </Fragment>
    );
}

function RenderDocumentLink(document: any, props: any) {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClick = (event: MouseEvent, document: any) => {
        props.setDocumentVersion(document.id, document.version);
        if (isSmallScreen) {
            props.setIsDrawerOpen(false);
        }
    };

    const isSelected = (id: number, version: string) => {
        return Number(props.selectedDocumentId) === id &&
                props.selectedDocumentVersion === version;
    }

    return (
        <ListItem
            key={document.id + document.version} 
            selected={isSelected(document.id, document.version)}
            onClick={(event: any) => handleClick(event, document)}
            button
            component="nav"
            >
            <ListItemText 
                key={document.id + document.version} 
                primary={<Typography key={document.title}>{document.title}</Typography>} 
                primaryTypographyProps={{component:'div'}}
                secondaryTypographyProps={{component:'div'}}
                secondary={document.version}
            />
        </ListItem>
    );
}