{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-09-27",
    "endpointPrefix": "voiceid",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceFullName": "Amazon Voice ID",
    "serviceId": "Voice ID",
    "signatureVersion": "v4",
    "signingName": "voiceid",
    "targetPrefix": "VoiceID",
    "uid": "voice-id-2021-09-27"
  },
  "operations": {
    "CreateDomain": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ServerSideEncryptionConfiguration"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "Description": {
            "shape": "S3"
          },
          "Name": {
            "shape": "S4"
          },
          "ServerSideEncryptionConfiguration": {
            "shape": "S5"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Domain": {
            "shape": "Sc"
          }
        }
      },
      "idempotent": true
    },
    "DeleteDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId"
        ],
        "members": {
          "DomainId": {}
        }
      },
      "idempotent": true
    },
    "DeleteFraudster": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "FraudsterId"
        ],
        "members": {
          "DomainId": {},
          "FraudsterId": {
            "shape": "Sm"
          }
        }
      }
    },
    "DeleteSpeaker": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "SpeakerId"
        ],
        "members": {
          "DomainId": {},
          "SpeakerId": {
            "shape": "So"
          }
        }
      }
    },
    "DescribeDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId"
        ],
        "members": {
          "DomainId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Domain": {
            "shape": "Sc"
          }
        }
      }
    },
    "DescribeFraudster": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "FraudsterId"
        ],
        "members": {
          "DomainId": {},
          "FraudsterId": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Fraudster": {
            "type": "structure",
            "members": {
              "CreatedAt": {
                "type": "timestamp"
              },
              "DomainId": {},
              "GeneratedFraudsterId": {}
            }
          }
        }
      }
    },
    "DescribeFraudsterRegistrationJob": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "JobId"
        ],
        "members": {
          "DomainId": {},
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Job": {
            "shape": "Sy"
          }
        }
      }
    },
    "DescribeSpeaker": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "SpeakerId"
        ],
        "members": {
          "DomainId": {},
          "SpeakerId": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Speaker": {
            "shape": "S1d"
          }
        }
      }
    },
    "DescribeSpeakerEnrollmentJob": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "JobId"
        ],
        "members": {
          "DomainId": {},
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Job": {
            "shape": "S1j"
          }
        }
      }
    },
    "EvaluateSession": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "SessionNameOrId"
        ],
        "members": {
          "DomainId": {},
          "SessionNameOrId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AuthenticationResult": {
            "type": "structure",
            "members": {
              "AudioAggregationEndedAt": {
                "type": "timestamp"
              },
              "AudioAggregationStartedAt": {
                "type": "timestamp"
              },
              "AuthenticationResultId": {},
              "Configuration": {
                "type": "structure",
                "required": [
                  "AcceptanceThreshold"
                ],
                "members": {
                  "AcceptanceThreshold": {
                    "type": "integer"
                  }
                }
              },
              "CustomerSpeakerId": {
                "shape": "S1e"
              },
              "Decision": {},
              "GeneratedSpeakerId": {},
              "Score": {
                "type": "integer"
              }
            }
          },
          "DomainId": {},
          "FraudDetectionResult": {
            "type": "structure",
            "members": {
              "AudioAggregationEndedAt": {
                "type": "timestamp"
              },
              "AudioAggregationStartedAt": {
                "type": "timestamp"
              },
              "Configuration": {
                "type": "structure",
                "required": [
                  "RiskThreshold"
                ],
                "members": {
                  "RiskThreshold": {
                    "type": "integer"
                  }
                }
              },
              "Decision": {},
              "FraudDetectionResultId": {},
              "Reasons": {
                "type": "list",
                "member": {}
              },
              "RiskDetails": {
                "type": "structure",
                "required": [
                  "KnownFraudsterRisk",
                  "VoiceSpoofingRisk"
                ],
                "members": {
                  "KnownFraudsterRisk": {
                    "type": "structure",
                    "required": [
                      "RiskScore"
                    ],
                    "members": {
                      "GeneratedFraudsterId": {},
                      "RiskScore": {
                        "type": "integer"
                      }
                    }
                  },
                  "VoiceSpoofingRisk": {
                    "type": "structure",
                    "required": [
                      "RiskScore"
                    ],
                    "members": {
                      "RiskScore": {
                        "type": "integer"
                      }
                    }
                  }
                }
              }
            }
          },
          "SessionId": {},
          "SessionName": {},
          "StreamingStatus": {}
        }
      }
    },
    "ListDomains": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "Description": {
                  "shape": "S3"
                },
                "DomainId": {},
                "DomainStatus": {},
                "Name": {
                  "shape": "S4"
                },
                "ServerSideEncryptionConfiguration": {
                  "shape": "S5"
                },
                "ServerSideEncryptionUpdateDetails": {
                  "shape": "Sh"
                },
                "UpdatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListFraudsterRegistrationJobs": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId"
        ],
        "members": {
          "DomainId": {},
          "JobStatus": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CreatedAt": {
                  "type": "timestamp"
                },
                "DomainId": {},
                "EndedAt": {
                  "type": "timestamp"
                },
                "FailureDetails": {
                  "shape": "S10"
                },
                "JobId": {},
                "JobName": {
                  "shape": "S14"
                },
                "JobProgress": {
                  "shape": "S15"
                },
                "JobStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSpeakerEnrollmentJobs": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId"
        ],
        "members": {
          "DomainId": {},
          "JobStatus": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CreatedAt": {
                  "type": "timestamp"
                },
                "DomainId": {},
                "EndedAt": {
                  "type": "timestamp"
                },
                "FailureDetails": {
                  "shape": "S10"
                },
                "JobId": {},
                "JobName": {
                  "shape": "S14"
                },
                "JobProgress": {
                  "shape": "S15"
                },
                "JobStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSpeakers": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId"
        ],
        "members": {
          "DomainId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "SpeakerSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CreatedAt": {
                  "type": "timestamp"
                },
                "CustomerSpeakerId": {
                  "shape": "S1e"
                },
                "DomainId": {},
                "GeneratedSpeakerId": {},
                "LastAccessedAt": {
                  "type": "timestamp"
                },
                "Status": {},
                "UpdatedAt": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "OptOutSpeaker": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "SpeakerId"
        ],
        "members": {
          "DomainId": {},
          "SpeakerId": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Speaker": {
            "shape": "S1d"
          }
        }
      }
    },
    "StartFraudsterRegistrationJob": {
      "input": {
        "type": "structure",
        "required": [
          "DataAccessRoleArn",
          "DomainId",
          "InputDataConfig",
          "OutputDataConfig"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "DataAccessRoleArn": {},
          "DomainId": {},
          "InputDataConfig": {
            "shape": "S12"
          },
          "JobName": {
            "shape": "S14"
          },
          "OutputDataConfig": {
            "shape": "S18"
          },
          "RegistrationConfig": {
            "shape": "S19"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Job": {
            "shape": "Sy"
          }
        }
      },
      "idempotent": true
    },
    "StartSpeakerEnrollmentJob": {
      "input": {
        "type": "structure",
        "required": [
          "DataAccessRoleArn",
          "DomainId",
          "InputDataConfig",
          "OutputDataConfig"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "DataAccessRoleArn": {},
          "DomainId": {},
          "EnrollmentConfig": {
            "shape": "S1k"
          },
          "InputDataConfig": {
            "shape": "S12"
          },
          "JobName": {
            "shape": "S14"
          },
          "OutputDataConfig": {
            "shape": "S18"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Job": {
            "shape": "S1j"
          }
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {
              "shape": "S9"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "Name",
          "ServerSideEncryptionConfiguration"
        ],
        "members": {
          "Description": {
            "shape": "S3"
          },
          "DomainId": {},
          "Name": {
            "shape": "S4"
          },
          "ServerSideEncryptionConfiguration": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Domain": {
            "shape": "Sc"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "string",
      "sensitive": true
    },
    "S4": {
      "type": "string",
      "sensitive": true
    },
    "S5": {
      "type": "structure",
      "required": [
        "KmsKeyId"
      ],
      "members": {
        "KmsKeyId": {}
      }
    },
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {
            "shape": "S9"
          },
          "Value": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "S9": {
      "type": "string",
      "sensitive": true
    },
    "Sc": {
      "type": "structure",
      "members": {
        "Arn": {},
        "CreatedAt": {
          "type": "timestamp"
        },
        "Description": {
          "shape": "S3"
        },
        "DomainId": {},
        "DomainStatus": {},
        "Name": {
          "shape": "S4"
        },
        "ServerSideEncryptionConfiguration": {
          "shape": "S5"
        },
        "ServerSideEncryptionUpdateDetails": {
          "shape": "Sh"
        },
        "UpdatedAt": {
          "type": "timestamp"
        }
      }
    },
    "Sh": {
      "type": "structure",
      "members": {
        "Message": {},
        "OldKmsKeyId": {},
        "UpdateStatus": {}
      }
    },
    "Sm": {
      "type": "string",
      "sensitive": true
    },
    "So": {
      "type": "string",
      "sensitive": true
    },
    "Sy": {
      "type": "structure",
      "members": {
        "CreatedAt": {
          "type": "timestamp"
        },
        "DataAccessRoleArn": {},
        "DomainId": {},
        "EndedAt": {
          "type": "timestamp"
        },
        "FailureDetails": {
          "shape": "S10"
        },
        "InputDataConfig": {
          "shape": "S12"
        },
        "JobId": {},
        "JobName": {
          "shape": "S14"
        },
        "JobProgress": {
          "shape": "S15"
        },
        "JobStatus": {},
        "OutputDataConfig": {
          "shape": "S18"
        },
        "RegistrationConfig": {
          "shape": "S19"
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "Message": {},
        "StatusCode": {
          "type": "integer"
        }
      }
    },
    "S12": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {}
      }
    },
    "S14": {
      "type": "string",
      "sensitive": true
    },
    "S15": {
      "type": "structure",
      "members": {
        "PercentComplete": {
          "type": "integer"
        }
      }
    },
    "S18": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "KmsKeyId": {},
        "S3Uri": {}
      }
    },
    "S19": {
      "type": "structure",
      "members": {
        "DuplicateRegistrationAction": {},
        "FraudsterSimilarityThreshold": {
          "type": "integer"
        }
      }
    },
    "S1d": {
      "type": "structure",
      "members": {
        "CreatedAt": {
          "type": "timestamp"
        },
        "CustomerSpeakerId": {
          "shape": "S1e"
        },
        "DomainId": {},
        "GeneratedSpeakerId": {},
        "LastAccessedAt": {
          "type": "timestamp"
        },
        "Status": {},
        "UpdatedAt": {
          "type": "timestamp"
        }
      }
    },
    "S1e": {
      "type": "string",
      "sensitive": true
    },
    "S1j": {
      "type": "structure",
      "members": {
        "CreatedAt": {
          "type": "timestamp"
        },
        "DataAccessRoleArn": {},
        "DomainId": {},
        "EndedAt": {
          "type": "timestamp"
        },
        "EnrollmentConfig": {
          "shape": "S1k"
        },
        "FailureDetails": {
          "shape": "S10"
        },
        "InputDataConfig": {
          "shape": "S12"
        },
        "JobId": {},
        "JobName": {
          "shape": "S14"
        },
        "JobProgress": {
          "shape": "S15"
        },
        "JobStatus": {},
        "OutputDataConfig": {
          "shape": "S18"
        }
      }
    },
    "S1k": {
      "type": "structure",
      "members": {
        "ExistingEnrollmentAction": {},
        "FraudDetectionConfig": {
          "type": "structure",
          "members": {
            "FraudDetectionAction": {},
            "RiskThreshold": {
              "type": "integer"
            }
          }
        }
      }
    }
  }
}