import React from "react";
import {Button, createTheme, FormControl, IconButton, InputLabel, Select, TextField} from "@material-ui/core";
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import {isAllValidated, isValidAmount, isValidDate, isValidReceipts, isValidText} from "./validation.js";
import Uploader from "./Uploader";
import LoadingBar from "./loading";
import {Auth} from "../core";
import {getApproversDictionary, postReport} from "../../api/report";
import {createExpense} from "../../api/expense";
import "./ReimbursementForm.css";
import {convertToCapitalCase} from "./Utility";
import moment from 'moment';
import {Chip} from "@mui/material";

const theme = createTheme({
  typography: {
    fontSize: 18,
    fontFamily: "Montserrat"
  }
});

export default class ReimbursementForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expenseDate: moment().format("YYYY-MM-DD"),
      expenseTypes: [],
      selectedExpenseType: "",
      expenseDescription: "",
      expenseAmount: "",
      approversDictionary: null,
      approverEmail: "",
      imagePaths: [''],
      isSubmitted: false,
      uploadPromises: [],
      failedToSubmit: false,
    };
  }

  async componentDidMount() {
    await this.loadUser();
    await this.loadApproversDictionary();
  }

  async loadUser() {
    const user = await Auth.getUser();
    this.setState({
      user
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleTypeChange = event => {
    const {user} = this.state;
    let expenseType = event.target.value;
    const approverList = this.state.approversDictionary[expenseType]
        .filter(approver => approver.email != user.email)
    const approverEmail = approverList.length > 0 ? approverList[0].email : undefined;
    this.setState({
      selectedExpenseType: expenseType,
      approverEmail
    })
  }

  async loadApproversDictionary() {
    const {user} = this.state;
    let approversDictionary = await getApproversDictionary();
    if (approversDictionary) {
      let expenseTypes =  Object.keys(approversDictionary);
      const selectedExpenseType = expenseTypes[0];
      const approverList = approversDictionary[selectedExpenseType]
          .filter(approver => approver.email != user.email)
      const approverEmail = approverList.length > 0 ? approverList[0].email : undefined;
      this.setState({
        approversDictionary,
        expenseTypes: expenseTypes,
        selectedExpenseType,
        approverEmail
      });
    }
  }

  handleSubmit = async event => {
    this.setState({ isSubmitted: true });

    let legacyRequest = {
      email: this.state.user.email,
      expenseTitle: this.state.selectedExpenseType,
      expenseDate: this.state.expenseDate,
      expenseDescription: this.state.expenseDescription,
      reimbursementAmount: this.state.expenseAmount,
      approverEmail: this.state.approverEmail,
      imagePaths: this.state.imagePaths
    };

    let northstarRequest = {
      userID: this.state.user.id,
      amount: {
        amount: 100 * parseFloat(this.state.expenseAmount),
        currency: "USD"
      },
      transactionDate:  this.state.expenseDate,
      type: "REIMBURSEMENT",
      description: this.state.expenseDescription,
      title: this.state.selectedExpenseType,
      category: this.state.selectedExpenseType,
      receipts: this.state.imagePaths
    }

    await Promise.all(this.state.uploadPromises);
    let legacyResult = await postReport(legacyRequest);

    try {
      await createExpense(northstarRequest);
    } catch(error) {
      // No-op. Ignoring error when writing to N* API. We need to check the backend API error instead.
    }

    if (legacyResult) {
      window.location.replace("/confirmation");
    } else {
      this.setState({
        failedToSubmit: true,
        isSubmitted: false
      });
    }
  };

  handleAddImage = () => {
    const { imagePaths } = this.state;
    if (imagePaths.length >= 9) {
      return;
    }
    imagePaths.push('');
    this.setState({imagePaths});
  };

  handleDeleteImage = i => {
    const { imagePaths, uploadPromises } = this.state;
    imagePaths.splice(i, 1);
    uploadPromises.splice(i, 1);
    this.setState({ imagePaths, uploadPromises });
  };

  onUpload = (uploadPromise, uploaderIndex) => {
    const { uploadPromises } = this.state;
    uploadPromises.push(
      uploadPromise.then(data => {
        const { imagePaths } = this.state;
        imagePaths[uploaderIndex] = data.Location;
        this.setState({ imagePaths });
      })
    );
    this.setState({uploadPromises});
  };

  removeUploadImage = uploaderIndex => {
    const imagePaths = this.state;
    updatedImagePaths[uploaderIndex] = '';
    this.setState({ imagePaths });
  };

  render() {
    const { approversDictionary, expenseTypes, selectedExpenseType, user } = this.state;
    const approverList = (!!approversDictionary ? approversDictionary[selectedExpenseType] : []).
        filter(approver => user && approver.email != user.email)
        .map(function(approver) {
          return (
            <option key={approver.id} value={approver.email}>
              {approver.firstName} {approver.lastName}
            </option>
          );
        });

    const expenseTypesList = expenseTypes.map(function(expenseType) {
      return (
        <option key={expenseType} value={expenseType}>
          {convertToCapitalCase(expenseType)}
        </option>
      );
    });

    const dateValidation = isValidDate(this.state.expenseDate);
    const descriptionValidation = isValidText(this.state.expenseDescription);
    const amountValidation = isValidAmount(this.state.expenseAmount);
    const receiptValidation = isValidReceipts(Object.values(this.state.imagePaths));
    const isEnabled = [
      dateValidation,
      descriptionValidation,
      amountValidation,
      receiptValidation
    ].every(isAllValidated);

    const { imagePaths } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <div className="container">
          <div
            className="loadingBar"
            style={{ display: this.state.isSubmitted ? "flex" : "none" }}
          >
            <LoadingBar inProgress={this.state.isSubmitted} />
          </div>
          <form className="reimbursementForm">
            <h1 align="center">Reimbursement Form</h1>

            {this.state.failedToSubmit && (
              <p className="errorMessage">
                Something went wrong. Failed to submit! Please verify input.
              </p>
            )}

            {/* User Info */}
            <div>
              Submitter Email: <Chip label={user ? user.email : ''} />
            </div>
            <br/>

            {/* Expense Info */}
            <div>
              <TextField
                required
                id="expenseDate"
                label="Expense Date"
                type="date"
                defaultValue={this.state.expenseDate}
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ shrink: true }}
                error={!dateValidation}
                onBlur={event =>
                  this.setState({ expenseDate: event.target.value })
                }
              />
            </div>

            <div>
              <FormControl required>
                <InputLabel>Expense</InputLabel>
                <Select
                  native
                  value={this.state.selectedExpenseType}
                  onChange={this.handleTypeChange}
                  name="expense"
                  disableUnderline={true}
                >
                  {expenseTypesList}
                </Select>
              </FormControl>
            </div>

            <div>
              <TextField
                multiline
                required
                id="expenseDescription"
                fullWidth
                label="Expense Details"
                margin="normal"
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ shrink: true }}
                error={!descriptionValidation}
                placeholder="Provide as many details and expenses breakdown as you can"
                minRows="10"
                value={this.state.expenseDescription}
                onChange={this.handleChange("expenseDescription")}
              />
            </div>

            <div>
              <TextField
                required
                id="reimbursementAmount"
                fullWidth
                label="Reimbursement Amount"
                margin="normal"
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ shrink: true }}
                error={!amountValidation}
                placeholder="25.00"
                value={this.state.expenseAmount}
                onChange={this.handleChange("expenseAmount")}
                helperText={!amountValidation ? "Enter a valid currency amount up to two decimal places" : ""}
                //model-view controller
              />
            </div>

            {this.state.selectedExpenseType && (
              <div>
                <FormControl required>
                  <InputLabel>Approver</InputLabel>
                  <Select
                    native
                    value={this.state.approverEmail}
                    onChange={this.handleChange("approverEmail")}
                    name="approver"
                    disableUnderline={true}
                  >
                    {approverList}
                  </Select>
                </FormControl>
              </div>
            )}

            <div>
              <FormControl
                required
                error={!receiptValidation}
              >
                <InputLabel shrink={true}>Receipts</InputLabel>
                <div id="receipts">
                  {imagePaths.map((imagePath, i) => {
                    return (
                      <div key={'uploader-' + i}>
                        <FormControl className="uploaderFormControl">
                          <Uploader
                            onUpload={this.onUpload}
                            index={i}
                            removeUploadImage={this.removeUploadImage}
                          />
                        </FormControl>
                        {i > 0 &&
                        <IconButton
                            className="delete"
                            aria-label="Delete"
                            onClick={this.handleDeleteImage.bind(this, i)}>
                          <DeleteIcon />
                        </IconButton>}
                      </div>
                    );
                  })}
                  <Button
                    aria-label="Add"
                    startIcon={<AddIcon />}
                    size="small"
                    onClick={this.handleAddImage}
                  >
                    Add more file
                  </Button>
                </div>
              </FormControl>
            </div>

            {/* Submit */}
            <div>
              <input
                id="submitButton"
                type="button"
                disabled={!isEnabled}
                value="Submit"
                onClick={this.handleSubmit}
              />
            </div>
          </form>
        </div>
      </MuiThemeProvider>
    );
  }
}
