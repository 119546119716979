import { Auth } from "../components/core/Auth";
import appconfig from "../appconfig";

const API_ADDRESS = appconfig.directoryUrl;

// Default headers for POST requests
const headers = new Headers()
headers.append('Content-Type', 'application/json')

export const getDirectoryByEmail = async (email) => {
  const url = new URL(`${API_ADDRESS}/emails/${email}`);
  const response = await fetch(url, {
    credentials: 'include',
  });

  return await Auth.handleResponse(response);
}

export const createSessionWithToken = async(token) => {
  const body = new FormData();
  body.append('assertion', token);
  const url = new URL(`${API_ADDRESS}/sessions`);
  const response = await fetch(url, {
    credentials: 'include',
    method: "POST",
    body,
  });

  return await Auth.handleResponse(response);
}

export const getSessionById = async(id) => {
  const url = new URL(`${API_ADDRESS}/sessions/${id}`);
  const response = await fetch(url, {
    credentials: 'include',
  });

  return await Auth.handleResponse(response);
}

export const getUser = async() => {
  const url = new URL(`${API_ADDRESS}/user`);
  const response = await fetch(url, {
    credentials: 'include',
  });

  return await Auth.handleGetUserResponse(response);
}

export const logout = async() => {
  const url = new URL(`${API_ADDRESS}/logout`);
  const response = await fetch(url, {
    credentials: 'include',
    method: "POST",
  });

  return;
}
