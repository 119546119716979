import { Auth } from "../components/core/Auth";
import appconfig from "../appconfig";

const API_ADDRESS = appconfig.worshipUrl;

const INSERT_SONG = "/insert";
const GET_SONG_SEARCH = "/SearchSongContents/";
const GET_SONG_META = "/songMetaId/";
const GET_SONG_CONTENTS = "/songContentsByMetaId/";
const GET_SONG_CONTENTS_BY_ALPHABET = "/songContentsByAlphabet/"
const UPDATE_SONG = "/update"
const DELETE_SONG = "/delete"

// Default headers for POST requests
const headers = new Headers()
headers.append('Content-Type', 'application/json')

export const insertSong = async (data, email) => {
  const url = new URL(API_ADDRESS + INSERT_SONG);
  url.searchParams.append("actorEmail", email);
  const response = await fetch(url, {
    credentials: 'include',
    method: "POST",
    body: JSON.stringify(data),
    headers,
  });

  return await Auth.handleResponse(response);
};

export const getSongContentsbySearch = async (searchText) => {
  const url = new URL(API_ADDRESS + GET_SONG_SEARCH + searchText);
  const response = await fetch(url, {
    credentials: 'include',
  });

  return await Auth.handleResponse(response);
};

export const getSongMetadata = async (id) => {
  const url = new URL(API_ADDRESS + GET_SONG_META + id);
  const response = await fetch(url, {
    credentials: 'include',
  });

  return await Auth.handleResponse(response);
};

export const getSongContents = async (id) => {
  const url = new URL(API_ADDRESS + GET_SONG_CONTENTS + id);
  const response = await fetch(url, {
    credentials: 'include',
  });

  return await Auth.handleResponse(response);
};

export const getSongbyIndex = async(index) => {
  // # is URL encoded as "%23"
  const encodedIndex = index === '#' ? '%23' : index;
  const address = API_ADDRESS + GET_SONG_CONTENTS_BY_ALPHABET + encodedIndex;
  const url = new URL(address);
  const response = await fetch(url, {
    credentials: 'include',
  });

  return await Auth.handleResponse(response);
}

export const updateSong = async (data, email) => {
  const url = new URL(API_ADDRESS + UPDATE_SONG);
  url.searchParams.append("actorEmail", email);
  const response = await fetch(url, {
    credentials: 'include',
    method: "POST",
    body: JSON.stringify(data),
    headers
  });

  return await Auth.handleResponse(response);
};

export const deleteSong = async (id, email) => {
  const url = new URL(API_ADDRESS + DELETE_SONG);
  url.searchParams.append("id", id)
  url.searchParams.append("actorEmail", email)
  const response = await fetch(url, {
    credentials: 'include',
    method: "PUT",
  });

  return await Auth.handleResponse(response);
}