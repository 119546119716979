{
  "pagination": {
    "ListApplicationRevisions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "revisions"
    },
    "ListApplications": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "applications"
    },
    "ListDeploymentConfigs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "deploymentConfigsList"
    },
    "ListDeploymentGroups": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "deploymentGroups"
    },
    "ListDeploymentInstances": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "instancesList"
    },
    "ListDeployments": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "deployments"
    }
  }
}