{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-08-17",
    "endpointPrefix": "gamesparks",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "GameSparks",
    "serviceId": "GameSparks",
    "signatureVersion": "v4",
    "signingName": "gamesparks",
    "uid": "gamesparks-2021-08-17"
  },
  "operations": {
    "CreateGame": {
      "http": {
        "requestUri": "/game",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName"
        ],
        "members": {
          "ClientToken": {},
          "Description": {},
          "GameName": {},
          "Tags": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Game": {
            "shape": "S9"
          }
        }
      },
      "idempotent": true
    },
    "CreateSnapshot": {
      "http": {
        "requestUri": "/game/{GameName}/snapshot",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName"
        ],
        "members": {
          "Description": {},
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sh"
          }
        }
      }
    },
    "CreateStage": {
      "http": {
        "requestUri": "/game/{GameName}/stage",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "Role",
          "StageName"
        ],
        "members": {
          "ClientToken": {},
          "Description": {},
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "Role": {},
          "StageName": {},
          "Tags": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Stage": {
            "shape": "St"
          }
        }
      },
      "idempotent": true
    },
    "DeleteGame": {
      "http": {
        "method": "DELETE",
        "requestUri": "/game/{GameName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteStage": {
      "http": {
        "method": "DELETE",
        "requestUri": "/game/{GameName}/stage/{StageName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "StageName"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "StageName": {
            "location": "uri",
            "locationName": "StageName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DisconnectPlayer": {
      "http": {
        "requestUri": "/runtime/game/{GameName}/stage/{StageName}/player/{PlayerId}/disconnect",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "PlayerId",
          "StageName"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "PlayerId": {
            "location": "uri",
            "locationName": "PlayerId"
          },
          "StageName": {
            "location": "uri",
            "locationName": "StageName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DisconnectFailures": {
            "shape": "S14"
          },
          "DisconnectSuccesses": {
            "shape": "S14"
          }
        }
      },
      "idempotent": true
    },
    "ExportSnapshot": {
      "http": {
        "method": "GET",
        "requestUri": "/game/{GameName}/snapshot/{SnapshotId}/export",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "SnapshotId"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "SnapshotId": {
            "location": "uri",
            "locationName": "SnapshotId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "S3Url": {}
        }
      }
    },
    "GetExtension": {
      "http": {
        "method": "GET",
        "requestUri": "/extension/{Namespace}/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Namespace"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Extension": {
            "shape": "S1d"
          }
        }
      }
    },
    "GetExtensionVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/extension/{Namespace}/{Name}/version/{ExtensionVersion}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ExtensionVersion",
          "Name",
          "Namespace"
        ],
        "members": {
          "ExtensionVersion": {
            "location": "uri",
            "locationName": "ExtensionVersion"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExtensionVersion": {
            "shape": "S1i"
          }
        }
      }
    },
    "GetGame": {
      "http": {
        "method": "GET",
        "requestUri": "/game/{GameName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Game": {
            "shape": "S9"
          }
        }
      }
    },
    "GetGameConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/game/{GameName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "Sections": {
            "shape": "S1n",
            "location": "querystring",
            "locationName": "Sections"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameConfiguration": {
            "shape": "S1p"
          }
        }
      }
    },
    "GetGeneratedCodeJob": {
      "http": {
        "method": "GET",
        "requestUri": "/game/{GameName}/snapshot/{SnapshotId}/generated-sdk-code-job/{JobId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "JobId",
          "SnapshotId"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "JobId": {
            "location": "uri",
            "locationName": "JobId"
          },
          "SnapshotId": {
            "location": "uri",
            "locationName": "SnapshotId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GeneratedCodeJob": {
            "shape": "S1t"
          }
        }
      }
    },
    "GetPlayerConnectionStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/runtime/game/{GameName}/stage/{StageName}/player/{PlayerId}/connection",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "PlayerId",
          "StageName"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "PlayerId": {
            "location": "uri",
            "locationName": "PlayerId"
          },
          "StageName": {
            "location": "uri",
            "locationName": "StageName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connections": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Created": {
                  "shape": "Sb"
                },
                "Id": {}
              }
            }
          }
        }
      }
    },
    "GetSnapshot": {
      "http": {
        "method": "GET",
        "requestUri": "/game/{GameName}/snapshot/{SnapshotId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "SnapshotId"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "Sections": {
            "shape": "S1n",
            "location": "querystring",
            "locationName": "Sections"
          },
          "SnapshotId": {
            "location": "uri",
            "locationName": "SnapshotId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sh"
          }
        }
      }
    },
    "GetStage": {
      "http": {
        "method": "GET",
        "requestUri": "/game/{GameName}/stage/{StageName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "StageName"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "StageName": {
            "location": "uri",
            "locationName": "StageName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Stage": {
            "shape": "St"
          }
        }
      }
    },
    "GetStageDeployment": {
      "http": {
        "method": "GET",
        "requestUri": "/game/{GameName}/stage/{StageName}/deployment",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "StageName"
        ],
        "members": {
          "DeploymentId": {
            "location": "querystring",
            "locationName": "DeploymentId"
          },
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "StageName": {
            "location": "uri",
            "locationName": "StageName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StageDeployment": {
            "shape": "S27"
          }
        }
      }
    },
    "ImportGameConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/game/{GameName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "ImportSource"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "ImportSource": {
            "type": "structure",
            "required": [
              "File"
            ],
            "members": {
              "File": {
                "type": "blob"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameConfiguration": {
            "shape": "S1p"
          }
        }
      },
      "idempotent": true
    },
    "ListExtensionVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/extension/{Namespace}/{Name}/version",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Namespace"
        ],
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExtensionVersions": {
            "type": "list",
            "member": {
              "shape": "S1i"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListExtensions": {
      "http": {
        "method": "GET",
        "requestUri": "/extension",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Extensions": {
            "type": "list",
            "member": {
              "shape": "S1d"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListGames": {
      "http": {
        "method": "GET",
        "requestUri": "/game",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Games": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Description": {},
                "Name": {},
                "State": {},
                "Tags": {
                  "shape": "S5"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListGeneratedCodeJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/game/{GameName}/snapshot/{SnapshotId}/generated-sdk-code-jobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "SnapshotId"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "SnapshotId": {
            "location": "uri",
            "locationName": "SnapshotId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GeneratedCodeJobs": {
            "type": "list",
            "member": {
              "shape": "S1t"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSnapshots": {
      "http": {
        "method": "GET",
        "requestUri": "/game/{GameName}/snapshot",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Snapshots": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Created": {
                  "shape": "Sb"
                },
                "Description": {},
                "Id": {},
                "LastUpdated": {
                  "shape": "Sb"
                }
              }
            }
          }
        }
      }
    },
    "ListStageDeployments": {
      "http": {
        "method": "GET",
        "requestUri": "/game/{GameName}/stage/{StageName}/deployments",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "StageName"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "StageName": {
            "location": "uri",
            "locationName": "StageName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "StageDeployments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DeploymentAction": {},
                "DeploymentId": {},
                "DeploymentResult": {
                  "shape": "S29"
                },
                "DeploymentState": {},
                "LastUpdated": {
                  "shape": "Sb"
                },
                "SnapshotId": {}
              }
            }
          }
        }
      }
    },
    "ListStages": {
      "http": {
        "method": "GET",
        "requestUri": "/game/{GameName}/stage",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Stages": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Description": {},
                "GameKey": {},
                "Name": {},
                "State": {},
                "Tags": {
                  "shape": "S5"
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S5"
          }
        }
      }
    },
    "StartGeneratedCodeJob": {
      "http": {
        "requestUri": "/game/{GameName}/snapshot/{SnapshotId}/generated-sdk-code-job",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "Generator",
          "SnapshotId"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "Generator": {
            "type": "structure",
            "members": {
              "GameSdkVersion": {},
              "Language": {},
              "TargetPlatform": {}
            }
          },
          "SnapshotId": {
            "location": "uri",
            "locationName": "SnapshotId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GeneratedCodeJobId": {}
        }
      }
    },
    "StartStageDeployment": {
      "http": {
        "requestUri": "/game/{GameName}/stage/{StageName}/deployment",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "SnapshotId",
          "StageName"
        ],
        "members": {
          "ClientToken": {},
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "SnapshotId": {},
          "StageName": {
            "location": "uri",
            "locationName": "StageName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StageDeployment": {
            "shape": "S27"
          }
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "tags": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "tagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateGame": {
      "http": {
        "method": "PATCH",
        "requestUri": "/game/{GameName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName"
        ],
        "members": {
          "Description": {},
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Game": {
            "shape": "S9"
          }
        }
      },
      "idempotent": true
    },
    "UpdateGameConfiguration": {
      "http": {
        "method": "PATCH",
        "requestUri": "/game/{GameName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "Modifications"
        ],
        "members": {
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "Modifications": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Operation",
                "Path",
                "Section"
              ],
              "members": {
                "Operation": {},
                "Path": {},
                "Section": {},
                "Value": {
                  "shape": "Sm"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameConfiguration": {
            "shape": "S1p"
          }
        }
      },
      "idempotent": true
    },
    "UpdateSnapshot": {
      "http": {
        "method": "PATCH",
        "requestUri": "/game/{GameName}/snapshot/{SnapshotId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "SnapshotId"
        ],
        "members": {
          "Description": {},
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "SnapshotId": {
            "location": "uri",
            "locationName": "SnapshotId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sh"
          }
        }
      },
      "idempotent": true
    },
    "UpdateStage": {
      "http": {
        "method": "PATCH",
        "requestUri": "/game/{GameName}/stage/{StageName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "GameName",
          "StageName"
        ],
        "members": {
          "Description": {},
          "GameName": {
            "location": "uri",
            "locationName": "GameName"
          },
          "Role": {},
          "StageName": {
            "location": "uri",
            "locationName": "StageName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Stage": {
            "shape": "St"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S5": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S9": {
      "type": "structure",
      "members": {
        "Arn": {},
        "Created": {
          "shape": "Sb"
        },
        "Description": {},
        "EnableTerminationProtection": {
          "type": "boolean"
        },
        "LastUpdated": {
          "shape": "Sb"
        },
        "Name": {},
        "State": {},
        "Tags": {
          "shape": "S5"
        }
      }
    },
    "Sb": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "Sh": {
      "type": "structure",
      "members": {
        "Created": {
          "shape": "Sb"
        },
        "Description": {},
        "Id": {},
        "LastUpdated": {
          "shape": "Sb"
        },
        "Sections": {
          "shape": "Sj"
        }
      }
    },
    "Sj": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Sm"
          },
          "Name": {},
          "Size": {
            "type": "integer"
          }
        }
      }
    },
    "Sm": {
      "type": "structure",
      "members": {},
      "document": true
    },
    "St": {
      "type": "structure",
      "members": {
        "Arn": {},
        "Created": {
          "shape": "Sb"
        },
        "Description": {},
        "GameKey": {},
        "LastUpdated": {
          "shape": "Sb"
        },
        "LogGroup": {},
        "Name": {},
        "Role": {},
        "State": {},
        "Tags": {
          "shape": "S5"
        }
      }
    },
    "S14": {
      "type": "list",
      "member": {}
    },
    "S1d": {
      "type": "structure",
      "members": {
        "Description": {},
        "Name": {},
        "Namespace": {}
      }
    },
    "S1i": {
      "type": "structure",
      "members": {
        "Name": {},
        "Namespace": {},
        "Schema": {},
        "Version": {}
      }
    },
    "S1n": {
      "type": "list",
      "member": {}
    },
    "S1p": {
      "type": "structure",
      "members": {
        "Created": {
          "shape": "Sb"
        },
        "LastUpdated": {
          "shape": "Sb"
        },
        "Sections": {
          "shape": "Sj"
        }
      }
    },
    "S1t": {
      "type": "structure",
      "members": {
        "Description": {},
        "ExpirationTime": {
          "shape": "Sb"
        },
        "GeneratedCodeJobId": {},
        "S3Url": {},
        "Status": {}
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "Created": {
          "shape": "Sb"
        },
        "DeploymentAction": {},
        "DeploymentId": {},
        "DeploymentResult": {
          "shape": "S29"
        },
        "DeploymentState": {},
        "LastUpdated": {
          "shape": "Sb"
        },
        "SnapshotId": {}
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "Message": {},
        "ResultCode": {}
      }
    }
  }
}