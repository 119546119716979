import useUrlState from "@ahooksjs/use-url-state";
import "@material-ui/core";
import { Box, CircularProgress, Drawer, Fab, Grid, Hidden, MuiThemeProvider, Toolbar } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';

import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { searchDocuments } from '../../../api/document';
import { Search } from "../shared/Search";
import { styles, theme } from "../shared/styles";
import { Document } from "./Document";
import { DocumentResults } from "./DocumentResults";

/** Default drawer width in pixels. */
const DEFAULT_DRAWER_WIDTH_PX = 360;

/** Minimal drawer width in pixels. */
const MIN_DRAWER_WIDTH_PX = 200;

/** Maximal drawer width in pixels. */
const MAX_DRAWER_WIDTH_PX = 1000;

/** Index width in pixels. */
const INDEX_WIDTH_PX = 53;

/** Index width in pixels. */
const CLOSED_WIDTH_PX = 18;

export default function Documents() {
    const classes = styles();
    const [documentResults, setDocumentResults] = useState([] as any[]);
    const [isLoading, setIsLoading] = useState(true);
    const [urlState, setUrlState] = useUrlState({ documentId: '', documentVersion: '', search: '' });
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [savedDrawerWidth, setSavedDrawerWidth] = useState(DEFAULT_DRAWER_WIDTH_PX);
    const [drawerWidth, setDrawerWidth] = useState(savedDrawerWidth);
    const [isDragging, setIsDragging] = useState(false);

    // Selected document version synced to url query param 'documentVersion'.
    const setDocumentVersion = (documentId: string, documentVersion: string) => {
        setUrlState({ ...urlState, documentId, documentVersion });
    }

    // Selected search keyword synced to url query param 'search'.
    const setSearch = (search: string) => {
        setUrlState({ ...urlState, search });
    }

    // Handle dragger mouse down event.
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
        setIsDragging(true);
        e.preventDefault();
    };

    // Handle dragger mouse up event.
    const handleMouseUp = (e: MouseEvent) => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
        setIsDragging(false);
        e.preventDefault();
    };

    // Handle dragger mouse move event.
    const handleMouseMove = useCallback(e => {
        const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > MIN_DRAWER_WIDTH_PX && newWidth < MAX_DRAWER_WIDTH_PX) {
            setSavedDrawerWidth(newWidth);
        }
        e.preventDefault();
    }, []);

    // Load document list based on search keyword
    async function searchDocumentsByKeyword(word: string) {
        setIsLoading(true);
        let response = await searchDocuments(word);
        setDocumentResults(response.categorizedSearchDocumentsResults);
        setIsLoading(false);
    }

    // Load document list based on selected index/search
    useEffect(() => {
    const fetchData = async () => {
        await searchDocumentsByKeyword(urlState.search)
    };
    fetchData();
    }, [urlState.search]);

    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    useEffect(() => {
        if (isDrawerOpen) {
            // Restore saved drawer width when opened.
            setDrawerWidth(savedDrawerWidth);
        } else {
            // Set drawer width to index width when drawer is closed.
            setDrawerWidth(CLOSED_WIDTH_PX);
        }
    }, [savedDrawerWidth, isDrawerOpen]);

    // Classes for drawer and its child paper container
    const drawerClasses = classNames(classes.drawer, {
        [classes.enterAnimation]: !isDragging && isDrawerOpen,
        [classes.leaveAnimation]: !isDragging && !isDrawerOpen,
    });

    // Styling for drawer and its child paper container
    const drawerStyle = { width: drawerWidth };

    // Dragger to manually set left panel width
    const dragger = (
        <div onMouseDown={e => handleMouseDown(e)}
            className={classes.dragger} />
    );

    const drawer = (
        <Grid container
            direction="row"
            className={classes.drawerContent}
            style={{ width: savedDrawerWidth }}>
            <Grid container
                direction="column"
                className={classes.listContainer}
                alignItems="stretch">
                <Box className={classes.searchContainer}>
                    <Box className={classes.drawerToggle}
                        onClick={handleDrawerToggle}>
                        {isDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
                    </Box>
                    <Search placeholder="Search by title or content"
                        searchText={urlState.search}
                        setSearchText={setSearch} />
                </Box>
                {isLoading &&
                    <Grid container justifyContent="center">
                        <CircularProgress className={classes.listLoading} />
                    </Grid>
                }
                {isDrawerOpen && !isLoading && <DocumentResults
                    className={classes.list}
                    documentResults={documentResults}
                    numDocuments={documentResults ? documentResults.length : 0}
                    query={urlState.search}
                    selectedDocumentId={urlState.documentId}
                    selectedDocumentVersion={urlState.documentVersion}
                    setDocumentVersion={setDocumentVersion}
                    setIsDrawerOpen={setIsDrawerOpen} />}
            </Grid>
        </Grid>
    );

    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.drawerContainer}>
                <Drawer variant="permanent"
                    className={drawerClasses}
                    PaperProps={{ style: drawerStyle }}
                    style={drawerStyle}>
                    <Toolbar />
                    {/* Hide dragger for small screen size / closed drawer. */}
                    {isDrawerOpen && (
                        <><Hidden xsDown>{dragger}</Hidden>
                        <Fab aria-label="add"
                            className={classes.fabDoc}
                            color="secondary"
                            component={Link}
                            to="/worship/documents/create">
                            <AddIcon />
                        </Fab></> 
                    )}
                    {drawer}
                </Drawer>
                <main className={classes.main}>
                    {/* Hide document for small screen & closed drawer. */}
                    <Hidden xsDown>
                        <Document
                            id={urlState.documentId}
                            version={urlState.documentVersion}
                            setDocumentVersion={setDocumentVersion} />
                    </Hidden>
                    <Hidden smUp>
                        {!isDrawerOpen && (
                            <Document
                                id={urlState.documentId}
                                version={urlState.documentVersion}
                                setDocumentVersion={setDocumentVersion} />
                        )}
                    </Hidden>
                </main>
            </div>
        </MuiThemeProvider>
    );
}