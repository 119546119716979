{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-02-27",
    "endpointPrefix": "pi",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "AWS PI",
    "serviceFullName": "AWS Performance Insights",
    "serviceId": "PI",
    "signatureVersion": "v4",
    "signingName": "pi",
    "targetPrefix": "PerformanceInsightsv20180227",
    "uid": "pi-2018-02-27"
  },
  "operations": {
    "DescribeDimensionKeys": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceType",
          "Identifier",
          "StartTime",
          "EndTime",
          "Metric",
          "GroupBy"
        ],
        "members": {
          "ServiceType": {},
          "Identifier": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Metric": {},
          "PeriodInSeconds": {
            "type": "integer"
          },
          "GroupBy": {
            "shape": "S6"
          },
          "AdditionalMetrics": {
            "type": "list",
            "member": {}
          },
          "PartitionBy": {
            "shape": "S6"
          },
          "Filter": {
            "shape": "Sa"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AlignedStartTime": {
            "type": "timestamp"
          },
          "AlignedEndTime": {
            "type": "timestamp"
          },
          "PartitionKeys": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Dimensions"
              ],
              "members": {
                "Dimensions": {
                  "shape": "Sg"
                }
              }
            }
          },
          "Keys": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Dimensions": {
                  "shape": "Sg"
                },
                "Total": {
                  "type": "double"
                },
                "AdditionalMetrics": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "double"
                  }
                },
                "Partitions": {
                  "type": "list",
                  "member": {
                    "type": "double"
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetDimensionKeyDetails": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceType",
          "Identifier",
          "Group",
          "GroupIdentifier"
        ],
        "members": {
          "ServiceType": {},
          "Identifier": {},
          "Group": {},
          "GroupIdentifier": {},
          "RequestedDimensions": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Dimensions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Value": {},
                "Dimension": {},
                "Status": {}
              }
            }
          }
        }
      }
    },
    "GetResourceMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceType",
          "Identifier"
        ],
        "members": {
          "ServiceType": {},
          "Identifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Identifier": {},
          "Features": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "Status": {}
              }
            }
          }
        }
      }
    },
    "GetResourceMetrics": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceType",
          "Identifier",
          "MetricQueries",
          "StartTime",
          "EndTime"
        ],
        "members": {
          "ServiceType": {},
          "Identifier": {},
          "MetricQueries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Metric"
              ],
              "members": {
                "Metric": {},
                "GroupBy": {
                  "shape": "S6"
                },
                "Filter": {
                  "shape": "Sa"
                }
              }
            }
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "PeriodInSeconds": {
            "type": "integer"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AlignedStartTime": {
            "type": "timestamp"
          },
          "AlignedEndTime": {
            "type": "timestamp"
          },
          "Identifier": {},
          "MetricList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {
                  "type": "structure",
                  "required": [
                    "Metric"
                  ],
                  "members": {
                    "Metric": {},
                    "Dimensions": {
                      "shape": "Sg"
                    }
                  }
                },
                "DataPoints": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Timestamp",
                      "Value"
                    ],
                    "members": {
                      "Timestamp": {
                        "type": "timestamp"
                      },
                      "Value": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListAvailableResourceDimensions": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceType",
          "Identifier",
          "Metrics"
        ],
        "members": {
          "ServiceType": {},
          "Identifier": {},
          "Metrics": {
            "type": "list",
            "member": {}
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MetricDimensions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Metric": {},
                "Groups": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Group": {},
                      "Dimensions": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Identifier": {}
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListAvailableResourceMetrics": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceType",
          "Identifier",
          "MetricTypes"
        ],
        "members": {
          "ServiceType": {},
          "Identifier": {},
          "MetricTypes": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Metrics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Metric": {},
                "Description": {},
                "Unit": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "structure",
      "required": [
        "Group"
      ],
      "members": {
        "Group": {},
        "Dimensions": {
          "type": "list",
          "member": {}
        },
        "Limit": {
          "type": "integer"
        }
      }
    },
    "Sa": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sg": {
      "type": "map",
      "key": {},
      "value": {}
    }
  }
}