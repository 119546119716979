{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-07-13",
    "endpointPrefix": "emr-serverless",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "EMR Serverless",
    "serviceId": "EMR Serverless",
    "signatureVersion": "v4",
    "signingName": "emr-serverless",
    "uid": "emr-serverless-2021-07-13"
  },
  "operations": {
    "CancelJobRun": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{applicationId}/jobruns/{jobRunId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "jobRunId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "jobRunId": {
            "location": "uri",
            "locationName": "jobRunId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "applicationId",
          "jobRunId"
        ],
        "members": {
          "applicationId": {},
          "jobRunId": {}
        }
      },
      "idempotent": true
    },
    "CreateApplication": {
      "http": {
        "requestUri": "/applications",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "releaseLabel",
          "type",
          "clientToken"
        ],
        "members": {
          "name": {},
          "releaseLabel": {},
          "type": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "initialCapacity": {
            "shape": "Sa"
          },
          "maximumCapacity": {
            "shape": "Si"
          },
          "tags": {
            "shape": "Sj"
          },
          "autoStartConfiguration": {
            "shape": "Sm"
          },
          "autoStopConfiguration": {
            "shape": "So"
          },
          "networkConfiguration": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "applicationId",
          "arn"
        ],
        "members": {
          "applicationId": {},
          "name": {},
          "arn": {}
        }
      },
      "idempotent": true
    },
    "DeleteApplication": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{applicationId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetApplication": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {
            "shape": "S11"
          }
        }
      }
    },
    "GetDashboardForJobRun": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/jobruns/{jobRunId}/dashboard",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "jobRunId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "jobRunId": {
            "location": "uri",
            "locationName": "jobRunId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "url": {}
        }
      }
    },
    "GetJobRun": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/jobruns/{jobRunId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "jobRunId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "jobRunId": {
            "location": "uri",
            "locationName": "jobRunId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobRun"
        ],
        "members": {
          "jobRun": {
            "type": "structure",
            "required": [
              "applicationId",
              "jobRunId",
              "arn",
              "createdBy",
              "createdAt",
              "updatedAt",
              "executionRole",
              "state",
              "stateDetails",
              "releaseLabel",
              "jobDriver"
            ],
            "members": {
              "applicationId": {},
              "jobRunId": {},
              "name": {},
              "arn": {},
              "createdBy": {},
              "createdAt": {
                "type": "timestamp"
              },
              "updatedAt": {
                "type": "timestamp"
              },
              "executionRole": {},
              "state": {},
              "stateDetails": {},
              "releaseLabel": {},
              "configurationOverrides": {
                "shape": "S1f"
              },
              "jobDriver": {
                "shape": "S1p"
              },
              "tags": {
                "shape": "Sj"
              },
              "totalResourceUtilization": {
                "type": "structure",
                "members": {
                  "vCPUHour": {
                    "type": "double"
                  },
                  "memoryGBHour": {
                    "type": "double"
                  },
                  "storageGBHour": {
                    "type": "double"
                  }
                }
              },
              "networkConfiguration": {
                "shape": "Sq"
              },
              "totalExecutionDurationSeconds": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "ListApplications": {
      "http": {
        "method": "GET",
        "requestUri": "/applications",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "states": {
            "location": "querystring",
            "locationName": "states",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "applications"
        ],
        "members": {
          "applications": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "arn",
                "releaseLabel",
                "type",
                "state",
                "createdAt",
                "updatedAt"
              ],
              "members": {
                "id": {},
                "name": {},
                "arn": {},
                "releaseLabel": {},
                "type": {},
                "state": {},
                "stateDetails": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListJobRuns": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/jobruns",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "createdAtAfter": {
            "location": "querystring",
            "locationName": "createdAtAfter",
            "type": "timestamp"
          },
          "createdAtBefore": {
            "location": "querystring",
            "locationName": "createdAtBefore",
            "type": "timestamp"
          },
          "states": {
            "location": "querystring",
            "locationName": "states",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobRuns"
        ],
        "members": {
          "jobRuns": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "applicationId",
                "id",
                "arn",
                "createdBy",
                "createdAt",
                "updatedAt",
                "executionRole",
                "state",
                "stateDetails",
                "releaseLabel"
              ],
              "members": {
                "applicationId": {},
                "id": {},
                "name": {},
                "arn": {},
                "createdBy": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "updatedAt": {
                  "type": "timestamp"
                },
                "executionRole": {},
                "state": {},
                "stateDetails": {},
                "releaseLabel": {},
                "type": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sj"
          }
        }
      }
    },
    "StartApplication": {
      "http": {
        "requestUri": "/applications/{applicationId}/start",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "StartJobRun": {
      "http": {
        "requestUri": "/applications/{applicationId}/jobruns",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "clientToken",
          "executionRoleArn"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "executionRoleArn": {},
          "jobDriver": {
            "shape": "S1p"
          },
          "configurationOverrides": {
            "shape": "S1f"
          },
          "tags": {
            "shape": "Sj"
          },
          "executionTimeoutMinutes": {
            "type": "long"
          },
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "applicationId",
          "jobRunId",
          "arn"
        ],
        "members": {
          "applicationId": {},
          "jobRunId": {},
          "arn": {}
        }
      },
      "idempotent": true
    },
    "StopApplication": {
      "http": {
        "requestUri": "/applications/{applicationId}/stop",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateApplication": {
      "http": {
        "method": "PATCH",
        "requestUri": "/applications/{applicationId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "clientToken"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "initialCapacity": {
            "shape": "Sa"
          },
          "maximumCapacity": {
            "shape": "Si"
          },
          "autoStartConfiguration": {
            "shape": "Sm"
          },
          "autoStopConfiguration": {
            "shape": "So"
          },
          "networkConfiguration": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {
            "shape": "S11"
          }
        }
      }
    }
  },
  "shapes": {
    "Sa": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "workerCount"
        ],
        "members": {
          "workerCount": {
            "type": "long"
          },
          "workerConfiguration": {
            "type": "structure",
            "required": [
              "cpu",
              "memory"
            ],
            "members": {
              "cpu": {},
              "memory": {},
              "disk": {}
            }
          }
        }
      }
    },
    "Si": {
      "type": "structure",
      "required": [
        "cpu",
        "memory"
      ],
      "members": {
        "cpu": {},
        "memory": {},
        "disk": {}
      }
    },
    "Sj": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sm": {
      "type": "structure",
      "members": {
        "enabled": {
          "type": "boolean"
        }
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "enabled": {
          "type": "boolean"
        },
        "idleTimeoutMinutes": {
          "type": "integer"
        }
      }
    },
    "Sq": {
      "type": "structure",
      "members": {
        "subnetIds": {
          "type": "list",
          "member": {}
        },
        "securityGroupIds": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S11": {
      "type": "structure",
      "required": [
        "applicationId",
        "arn",
        "releaseLabel",
        "type",
        "state",
        "createdAt",
        "updatedAt"
      ],
      "members": {
        "applicationId": {},
        "name": {},
        "arn": {},
        "releaseLabel": {},
        "type": {},
        "state": {},
        "stateDetails": {},
        "initialCapacity": {
          "shape": "Sa"
        },
        "maximumCapacity": {
          "shape": "Si"
        },
        "createdAt": {
          "type": "timestamp"
        },
        "updatedAt": {
          "type": "timestamp"
        },
        "tags": {
          "shape": "Sj"
        },
        "autoStartConfiguration": {
          "shape": "Sm"
        },
        "autoStopConfiguration": {
          "shape": "So"
        },
        "networkConfiguration": {
          "shape": "Sq"
        }
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "applicationConfiguration": {
          "shape": "S1g"
        },
        "monitoringConfiguration": {
          "type": "structure",
          "members": {
            "s3MonitoringConfiguration": {
              "type": "structure",
              "members": {
                "logUri": {},
                "encryptionKeyArn": {}
              }
            },
            "managedPersistenceMonitoringConfiguration": {
              "type": "structure",
              "members": {
                "enabled": {
                  "type": "boolean"
                },
                "encryptionKeyArn": {}
              }
            }
          }
        }
      }
    },
    "S1g": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "classification"
        ],
        "members": {
          "classification": {},
          "properties": {
            "type": "map",
            "key": {},
            "value": {},
            "sensitive": true
          },
          "configurations": {
            "shape": "S1g"
          }
        }
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "sparkSubmit": {
          "type": "structure",
          "required": [
            "entryPoint"
          ],
          "members": {
            "entryPoint": {
              "type": "string",
              "sensitive": true
            },
            "entryPointArguments": {
              "type": "list",
              "member": {
                "type": "string",
                "sensitive": true
              }
            },
            "sparkSubmitParameters": {
              "type": "string",
              "sensitive": true
            }
          }
        },
        "hive": {
          "type": "structure",
          "required": [
            "query"
          ],
          "members": {
            "query": {
              "type": "string",
              "sensitive": true
            },
            "initQueryFile": {
              "type": "string",
              "sensitive": true
            },
            "parameters": {
              "type": "string",
              "sensitive": true
            }
          }
        }
      },
      "union": true
    }
  }
}