import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {deleteSong, getSongContents, getSongMetadata} from "../../../api/song";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {makeStyles} from "@material-ui/core/styles";
import {LoadingBar} from "../shared/Loading";
import {Auth} from "../../core/Auth";

import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";
import { getUser } from "../../../api/directory";

const songStyles = makeStyles({
    songContent: {
        marginTop: "2em",
    },
    grid: {
        marginTop: "2em"
    },
    input: {
        fontSize: 14,
        lineHeight: 2, 
        color: "black"
    },
})

const CLOSING_TIME = 1000;

function RenderLangDropdown(props: any) {
    const handleChange = (event: any) => {
        props.setLanguage(event.target.value.language);
        props.setSongContent(event.target.value);
    }
    return (
        <FormControl size="small">
            <InputLabel>Language</InputLabel>
            <Select
                value={props.songContents.find((songContent: any) => songContent.language === props.language) || ''}
                onChange={handleChange}
                label="Language"
            >
                { props.songContents.map((songContent: any, index: any) =>
                    {
                    return (
                    <MenuItem
                        key={index}
                        value={songContent}
                    >
                        {songContent.language}
                    </MenuItem>)}
                )}
            </Select>
        </FormControl>
    );
}

function RenderSong({
    songContent,
    songMetadata
}: any) {
    if (songContent && songMetadata) {
        return (
            <RenderSongContent
                songContent={songContent}
                songMetadata={songMetadata}
            />
        )
    } else {
        return null;
    }
}

function RenderSongContent(props: any) {
    const styles = songStyles()

    return (
        <div className={styles.songContent}>
            <Typography variant="h5" gutterBottom>
                {props.songContent.title}
                <CopyButton label="title" content={props.songContent.title} />
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">Lyrics By</Typography>
                    <Typography variant="body1" gutterBottom>
                        {props.songMetadata.lyricsComposer}
                        <CopyButton label="lyrics" content={props.songMetadata.lyricsComposer} /> 
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">Music By</Typography>
                    <Typography variant="body1" gutterBottom>
                        {props.songMetadata.musicComposer}
                        <CopyButton label="lyrics" content={props.songMetadata.musicComposer} />
                    </Typography>
                </Grid>
            </Grid>

            <hr />

            <Typography variant="subtitle1">
                <b>Lyrics</b>
                <CopyButton label="lyrics" content={props.songContent.lyrics} /> 
            </Typography>
            <TextField
                fullWidth
                inputProps={{className: styles.input}}
                InputProps={{disableUnderline: true}}
                multiline
                disabled
                value={props.songContent.lyrics}
            />

            <hr />

            <Grid container spacing={2}>
                {props.songMetadata.scriptureReference && 
                    <Grid item xs={12} sm={6}>
                        <Typography variant="caption">Scriptures</Typography>
                        <Typography variant="body1" gutterBottom>
                            {props.songMetadata.scriptureReference}
                        </Typography>
                    </Grid>
                }
                {props.songMetadata.musicSheetLink && 
                    <Grid item xs={12} sm={6}>
                        <Typography variant="caption">Various Links</Typography>
                        <Typography variant="body1" gutterBottom>
                            <a href={props.songMetadata.musicSheetLink}
                                target="_blank">
                                Music Sheet
                            </a>
                        </Typography>
                    </Grid>
                }
            </Grid>
        </div>
    )
}

function CopyButton(props: any) {
    const [open, setOpen] = useState(false);
    const handleCopy = (content: any) => {
        navigator.clipboard.writeText(content)
        setOpen(true)
    }
    return (
        <Tooltip title={'Copy ' + props.label}>
            <IconButton size="small" onClick={() => handleCopy(props.content)} >
                <FileCopyIcon fontSize="small" />
                <Snackbar
                    open={open}
                    autoHideDuration={CLOSING_TIME}
                    message={`Copied ${props.label} to Clipboard`}
                    onClose={() => setOpen(false)}
                />
            </IconButton>
        </Tooltip>
    )
}

function RenderButton(props: any) {
    const styles = songStyles()
    let [openDelete, setOpenDelete] = useState(false);

    const handleClickDelete = () => {
        setOpenDelete(true);
    }

    const handleDeleteConfirm = async () => {
        const user = await getUser();
        let response = await deleteSong(props.id, user.email);
        if (response) {
            const url = new URL(window.location.origin + "/worship");
            window.location.assign(url);
        }   
    }

    const handleDeleteCancel = () => {
        setOpenDelete(false);
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={true}>
                    <RenderLangDropdown
                        setSongContent={props.setSongContent}
                        songContents={props.songContents}
                        language={props.language}
                        setLanguage={props.setLanguage}
                    />
                </Grid>
                <Grid item xs={12} sm='auto'>
                    <Button variant="contained"
                            component={Link}
                            to={"/worship/songs/update/" + props.id}
                            startIcon={<EditIcon />}>
                        Edit
                    </Button>
                </Grid>
                <Grid item xs={12} sm='auto'>
                    <Button variant="contained"
                            onClick={handleClickDelete}
                            startIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                </Grid>
            </Grid>
            <Dialog open={openDelete}
                    onClose={handleDeleteCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    You are about to delete a song!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteConfirm}>
                        Yes
                    </Button>
                    <Button onClick={handleDeleteCancel}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function RenderLoading(props: any) {
    return (
        <Grid container justifyContent="center">
            <LoadingBar inProgress={props.state} />
        </Grid>
    )
}

export function _Song(props: any) {
    const styles = songStyles()
    let [songMetadata, setSongMetadata] = useState(undefined);
    let [songs, setSongs] = useState([] as any[]);
    let [songContent, setSongContent] = useState({});
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [selectedLang, setSelectedLang] = useState('');

    useEffect(() => {
        async function getSongData() {
            setLoadingStatus(true)
            songMetadata = await getSongMetadata(props.selectedSongId);
            songs = await getSongContents(props.selectedSongId);
            setSongMetadata(songMetadata);
            setSongs(songs);
            if (songs.length !== 0) {
                setSelectedLang(songs[0].language);
                setSongContent(songs[0]);
            } else {
                setSongContent(songs[songs.findIndex((song) => song.language === selectedLang)]);
            }
            setLoadingStatus(false);
        }
        if (props.selectedSongId) getSongData();
    }, [props.selectedSongId])
    
    if (!props.selectedSongId) {
        return null;
    }
    return (
        <Container className={styles.songContent}>
            {
                loadingStatus ?
                    <RenderLoading state={loadingStatus} />
                : <>
                    <RenderButton
                        id={props.selectedSongId}
                        songContents={songs}
                        setSongContent={setSongContent}
                        language={selectedLang}
                        setLanguage={setSelectedLang}
                    />
                    <RenderSong
                        songContent={songContent}
                        songMetadata={songMetadata}
                    />
                </>
            }
        </Container>
    );
}

export const Song = _Song