{
  "pagination": {
    "ListJobs": {
      "input_token": "marker",
      "limit_key": "limit",
      "output_token": "Marker",
      "result_key": "JobList"
    },
    "ListMultipartUploads": {
      "input_token": "marker",
      "limit_key": "limit",
      "output_token": "Marker",
      "result_key": "UploadsList"
    },
    "ListParts": {
      "input_token": "marker",
      "limit_key": "limit",
      "output_token": "Marker",
      "result_key": "Parts"
    },
    "ListVaults": {
      "input_token": "marker",
      "limit_key": "limit",
      "output_token": "Marker",
      "result_key": "VaultList"
    }
  }
}