{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-10",
    "endpointPrefix": "controltower",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS Control Tower",
    "serviceId": "ControlTower",
    "signatureVersion": "v4",
    "signingName": "controltower",
    "uid": "controltower-2018-05-10"
  },
  "operations": {
    "DisableControl": {
      "http": {
        "requestUri": "/disable-control",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "controlIdentifier",
          "targetIdentifier"
        ],
        "members": {
          "controlIdentifier": {},
          "targetIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "operationIdentifier"
        ],
        "members": {
          "operationIdentifier": {}
        }
      }
    },
    "EnableControl": {
      "http": {
        "requestUri": "/enable-control",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "controlIdentifier",
          "targetIdentifier"
        ],
        "members": {
          "controlIdentifier": {},
          "targetIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "operationIdentifier"
        ],
        "members": {
          "operationIdentifier": {}
        }
      }
    },
    "GetControlOperation": {
      "http": {
        "requestUri": "/get-control-operation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "operationIdentifier"
        ],
        "members": {
          "operationIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "controlOperation"
        ],
        "members": {
          "controlOperation": {
            "type": "structure",
            "members": {
              "endTime": {
                "shape": "Sb"
              },
              "operationType": {},
              "startTime": {
                "shape": "Sb"
              },
              "status": {},
              "statusMessage": {}
            }
          }
        }
      }
    },
    "ListEnabledControls": {
      "http": {
        "requestUri": "/list-enabled-controls",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "targetIdentifier"
        ],
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "targetIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "enabledControls"
        ],
        "members": {
          "enabledControls": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "controlIdentifier": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    }
  },
  "shapes": {
    "Sb": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    }
  }
}